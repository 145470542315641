import '../../Help.css'

//import {OptionNav} from '../../../navigation/SimpleNavs'
//import {IntervalTagPopover, IntervalPopover} from '../PalantirTerminology'

export function HelpEventTemplateConfiguration(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">Event Template</div>
            <hr></hr>
            <p className="footnote">Click on a column header to view more detailed information on the corresponding field.</p>
            <hr></hr>
            <p>Event templates are attached to event blueprints. Each blueprint must attach to a single template.</p>
            <p>Event templates are not tied to a specific asset, so all templates are available to all sites.</p>
            <p>
                The event template defines the type, or meaning, of events created by the associated blueprint.
                The blueprint derives this information through the <i>code</i> and <i>description</i> fields.
            </p>
            <p>
                Optionally, a template defines a set of evaluation parameters that the attached blueprint has the option to inherit as its own.
                This is useful when applying a common event with the same evaluation parameters across multiple sites.
                More information on inheriting can be found by visiting the event blueprint <i>inherit</i> help panel.
            </p>
            <p className="header-2">Example</p>
            <p>
                An event template could define scenarios in which the capacity of a battery system is degraded.
                In which case an appropriate code would be 'BESS_CAPACITY_DEGRADED', and the description could provide some more detail.
            </p>
            <p>All events created by the attached blueprint would be defined as 'BESS_CAPACITY_DEGRADED' events.</p>
        </div>
    )
}

export function HelpEventBlueprintConfiguration(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">Event Blueprint</div>
            <hr></hr>
            <p>Event Blueprints</p>
        </div>
    )
}