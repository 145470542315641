import { buildDropdownItems, transformDisplayValue } from '../../utils/databaseAppUtils'
import { schemas } from '../Constants'
import Table from './BaseTable'
import { booleanOptions } from './CommonColumns'
import { ServiceProvider } from './Counterparty'
import { Counterparty } from './Entity'


const validateEquipmentSpec = (record, manufacturerCol, modelCol) => {

    if (!record[manufacturerCol]) return {pass: false, message: "Please specify a manufacturer"}
    if (!record[modelCol]) return {pass: false, message: "Please specify a model"}
    return {pass: true, message: null}
}

function makeEquipmentSpecId(key) {
    return {
        name: "ID",
        key: key,
        cellType: "readOnly",
        filterable: true,
        sortable: true,
        colProps: {
            width: 100,
            fixed: "left",
            resizable: true,
        },
    }
}

function getManufacturerColumnConfig(key) {
    return {
        name: "Manufacturer",
        key: key,
        cellType: "editable",
        filterable: true,
        sortable: true,
        colProps: {
            width: 200,
            resizable: true,
        },
    }
}
function getModelColumnConfig(key) {
    return {
        name: "Model",
        key: key,
        cellType: "editable",
        filterable: true,
        sortable: true,
        colProps: {
            width: 150,
            resizable: true,
        }
    }
}


class InverterSpecTable extends Table {

    constructor() {

        var columnSchema = {
            inverterSpecId: "inverter_spec_id",
            manufacturer: "manufacturer",
            model: "model",
            nominalRealPowerKw: "nominal_real_power__kw",
            nominalAppPowerKva: "nominal_app_power__kva",
            type: "type",
            outputVoltageKv: "output_voltage__kv",
            minMpptVoltageV: "min_mppt_voltage__v",
            maxMpptVoltageV: "max_mppt_voltage__v",
            minOpAmbTempC: "min_op_amb_temp__c",
            maxOpAmbTempC: "max_op_amb_temp__c",
            stdOpElevationM: "std_op_elevation__m",
            maxOpElevationM: "max_op_elevation__m",
            derateTemp1C: "derate_temp_1__c",
            derateTemp2C: "derate_temp_2__c",
            derateTemp3C: "derate_temp_3__c",
            derate1Kva: "derate_1__kva",
            nightCapability: "night_capability",
            numMppts: "num_mppts",
            mvtImpedance: "mvt_impedance",
            stdMvtWinding: "std_mvt_winding",
            plantPredictId: "plant_predict_id",
            avlcApproved: "avlc_approved",
            ondFileName: "ond_file_name",
            testingCounterpartyId: "testing_counterparty_id",
        }

        var schemaName = schemas.equipmentSpec
        var tableName = "inverter"

        var displayNameSingular = "Inverter"
        var displayNamePlural = "Inverters"

        var pkUidColumn = columnSchema.inverterSpecId
        var identifiers = [columnSchema.inverterSpecId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
        this.allowProjectDisplay = false
    }

    initOptions() {
        this.options = {
            type: [{
                label: "Central", value: "Central"
            }, {
                label: "String", value: "String"
            }]
        }
    }

    getIdCol() {
        return this.columnSchema.inverterSpecId
    }

    buildTableProps = (data) => {

        var manufacturerCol = this.columnSchema.manufacturer
        var modelCol = this.columnSchema.model
        var validateInverterSpec = (inverterSpecRecord) => validateEquipmentSpec(inverterSpecRecord, manufacturerCol, modelCol)

        const counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        const counterpartyIdCol = Counterparty.columnSchema.counterpartyId
        const counterpartyNameCol = Counterparty.columnSchema.counterpartyName

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateInverterSpec,
            validateUpdateItem: validateInverterSpec,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEquipmentSpecId(this.columnSchema.inverterSpecId),
                    getManufacturerColumnConfig(this.columnSchema.manufacturer), 
                    getModelColumnConfig(this.columnSchema.model), 
                    {
                        name: "Nominal Real Power (kW)",
                        key: this.columnSchema.nominalRealPowerKw,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Nominal App Power (kVa)",
                        key: this.columnSchema.nominalAppPowerKva,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Type",
                        key: this.columnSchema.type,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.type
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Output Voltage (kV)",
                        key: this.columnSchema.outputVoltageKv,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Min MPPT Voltage (V)",
                        key: this.columnSchema.minMpptVoltageV,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Max MPTT Voltage (V)",
                        key: this.columnSchema.maxMpptVoltageV,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Min Operating Amb Temp (C)",
                        key: this.columnSchema.minOpAmbTempC,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Max Operating Amb Temp (C)",
                        key: this.columnSchema.maxOpAmbTempC,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Std Operating Elevation (m)",
                        key: this.columnSchema.stdOpElevationM,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Max Operating Elevation (m)",
                        key: this.columnSchema.maxOpElevationM,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Derate Temp 1 (C)",
                        key: this.columnSchema.derateTemp1C,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Derate Temp 2 (C)",
                        key: this.columnSchema.derateTemp2C,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Derate Temp 3 (C)",
                        key: this.columnSchema.derateTemp3C,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Derate 1 (kVA)",
                        key: this.columnSchema.derate1Kva,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Night Capability",
                        key: this.columnSchema.nightCapability,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Num MPPTs",
                        key: this.columnSchema.numMppts,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "MVT Impedance",
                        key: this.columnSchema.mvtImpedance,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Std MVT Winding",
                        key: this.columnSchema.stdMvtWinding,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Plant Predict ID",
                        key: this.columnSchema.plantPredictId,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "AVLC Approved",
                        key: this.columnSchema.avlcApproved,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "OND File Name",
                        key: this.columnSchema.ondFileName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Testing Counterparty",
                        key: this.columnSchema.testingCounterpartyId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(counterpartyRecords, value, counterpartyIdCol, counterpartyNameCol),
                            dropdownItems: buildDropdownItems(counterpartyRecords, counterpartyIdCol, counterpartyNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}

export const InverterSpec = new InverterSpecTable()

class ModuleSpecTable extends Table {

    constructor() {

        var columnSchema = {
            moduleSpecId: "module_spec_id",
            manufacturer: "manufacturer",
            model: "model",
            pMaxW: "pmax__w",
            vmpV: "vmp__v",
            vocV: "voc__v",
            impA: "imp__a",
            iscA: "isc__a",
            efficiencyPct: "efficiency__pct",
            degradationFactorManufacturer: "degradation_factor_manufacturer",
            degradationFactorInternal: "degradation_factor_internal",
            bifacialityFactorManufacturer: "bifaciality_factor_manufacturer",
            bifacialityFactorUncertaintyManufacturer: "bifaciality_factor_uncertainty_manufacturer",
            bifacialityFactorInternal: "bifaciality_factor_internal",
            pmppTempCoeffPct: "pmpp_temp_coeff__pct",
            vocTempCoeffPct: "voc_temp_coeff__pct",
            iscTempCoeffPct: "isc_temp_coeff__pct",
            numCells: "num_cells",
            lengthMm: "length__mm",
            widthMm: "width__mm",
            thicknessMm: "thickness__mm",
            weightKg: "weight__kg",
            cellType: "cell_type",
            avlcApproved: "avlc_approved",
            plantPredictId: "plant_predict_id",
            panFileName: "pan_file_name",
            testingCounterpartyId: "testing_counterparty_id",
        }

        var schemaName = schemas.equipmentSpec
        var tableName = "module"

        var displayNameSingular = "Module"
        var displayNamePlural = "Modules"

        var pkUidColumn = columnSchema.moduleSpecId
        var identifiers = [columnSchema.moduleSpecId]
        var dependencies = [Counterparty]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
        this.allowProjectDisplay = false
    }

    initOptions() {
        this.options = {
            cellType: [{
                label: "Si-Poly", value: "Si-Poly"
            }, {
                label: "Si-Mono", value: "Si-Mono"
            }, {
                label: "CdTe", value: "CdTe"
            }]
        }
    }

    getIdCol() {
        return this.columnSchema.moduleSpecId
    }

    buildTableProps = (data) => {

        var manufacturerCol = this.columnSchema.manufacturer
        var modelCol = this.columnSchema.model
        var validateModuleSpec = (moduleSpecRecord) => validateEquipmentSpec(moduleSpecRecord, manufacturerCol, modelCol)

        const counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        const counterpartyIdCol = Counterparty.columnSchema.counterpartyId
        const counterpartyNameCol = Counterparty.columnSchema.counterpartyName

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateModuleSpec,
            validateUpdateItem: validateModuleSpec,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEquipmentSpecId(this.columnSchema.moduleSpecId),
                    getManufacturerColumnConfig(this.columnSchema.manufacturer), 
                    getModelColumnConfig(this.columnSchema.model),
                    {
                        name: "P Max (W)",
                        key: this.columnSchema.pMaxW,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Vmp (V)",
                        key: this.columnSchema.vmpV,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Voc (V)",
                        key: this.columnSchema.vocV,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Imp (A)",
                        key: this.columnSchema.impA,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Isc (A)",
                        key: this.columnSchema.iscA,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Efficiency (%)",
                        key: this.columnSchema.efficiencyPct,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Manufacturer Degradation Factor",
                        key: this.columnSchema.degradationFactorManufacturer,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Internal Degradation Factor",
                        key: this.columnSchema.degradationFactorInternal,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Manufacturer Bifaciality Factor",
                        key: this.columnSchema.bifacialityFactorManufacturer,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Manufacturer Bifaciality Facturer Uncertainty",
                        key: this.columnSchema.bifacialityFactorUncertaintyManufacturer,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Internal Bifaciality Factor",
                        key: this.columnSchema.bifacialityFactorInternal,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Pmpp Temp Coeff (%/C)",
                        key: this.columnSchema.pmppTempCoeffPct,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Voc Temp Coeff (%/C)",
                        key: this.columnSchema.vocTempCoeffPct,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Isc Temp Coeff (%/C)",
                        key: this.columnSchema.iscTempCoeffPct,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Num Cells",
                        key: this.columnSchema.numCells,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Length (mm)",
                        key: this.columnSchema.lengthMm,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Width (mm)",
                        key: this.columnSchema.widthMm,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Thickness (mm)",
                        key: this.columnSchema.thicknessMm,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Weight (kg)",
                        key: this.columnSchema.weightKg,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Cell Type",
                        key: this.columnSchema.cellType,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.cellType
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "AVLC Approved",
                        key: this.columnSchema.avlcApproved,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Plant Predict ID",
                        key: this.columnSchema.plantPredictId,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "PAN File Name",
                        key: this.columnSchema.panFileName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Testing Counterparty",
                        key: this.columnSchema.testingCounterpartyId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(counterpartyRecords, value, counterpartyIdCol, counterpartyNameCol),
                            dropdownItems: buildDropdownItems(counterpartyRecords, counterpartyIdCol, counterpartyNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}

export const ModuleSpec = new ModuleSpecTable()

class TransformerSpecTable extends Table {

    constructor() {

        var columnSchema = {
            transformerSpecId: "transformer_spec_id",
            manufacturer: "manufacturer",
            transformerType: "transformer_type",
            ratingRealMV: "rating_real__mv",
            ratingReactiveMVAR: "rating_reactive__mvar",
            ratingApparentMVA: "rating_apparent__mva",
            windingsRealkV: "windings_real__kv",
            windingsReactivekVAR: "windings_reactive__kvar",
            windingsApparentkVA: "windings_apparent__kva",
            impedancePercent: "impedance__percent",
            XRRatio: "xr_ratio"
        }

        var schemaName = schemas.equipmentSpec
        var tableName = "transformer"

        var displayNameSingular = "Transformer"
        var displayNamePlural = "Transformers"

        var pkUidColumn = columnSchema.transformerSpecId
        var identifiers = [columnSchema.transformerSpecId]
        var dependencies = []
        
        super(
            schemaName, tableName,
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        
        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            transformerType: [{
                label: "MVT", value: "Medium-Voltage Transformer"
            }, {
                label: "GSU", value: "Generator Step-Up Transformer"
            }]
        }
    }

    buildTableProps = (data) => {

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEquipmentSpecId(this.columnSchema.transformerSpecId),
                    getManufacturerColumnConfig(this.columnSchema.manufacturer), 
                    {
                        name: "Transformer Type",
                        key: this.columnSchema.transformerType,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.transformerType
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Rating Real (MV)",
                        key: this.columnSchema.ratingRealMV,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Rating Reactive (MVAR)",
                        key: this.columnSchema.ratingReactiveMVAR,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Rating Apparent (MVA)",
                        key: this.columnSchema.ratingApparentMVA,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Windings Real (MV)",
                        key: this.columnSchema.windingsRealkV,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Windings Reactive (MVAR)",
                        key: this.columnSchema.windingsReactivekVAR,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Windings Apparent (MVA)",
                        key: this.columnSchema.windingsApparentkVA,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Impedance (%)",
                        key: this.columnSchema.impedancePercent,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "X/R Ratio",
                        key: this.columnSchema.XRRatio,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}

export const TransformerSpec = new TransformerSpecTable()

class BESSSpecTable extends Table {

    constructor() {

        var columnSchema = {
            bessSpecId: "bess_spec_id",
            manufacturer: "manufacturer",
            model: "model",
            chemistry: "chemistry",
            energyCapacity: "energy_capacity__kwh",
            powerCapacity: "power_capacity__kva",
        }

        var schemaName = schemas.equipmentSpec
        var tableName = "bess"

        var displayNameSingular = "BESS"
        var displayNamePlural = "BESS"

        var pkUidColumn = columnSchema.bessSpecId
        var identifiers = [columnSchema.bessSpecId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        
        this.allowProjectDisplay = false
    }

    getIdCol() {
        return this.columnSchema.bessSpecId
    }

    buildTableProps = (data) => {

        var manufacturerCol = this.columnSchema.manufacturer
        var modelCol = this.columnSchema.model
        var validateBESSSpec = (bessSpecRecord) => validateEquipmentSpec(bessSpecRecord, manufacturerCol, modelCol)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateBESSSpec,
            validateUpdateItem: validateBESSSpec,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEquipmentSpecId(this.columnSchema.bessSpecId),
                    getManufacturerColumnConfig(this.columnSchema.manufacturer), 
                    getModelColumnConfig(this.columnSchema.model), 
                    {
                        name: "Chemistry",
                        key: this.columnSchema.chemistry,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Energy Capacity (kWh)",
                        key: this.columnSchema.energyCapacity,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Power Capacity (kVa)",
                        key: this.columnSchema.powerCapacity,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const BESSSpec = new BESSSpecTable()

class TrackerSpecTable extends Table {

    constructor() {

        var columnSchema = {
            trackerSpecId: "tracker_spec_id",
            manufacturer: "manufacturer",
            model: "model",
        }

        var schemaName = schemas.equipmentSpec
        var tableName = "tracker"

        var displayNameSingular = "Tracker"
        var displayNamePlural = "Trackers"

        var pkUidColumn = columnSchema.trackerSpecId
        var identifiers = [columnSchema.trackerSpecId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        
        this.allowProjectDisplay = false
    }

    getIdCol() {
        return this.columnSchema.trackerSpecId
    }

    getName(r) {
        return r[this.columnSchema.manufacturer] + " - " + r[this.columnSchema.model]
    }

    buildTableProps = (data) => {

        var manufacturerCol = this.columnSchema.manufacturer
        var modelCol = this.columnSchema.model
        var validateTrackerSpec = (trackerSpecRecord) => validateEquipmentSpec(trackerSpecRecord, manufacturerCol, modelCol)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateTrackerSpec,
            validateUpdateItem: validateTrackerSpec,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEquipmentSpecId(this.columnSchema.trackerSpecId),
                    getManufacturerColumnConfig(this.columnSchema.manufacturer), 
                    getModelColumnConfig(this.columnSchema.model)
                ]
            },
        }
    }

}
export const TrackerSpec = new TrackerSpecTable()