import { BusinessCategory, LessonLearned, LessonLearnedInstances, OpportunityRegister, Plant, RiskRegister, TemporalProjectPricingBlocks, TemporalProjectPricingPrices, TemporalProjectPricingTemplate, TemporalProjectPricingType } from "../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from "../../components/input/Text"
import { useChangeLog } from "../../hooks/changeLog"
import { useDBViewFormValidation } from "../../hooks/databaseViewFormValidation"
import ViewPanel from "./ViewPanel"
import { generateUUID } from "../../utils/databaseAppUtils"
import { Card, CardHeaderWithMenu } from "../../components/panelv2/Panel2"
import { useState } from "react"
import { DialogProvider, useDialog } from "../../components/dialog/DialogProvider"
import { DialogContentText } from "@mui/material"
import { PalantirBasicCRUDMenu } from "../../components/menuv2/Menu"
import _ from 'lodash'
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from "../../components/button/FloaterButtonWithPrompt"
import { Report, ReportComment, ReportCommentTopic, ReportRelevantCommentTopics, ReportType } from "../table_configuration/Reporting"


export const DevelopmentReportCommentsPanel = (props) => {
    
    const reportTypeName = "Development"
    const assetAssociationType = "project.plant"

    return (
        <ReportCommentsPanelProxy
            reportTypeName={reportTypeName}
            assetAssociationType={assetAssociationType}
            {...props}
        />
    )
}

export const ConstructionReportCommentsPanel = (props) => {
    
    const reportTypeName = "Construction"
    const assetAssociationType = "project.plant"

    return (
        <ReportCommentsPanelProxy
            reportTypeName={reportTypeName}
            assetAssociationType={assetAssociationType}
            {...props}
        />
    )
}


function ReportCommentsPanelProxy(props) {

    const reportType = props.data[ReportType.buildId()].find(x => x[ReportType.columnSchema.name]===props.reportTypeName)
    if (!reportType) {
        return (
            <div>
                No report type named '{props.reportTypeName}' exists.
            </div>
        )
    }
    const reportTypeId = reportType[ReportType.columnSchema.id]
    const reports = props.data[Report.buildId()].filter(x => x[Report.columnSchema.reportTypeId]===reportTypeId)
    const reportIds = reports.map(x => x[Report.columnSchema.id])

    const reportRelevantCommentTopicIds = props.data[ReportRelevantCommentTopics.buildId()].filter(x => x[ReportRelevantCommentTopics.columnSchema.reportTypeId]===reportTypeId).map(x => x[ReportRelevantCommentTopics.columnSchema.reportCommentTopicId])
    const reportCommentTopics = props.data[ReportCommentTopic.buildId()].filter(x => reportRelevantCommentTopicIds.includes(x[ReportCommentTopic.columnSchema.id]))
    const reportComments = props.data[ReportComment.buildId()].filter(x => (x[ReportComment.columnSchema.assetAssociationTable]===props.assetAssociationType && x[ReportComment.columnSchema.assetAssociationId]===props.selectedPlantId) && reportIds.includes(x[ReportComment.columnSchema.reportId]))
    console.log(reportComments)

    return (
        <DialogProvider>
            <LessonsLearnedPanel
                reports={reports}
                reportCommentTopics={reportCommentTopics}
                initialReportComments={reportComments}
                {...props}
            />
        </DialogProvider>
    )
}

function LessonsLearnedPanel({initialReportComments, reports, reportCommentTopics, ...props}) {

    const [
        comments, prepareCommentsLog, updateCommentsLog, addToCommentsLog,
        deleteFromCommentsLog, bulkOpOnCommentsLog, mergeAndResetCommentsLog, resetCommentsLog, popFromCommentsLog
    ] = useChangeLog(initialReportComments, ReportComment)
    
    const [selectedReportId, setSelectedReportId] = useState(null)
    const selectedReport = selectedReportId ? reports.find(x => x[Report.columnSchema.id]===selectedReportId) : null

    const reportComments = comments.filter(x => x[ReportComment.columnSchema.reportId]===selectedReportId)

    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()
    const [openDialog, closeDialog] = useDialog();


    const updateCommentsLogProxy = (comment, update) => updateCommentsLog(comment, update)
    /* Functions to create a new lesson */
    const addToCommentsLogProxy = (topicId) => {
        const newComment = ReportComment.buildNewRecord()
        const commentId = generateUUID()
        newComment[ReportComment.columnSchema.id] = commentId
        newComment[ReportComment.columnSchema.reportId] = selectedReportId
        newComment[ReportComment.columnSchema.topicId] = topicId
        newComment[ReportComment.columnSchema.assetAssociationTable] = props.assetAssociationType
        newComment[ReportComment.columnSchema.assetAssociationId] = props.selectedPlantId

        addToCommentsLog(newComment)
    }

    const selectReport = (record) => {
        const reportId = record[ReportComment.columnSchema.id]
        if (reportId===selectedReportId) return
        tryPurgeChanges(prepareCommentsLog(), () => setSelectedReportId(reportId))
    }
    const tryPurgeChanges = (log1, callback) => {
        const purge = () => {
            if (callback) callback()
            resetCommentsLog()
            resetErrors()
        }
        if (log1.length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    purge()
                }
            });
        }
        else {
            purge()
        }
    }

    return (
        <div className="flow-horizontal fill-parent" style={{overflow: "auto"}}>
            <PalantirBasicCRUDMenu
                title="Reports"
                selectedId={selectedReportId}
                records={_.reverse(_.sortBy(reports, (x) => new Date(x[Report.columnSchema.datasetDate])))}
                recordIdCol={Report.columnSchema.id}
                recordNameCol={Report.columnSchema.name}
                onSelect={selectReport}
                isCreateActive={false}
                defaultSort={false}
                style={{marginRight: "20px", marginTop: "25px", width: "225px", flexShrink: 0}}
            />
            <ViewPanel
                services={props.services}
                submitUrl='api/precious/table/report_comments'
                verifySubmit={(payload) => {
                    return verifyChangelogSubmission(
                        {
                            changeLog: payload.reportCommentsChangeLog,
                            checks: []
                        }
                    )
                }}
                onSubmitSuccess={(response, requestPayload) => {
                    props.handleUpdate(false)
                    resetErrors()
                    mergeAndResetCommentsLog()
                }}
                showDelete={false}
                onSubmitError={null}
                buildSubmitPayload={() => {
                    return {
                        reportCommentsChangeLog: prepareCommentsLog()
                    }
                }}
                style={{minWidth: "400px"}}
            >
                {selectedReport ?
                    <ReportCommentsForm
                        key={selectedReportId}
                        report={selectedReport}
                        selectedProject={props.selectedPlant}

                        comments={reportComments}
                        relevantCommentTopics={reportCommentTopics}
                        
                        updateComment={updateCommentsLogProxy}
                        addComment={addToCommentsLogProxy}
                        errors={errors}
                        removeErrors={removeErrors}
                    /> :
                    <div className="flow-vertical" style={{textAlign: "center"}}>Please select a Report.</div>
                }
            </ViewPanel>
        </div>
    )
}

const ReportCommentsForm = ({report, comments, relevantCommentTopics, updateComment, addComment, errors, removeErrors}) => {

    const commentComponents = relevantCommentTopics.map(topic => {
        const topicId = topic[ReportRelevantCommentTopics.columnSchema.id]
        // DB only allows one comment per report/topic/asset combination, so max of one comment is assured here
        const comment = comments.find(x => x[ReportComment.columnSchema.topicId]===topicId)
        return (
            <Card style={{marginBottom: "15px"}}>
                <CardHeaderWithMenu
                    label={topic.name}
                />
                {comment ?
                    <PalantirDispatchedTextField
                        label="Comment"
                        value={comment[ReportComment.columnSchema.content]}
                        onChange={(x) => updateComment(comment, {[ReportComment.columnSchema.content]: x})}
                        multiline
                        minRows={2}
                        maxRows={7}
                        variant="filled"
                        style={{flexGrow: 1, minWidth: "250px", margin: "0 15px 20px 15px"}}
                    />
                    :
                    <AddFloaterButtonWithPrompt
                        onClick={() => addComment(topicId)}
                        labelContent="Add comment"
                        style={{marginLeft: "22px", marginBottom: "15px"}}
                    />
                }
                
            </Card>
        )
    })

    return (
        <div className="body flow-vertical">
            {commentComponents}
        </div>
    )
}