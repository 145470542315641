import { schemas } from '../Constants'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import Table from './BaseTable'
import { booleanOptions, makeEntityId, makePlantId } from './CommonColumns'
import { Fund, Portfolio, Employee, Counterparty, Project } from './Entity'
import { PlantLLC } from './LLC'
import { injectCounterpartyName, AssetManager, Owner } from './Counterparty'
import { timezones } from '../../utils/timezones'
import { faMedal, fa1, fa2, fa3 } from '@fortawesome/free-solid-svg-icons'
import { BESSSpec, InverterSpec, ModuleSpec, TrackerSpec } from './EquipmentSpec'
import { formatDate, formatDateDisplay } from '../../utils/Dates'


class PlantTable extends Table {

    constructor() {

        var columnSchema = {
            plantId: "plant_id",
            plantName: "plant_name",
            llcId: "llc_id",
            fundId: "fund_id",
            portfolioId: "portfolio_id",
            subPortfolioName: "sub_portfolio_name",
            stage: "stage",
            developmentSubstage: "development_substage",
            CPsNeededInPPA: "condition_precedents_needed_in_ppa",
            stageAcquired: "stage_acquired",
            acquisitionDate: "acquisition_date",
            soldDate: "sold_date",
            writeOffDate: "write_off_date",
            plannedCommercialOperationDate: "planned_commercial_operation_date",
            commercialOperationDate: "commercial_operation_date",
            targetCommercialOperationDate: "target_commercial_operation_date",
            baseCommercialOperationDate: "base_commercial_operation_date",
            downsideCommercialOperationDate: "downside_commercial_operation_date",
            actualFinancialCloseDate: "actual_financial_close_date",
            memoApprovedFinancialCloseDate: "memo_approved_financial_close_date",
            targetFinancialCloseDate: "target_financial_close_date",
            baseFinancialCloseDate: "base_financial_close_date",
            downsideFinancialCloseDate: "downside_financial_close_date",
            termConversionP50: "term_conversion_p50",
            termConversionP90: "term_conversion_p90",
            commercialViability: "commercial_viability",
            developmentViability: "development_viability",
            assetManagementCompanyId: "asset_management_company_id",
            boardApproval: "board_approval",
            boardApprovalDate: "board_approval_date",
            boardApprovalNotes: "board_approval_notes",
            scale: "scale",
            technology: "technology",
            country: "country",
            region: "region",
            state: "state",
            city: "city",
            county: "county",
            streetAddress: "street_address",
            zipcode: "zipcode",
            latitude: "latitude",
            longitude: "longitude",
            timezone: "timezone",
            capacityMwAc: "capacity_mw_ac",
            capacityMwDc: "capacity_mw_dc",
            storageCapacityMw: "storage_capacity_mw",
            storageCapacityMwh: "storage_capacity_mwh",
            domesticContent: "domestic_content",
            prevailingWage: "prevailing_wage",
            itcQualifying: "itc_qualifying__percent",
            energyCommunityQualifying: "energy_community_qualifying",
            energyCommunityQualifyingNotes: "energy_community_qualifying_notes",
            lowIncomeCommunityQualifying: "low_income_community_qualifying",
            lowIncomeCommunityQualifyingNotes: "low_income_community_qualifying_notes",
            website: "website",
            rollup: "rollup",
            notes: "notes",
            summary: "summary"
        }

        var schemaName = schemas.project
        var tableName = "plant"

        var displayNameSingular = "Plant"
        var displayNamePlural = "Plants"

        var pkUidColumn = columnSchema.plantId
        var identifiers = [columnSchema.plantId]
        var dependencies = [PlantLLC, Fund, Portfolio, AssetManager]

        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            stage: [{
                    label: "Due Diligence",
                    value: "Due Diligence"
                }, {
                    label: "M&A",
                    value: "M&A"
                }, {
                    label: "Greenfield",
                    value: "Greenfield"
                }, {
                    label: "Development",
                    value: "Development"
                }, {
                    label: "Construction",
                    value: "Construction"
                }, {
                    label: "Operational",
                    value: "Operational"
                }, {
                    label: "Sold - Proposed",
                    value: "Sold - Proposed"
                }, {
                    label: "Sold",
                    value: "Sold"
                }, {
                    label: "Write Off - Proposed",
                    value: "Write Off - Proposed"
                }, {
                    label: "Write Off",
                    value: "Write Off"
                }
            ],
            developmentSubstage: [{
                    label: "Not Marketing", value: "Not Marketing"
                }, {
                    label: "Marketing", value: "Marketing"
                }, {
                    label: "In Negotiation", value: "In Negotiation"
                }, {
                    label: "Contracted", value: "Contracted"
                }
            ],
            stageAcquired: [{
                label: "Development", value: "Development"
            }, {
                label: "Under Construction", value: "Under Construction"
            }, {
                label: "FNTP", value: "FNTP"
            }, {
                label: "Operational", value: "Operational"
            }],
            scale: [{
                    label: "Utility",
                    value: "Utility",
                }, {
                    label: "DG",
                    value: "DG"
                }
            ],
            technology: [{
                    label: "Solar",
                    value: "PV",
                }, {
                    label: "Wind",
                    value: "Wind"
                }, {
                    label: "Stand-Alone BESS",
                    value: "Stand-Alone"
                }, {
                    label: "Hybrid (Solar + BESS)",
                    value: "PV + S"
                }, {
                    label: "Natural Gas",
                    value: "CCGT"
                }
            ],
            commercialViability: [{
                label: "Low", value: "Low",
            }, {
                label: "Medium", value: "Medium",
            }, {
                label: "High", value: "High",
            }],
            developmentViability: [{
                label: 0, value: 0,
            }, {
                label: 1, value: 1,
            }, {
                label: 2, value: 2,
            }, {
                label: 3, value: 3,
            }, {
                label: 4, value: 4,
            }]
        }
    }

    buildNewRecord() {
        const x = super.buildNewRecord.call(this)
        x[this.columnSchema.capacityMwAc] = 0
        x[this.columnSchema.capacityMwDc] = 0
        x[this.columnSchema.storageCapacityMw] = 0
        x[this.columnSchema.storageCapacityMwh] = 0
        return x
    }

    buildTableProps = (data) => {
    
        //var plantRecords = this.findTableObjectRecords(data, this)
        var llcRecords = this.findTableObjectRecords(data, PlantLLC)
        var fundRecords = this.findTableObjectRecords(data, Fund)    
        var portfolioRecords = this.findTableObjectRecords(data, Portfolio)
        var managementRecords = this.findTableObjectRecords(data, AssetManager)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var llcIdCol = PlantLLC.columnSchema.plantLLCId
        var llcNameCol = PlantLLC.columnSchema.plantLLCName
        var fundIdCol = Fund.columnSchema.fundId
        var fundNameCol = Fund.columnSchema.fundName
        var portfolioIdCol = Portfolio.columnSchema.portfolioId
        var portfolioNameCol = Portfolio.columnSchema.portfolioName
        var managementIdCol = AssetManager.columnSchema.managementId
        var managementNameCol = "managementName"

        managementRecords = injectCounterpartyName(managementRecords, counterpartyRecords, managementIdCol, managementNameCol)

        const validatePlant = (plant) => {
            if (!plant[this.columnSchema.plantName]) return {pass: false, message: "Please give the plant a name."}
            if (!plant[this.columnSchema.llcId]) return {pass: false, message: "Please give the plant an LLC."}
            if (!plant[this.columnSchema.fundId]) return {pass: false, message: "Please give the plant a Fund."}
            if (!plant[this.columnSchema.portfolioId]) return {pass: false, message: "Please give the plant a Portfolio."}

            /*let filteredPlants = plantRecords.filter(x => x[this.columnSchema.plantId]!==plant[this.columnSchema.plantId])
            let plantNames = filteredPlants.map(x => (x[this.columnSchema.plantName] || "").toLowerCase())

            if (plantNames.includes((plant[this.columnSchema.plantName] || "").toLowerCase())) return "Plant name already exists."*/
            
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validatePlant,
            validateUpdateItem: validatePlant,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.plantId, {fixed: 'left'}),
                    {
                        name: "Name",
                        key: this.columnSchema.plantName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: 'left'
                        }
                    }, {
                        name: "LLC",
                        key: this.columnSchema.llcId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(llcRecords, value, llcIdCol, llcNameCol),
                            dropdownItems: buildDropdownItems(llcRecords, llcIdCol, llcNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Fund",
                        key: this.columnSchema.fundId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(fundRecords, value, fundIdCol, fundNameCol),
                            dropdownItems: buildDropdownItems(fundRecords, fundIdCol, fundNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Portfolio",
                        key: this.columnSchema.portfolioId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(portfolioRecords, value, portfolioIdCol, portfolioNameCol),
                            dropdownItems: buildDropdownItems(portfolioRecords, portfolioIdCol, portfolioNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Sub Portfolio",
                        key: this.columnSchema.subPortfolioName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.stage,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            dropdownItems: this.options.stage
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Offtake Status",
                        key: this.columnSchema.developmentSubstage,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            dropdownItems: this.options.developmentSubstage,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "CPs Needed in PPA",
                        key: this.columnSchema.CPsNeededInPPA,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Status Acquired",
                        key: this.columnSchema.stageAcquired,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            dropdownItems: this.options.stageAcquired,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Acquisition Date",
                        key: this.columnSchema.acquisitionDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Sold Date",
                        key: this.columnSchema.soldDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Write Off Date",
                        key: this.columnSchema.writeOffDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Actual COD",
                        key: this.columnSchema.plannedCommercialOperationDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Memo Approved COD",
                        key: this.columnSchema.commercialOperationDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Target COD",
                        key: this.columnSchema.targetCommercialOperationDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Base COD",
                        key: this.columnSchema.baseCommercialOperationDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Downside COD",
                        key: this.columnSchema.downsideCommercialOperationDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Actual Financial Close Date",
                        key: this.columnSchema.actualFinancialCloseDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Memo Approved Financial Close Date",
                        key: this.columnSchema.memoApprovedFinancialCloseDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Target Financial Close Date",
                        key: this.columnSchema.targetFinancialCloseDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Base Financial Close Date",
                        key: this.columnSchema.baseFinancialCloseDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Downside Financial Close Date",
                        key: this.columnSchema.downsideFinancialCloseDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Commercial Viability",
                        key: this.columnSchema.commercialViability,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.commercialViability
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Development Viability",
                        key: this.columnSchema.developmentViability,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.developmentViability
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Asset Management Company",
                        key: this.columnSchema.assetManagementCompanyId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(managementRecords, value, managementIdCol, managementNameCol),
                            dropdownItems: buildDropdownItems(managementRecords, managementIdCol, managementNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Scale",
                        key: this.columnSchema.scale,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            dropdownItems: this.options.scale,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Technology",
                        key: this.columnSchema.technology,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            dropdownItems: this.options.technology,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Board Approval",
                        key: this.columnSchema.boardApproval,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Board Approval Date",
                        key: this.columnSchema.boardApprovalDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Board Approval Notes",
                        key: this.columnSchema.boardApprovalNotes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Domestic Content",
                        key: this.columnSchema.domesticContent,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Prevailing Wage",
                        key: this.columnSchema.prevailingWage,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "ITC Qualifying (%)",
                        key: this.columnSchema.itcQualifying,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Energy Community Qualifying",
                        key: this.columnSchema.energyCommunityQualifying,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Energy Community Qualifying Notes",
                        key: this.columnSchema.energyCommunityQualifyingNotes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Low-Income Community Qualifying",
                        key: this.columnSchema.lowIncomeCommunityQualifying,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Low-Income Community Qualifying Notes",
                        key: this.columnSchema.lowIncomeCommunityQualifyingNotes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "DC Capacity (MW)",
                        key: this.columnSchema.capacityMwDc,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "AC Capacity (MW)",
                        key: this.columnSchema.capacityMwAc,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Storage Capacity (MW)",
                        key: this.columnSchema.storageCapacityMw,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 180,
                            resizable: true,
                        }
                    }, {
                        name: "Storage Capacity (MWh)",
                        key: this.columnSchema.storageCapacityMwh,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 180,
                            resizable: true,
                        }
                    }, {
                        name: "Country",
                        key: this.columnSchema.country,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 120,
                            resizable: true,
                        }
                    }, {
                        name: "Region",
                        key: this.columnSchema.region,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 100,
                            resizable: true,
                        }
                    }, {
                        name: "State",
                        key: this.columnSchema.state,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 100,
                            resizable: true,
                        }
                    }, {
                        name: "City",
                        key: this.columnSchema.city,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 100,
                            resizable: true,
                        }
                    }, {
                        name: "County",
                        key: this.columnSchema.county,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 100,
                            resizable: true,
                        }
                    }, {
                        name: "Street Address",
                        key: this.columnSchema.streetAddress,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Zipcode",
                        key: this.columnSchema.zipcode,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 100,
                            resizable: true,
                        }
                    }, {
                        name: "Latitude",
                        key: this.columnSchema.latitude,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 120,
                            resizable: true,
                        }
                    }, {
                        name: "Longitude",
                        key: this.columnSchema.longitude,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 120,
                            resizable: true,
                        }
                    }, {
                        name: "Website",
                        key: this.columnSchema.website,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 120,
                            resizable: true,
                        }
                    }, {
                        name: "Rollup",
                        key: this.columnSchema.rollup,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 120,
                            resizable: true,
                        }
                    }, {
                        name: "Timezone",
                        key: this.columnSchema.timezone,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            dropdownItems: timezones.map(x => {return {label: x, value: x}}),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                    }
                }]
            },
        }
    }

}

export var Plant = new PlantTable()

class PersonnelProjectRelationshipsTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            name: "name"
        }

        var schemaName = schemas.dbo
        var tableName = "personnel_project_relationships"

        var displayNameSingular = "Personnel Project Relationship"
        var displayNamePlural = "Personnel Project Relationships"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
    }

    buildTableProps = () => {

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}

export var PersonnelProjectRelationships = new PersonnelProjectRelationshipsTable()

class PersonnelRelationshipContactLevelsTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            name: "name"
        }

        var schemaName = schemas.dbo
        var tableName = "personnel_relationship_contact_levels"

        var displayNameSingular = "Personnel Relationship Contact Level"
        var displayNamePlural = "Personnel Relationship Contact Levels"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
    }

    buildTableProps = () => {

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}

export var PersonnelRelationshipContactLevels = new PersonnelRelationshipContactLevelsTable()

class PersonnelTable extends Table {

    constructor() {

        var columnSchema = {
            plantId: "plant_id",
            employeeId: "employee_id",
            relationshipId: "relationship_id",
            contactLevelId: "contact_level_id"
        }

        var schemaName = schemas.project
        var tableName = "personnel"

        var displayNameSingular = "Personnel"
        var displayNamePlural = "Personnel"

        var pkUidColumn = null
        var identifiers = [columnSchema.plantId, columnSchema.employeeId, columnSchema.relationshipId]
        var dependencies = [Plant, Employee, PersonnelProjectRelationships, PersonnelRelationshipContactLevels]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            relationship: [{
                    label: "Accounting", value: "Accounting",
                }, {
                    label: "Asset Manager", value: "Asset Manager",
                }, {
                    label: "Construction - Project Manager", value: "Construction",
                }, {
                    label: "Development - Project Manager", value: "Development",
                }, {
                    label: "FP&A", value: "FP&A",
                }, {
                    label: "Fund Manager", value: "Fund Manager",
                }, {
                    label: "Interconnection", value: "Interconnection",
                }, {
                    label: "Investments", value: "Investments"
                }, {
                    label: "Performance Engineering - Operations", value: "Performance Engineering",
                }, {
                    label: "Procurement", value: "Procurement",
                }, {
                    label: "Project Engineering - Development", value: "Project Engineering",
                }, {
                    label: "Project Finance", value: "Project Finance",
                }, {
                    label: "Origination", value: "Origination"
                }, {
                    label: "Community Relations", value: "Community Relations"
                }, {
                    label: "Tax", value: "Tax"
                }
            ],
            contactLevel: [{
                    label: "Leadership", value: "Leadership", icon: faMedal
                }, {
                    label: "Primary", value: "Primary", icon: fa1
                }, {
                    label: "Secondary", value: "Secondary", icon: fa2
                }, {
                    label: "Tertiary", value: "Tertiary", icon: fa3
                }
            ],

        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var employeeRecords = this.findTableObjectRecords(data, Employee)
        var relationshipRecords = this.findTableObjectRecords(data, PersonnelProjectRelationships)
        var contactLevelRecords = this.findTableObjectRecords(data, PersonnelRelationshipContactLevels)

        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName
        var employeeIdCol = Employee.columnSchema.employeeId
        var employeeFirstNameCol = Employee.columnSchema.firstName
        var employeeLastNameCol = Employee.columnSchema.lastName
        const relationshipIdCol = PersonnelProjectRelationships.columnSchema.oid
        const relationshipNameCol = PersonnelProjectRelationships.columnSchema.name
        const contactLevelIdCol = PersonnelRelationshipContactLevels.columnSchema.oid
        const contactLevelNameCol = PersonnelRelationshipContactLevels.columnSchema.name

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, plantIdCol, plantNameCol),
                    {
                        name: "Employee",
                        key: this.columnSchema.employeeId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(employeeRecords, value, employeeIdCol, [employeeFirstNameCol, employeeLastNameCol], " "),
                            dropdownItems: buildDropdownItems(employeeRecords, employeeIdCol, [employeeFirstNameCol, employeeLastNameCol], " "),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Relationship",
                        key: this.columnSchema.relationshipId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(relationshipRecords, value, relationshipIdCol, relationshipNameCol),
                            dropdownItems: buildDropdownItems(relationshipRecords, relationshipIdCol, relationshipNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Contact Level",
                        key: this.columnSchema.contactLevelId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contactLevelRecords, value, contactLevelIdCol, contactLevelNameCol),
                            dropdownItems: buildDropdownItems(contactLevelRecords, contactLevelIdCol, contactLevelNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}

export var Personnel = new PersonnelTable()

class OwnershipTable extends Table {

    constructor() {

        var columnSchema = {
            plantId: "plant_id",
            ownerId: "owner_id",
            plantLevelOwnershipPercentage: "plant_level_ownership_percentage",
            fundLevelOwnershipPercentage: "fund_level_ownership_percentage",
        }

        var schemaName = schemas.project
        var tableName = "ownership"

        var displayNameSingular = "Ownership"
        var displayNamePlural = "Ownership"

        var pkUidColumn = null
        var identifiers = [columnSchema.plantId, columnSchema.ownerId]
        var dependencies = [Plant, Owner]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var ownerRecords = this.findTableObjectRecords(data, Owner)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName
        var ownerIdCol = Owner.columnSchema.ownerId
        var ownerNameCol = "ownerName"

        ownerRecords = injectCounterpartyName(ownerRecords, counterpartyRecords, ownerIdCol, ownerNameCol)

        const validateOwner = (owner) => {
            if (!owner[this.columnSchema.plantId]) return {pass: false, message: "Please associate the owner with a plant."}
            if (!owner[this.columnSchema.ownerId]) return {pass: false, message: "Please associate the owner with an owner."}
            
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateOwner,
            validateUpdateItem: validateOwner,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, plantIdCol, plantNameCol),
                    {
                        name: "Owner",
                        key: this.columnSchema.ownerId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(ownerRecords, value, ownerIdCol, ownerNameCol),
                            dropdownItems: buildDropdownItems(ownerRecords, ownerIdCol, ownerNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Ownership Percentage (Plant Level)",
                        key: this.columnSchema.plantLevelOwnershipPercentage,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                            max: 100,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Ownership Percentage (Fund Level)",
                        key: this.columnSchema.fundLevelOwnershipPercentage,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            min: 0,
                            max: 100,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}

export var Ownership = new OwnershipTable()

class GovernmentAffairsTable extends Table {

    constructor() {

        var columnSchema = {
            plantId: "plant_id",
            congressionalDistrict: "congressional_district",
            congressionalRepresentative: "congressional_representative",
            representativeParty: "representative_party",
        }

        var schemaName = schemas.project
        var tableName = "government_affairs"

        var displayNameSingular = "Government Affairs"
        var displayNamePlural = "Government Affairs"

        var pkUidColumn = null
        var identifiers = [columnSchema.plantId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            party: [{
                    label: "Democrat", value: "democrat",
                }, {
                    label: "Republican", value: "republican",
                }, {
                    label: "Other", value: "other"
                }
            ]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: null,
            validateUpdateItem: null,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, plantIdCol, plantNameCol),
                    {
                        name: "Congressional District",
                        key: this.columnSchema.congressionalDistrict,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Congressional Representative",
                        key: this.columnSchema.congressionalRepresentative,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Representative Party",
                        key: this.columnSchema.representativeParty,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.party,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}

export var GovernmentAffairs = new GovernmentAffairsTable()

class ComplianceTable extends Table {

    constructor() {

        var columnSchema = {
            plantId: "plant_id",
            nercBes: "nerc_bes",
            registrationStatus: "registration_status",
            regionalEntity: "regional_entity",
            goNcrName: "go_ncr_name",
            goNcrNumber: "go_ncr_number",
            nercGo: "nerc_go",
            goServiceProvider: "go_service_provider",
            gopNcrName: "gop_ncr_name",
            gopNcr: "gop_ncr",
            gopServiceProvider: "gop_service_provider",
            cipMcsServicesProvider: "cip_mcs_services_provider",
            managedSecurityServicesProvider: "managed_security_services_provider",
            mbrFiling: "mbr_filing",
            mbrFilingLink: "mbr_filing_link",
            qualifyingFacility: "qualifying_facility",
            qfLink: "qualifying_facility_link",
            exemptWholesaleGenerator: "exempt_wholesale_generator",
            ewgFilingStorageLocation: "ewg_filing_storage_location",
        }

        var schemaName = schemas.project
        var tableName = "nerc_compliance"

        var displayNameSingular = "Compliance"
        var displayNamePlural = "Compliance"

        var pkUidColumn = null
        var identifiers = [columnSchema.plantId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: null,
            validateUpdateItem: null,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, plantIdCol, plantNameCol),
                    {
                        name: "NERC BES",
                        key: this.columnSchema.nercBes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Registration Status",
                        key: this.columnSchema.registrationStatus,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Regional Entity",
                        key: this.columnSchema.regionalEntity,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "GO NCR Name",
                        key: this.columnSchema.goNcrName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "GO NCR Number",
                        key: this.columnSchema.goNcrNumber,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "NERC GO",
                        key: this.columnSchema.nercGo,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "GO Service Provider",
                        key: this.columnSchema.goServiceProvider,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "GOP NCR",
                        key: this.columnSchema.gopNcr,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "GOP NCR Name",
                        key: this.columnSchema.gopNcrName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "GOP Service Provider",
                        key: this.columnSchema.gopServiceProvider,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "CIP MCS Services Provider",
                        key: this.columnSchema.cipMcsServicesProvider,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Managed Security Services Provider",
                        key: this.columnSchema.managedSecurityServicesProvider,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "MBR Filing",
                        key: this.columnSchema.mbrFiling,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "MBR Filing Link",
                        key: this.columnSchema.mbrFilingLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Qualifying Facility",
                        key: this.columnSchema.qualifyingFacility,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Qualifying Facility Link",
                        key: this.columnSchema.qfLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Exempt Wholesale Generator",
                        key: this.columnSchema.exemptWholesaleGenerator,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "EWG Filing Storage Location",
                        key: this.columnSchema.ewgFilingStorageLocation,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}

export var Compliance = new ComplianceTable()

class TechnicalDueDiligenceTable extends Table {

    constructor() {

        var columnSchema = {
            projectId: "project_id",
            dateOfCompletion: "date_of_completion",
            riskCategory: "risk_category",
            IBCAdoptedYear: "international_building_code_adopted_year",
            NECAdoptedYear: "national_electric_code_adopted_year",
            ASCECode: "asce_code",
            elevationFt: "elevation__ft",
            extremeAnnualDryBulbTempMinCelcius: "extreme_annual_dry_bulb_temp_min__celcius",
            extremeAnnualDryBulbTempMaxCelcius: "extreme_annual_dry_bulb_temp_max__celcius",
            annualRainfallIn: "annual_rainfall__in",
            snowLoad: "snow_load__lbspersqft",
            windLoad: "wind_load__mph",
            seismicRiskCategory: "seismic_risk_category",
            seismicSS: "seismic_ss",
            seismicS1: "seismic_s1",
            seismicSDS: "seismic_sds",
            seismicSD1: "seismic_sd1",
            floodMapNumber: "flood_map_number",
            floodMapRevisedDate: "flood_map_revised_date",
            wetlandsDescription: "wetlands_description",
            wetlandsDocumentLink: "wetlands_document_link",
            biologicalDescription: "biological_description",
            biologicalDocumentLink: "biological_document_link",
            summary: "summary"
        }

        var schemaName = schemas.project
        var tableName = "technical_due_diligence"

        var displayNameSingular = "Technical Due-Diligence"
        var displayNamePlural = "Technical Due-Diligence"

        var pkUidColumn = null
        var identifiers = [columnSchema.projectId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()

        /*var injectedColumnSchema = {
            injectedProjectId: "_injectedProjectId"
        }

        super({
            url: "precious/table/project_technical_due_diligence",
            columnSchema: columnSchema,
            injectedColumnSchema: injectedColumnSchema,
            displayNameSingular: "Technical Due Diligence",
            displayNamePlural: "Technical Due Diligence"
        })*/

    }

    initOptions() {
        this.options = {
            riskCategory: [{
                label: "I", value: 1
            }, {
                label: "II", value: 2
            }, {
                label: "III", value: 3
            }, {
                label: "IV", value: 4
            }],
            ASCECode: [{
                label: "ASCE 7-16", value: "ASCE 7-16"
            }, {
                label: "ASCE 7-10", value: "ASCE 7-10"
            }, {
                label: "ASCE 7-05", value: "ASCE 7-05"
            }],
            seismicRisk: [{
                label: "A", value: "A"
            }, {
                label: "B", value: "B"
            }, {
                label: "C", value: "C"
            }, {
                label: "D", value: "D"
            }, {
                label: "E", value: "E"
            }, {
                label: "F", value: "F"
            }]
        }
    }

    /*buildTableProps = (data) => {

        const projectRecords = data.dependencies.projects

        return {
            itemSchema: this.buildNewRecord(),
            validateNewItem: null,
            validateUpdateItem: null,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeProjectIdRefConfig(projectRecords, this.columnSchema.projectId, {fixed: true}),
                    makeDateConfig("Date", this.columnSchema.dateOfCompletion, {fixed: true}),
                    makeNumberConfig("actualBESSChargeMWh", this.columnSchema.elevationFt, {width: 200}),
                    makeNumberConfig("actualBESSDischargeMWh", this.columnSchema.extremeAnnualDryBulbTempMinCelcius, {width: 200}),
                    makeNumberConfig("actualProductionSolarMWh", this.columnSchema.extremeAnnualDryBulbTempMaxCelcius, {width: 200}),
                    makeNumberConfig("actualRevenuePPA", this.columnSchema.annualRainfallIn, {width: 200}),
                ]
            },
        }
    }*/

}
export const TechnicalDueDiligence = new TechnicalDueDiligenceTable()

class TechnicalChecklistItemsTable extends Table {

    constructor() {
        var columnSchema = {
            id: "id",
            name: "name",
            parent: "parent",
            sortOrder: "sort_order",
            category: "category"
        }

        var schemaName = schemas.project
        var tableName = "technical_datasite_checklist_items"

        var displayNameSingular = "Technical Datasite Checklist Item"
        var displayNamePlural = "Technical Datasite Checklist Items"

        var pkUidColumn = null
        var identifiers = [columnSchema.id]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions() {
        this.options = {
            category: [{
                label: "Development", value: "Development"
            }, {
                label: "Construction", value: "Construction"
            }]
        }
    }

    buildTableProps = (data) => {

        var itemRecords = this.findTableObjectRecords(data, this)
        var itemIdCol = this.columnSchema.id
        var itemNameCol = this.columnSchema.name

        return {
            itemSchema: this.buildNewRecord(),
            validateNewItem: null,
            validateUpdateItem: null,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.id),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Parent",
                        key: this.columnSchema.parent,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(itemRecords, value, itemIdCol, itemNameCol),
                            dropdownItems: buildDropdownItems(itemRecords, itemIdCol, itemNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Sort Order",
                        key: this.columnSchema.sortOrder,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Category",
                        key: this.columnSchema.category,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.category,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const TechnicalChecklistItems = new TechnicalChecklistItemsTable()

class TechnicalChecklistTable extends Table {

    constructor() {
        var columnSchema = {
            projectId: "project_id",
            itemId: "item_id",
            required: "required",
            status: "status",
            comments: "comments",
            documentLink: "document_link",
            lastUpdatedAt: "last_updated_at"
        }

        var schemaName = schemas.project
        var tableName = "technical_datasite_checklist"

        var displayNameSingular = "Technical Datasite Checklist"
        var displayNamePlural = "Technical Datasite Checklist"

        var pkUidColumn = null
        var identifiers = [columnSchema.projectId, columnSchema.itemId]
        var dependencies = [Plant, TechnicalChecklistItems]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
        this.allowProjectDisplay = false
    }

    initOptions() {
        this.options = {
            status: [{
                label: "Final", value: "Final"
            }, {
                label: "Prelim", value: "Prelim"
            }, {
                label: "NA", value: "NA"
            }]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName

        var itemRecords = this.findTableObjectRecords(data, TechnicalChecklistItems)
        var itemIdCol = TechnicalChecklistItems.columnSchema.id
        var itemNameCol = TechnicalChecklistItems.columnSchema.name

        return {
            itemSchema: this.buildNewRecord(),
            validateNewItem: null,
            validateUpdateItem: null,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, plantIdCol, plantNameCol),
                            dropdownItems: buildDropdownItems(plantRecords, plantIdCol, plantNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Item",
                        key: this.columnSchema.itemId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(itemRecords, value, itemIdCol, [itemIdCol, itemNameCol]),
                            dropdownItems: buildDropdownItems(itemRecords, itemIdCol, [itemIdCol, itemNameCol]),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Required",
                        key: this.columnSchema.required,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Document Link",
                        key: this.columnSchema.documentLink,
                        cellType: "editable",
                        filterable: false,
                        sortable: false,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Comments",
                        key: this.columnSchema.comments,
                        cellType: "editable",
                        filterable: false,
                        sortable: false,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const TechnicalChecklist = new TechnicalChecklistTable()

class WBSCodeTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            code: "code",
            description: "description"
        }

        var schemaName = schemas.dbo
        var tableName = "wbs_code"

        var displayNameSingular = "WBS Code"
        var displayNamePlural = "WBS Codes"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        return {
            itemSchema: this.buildNewRecord(),
            validateNewItem: null,
            validateUpdateItem: null,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Code",
                        key: this.columnSchema.code,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Description",
                        key: this.columnSchema.description,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const WBSCode = new WBSCodeTable()

class RiskRegisterTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            projectId: "project_id",
            riskName: "risk_name",
            riskDescription: "risk_description",
            status: "status",
            category: "category",
            audienceLevel: "audience_level",
            probability: "probability",
            boardApproval: "board_approval",
            worstCaseCostImpact: "worst_case_cost_impact__dollars",
            costImpactRating: "cost_impact_rating",
            estimatedCostImpact: "estimated_cost_impact__dollars",
            scheduleImpactExactWeeks: "schedule_impact_exact__weeks",
            scheduleImpactLowWeeks: "schedule_impact_low__weeks",
            scheduleImpactHighWeeks: "schedule_impact_high__weeks",
            mitigationPlan: "mitigation_plan",
            riskOwnerCompanyId: "risk_owner_company_id",
            riskItemLead: "risk_item_lead",
            closedDate: "closed_date",
            isCritical: "is_critical",
            resolutionDetails: "resolution_details",
            finalCostImpact: "final_cost_impact__dollars",
            finalScheduleImpact: "final_schedule_impact__weeks",
            targetResolutionDate: "target_resolution_date",
            WBSCodeId: "wbs_code_id"
        }

        var schemaName = schemas.project
        var tableName = "deveng_risk_register"

        var displayNameSingular = "Risk"
        var displayNamePlural = "Risk Register"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant, Counterparty, WBSCode]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
        this.allowProjectDisplay = false
    }

    buildNewRecord() {
        const x = super.buildNewRecord.call(this)
        x[this.columnSchema.status] = "Open"
        return x
    }

    initOptions() {
        this.options = {
            status: [{
                label: "Open", value: "Open"
            }, {
                label: "Closed", value: "Closed"
            }],
            probability: [{
                label: "Very Unlikely", value: 1
            }, {
                label: "Unlikely", value: 2
            }, {
                label: "Somewhat Likely", value: 3
            }, {
                label: "Likely", value: 4
            }, {
                label: "Very Likely", value: 5
            }],
            audienceLevel: [{
                label: "Investor", value: "Investor"
            }, {
                label: "Lender", value: "Lender"
            }, {
                label: "Executive Team", value: "Executive Team"
            }, {
                label: "Project Team", value: "Project Team"
            }],
            costImpactRating: [{
                label: "Very Low", value: 0, low: 0, high: 100000
            }, {
                label: "Low", value: 1, low: 100000, high: 500000
            }, {
                label: "Medium", value: 2, low: 500000, high: 1000000
            }, {
                label: "Somewhat High", value: 3, low: 1000000, high: 2000000
            }, {
                label: "High", value: 4, low: 2000000, high: 5000000
            }, {
                label: "Very High", value: 5, low: 5000000
            }],
            category: [{
                label: "Interconnection", value: "Interconnection"
            }, {
                label: "Commercial/Offtake", value: "Commercial/Offtake"
            }, {
                label: "Real Estate", value: "Real Estate"
            }, {
                label: "Permitting/Community Relations", value: "Permitting/Community Relations"
            }, {
                label: "Engineering/Construction", value: "Engineering/Construction"
            }, {
                label: "Resource", value: "Resource"
            }, {
                label: "Project Finance", value: "Project Finance"
            }, {
                label: "Insurance", value: "Insurance"
            }, {
                label: "Other", value: "Other"
            }]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName

        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        var counterpartyIdCol = Counterparty.columnSchema.counterpartyId
        var counterpartyNameCol = Counterparty.columnSchema.counterpartyName
        
        var WBSCodeRecords = this.findTableObjectRecords(data, WBSCode)
        var WBSCodeIdCol = WBSCode.columnSchema.oid
        var WBSCodeNameCol = [WBSCode.columnSchema.code, WBSCode.columnSchema.description]

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, plantIdCol, plantNameCol),
                            dropdownItems: buildDropdownItems(plantRecords, plantIdCol, plantNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: true
                        }
                    }, {
                        name: "Name",
                        key: this.columnSchema.riskName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Description",
                        key: this.columnSchema.riskDescription,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Category",
                        key: this.columnSchema.category,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.category
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }/*, {
                        name: "Board Approval",
                        key: this.columnSchema.boardApproval,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Audience Level",
                        key: this.columnSchema.audienceLevel,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.audienceLevel
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }*/, {
                        name: "WBS Code",
                        key: this.columnSchema.WBSCodeId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(WBSCodeRecords, value, WBSCodeIdCol, WBSCodeNameCol),
                            dropdownItems: buildDropdownItems(WBSCodeRecords, WBSCodeIdCol, WBSCodeNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Probability",
                        key: this.columnSchema.probability,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.probability,
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                const estimatedCostImpact = record[this.columnSchema.worstCaseCostImpact] * (newValue/5)
                                const option = this.options.costImpactRating.find(x => estimatedCostImpact>=x.low && x.high ? estimatedCostImpact<x.high : true)
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.estimatedCostImpact]: estimatedCostImpact,
                                    [this.columnSchema.costImpactRating]: option ? option.value : null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Worst Case Cost Impact ($)",
                        key: this.columnSchema.worstCaseCostImpact,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                const estimatedCostImpact = newValue * (record[this.columnSchema.probability]/5)
                                const option = this.options.costImpactRating.find(x => estimatedCostImpact>=x.low && x.high ? estimatedCostImpact<x.high : true)
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.estimatedCostImpact]: estimatedCostImpact,
                                    [this.columnSchema.costImpactRating]: option ? option.value : null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Budgeted Cost Impact ($)",
                        key: this.columnSchema.estimatedCostImpact,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                const option = this.options.costImpactRating.find(x => newValue>=x.low && x.high ? newValue<x.high : true)
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.costImpactRating]: option ? option.value : null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Cost Impact Rating",
                        key: this.columnSchema.costImpactRating,
                        cellType: "readOnly",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Schedule Impact Exact (Weeks)",
                        key: this.columnSchema.scheduleImpactExactWeeks,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.scheduleImpactHighWeeks]: null,
                                    [this.columnSchema.scheduleImpactLowWeeks]: null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Schedule Impact Lowside (Weeks)",
                        key: this.columnSchema.scheduleImpactLowWeeks,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.scheduleImpactExactWeeks]: null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Schedule Impact Highside (Weeks)",
                        key: this.columnSchema.scheduleImpactHighWeeks,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.scheduleImpactExactWeeks]: null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Mitigation Plan",
                        key: this.columnSchema.mitigationPlan,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Is Critical",
                        key: this.columnSchema.isCritical,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Risk Owner Company ID",
                        key: this.columnSchema.riskOwnerCompanyId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(counterpartyRecords, value, counterpartyIdCol, counterpartyNameCol),
                            dropdownItems: buildDropdownItems(counterpartyRecords, counterpartyIdCol, counterpartyNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Risk Item Lead",
                        key: this.columnSchema.riskItemLead,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Target Resolution Date",
                        key: this.columnSchema.targetResolutionDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Closed Date",
                        key: this.columnSchema.closedDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Resolution Details",
                        key: this.columnSchema.resolutionDetails,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Final Cost Impact",
                        key: this.columnSchema.finalCostImpact,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Final Schedule Impact",
                        key: this.columnSchema.finalScheduleImpact,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const RiskRegister = new RiskRegisterTable()


class OpportunityRegisterTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            projectId: "project_id",
            opportunityName: "opportunity_name",
            opportunityDescription: "opportunity_description",
            realizationPlan: "realization_plan",
            status: "status",
            probability: "probability",
            boardApproval: "board_approval",
            audienceLevel: "audience_level",
            bestCaseCostImpact: "best_case_cost_impact__dollars",
            costImpactRating: "cost_impact_rating",
            estimatedCostImpact: "estimated_cost_impact__dollars",
            scheduleImpactExactWeeks: "schedule_impact_exact__weeks",
            scheduleImpactLowWeeks: "schedule_impact_low__weeks",
            scheduleImpactHighWeeks: "schedule_impact_high__weeks",
            opportunityOwnerCompanyId: "opportunity_owner_company_id",
            opportunityItemLead: "opportunity_item_lead",
            closedDate: "closed_date",
            resolutionDetails: "resolution_details",
            finalCostImpact: "final_cost_impact__dollars",
            finalScheduleImpact: "final_schedule_impact__weeks",
            targetResolutionDate: "target_resolution_date",
            WBSCodeId: "wbs_code_id"
        }

        var schemaName = schemas.project
        var tableName = "deveng_opportunity_register"

        var displayNameSingular = "Opportunity"
        var displayNamePlural = "Opportunity Register"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant, Counterparty, WBSCode]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
        this.allowProjectDisplay = false
    }

    buildNewRecord() {
        const x = super.buildNewRecord.call(this)
        x[this.columnSchema.status] = "Open"
        return x
    }

    initOptions() {
        this.options = {
            status: [{
                label: "Open", value: "Open"
            }, {
                label: "Closed", value: "Closed"
            }],
            probability: [{
                label: "Very Unlikely", value: 1
            }, {
                label: "Unlikely", value: 2
            }, {
                label: "Somewhat Likely", value: 3
            }, {
                label: "Likely", value: 4
            }, {
                label: "Very Likely", value: 5
            }],
            audienceLevel: [{
                label: "Investor", value: "Investor"
            }, {
                label: "Lender", value: "Lender"
            }, {
                label: "Executive Team", value: "Executive Team"
            }, {
                label: "Project Team", value: "Project Team"
            }],
            costImpactRating: [{
                label: "Very Low", value: 0, low: 0, high: 100000
            }, {
                label: "Low", value: 1, low: 100000, high: 500000
            }, {
                label: "Medium", value: 2, low: 500000, high: 1000000
            }, {
                label: "Somewhat High", value: 3, low: 1000000, high: 2000000
            }, {
                label: "High", value: 4, low: 2000000, high: 5000000
            }, {
                label: "Very High", value: 5, low: 5000000
            }],
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName

        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        var counterpartyIdCol = Counterparty.columnSchema.counterpartyId
        var counterpartyNameCol = Counterparty.columnSchema.counterpartyName

        var WBSCodeRecords = this.findTableObjectRecords(data, WBSCode)
        var WBSCodeIdCol = WBSCode.columnSchema.oid
        var WBSCodeNameCol = [WBSCode.columnSchema.code, WBSCode.columnSchema.description]

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, plantIdCol, plantNameCol),
                            dropdownItems: buildDropdownItems(plantRecords, plantIdCol, plantNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: true
                        }
                    }, {
                        name: "Name",
                        key: this.columnSchema.opportunityName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Description",
                        key: this.columnSchema.opportunityDescription,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Board Approval",
                        key: this.columnSchema.boardApproval,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Audience Level",
                        key: this.columnSchema.audienceLevel,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.audienceLevel
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "WBS Code",
                        key: this.columnSchema.WBSCodeId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(WBSCodeRecords, value, WBSCodeIdCol, WBSCodeNameCol),
                            dropdownItems: buildDropdownItems(WBSCodeRecords, WBSCodeIdCol, WBSCodeNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Probability",
                        key: this.columnSchema.probability,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.probability,
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                const estimatedCostImpact = record[this.columnSchema.bestCaseCostImpact] * (newValue/5)
                                const option = this.options.costImpactRating.find(x => estimatedCostImpact>=x.low && x.high ? estimatedCostImpact<x.high : true)
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.estimatedCostImpact]: estimatedCostImpact,
                                    [this.columnSchema.costImpactRating]: option ? option.value : null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Best Case Cost Impact ($)",
                        key: this.columnSchema.bestCaseCostImpact,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                const estimatedCostImpact = newValue * (record[this.columnSchema.probability]/5)
                                const option = this.options.costImpactRating.find(x => estimatedCostImpact>=x.low && x.high ? estimatedCostImpact<x.high : true)
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.estimatedCostImpact]: estimatedCostImpact,
                                    [this.columnSchema.costImpactRating]: option ? option.value : null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Budgeted Cost Impact ($)",
                        key: this.columnSchema.estimatedCostImpact,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                const option = this.options.costImpactRating.find(x => newValue>=x.low && x.high ? newValue<x.high : true)
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.costImpactRating]: option ? option.value : null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Cost Impact Rating",
                        key: this.columnSchema.costImpactRating,
                        cellType: "readOnly",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Schedule Impact Exact (Weeks)",
                        key: this.columnSchema.scheduleImpactExactWeeks,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.scheduleImpactHighWeeks]: null,
                                    [this.columnSchema.scheduleImpactLowWeeks]: null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Schedule Impact Lowside (Weeks)",
                        key: this.columnSchema.scheduleImpactLowWeeks,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.scheduleImpactExactWeeks]: null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Schedule Impact Highside (Weeks)",
                        key: this.columnSchema.scheduleImpactHighWeeks,
                        cellType: "editable",
                        cellOptions: {
                            type: "number",
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.scheduleImpactExactWeeks]: null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Realization Plan",
                        key: this.columnSchema.realizationPlan,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Opportunity Owner Company ID",
                        key: this.columnSchema.opportunityOwnerCompanyId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(counterpartyRecords, value, counterpartyIdCol, counterpartyNameCol),
                            dropdownItems: buildDropdownItems(counterpartyRecords, counterpartyIdCol, counterpartyNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Opportunity Item Lead",
                        key: this.columnSchema.opportunityItemLead,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Target Resolution Date",
                        key: this.columnSchema.targetResolutionDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Closed Date",
                        key: this.columnSchema.closedDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Resolution Details",
                        key: this.columnSchema.resolutionDetails,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Final Cost Impact",
                        key: this.columnSchema.finalCostImpact,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Final Schedule Impact",
                        key: this.columnSchema.finalScheduleImpact,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const OpportunityRegister = new OpportunityRegisterTable()

class TemporalProjectPricingTypeTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            pricingType: "pricing_type",
            units: "units",
            equipmentSpecTable: "equipment_spec_table"
        }

        var schemaName = schemas.dbo
        var tableName = "temporal_project_pricing_type"

        var displayNameSingular = "Project Pricing Type"
        var displayNamePlural = "Project Pricing Types"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = () => {

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Pricing Type",
                        key: this.columnSchema.pricingType,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Units",
                        key: this.columnSchema.units,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Equipment Spec Table",
                        key: this.columnSchema.equipmentSpecTable,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const TemporalProjectPricingType = new TemporalProjectPricingTypeTable()

class TemporalProjectPricingBlocksTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            name: "name",
            projectId: "project_id",
            date: "date",
            notes: "notes",
        }

        var schemaName = schemas.dbo
        var tableName = "temporal_project_pricing_blocks"

        var displayNameSingular = "Project Pricing Block"
        var displayNamePlural = "Project Pricing Blocks"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var plantIdCol = Plant.columnSchema.plantId
        var plantNameCol = Plant.columnSchema.plantName
        const plantRecordsMap = Object.fromEntries(plantRecords.map(x => [x[Plant.columnSchema.plantId], x]))

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "readOnly",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: true
                        }
                    }, {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, plantIdCol, plantNameCol),
                            dropdownItems: buildDropdownItems(plantRecords, plantIdCol, plantNameCol),
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                const plant = plantRecordsMap[newValue] || {}
                                const plantName = plant[Plant.columnSchema.plantName]
                                const date = record[this.columnSchema.date]
                                const name = (plantName && date) ? `${plantName} - ${date}` : null
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.name]: name
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true
                        }
                    }, {
                        name: "Date",
                        key: this.columnSchema.date,
                        cellType: "date",
                        cellOptions: {
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                const plantId = record[this.columnSchema.projectId]
                                const plant = plantRecordsMap[plantId] || {}
                                const plantName = plant[Plant.columnSchema.plantName]
                                const date = newValue ? formatDateDisplay(newValue) : null
                                const name = (plantName && date) ? `${plantName} - ${date}` : null
                                return {
                                    [newValueColumn]: date,
                                    [this.columnSchema.name]: name
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Notes",
                        key: this.columnSchema.notes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const TemporalProjectPricingBlocks = new TemporalProjectPricingBlocksTable()

class TemporalProjectPricingPricesTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            priceBlockId: "price_block_id",
            pricingTypeId: "pricing_type_id",
            equipmentSpecId: "equipment_spec_id",
            price: "price"
        }

        var schemaName = schemas.dbo
        var tableName = "temporal_project_pricing_prices"

        var displayNameSingular = "Project Pricing Price"
        var displayNamePlural = "Project Pricing Prices"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [TemporalProjectPricingType, TemporalProjectPricingBlocks, ModuleSpec, TrackerSpec, BESSSpec, InverterSpec]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var pricingTypeRecords = this.findTableObjectRecords(data, TemporalProjectPricingType)
        var pricingTypeIdCol = TemporalProjectPricingType.columnSchema.oid
        var pricingTypeNameCol = TemporalProjectPricingType.columnSchema.pricingType
        const pricingTypeRecordsMap = Object.fromEntries(pricingTypeRecords.map(x => [x[TemporalProjectPricingType.columnSchema.oid], x]))

        var blockRecords = this.findTableObjectRecords(data, TemporalProjectPricingBlocks)
        var blockIdCol = TemporalProjectPricingBlocks.columnSchema.oid
        var blockNameCol = TemporalProjectPricingBlocks.columnSchema.name

        const equipmentSpecTables = [ModuleSpec, TrackerSpec, BESSSpec, InverterSpec]
        const equipmentSpecMap = Object.fromEntries(equipmentSpecTables.map(specTable => {
            return [specTable.tableName, [specTable, this.findTableObjectRecords(data, specTable)]]
        }))


        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Price Block",
                        key: this.columnSchema.priceBlockId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(blockRecords, value, blockIdCol, blockNameCol),
                            dropdownItems: buildDropdownItems(blockRecords, blockIdCol, blockNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Pricing Type",
                        key: this.columnSchema.pricingTypeId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(pricingTypeRecords, value, pricingTypeIdCol, pricingTypeNameCol),
                            dropdownItems: buildDropdownItems(pricingTypeRecords, pricingTypeIdCol, pricingTypeNameCol),
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.equipmentSpecId]: null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Equipment Spec",
                        key: this.columnSchema.equipmentSpecId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value, record) => {
                                const pricingTypeId = record[this.columnSchema.pricingTypeId]
                                const pricingType = pricingTypeRecordsMap[pricingTypeId]
                                if (!pricingType) return null
                                const equipmentSpecTableName = pricingType[TemporalProjectPricingType.columnSchema.equipmentSpecTable]
                                if (!(equipmentSpecTableName in equipmentSpecMap)) return null
                                const [equipmentSpecTable, equipmentSpecRecords] = equipmentSpecMap[equipmentSpecTableName] || []
                                return transformDisplayValue(equipmentSpecRecords, value, equipmentSpecTable.getIdCol(), [equipmentSpecTable.columnSchema.manufacturer, equipmentSpecTable.columnSchema.model])
                            },
                            dropdownItems: (record) => {
                                const pricingTypeId = record[this.columnSchema.pricingTypeId]
                                const pricingType = pricingTypeRecordsMap[pricingTypeId]
                                if (!pricingType) return []
                                const equipmentSpecTableName = pricingType[TemporalProjectPricingType.columnSchema.equipmentSpecTable]
                                if (!(equipmentSpecTableName in equipmentSpecMap)) return []
                                const [equipmentSpecTable, equipmentSpecRecords] = equipmentSpecMap[equipmentSpecTableName] || []
                                return buildDropdownItems(equipmentSpecRecords, equipmentSpecTable.getIdCol(), [equipmentSpecTable.columnSchema.manufacturer, equipmentSpecTable.columnSchema.model])
                            },
                        },
                        filterable: false,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Price",
                        key: this.columnSchema.price,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const TemporalProjectPricingPrices = new TemporalProjectPricingPricesTable()



class IDTypeTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            idType: "id_type",
            description: "description"
        }

        var schemaName = schemas.dbo
        var tableName = "id_type"

        var displayNameSingular = "ID Type"
        var displayNamePlural = "ID Types"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "ID Type",
                        key: this.columnSchema.idType,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Description",
                        key: this.columnSchema.description,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const IDType = new IDTypeTable()

class ProjectIDsTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            plantId: "plant_id",
            idType: "id_type",
            id: "id",
            notes: "notes"
        }

        var schemaName = schemas.project
        var tableName = "ids"

        var displayNameSingular = "Project ID"
        var displayNamePlural = "Project IDs"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant, IDType]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = true
    }

    buildTableProps = (data) => {

        var projectRecords = this.findTableObjectRecords(data, Plant)
        var projectIdCol = Plant.columnSchema.plantId
        var projectNameCol = Plant.columnSchema.plantName
        
        var IDTypeRecords = this.findTableObjectRecords(data, IDType)
        var IDTypeIdCol = IDType.columnSchema.oid
        var IDTypeNameCol = IDType.columnSchema.idType

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Project ID",
                        key: this.columnSchema.plantId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(projectRecords, value, projectIdCol, projectNameCol),
                            dropdownItems: buildDropdownItems(projectRecords, projectIdCol, projectNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: true
                        }
                    }, {
                        name: "ID Type",
                        key: this.columnSchema.idType,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(IDTypeRecords, value, IDTypeIdCol, IDTypeNameCol),
                            dropdownItems: buildDropdownItems(IDTypeRecords, IDTypeIdCol, IDTypeNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                            fixed: true
                        }
                    }, {
                        name: "ID",
                        key: this.columnSchema.id,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Notes",
                        key: this.columnSchema.notes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const ProjectIDs = new ProjectIDsTable()

class BusinessCategoryTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            name: "name"
        }

        var schemaName = schemas.dbo
        var tableName = "business_category"

        var displayNameSingular = "Business Category"
        var displayNamePlural = "Business Categories"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
    }

    buildTableProps = () => {

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const BusinessCategory = new BusinessCategoryTable()

class LessonLearnedTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            title: "title",
            projectId: "project_id",
            status: "status",
            categoryId: "category_id",
            originStage: "origin_stage",
            impactStage: "impact_stage",
            issueDescription: "issue_description",
            dateIdentified: "date_identified",
            resolutionDescription: "resolution_description",
            dateResolved: "date_resolved",
            submittedByEmployeeId: "submitted_by_employee_id"
        }

        var schemaName = schemas.dbo
        var tableName = "lesson_learned"

        var displayNameSingular = "Lesson Learned"
        var displayNamePlural = "Lessons Learned"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant, BusinessCategory, Employee]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions() {
        this.options = {
            status: [{
                label: "Submitted", value: "Submitted"
            }, {
                label: "Acknowledged", value: "Acknowledged"
            }],
            impactStage: Plant.options.stage
        }
    }

    buildTableProps = (data) => {

        var projectRecords = this.findTableObjectRecords(data, Plant)
        var projectIdCol = Plant.columnSchema.plantId
        var projectNameCol = Plant.columnSchema.plantName
        
        var categoryRecords = this.findTableObjectRecords(data, BusinessCategory)
        var categoryIdCol = BusinessCategory.columnSchema.oid
        var categoryNameCol = BusinessCategory.columnSchema.name
        
        var employeeRecords = this.findTableObjectRecords(data, Employee)
        var employeeIdCol = Employee.columnSchema.employeeId
        var employeeFirstNameCol = Employee.columnSchema.firstName
        var employeeLastNameCol = Employee.columnSchema.lastName

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Title",
                        key: this.columnSchema.title,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(projectRecords, value, projectIdCol, projectNameCol),
                            dropdownItems: buildDropdownItems(projectRecords, projectIdCol, projectNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Business Category",
                        key: this.columnSchema.categoryId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(categoryRecords, value, categoryIdCol, categoryNameCol),
                            dropdownItems: buildDropdownItems(categoryRecords, categoryIdCol, categoryNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Submitted By",
                        key: this.columnSchema.submittedByEmployeeId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(employeeRecords, value, employeeIdCol, [employeeFirstNameCol, employeeLastNameCol]),
                            dropdownItems: buildDropdownItems(employeeRecords, employeeIdCol, [employeeFirstNameCol, employeeLastNameCol]),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Origin Stage",
                        key: this.columnSchema.originStage,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.impactStage
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Impact Stage",
                        key: this.columnSchema.impactStage,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.impactStage
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Date Identified",
                        key: this.columnSchema.dateIdentified,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Issue Description",
                        key: this.columnSchema.issueDescription,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Date Resolved",
                        key: this.columnSchema.dateResolved,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Resolution Description",
                        key: this.columnSchema.resolutionDescription,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const LessonLearned = new LessonLearnedTable()

class LessonLearnedInstancesTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            lessonLearnedId: "lesson_learned_id",
            instanceTable: "instance_table",
            instanceId: "instance_id"
        }

        var schemaName = schemas.dbo
        var tableName = "lesson_learned_instances"

        var displayNameSingular = "Lesson Learned Instance"
        var displayNamePlural = "Lesson Learned Instances"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [LessonLearned, RiskRegister, OpportunityRegister]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions() {
        this.options = {
            instanceTable: [{
                label: "Opportunity", value: "opportunity"
            }, {
                label: "Risk", value: "risk"
            }]
        }
    }

    buildTableProps = (data) => {

        var lessonLearnedRecords = this.findTableObjectRecords(data, LessonLearned)
        var lessonLearnedIdCol = LessonLearned.columnSchema.oid
        var lessonLearnedNameCol = LessonLearned.columnSchema.title
        
        var riskRecords = this.findTableObjectRecords(data, RiskRegister)
        var riskIdCol = RiskRegister.columnSchema.oid
        var riskNameCol = RiskRegister.columnSchema.riskName
        
        var opportunityRecords = this.findTableObjectRecords(data, OpportunityRegister)
        var opportunityIdCol = OpportunityRegister.columnSchema.oid
        var opportunityNameCol = OpportunityRegister.columnSchema.opportunityName

        const instanceTableCol = this.columnSchema.instanceTable

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Lesson Learned",
                        key: this.columnSchema.lessonLearnedId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(lessonLearnedRecords, value, lessonLearnedIdCol, lessonLearnedNameCol),
                            dropdownItems: buildDropdownItems(lessonLearnedRecords, lessonLearnedIdCol, lessonLearnedNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Instance Table",
                        key: this.columnSchema.instanceTable,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.instanceTable,
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                return {
                                    [newValueColumn]: newValue,
                                    [this.columnSchema.instanceId]: null
                                }
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Instance ID",
                        key: this.columnSchema.instanceId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value, data) => {
                                if (data[instanceTableCol]==="risk") return transformDisplayValue(riskRecords, value, riskIdCol, riskNameCol)
                                else if (data[instanceTableCol]==="opportunity") return transformDisplayValue(opportunityRecords, value, opportunityIdCol, opportunityNameCol)
                                else return ""
                            },
                            dropdownItems: (data) => {
                                if (data[instanceTableCol]==="risk") return buildDropdownItems(riskRecords, riskIdCol, riskNameCol)
                                else if (data[instanceTableCol]==="opportunity") return buildDropdownItems(opportunityRecords, opportunityIdCol, opportunityNameCol)
                                else return []
                            },
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const LessonLearnedInstances = new LessonLearnedInstancesTable()

class ProjectBudgetingTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            projectId: "project_id",
            budgetedCOD: "budgeted_cod",
            budgetedFinancialCloseDate: "budgeted_financial_close_date"
        }

        var schemaName = schemas.dbo
        var tableName = "project_budgeting_info"

        var displayNameSingular = "Project Budget Info"
        var displayNamePlural = "Project Budgets Info"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var projectRecords = this.findTableObjectRecords(data, Plant)
        var projectIdCol = Plant.columnSchema.plantId
        var projectNameCol = Plant.columnSchema.plantName

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(projectRecords, value, projectIdCol, projectNameCol),
                            dropdownItems: buildDropdownItems(projectRecords, projectIdCol, projectNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Budgeted COD",
                        key: this.columnSchema.budgetedCOD,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Budgeted Financial Close date",
                        key: this.columnSchema.budgetedFinancialCloseDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const ProjectBudgeting = new ProjectBudgetingTable()

class EnvironmentalAndPermittingChecklistTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            projectId: "project_id",
            name: "name"
        }

        var schemaName = schemas.dbo
        var tableName = "environmental_and_permitting_checklist"

        var displayNameSingular = "Environmental And Permitting Checklist"
        var displayNamePlural = "Environmental And Permitting Checklists"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var projectRecords = this.findTableObjectRecords(data, Plant)
        var projectIdCol = Plant.columnSchema.plantId
        var projectNameCol = Plant.columnSchema.plantName

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(projectRecords, value, projectIdCol, projectNameCol),
                            dropdownItems: buildDropdownItems(projectRecords, projectIdCol, projectNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const EnvironmentalAndPermittingChecklist = new EnvironmentalAndPermittingChecklistTable()

class EnvironmentalAndPermittingChecklistItemTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            name: "name",
            parentId: "parent_id",
            isTemplateItem: "is_template_item",
            sortOrder: "sort_order"
        }

        var schemaName = schemas.dbo
        var tableName = "environmental_and_permitting_checklist_item"

        var displayNameSingular = "Environmental And Permitting Checklist Item"
        var displayNamePlural = "Environmental And Permitting Checklist Items"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var parentRecords = this.findTableObjectRecords(data, EnvironmentalAndPermittingChecklistItem)
        var parentIdCol = EnvironmentalAndPermittingChecklistItem.columnSchema.oid
        var parentNameCol = EnvironmentalAndPermittingChecklistItem.columnSchema.name

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Name",
                        key: this.columnSchema.name,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Parent",
                        key: this.columnSchema.parentId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(parentRecords, value, parentIdCol, parentNameCol),
                            dropdownItems: buildDropdownItems(parentRecords, parentIdCol, parentNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Is Template Item",
                        key: this.columnSchema.isTemplateItem,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Sort Order",
                        key: this.columnSchema.sortOrder,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const EnvironmentalAndPermittingChecklistItem = new EnvironmentalAndPermittingChecklistItemTable()

class EnvironmentalAndPermittingChecklistEntryTable extends Table {

    constructor() {
        var columnSchema = {
            oid: "oid",
            checklistId: "checklist_id",
            itemId: "item_id",
            status: "status",
            required: "required",
            documentLink: "document_link",
            comments: "comments"
        }

        var schemaName = schemas.dbo
        var tableName = "environmental_and_permitting_checklist_entry"

        var displayNameSingular = "Environmental And Permitting Checklist Entry"
        var displayNamePlural = "Environmental And Permitting Checklist Entries"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [EnvironmentalAndPermittingChecklist, EnvironmentalAndPermittingChecklistItem]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
        this.initOptions()
    }

    initOptions() {
        this.options = {
            status: [{
                label: "N/A", value: "N/A"
            }, {
                label: "Not Started", value: "Not Started"
            }, {
                label: "In Progress", value: "In Progress"
            }, {
                label: "Complete", value: "Complete"
            }]
        }
    }

    buildTableProps = (data) => {

        var checklistRecords = this.findTableObjectRecords(data, EnvironmentalAndPermittingChecklist)
        var checklistIdCol = EnvironmentalAndPermittingChecklist.columnSchema.oid
        var checklistNameCol = EnvironmentalAndPermittingChecklist.columnSchema.name

        var checklistItemRecords = this.findTableObjectRecords(data, EnvironmentalAndPermittingChecklistItem)
        var checklistItemIdCol = EnvironmentalAndPermittingChecklistItem.columnSchema.oid
        var checklistItemNameCol = EnvironmentalAndPermittingChecklistItem.columnSchema.name

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Checklist",
                        key: this.columnSchema.checklistId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(checklistRecords, value, checklistIdCol, checklistNameCol),
                            dropdownItems: buildDropdownItems(checklistRecords, checklistIdCol, checklistNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Item",
                        key: this.columnSchema.itemId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(checklistItemRecords, value, checklistItemIdCol, checklistItemNameCol),
                            dropdownItems: buildDropdownItems(checklistItemRecords, checklistItemIdCol, checklistItemNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "required",
                        key: this.columnSchema.required,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Document Link",
                        key: this.columnSchema.documentLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Comments",
                        key: this.columnSchema.comments,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const EnvironmentalAndPermittingChecklistEntry = new EnvironmentalAndPermittingChecklistEntryTable()