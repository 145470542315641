import React, {Component} from 'react'

import DatePicker from '../../../date_picker/DateRangePicker'
import StandardDataProvider from '../../../data_provider/StandardDataProvider'
import AvailabilitySummary from './AvailabilitySummary'

import { formatDate } from '../../../../utils/Dates'
import { startOfDay, endOfDay } from 'date-fns'
import Highcharts from 'highcharts'
import HC_more from 'highcharts/highcharts-more'
HC_more(Highcharts)

class AvailabilitySummaryContainer extends Component {

    constructor(props) {
        super()

        this.state = {
            selectedStartDate: formatDate(startOfDay(new Date())),
            selectedEndDate: formatDate(endOfDay(new Date())),
        }
        
    }

    render() {
        var siteTimezone = this.props.services.assetFramework.getSiteTimezone(this.props.selectedSiteId)

        return (
            <div className="section-panel flow-vertical fill-parent" style={this.props.style || {}}>
                <div className="section-header flow-horizontal flex-fixed flex-centered">
                    <div className="title">Availability Summary</div>
                </div>
                <StandardDataProvider
                    services={this.props.services}
                    selectedSiteId={this.props.selectedSiteId}
                    queryProps={["selectedSiteId"]}
                    queryUrl={'/api/market/regulation/availability_summary'}
                    render={(data) => {
                        return <AvailabilitySummary services={this.props.services} data={data} />
                    }}
                />
            </div>
        )
    }

    updateSelectedDates = (start, end) => {
        this.setState({
            selectedStartDate: start,
            selectedEndDate: end
        })
    }

}

export default AvailabilitySummaryContainer