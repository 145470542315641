import './DatePickerCell.css'

import { Table, DatePicker, Alert } from 'rsuite'
const { Cell } = Table

export default function DateCell({ rowData, dataIdKey, dataKey, onChange, cellOptions, isEditingFunction, pageLength, ...props }) {

    var itemId = rowData[dataIdKey]

    var isEditing = isEditingFunction(itemId)

    var dateValue = rowData[dataKey]

    //var placement = props.rowIndex % pageLength >= 13 ? "leftEnd" : "bottomStart"
    var rowHeight = (props.rowIndex+1)*props.height + props.headerHeight + (30+15+50)
    var placement = rowHeight + 400 > window.innerHeight ? "leftEnd" : "bottomStart"
    if (props.rowIndex <= 3) placement = "bottomStart"

    const processChange = (d) => {
        if (onChange) {
            if (cellOptions.transformNewValue) {
                try {
                    let updateObject = cellOptions.transformNewValue(d, dataKey, rowData)
                    if (onChange) onChange(rowData.pltr_table_id, Object.keys(updateObject), Object.values(updateObject));
                }
                catch (e) {
                    console.log(e)
                    Alert.error(`
                    Failed to process side effect on instance ${dataKey} change.
                    ${e}
                    `, 8000)
                    if (onChange) onChange(rowData.pltr_table_id, dataKey, d)
                }
            }
            else {
                if (onChange) onChange(rowData.pltr_table_id, dataKey, d)
            }
        }
    }

    return (
        <Cell {...props} className={isEditing ? 'table-content-editing' : ''}>
            {isEditing ? (
                <DatePicker
                    value={dateValue}
                    placement={placement}
                    {...cellOptions}
                    onOk={(date, e) => processChange(date)}
                    onClean={(e) => processChange(null)}
                />
            ) : (
                //<span className="table-content-edit-span">{dateValue instanceof Date ? dateValue.toLocaleDateString() : dateValue}</span>
                <span className="table-content-edit-span">{dateValue ? new Date(dateValue).toLocaleDateString('en-US', {timeZone: cellOptions.timezone || "UTC"}) : dateValue}</span>
            )}
        </Cell>
    );
};