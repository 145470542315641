import React, { useEffect, useRef } from 'react'

import { useInputDispatch } from '../../hooks/inputDispatch'

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { HelpPrompt } from '../help/HelpPrompt';

import { IMaskInput } from 'react-imask'


export function PalantirTextField({label, value, onChange, HelpProps, ...props}) {

    if (HelpProps) {
        let adornment = <InputAdornment position="end">
                            <HelpPrompt
                                services={props.services}
                                style={{position: "relative", bottom: "0px"}}
                                title={HelpProps.title} item={HelpProps.item}
                            />
                        </InputAdornment>
        if (props.InputProps) props.InputProps.endAdornment = adornment
        else props.InputProps = {endAdornment: adornment}
    }

    return (
        <TextField
            variant="standard"
            size="small"
            value={(value===null || value===undefined) ? "" : value}
            label={label}
            onChange={(x) => {
                console.log(x)
                let value = x.target.value
                if (value==="") value = null
                onChange(value)
            }}
            {...props}
        />
    )
}
export function PalantirDispatchedTextField({label, value, onChange, HelpProps, dispatchTimer=500, ...props}) {

    const ref = useRef()
    const dispatch = useInputDispatch(dispatchTimer)

    useEffect(() => {
        if (ref.current) {
            if (String(value)!=ref.current.value) {
                ref.current.value = value
            }
        }
    }, [value])


    if (HelpProps) {
        let adornment = <InputAdornment position="end">
                            <HelpPrompt
                                services={props.services}
                                style={{position: "relative", bottom: "0px"}}
                                title={HelpProps.title} item={HelpProps.item}
                            />
                        </InputAdornment>
        if (props.InputProps) props.InputProps.endAdornment = adornment
        else props.InputProps = {endAdornment: adornment}
    }

    return (
        <TextField
            variant="standard"
            size="small"
            defaultValue={(value===null || value===undefined) ? "" : value}
            label={label}
            onChange={(x) => {
                let v = x.target.value
                dispatch(() => onChange(v))
            }}
            {...props}
            inputProps={{
                ref: ref
            }}
        />
    )
}

export const makeTextMaskNumber = (args) => {
    
    return React.forwardRef(function TextMaskCustom(props, ref) {

        var { value, onChange, ...other } = props;

        return (
        <IMaskInput
            value={String(value)}
            mask={Number}
            radix="."
            signed={true}
            thousandsSeparator=","
            inputRef={ref}
            onAccept={(newValue) => {
                newValue = newValue.replaceAll(",", "")   // replace thousand separator
                if (newValue==="") newValue = null
                else if (newValue==="0") newValue = 0
                else if (Number(newValue)===Number(value)) return   // Don't callback when the numeric value hasn't changed
                onChange({
                    target: {
                        name: props.name,
                        value: newValue
                    }
                })
            }}
            {...other}
            {...args}
        />
        )
    })

}
export const makeTextMaskPercentage = (args) => {
    
    return React.forwardRef(function TextMaskCustom(props, ref) {

        var { value, onChange, ...other } = props;

        return (
        <IMaskInput
            value={value!=="" ? String(value*100) : null}
            mask={Number}
            radix="."
            signed={true}
            thousandsSeparator=","
            inputRef={ref}
            onAccept={(newValue) => {
                if (newValue==="") newValue = null
                console.log(newValue)
                newValue = typeof(newValue)==="string" ? ((parseFloat(newValue.replaceAll(",", ""))*100)/10000) : null
                console.log(newValue)
                // Don't exec callback if the parsed Number value hasn't changed. This won't exec callback when a . (decimal) is typed as it doesn't change the numerical value
                if (newValue===value) return
                onChange({
                    target: {
                        name: props.name,
                        value: newValue
                    }
                })
            }}
            max={100}
            min={0}
            {...other}
            {...args}
        />
        )
    })

}
/*export const TextMaskNumber = React.forwardRef(function TextMaskCustom(props, ref) {

    var { value, onChange, ...other } = props;

    return (
    <IMaskInput
        value={String(value)}
        mask={Number}
        radix="."
        signed={true}
        thousandsSeparator=","
        inputRef={ref}
        onAccept={(newValue) => {
            newValue = typeof(newValue)==="string" ? parseFloat(newValue.replaceAll(",", "")) : null
            console.log(value, newValue)
            // Don't exec callback if the parsed Number value hasn't changed. This won't exec callback when a . (decimal) is typed as it doesn't change the numerical value
            if (newValue===value) return
            onChange({
                target: {
                    name: props.name,
                    value: newValue
                }
            })
        }}
        {...other}
    />
    )
})*/