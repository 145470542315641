export function HelpEntityId(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{"ID"}</div>
            <hr></hr>
            <p>All entities have an associated UID that is used to uniquely identify them.</p>
            <p>
                This ID is programatically assigned at creation time and can not be altered through the lifetime of the entity.
            </p>
            <p>
                In most scenarios, you will never need to interact with the ID attribute; it is used internally for record identification.
            </p>
        </div>
    )
}

export function HelpEntityName(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{props.type + " > Name"}</div>
            <hr></hr>
            <p>
                The common name of the {props.type}.
            </p>
            {props.notes && <p>
                {props.notes}
            </p>}
        </div>
    )
}

export function HelpEntityDescription(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{props.type + " > Description"}</div>
            <hr></hr>
            <p>
                A description of the {props.type}.
            </p>
            {props.notes && <p>
                {props.notes}
            </p>}
        </div>
    )
}