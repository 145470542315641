import { schemas } from '../Constants'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import { Plant } from './Project'
import Table from './BaseTable'
import { makePlantId, makeNotes } from './CommonColumns'
import { contractStatusOptions, makeContractID, makeContractName, buildGetReferencedPlantId } from './Contract'
import { HelpLLCTable } from '../help_panels/Entity'
import { makeLLCId, makeLLCName, makeLLCDescription } from './CommonColumns'
import { injectCounterpartyName, Bank } from './Counterparty'
import { validateEntity, Counterparty } from './Entity'


class TaxEquityLLCTable extends Table {

    constructor() {

        var columnSchema = {
            taxEquityId: "tax_equity_id",
            taxEquityName: "tax_equity_name",
            description: "description",
        }

        var schemaName = schemas.llc
        var tableName = "tax_equity"

        var displayNameSingular = "Tax Equity LLC"
        var displayNamePlural = "Tax Equity LLCs"

        var pkUidColumn = columnSchema.taxEquityId
        var identifiers = [columnSchema.taxEquityId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var taxEquityLLCRecords = this.findTableObjectRecords(data, this)

        var validateTaxEquityPartnerLLC = (taxEquityLLCRecord) => validateEntity(taxEquityLLCRecords, taxEquityLLCRecord, this.columnSchema.taxEquityId, this.columnSchema.taxEquityName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validateTaxEquityPartnerLLC,
            validateUpdateItem: validateTaxEquityPartnerLLC,
            tableConfig: {
                columns: [
                    makeLLCId(this.columnSchema.taxEquityId),
                    makeLLCName(this.columnSchema.taxEquityName, this.displayNameSingular),
                    makeLLCDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var TaxEquityLLC = new TaxEquityLLCTable()

class TaxEquityLLCBanksTable extends Table {

    constructor() {

        var columnSchema = {
            taxEquityId: "tax_equity_llc_id",
            bankId: "bank_id",
            notes: "notes"
        }

        var schemaName = schemas.llc
        var tableName = "tax_equity_banks"

        var displayNameSingular = "Tax Equity LLC Bank"
        var displayNamePlural = "Tax Equity LLC Banks"

        var pkUidColumn = null
        var identifiers = [columnSchema.taxEquityId, columnSchema.bankId]
        var dependencies = [TaxEquityLLC, Bank]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = true
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var taxEquityLLCRecords = this.findTableObjectRecords(data, TaxEquityLLC)
        var bankRecords = this.findTableObjectRecords(data, Bank)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var taxEquityIdCol =  TaxEquityLLC.columnSchema.taxEquityId
        var taxEquityNameCol =  TaxEquityLLC.columnSchema.taxEquityName
        var bankIdCol = Bank.columnSchema.bankId
        var bankNameCol = "bankName"

        bankRecords = injectCounterpartyName(bankRecords, counterpartyRecords, bankIdCol, bankNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    {
                        name: "Tax Equity LLC",
                        key: this.columnSchema.taxEquityId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(taxEquityLLCRecords, value, taxEquityIdCol, taxEquityNameCol),
                            dropdownItems: buildDropdownItems(taxEquityLLCRecords, taxEquityIdCol, taxEquityNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Bank",
                        key: this.columnSchema.bankId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(bankRecords, value, bankIdCol, bankNameCol),
                            dropdownItems: buildDropdownItems(bankRecords, bankIdCol, bankNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Notes",
                        key: this.columnSchema.notes,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            flexGrow: 1,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export var TaxEquityLLCBanks = new TaxEquityLLCBanksTable()

class TaxEquityContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            contractName: "contract_name",
            plantId: "plant_id",
            taxEquityLLCId: "tax_equity_llc_id",
            status: "status",
            effectiveDate: "effective_date",
            endDate: "end_date",
            termYears: "term_years",
            expectedFinancialCloseDate: "expected_financial_close_date",
            actualFinancialCloseDate: "actual_financial_close_date",
            expectedFlipDate: "expected_flip_date",
            actualFlipDate: "actual_flip_date",
            expectedBuyOutDate: "expected_buy_out_date",
            actualBuyOutDate: "actual_buy_out_date",
            sharepointLink: "sharepoint_link",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "tax_equity"

        var displayNameSingular = "Tax Equity Contract"
        var displayNamePlural = "Tax Equity Contracts"

        var pkUidColumn = columnSchema.contractId
        var identifiers = [columnSchema.contractId]
        var dependencies = [Plant, TaxEquityLLC]
        
        super(
            schemaName, tableName,
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = true
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            status: [{
                label: "Not Started", value: "Not Started"
            }, {
                label: "In Progress", value: "In Progress"
            }, {
                label: "Secured", value: "Secured"
            }]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var llcRecords = this.findTableObjectRecords(data, TaxEquityLLC)
        var llcIdCol = TaxEquityLLC.columnSchema.taxEquityId
        var llcNameCol = TaxEquityLLC.columnSchema.taxEquityName

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.plantId]) return {pass: false, message: "Please give the contract a plant."}
            if (!contract[this.columnSchema.contractName]) return {pass: false, message: "Please give the contract a unique name."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.contractId),
                    makeContractName(this.columnSchema.contractName),
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                    {
                        name: "Tax Equity LLC",
                        key: this.columnSchema.taxEquityLLCId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(llcRecords, value, llcIdCol, llcNameCol),
                            dropdownItems: buildDropdownItems(llcRecords, llcIdCol, llcNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Effective Date",
                        key: this.columnSchema.effectiveDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "End Date",
                        key: this.columnSchema.endDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Term (years)",
                        key: this.columnSchema.termYears,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Expected Financial Close Date",
                        key: this.columnSchema.expectedFinancialCloseDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Actual Financial Close Date",
                        key: this.columnSchema.actualFinancialCloseDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Expected Flip Date",
                        key: this.columnSchema.expectedFlipDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Actual Flip Date",
                        key: this.columnSchema.actualFlipDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Expected Buy Out Date",
                        key: this.columnSchema.expectedBuyOutDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Actual Buy Out Date",
                        key: this.columnSchema.actualBuyOutDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes),
                ]
            },
        }
    }

}

export var TaxEquityContract = new TaxEquityContractTable()

class TaxEquityContractCounterpartiesTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            bankId: "bank_id",
            financingStatus: "financing_status",
            notes: "notes"
        }

        var schemaName = schemas.contract
        var tableName = "tax_equity_counterparties"

        var displayNameSingular = "Tax Equity Contract Bank"
        var displayNamePlural = "Tax Equity Contract Banks"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.bankId]
        var dependencies = [TaxEquityContract, Bank]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = true
        this.initOptions()
    }

    initOptions() {
        this.options = {
            status: [{
                label: "Not Started", value: "Not Started"
            }, {
                label: "In Progress", value: "In Progress"
            }, {
                label: "Completed", value: "Completed"
            }]
        }
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    getReferencedPlantId = buildGetReferencedPlantId(this, TaxEquityContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, TaxEquityContract)
        var bankRecords = this.findTableObjectRecords(data, Bank)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var contractIdCol = TaxEquityContract.columnSchema.contractId
        var contractNameCol = TaxEquityContract.columnSchema.contractName
        var bankIdCol =  Bank.columnSchema.bankId
        var bankNameCol =  "bankName"

        bankRecords = injectCounterpartyName(bankRecords, counterpartyRecords, bankIdCol, bankNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            flexGrow: 1,
                            resizable: true,
                        }
                    }, {
                        name: "Tax Equity Bank",
                        key: this.columnSchema.bankId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(bankRecords, value, bankIdCol, bankNameCol),
                            dropdownItems: buildDropdownItems(bankRecords, bankIdCol, bankNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Financing Status",
                        key: this.columnSchema.financingStatus,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.status,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes, 1),
                ]
            },
        }
    }

}
export const TaxEquityContractCounterparties = new TaxEquityContractCounterpartiesTable()