import {Component} from 'react'

import CRUDTable from '../../../table/CRUDTable'
import {cobjectTableConfig} from '../../../table/config_templates/cobject'
import {HelpDescription, HelpCode} from '../../../help/descriptions/EventTemplate'

class TemplateConfigTable extends Component {

    constructor(props) {
        super()

        this.state = {
            
        }
    }

    render() {

        return (
            <CRUDTable 
                services={this.props.services}
                initialItems={this.props.services.assetFramework.getEventTemplates()}
                itemType="Event Template"
                itemSchema={this.getBlueprintTemplateSchema()}
                flattenItem={this.flattenBlueprintTemplate}
                structureItem={this.structureBlueprintTemplate}
                tableConfig={{
                    columns: [
                        {
                            name: "Id",
                            key: "_id",
                            cellType: "standard",
                            colProps: {
                                width: 100,
                                resizable: true,
                                fixed: "left",
                            }
                        },
                        {
                            name: "Event Code",
                            key: "code",
                            cellType: "editable",
                            colProps: {
                                width: 200,
                                resizable: true,
                            },
                            info: HelpCode
                        },
                        {
                            name: "Event Description",
                            key: "description",
                            cellType: "editable",
                            filterable: false,
                            sortable: false,
                            colProps: {
                                width: 200,
                                resizable: true,
                            },
                            info: HelpDescription
                        },
                        {
                            colGroup: true,
                            header: "Evaluation",
                            columns: [
                                cobjectTableConfig.type(),
                                cobjectTableConfig.function(),
                                cobjectTableConfig.code({services: this.props.services, selectedSiteId: this.props.selectedSiteId}),
                                cobjectTableConfig.range(),
                                cobjectTableConfig.rangedDataAccessMethod(),
                            ]
                        },
                    ]
                }}
                endpoints={{
                    create: "/api/events/template/create",
                    update: "/api/events/template/update",
                    delete: "/api/events/template/delete"
                }}
                onCreateSuccess={(newTemplate) => {
                    this.props.services.assetFramework.addEventTemplate(newTemplate)
                }}
                onUpdateSuccess={(updatedTemplate) => {
                    this.props.services.assetFramework.updateEventTemplate(updatedTemplate)
                }}
                onDeleteSuccess={(deletedTemplate) => {
                    this.props.services.assetFramework.deleteEventTemplate(deletedTemplate)
                }}
                validateNewItem={this.validateItem}
                validateUpdateItem={this.validateItem}
            />
        );
        
    }

    getBlueprintTemplateSchema = () => {
        var schema = {
            _id: null,
            code: "",
            description: "",

            cobjectInherit: false,
            cobjectType: "standard",
            cobjectFunction: "evaluate",
            cobjectCode: "",
            cobjectRange: [0,0],
            cobjectRangedDataAccessMethod: null,
        }
        return schema
    }

    flattenBlueprintTemplate = (bpt) => {
        return {
            _id: bpt._id,
            code: bpt.code,
            description: bpt.description,
            cobjectInherit: bpt.cobject?.inherit,
            cobjectType: bpt.cobject?.type,
            cobjectFunction: bpt.cobject?.function,
            cobjectCode: bpt.cobject?.code,
            cobjectRange: bpt.cobject?.range,
            cobjectRangedDataAccessMethod: bpt.cobject?.ranged_data_access_method,
        }
    }

    structureBlueprintTemplate = (bptFlat) => {
        return {
            _id: bptFlat._id,
            code: bptFlat.code,
            description: bptFlat.description,

            cobject: {
                inherit: bptFlat.cobjectInherit,
                type: bptFlat.cobjectType,
                function: bptFlat.cobjectFunction,
                code: bptFlat.cobjectCode,
                range: bptFlat.cobjectRange,
                ranged_data_access_method: bptFlat.cobjectRangedDataAccessMethod
            },
        }
    }

    validateItem = (item) => {
        // Perform checks on template
        var isCodeEmpty = item.code === ""
        var isDescriptionEmpty = item.description === ""
        if (isCodeEmpty || isDescriptionEmpty) {
            return {pass: false, message: "Please fill in event code and description fields"}
        }
        return {pass: true}
    }

}

export default TemplateConfigTable