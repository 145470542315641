import './View.css'

import { TabPanel, useTabCache } from './DatabaseView.js'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'


export default function ViewSelection({panels, ...props}) {

    const [selectedTabValue, visitedTabValues, addVisitedTab, setSelectedTabValue] = useTabCache()

    var [TabLabels, TabPanels] = [[], []]
    panels.forEach((panel, idx) => {
        const Component = panel.component
        TabLabels.push(<Tab key={panel.label} label={panel.label} id={`tab-${idx}`} />)
        TabPanels.push(
            <TabPanel key={panel.label} tabValue={idx} selectedTabValue={selectedTabValue} visitedTabValues={visitedTabValues} >
                {
                    (!props.selectedPlant && !panel.allowNullProject)
                    ?
                    <div
                        className="flex"
                        style={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center"}}
                    >
                        This form requires a project selection.
                    </div>
                    :
                    <Component {...props} />
                }
            </TabPanel>
        )
    })

    return (
        <div className="view-container flow-vertical fill-parent">
            <Tabs value={selectedTabValue} onChange={(event, newValue) => {addVisitedTab(newValue); setSelectedTabValue(newValue)}} variant="scrollable" >
                {TabLabels}
            </Tabs>
            {TabPanels}
        </div>
    )
}
