import './HeaderCellContent.css'
import React from 'react'

import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion, faSort, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import { deepIncludes } from "../../../utils/MiscUtils"
import MenuWrapper from '../../menu/MenuWrapper'
import { ITEM_SELECTION_TYPES } from '../../menu/MenuContainer'

import { Whisper, Popover } from 'rsuite'

export default function HeaderCellContent({allItems, allColumnItems, columnFilters, sortType, sortColumn, colTitle, colDataKey, ColHelp, filterable, sortable, transformDisplayValueFunction, handleFilter, handleSort, setHelp}) {

    if (filterable === undefined) filterable = true
    if (sortable === undefined) sortable = true

    let uniqueItems = _.uniqBy(allItems, (x) => String(x[colDataKey]))
    
    let selectedItems = uniqueItems
    if (columnFilters) {   // null or empty filter implies no filter
        selectedItems = uniqueItems.filter(x => deepIncludes(columnFilters, x[colDataKey]))
    }
    uniqueItems = _.sortBy(uniqueItems, x => transformDisplayValueFunction(x[colDataKey], x))
    uniqueItems = uniqueItems.map(x => x[colDataKey])

    // Ref to close filter menu on close
    const triggerRef = React.createRef()
    const close = () => triggerRef.current.close();
    const open = () => triggerRef.current.open();

    const ItemMenu = (
        <MenuWrapper
            createSkeleton={(item, selectedItems) => {
                let value = item
                let check = deepIncludes(selectedItems, item)
                let label = transformDisplayValueFunction(value)
                return {
                    "key": value,
                    "label": label,
                    "selectable": true,
                    "active": check,
                }
            }}
            customSort={true}
            onSubmit={(selectedItems) => handleFilter(colDataKey, selectedItems, uniqueItems)}
            onClose={() => close()}
            items={uniqueItems}
            selectedItems={selectedItems}
            updateState={(curState, newItem) => {
                let newState = curState;   // this is already cloned
                let selectedItemIndex = curState.findIndex(x => _.isEqual(x, newItem))
                if (selectedItemIndex !== -1) {
                    newState.splice(selectedItemIndex, 1)
                }
                else {
                    newState.push(newItem)
                }
                return newState
            }}
            menuProps={{
                headerName: `Filter - ${colTitle}`,
                peripheralsConfig: {enabled: true},
                className: "",
                itemSelectionType: ITEM_SELECTION_TYPES.checkbox,
            }}
        />
    )

    const itemMenuPopup = <Popover style={{minWidth: '400px', maxWidth: '400px', maxHeight: '400px', overflowY: 'auto'}}>
                            {ItemMenu}
                        </Popover>

    // determine sort icon
    let nextSortOrder = (sortType==="asc" ? "desc" : "asc")
    let sortIcon = faSort
    let isSorted = false
    if (sortColumn===colDataKey) {
        sortIcon = (sortType==="asc" ? faSortUp : faSortDown)
        isSorted = true
    }

    const openHelpPanel = () => {
        const HelpComponent = ColHelp ? <ColHelp /> : "";
        if (setHelp) setHelp(colTitle, HelpComponent);
    }

    return (
            <span>
                <span className="label">{colTitle}</span>
                {filterable &&
                    <Whisper placement="bottom" trigger="none" speaker={itemMenuPopup} ref={triggerRef} enterable preventOverflow>
                        <span onClick={open} style={{marginLeft: '6px'}} ><FontAwesomeIcon 
                            className={"fa-icon filter clickable" + (columnFilters ? " active" : "")}
                            size="sm" 
                            icon={faFilter}
                        /></span>
                    </Whisper>
                }
                {sortable && <span style={{marginLeft: '6px'}}>
                    <FontAwesomeIcon
                        onClick={() => handleSort(colDataKey, nextSortOrder)} 
                        className={"fa-icon sort clickable" + (isSorted ? " active" : "")}
                        size="1x" 
                        icon={sortIcon} 
                    />
                </span>}
                <span style={{marginLeft: "6px"}} onClick={openHelpPanel} >
                    <FontAwesomeIcon icon={faQuestion} className="fa-icon" size="1x" />
                </span>
            </span>
    )
}