import _ from "lodash"

export function deepIncludes(collection, item) {
    return collection.reduce((prev, cur, idx) => prev || _.isEqual(cur, item), false)
}

export function validateEmail(email) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true
    }
    return false
}

export const validateHTTPURL = (input) => {
    let url;
    try {
      url = new URL(input);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export function groupBy(data, key) {
    var groups = {}
    data.forEach(function(element) {
        let keyValue = element[key]
        if (keyValue !== null && keyValue !== undefined) {
            if (keyValue in groups === false) {
                groups[keyValue] = []
            }
            groups[keyValue].push(element)
        }
    })
    return groups
}

export const list2Dict = (list, key) => {
    const dict = {}
    list.forEach(x => {
        dict[x[key]] = x
    })
    return dict
}