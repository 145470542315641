import {Component} from 'react'

/**
 * Persists the URL location query params on page navigation
 * @param {Component} WrappedComponent React child component to render
 * @param {boolean} updateOnRender Determines whether to push the state location to the URL on a rerender
 * @returns 
 */
function PersistURLQuery(WrappedComponent) {

    return class extends Component {

        constructor(props) {
            super()
            this.state = {
                locationParams: window.location.search
            }
        }

        render() {

            // This will update the url search params if the current search is empty. This works because whenever navigation happens, all url params are cleared
            // Note this happens before rendering child components so all children will have access to the correct URL params
            if (window.location.search==="") {
                this.pushStateToURL()
            }

            //if (true) this.pushStateToURL()   // this will push the state to the URL before child components render so that children have access the the current URL
            return <WrappedComponent onRouterLinkClick={this.setLocationParams.bind(this)} {...this.props} />
        }

        /**
         * Callback function to provide to child router component
         * This function should be invoked when child router component links are clicked
         *  This will update the local state with the correct params, then reflect this in the URL bar by pushing the local params without triggering a rerender of the page, and finally rendering the child router
         *  This allows the child router component to be oblivious to URL query params when specifying routes, while still retaining the params
         * @param {*} query 
         */
        setLocationParams() {
            var curLocation = window.location.search
            this.setState({
                locationParams: curLocation,
            })
        }

        pushStateToURL() {
            const url = new URL(window.location)
            url.search = this.state.locationParams
            window.history.pushState({}, '', url)
        }

    }

}

export default PersistURLQuery