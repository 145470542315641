import {Component} from 'react'

import DataProvider from '../../../data_provider/StandardDataProvider'
import BaseChart from '../../../charts/BaseChart'
import DimensionSelectionModal from '../../../modal/DimensionSelectionModal'

import _ from 'lodash'

class AwardContainer extends Component {
    constructor(props) {
        super()
        this.state = {
            selectedDimensions: {1: [1], 2: [1,2,3], 3: [1,2,3]},
        }
    }

    render() {
        return (
            <div className="flow-vertical fill-parent section-panel">
                <div className="flow-horizontal section-header">
                    <div className="title">Awards</div>
                    <div className="fill-parent" />
                    <DimensionSelectionModal services={this.props.services} selectedDimensions={this.state.selectedDimensions} onClose={(selectedDimensions) => {this.setState({selectedDimensions: selectedDimensions})}}  />
                </div>
                <DataProvider
                    services={this.props.services}
                    selectedSiteId={this.props.selectedSiteId}
                    selectedStartDate={this.props.selectedStartDate}
                    selectedEndDate={this.props.selectedEndDate}
                    selectedResolution={this.props.selectedResolution}
                    additionalParams={{
                        dimensions: this.state.selectedDimensions
                    }}
                    queryProps={["selectedSiteId", "selectedStartDate", "selectedEndDate", "selectedResolution", "additionalParams"]}
                    queryUrl={'/api/market/awards'}
                    autoRefresh
                    render={this.renderChart}
                />
            </div>
        )
    }

    renderChart = (data, dataRequestId) => {

        var chartOptions = _.cloneDeep(this.props.chartOptions)
        //chartOptions.chart = {type: "column"}
        //chartOptions.plotOptions.series.stacking = "normal"

        return <BaseChart 
                    services={this.props.services}
                    timezone={this.props.services.assetFramework.getSiteTimezone(this.props.selectedSiteId)}
                    data={data}
                    seriesValueDecimals={2}
                    yAxes={{title: "Award", units: "MW"}} 
                    chartOptions={chartOptions}
                />

    }

}

export default AwardContainer