import {Component} from 'react'

import BlueprintConfigTable from './EventBlueprintConfigTable'
import SectionHeader from '../../../section/SectionHeader'
import {HelpEventBlueprintConfiguration} from '../../../help/descriptions/tabs/Configuration'
import HelpPanel from '../../../help/HelpPanel'

class EventBlueprintConfiguration extends Component {

    constructor(props) {
        super()
        this.state = {
            
        }
    }

    render() {
        const Tab = <HelpPanel
                        services={this.props.services}
                        render={(newServices) => {
                            return (
                                <div className="section-main flow-vertical fill-parent">
                                    <SectionHeader services={newServices} sectionTitle="Event Blueprint Configuration" sectionDescription={<HelpEventBlueprintConfiguration />} />
                                    <BlueprintConfigTable services={newServices} selectedSiteId={this.props.selectedSiteId} />
                                </div>
                            )
                        }}
                    />

        return Tab
    }

}

export default EventBlueprintConfiguration