import { BusinessCategory, LessonLearned, LessonLearnedInstances, OpportunityRegister, Plant, RiskRegister, TemporalProjectPricingBlocks, TemporalProjectPricingPrices, TemporalProjectPricingTemplate, TemporalProjectPricingType } from "../../../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from "../../../../components/input/Text"
import { PalantirAutocomplete, PalantirBooleanSelector, PalantirDatePicker, PalantirSelector, } from "../../../../components/input/SelectPicker"
import { useChangeLog } from "../../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../../hooks/databaseViewFormValidation"
import ViewPanel from "../../ViewPanel"
import { generateUUID } from "../../../../utils/databaseAppUtils"
import { useState } from "react"
import { DialogProvider, useDialog } from "../../../../components/dialog/DialogProvider"
import { DialogContentText } from "@mui/material"
import { PalantirBasicCRUDMenu } from "../../../../components/menuv2/Menu"
import { Text, SegmentedControl, Badge, Alert } from "@mantine/core"
import { Counterparty, Employee } from "../../../table_configuration/Entity"
import _ from 'lodash'
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from "../../../../components/button/FloaterButtonWithPrompt"
import { PermittingContract, PermittingDatesContract } from "../../../table_configuration/Contract"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons"


const makePermittingContractRecord = (projectId) => {
    const record = PermittingContract.buildNewRecord()
    record[PermittingContract.columnSchema.oid] = generateUUID()
    record[PermittingContract.columnSchema.projectId] = projectId
    return record
}


export default function PermittingPanelProxy(props) {

    const permittingContracts = props.data[PermittingContract.buildId()].filter(x => x[PermittingContract.columnSchema.projectId]===props.selectedPlantId)
    const permittingContractDates = props.data[PermittingDatesContract.buildId()]
    const counterparties = _.sortBy(props.data[Counterparty.buildId()], Counterparty.columnSchema.counterpartyName)

    return (
        <DialogProvider>
            <PermittingPanel
                initialPermittingContracts={permittingContracts}
                initialPermittingContractDates={permittingContractDates}
                counterparties={counterparties}
                {...props}
            />
        </DialogProvider>
    )
}

function PermittingPanel({initialPermittingContracts, initialPermittingContractDates, counterparties, ...props}) {

    const [
        contractRecords, prepareContractLog, updateContractLog, addToContractLog,
        deleteFromContractLog, bulkOpOnContractLog, mergeAndResetContractLog, resetContractLog, popFromContractLog
    ] = useChangeLog(initialPermittingContracts, PermittingContract)
    const [
        contractDatesRecords, prepareContractDatesLog, updateContractDatesLog, addToContractDatesLog,
        deleteFromContractDatesLog, bulkOpOnContractDatesLog, mergeAndResetContractDatesLog, resetContractDatesLog, popFromContractDatesLog
    ] = useChangeLog(initialPermittingContractDates, PermittingDatesContract)
    
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()
    
    const addNewPermittingContract = () => {
        const record = PermittingContract.buildNewRecord()
        record[PermittingContract.columnSchema.oid] = generateUUID()
        record[PermittingContract.columnSchema.projectId] = props.selectedPlantId
        addToContractLog(record)
    }
    const addNewPermittinContractDate = (contractId) => {
        const record = PermittingDatesContract.buildNewRecord()
        record[PermittingDatesContract.columnSchema.oid] = generateUUID()
        record[PermittingDatesContract.columnSchema.contractId] = contractId
        addToContractDatesLog(record)
    }

    let ctr = 3
    const items = contractRecords.map(contractRecord => {

        const contractItemIdx = ctr
        ctr+=1
        const contractDateTableIdx = ctr
        ctr+=1

        const contractId = contractRecord[PermittingContract.columnSchema.oid]
        const filteredContractDates = _.sortBy(contractDatesRecords.filter(x => x[PermittingDatesContract.columnSchema.contractId]===contractId), PermittingDatesContract.columnSchema.startDate)

        return (
            <ContractItem
                contractItemRow={contractItemIdx}
                contractDateTableRow={contractDateTableIdx}
                contract={contractRecord}
                contractDates={filteredContractDates}
                counterparties={counterparties}
                addContractDate={() => addNewPermittinContractDate(contractId)}
                updateContract={(update) => updateContractLog(contractRecord, update)}
                updateContractDatesLog={updateContractDatesLog}
                deleteContract={() => {
                    deleteFromContractLog(contractRecord)
                    deleteFromContractDatesLog(filteredContractDates)
                }}
                deleteContractDate={deleteFromContractDatesLog}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Permitting"
            submitUrl='api/precious/table/permitting_contract'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.contractChangeLog,
                        checks: []
                    }, {
                        changeLog: payload.contractDatesChangeLog,
                        checks: []
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetContractLog()
                mergeAndResetContractDatesLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    contractChangeLog: prepareContractLog(),
                    contractDatesChangeLog: prepareContractDatesLog()
                }
            }}
        >
            <div className="form-instance" style={{minWidth: "1500px"}}>
                <div style={{display: "grid", gridTemplateColumns: "50px auto 140px 180px auto 140px 110px 140px", gridTemplateRows: "30px 40px auto", columnGap: "20px", rowGap: "10px", marginLeft: "30px"}}>
                    <Text weight={500} style={{gridRow: 1, gridColumn: "1 / -1"}} size="lg">Permitting</Text>
                    <div style={{gridRow: 2, gridColumn: 1}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>ID</Text></div>
                    <div style={{gridRow: 2, gridColumn: 2}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Name</Text></div>
                    <div style={{gridRow: 2, gridColumn: 3}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Status</Text></div>
                    <div style={{gridRow: 2, gridColumn: 4}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Authorizing Agency</Text></div>
                    <div style={{gridRow: 2, gridColumn: 5}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Trigger</Text></div>
                    <div style={{gridRow: 2, gridColumn: 6}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Responsibility</Text></div>
                    <div style={{gridRow: 2, gridColumn: 7}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Jurisdiction</Text></div>
                    <div style={{gridRow: 2, gridColumn: 8}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Sharepoint Link</Text></div>
                    {items}
                    <div style={{gridRow: ctr, gridColumn: "1 / -1"}}>
                        <AddFloaterButtonWithPrompt
                            onClick={addNewPermittingContract}
                            labelContent="Add permit"
                        />
                    </div>
                </div>
            </div>
        </ViewPanel>
    )
}

const ContractItem = ({contractItemRow, contractDateTableRow, contract, contractDates, counterparties, updateContract, updateContractDatesLog, addContractDate, deleteContract, deleteContractDate}) => {

    const [isContractDatesVisible, setIsContractDatesVisible] = useState(false)
    const toggleIsContractDatesVisible = () => {
        setIsContractDatesVisible(!isContractDatesVisible)
    }

    const counterpartyId = contract[PermittingContract.columnSchema.responsibility]
    const counterparty = counterparties.find(x => x[Counterparty.columnSchema.counterpartyId]===counterpartyId) || {}

    let contractDateTableCtr = 3
    const filteredContractDateComponents = contractDates.map(contractDate => {

        const contractDateIdx = contractDateTableCtr
        contractDateTableCtr+=1

        return (
            <ContractDate
                gridRow={contractDateIdx}
                contractDate={contractDate}
                updateContractDate={(update) => updateContractDatesLog(contractDate, update)}
                deleteContractDate={() => deleteContractDate(contractDate)}
            />
        )
    })

    return (
        <>
            <DeleteFloaterButtonWithPrompt
                onClick={deleteContract}
                style={{gridRow: contractItemRow, gridColumn: 1, width: "15px", position: "relative", top: "8px", right: "42px", display: "block"}}
            />
            <div style={{gridRow: contractItemRow, gridColumn: 1, width: "15px", position: "relative", top: "8px", right: "18px"}}>
                <FontAwesomeIcon
                    onClick={toggleIsContractDatesVisible}
                    icon={isContractDatesVisible ? faAngleDown : faAngleRight}
                    style={{}}
                />
            </div>
            
            <PalantirTextField
                value={contract[PermittingContract.columnSchema.oid]}
                disabled
                style={{gridRow: contractItemRow, gridColumn: 1}}
            />
            <PalantirTextField
                value={contract[PermittingContract.columnSchema.contractName]}
                onChange={(x) => updateContract({[PermittingContract.columnSchema.contractName]: x})}
                style={{gridRow: contractItemRow, gridColumn: 2}}
            />
            <PalantirSelector
                value={contract[PermittingContract.columnSchema.status]}
                items={PermittingContract.options.status}
                onChange={(x) => updateContract({[PermittingContract.columnSchema.status]: x})}
                style={{gridRow: contractItemRow, gridColumn: 3, minWidth: "0px"}}
            />
            <PalantirDispatchedTextField
                value={contract[PermittingContract.columnSchema.authorizingAgency]}
                onChange={(x) => updateContract({[PermittingContract.columnSchema.authorizingAgency]: x})}
                style={{gridRow: contractItemRow, gridColumn: 4}}
            />
            <PalantirDispatchedTextField
                value={contract[PermittingContract.columnSchema.triggerDescription]}
                onChange={(x) => updateContract({[PermittingContract.columnSchema.triggerDescription]: x})}
                style={{gridRow: contractItemRow, gridColumn: 5}}
            />
            <PalantirAutocomplete
                value={{label: counterparty[Counterparty.columnSchema.counterpartyName], id: counterpartyId}}
                options={counterparties.map(cp => {
                    return {
                        label: cp[Counterparty.columnSchema.counterpartyName], id: cp[Counterparty.columnSchema.counterpartyId]
                    }
                })}
                onUpdate={(value) => updateContract({[PermittingContract.columnSchema.responsibility]: value.id})}
                style={{gridRow: contractItemRow, gridColumn: 6}}
            />
            <PalantirSelector
                value={contract[PermittingContract.columnSchema.jurisdictionLevel]}
                items={PermittingContract.options.jurisdictionLevel}
                onChange={(x) => updateContract({[PermittingContract.columnSchema.jurisdictionLevel]: x})}
                style={{gridRow: contractItemRow, gridColumn: 7, minWidth: "0px"}}
            />
            <PalantirTextField
                value={contract[PermittingContract.columnSchema.sharepointLink]}
                onChange={(x) => updateContract({[PermittingContract.columnSchema.sharepointLink]: x})}
                style={{gridRow: contractItemRow, gridColumn: 8}}
            />
            {isContractDatesVisible &&
                <div style={{gridRow: contractDateTableRow, gridColumn: "1 / span 6", display: "grid", gridTemplateColumns: "50px auto 140px 160px 160px", gridTemplateRows: "20px 30px auto", columnGap: "20px", rowGap: "10px", marginLeft: "30px", marginBottom: "15px"}}>
                    <Text weight={500} style={{gridRow: 1, gridColumn: "1 / -1"}}>Dates</Text>
                    <div style={{gridRow: 2, gridColumn: 1}}><Text size="lg" style={{borderBottom: "dotted grey 1px"}}>ID</Text></div>
                    <div style={{gridRow: 2, gridColumn: 2}}><Text size="lg" style={{borderBottom: "dotted grey 1px"}}>Title</Text></div>
                    <div style={{gridRow: 2, gridColumn: 3}}><Text size="lg" style={{borderBottom: "dotted grey 1px"}}>Status</Text></div>
                    <div style={{gridRow: 2, gridColumn: 4}}><Text size="lg" style={{borderBottom: "dotted grey 1px"}}>Start Date</Text></div>
                    <div style={{gridRow: 2, gridColumn: 5}}><Text size="lg" style={{borderBottom: "dotted grey 1px"}}>End Date</Text></div>
                    <div style={{gridRow: 2, gridColumn: 6}}><Text size="lg" style={{borderBottom: "dotted grey 1px"}}>Description</Text></div>
                    {filteredContractDateComponents}
                    <div style={{gridRow: contractDateTableCtr, gridColumn: "1 / -1"}}>
                        <AddFloaterButtonWithPrompt
                            onClick={addContractDate}
                            labelContent="Add date"
                        />
                    </div>
                    
                </div>
            }
        </>
    )
}
const ContractDate = ({gridRow, contractDate, updateContractDate, deleteContractDate}) => {
    return (
        <>
            <DeleteFloaterButtonWithPrompt
                onClick={deleteContractDate}
                style={{gridRow: gridRow, gridColumn: 1, position: "relative", top: "0px", right: "22px"}}
            />
            <PalantirTextField
                value={contractDate[PermittingDatesContract.columnSchema.oid]}
                disabled
                style={{gridRow: gridRow, gridColumn: 1}}
            />
            <PalantirTextField
                value={contractDate[PermittingDatesContract.columnSchema.title]}
                onChange={(x) => updateContractDate({[PermittingDatesContract.columnSchema.title]: x})}
                style={{gridRow: gridRow, gridColumn: 2}}
            />
            <PalantirSelector
                value={contractDate[PermittingDatesContract.columnSchema.status]}
                items={PermittingDatesContract.options.status}
                onChange={(x) => updateContractDate({[PermittingDatesContract.columnSchema.status]: x})}
                style={{gridRow: gridRow, gridColumn: 3, minWidth: "0px"}}
            />
            <PalantirDatePicker
                value={contractDate[PermittingDatesContract.columnSchema.startDate]}
                onChange={(x) => updateContractDate({[PermittingDatesContract.columnSchema.startDate]: x})}
                style={{gridRow: gridRow, gridColumn: 4}}
            />
            <PalantirDatePicker
                value={contractDate[PermittingDatesContract.columnSchema.endDate]}
                onChange={(x) => updateContractDate({[PermittingDatesContract.columnSchema.endDate]: x})}
                style={{gridRow: gridRow, gridColumn: 5}}
            />
            <PalantirDispatchedTextField
                value={contractDate[PermittingDatesContract.columnSchema.description]}
                onChange={(x) => updateContractDate({[PermittingDatesContract.columnSchema.description]: x})}
                style={{gridRow: gridRow, gridColumn: 6}}
            />
        </>
    )
}