import { Plant, TechnicalDueDiligence } from "../../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber, makeTextMaskPercentage } from "../../../components/input/Text"
import { PalantirBooleanSelector, PalantirDatePicker, PalantirSelector, } from "../../../components/input/SelectPicker"
import { useChangeLog } from "../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../hooks/databaseViewFormValidation"
import ViewPanel from "../ViewPanel"
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from "../../../components/button/FloaterButtonWithPrompt"
import { Text, Tabs } from "@mantine/core"
import { HelpPrompt } from "../../../components/help/HelpPrompt"
import { HelpDryBulbTemp, HelpRainfall, HelpSnowLoad, HelpWindLoad, HelpGlare, HelpSeismic, HelpFlood, HelpWetlands, HelpBiological } from "../../../components/help/precious/Columns"
import { booleanOptions } from "../../table_configuration/CommonColumns"
import { useState } from "react"


const TextMaskBasic = makeTextMaskNumber({
    scale: 5
})
const TextMaskITCQualifying = makeTextMaskPercentage()


export default function ProjectTaxPanelProxy(props) {

    const project = props.data[Plant.buildId()].find(x => x[Plant.columnSchema.plantId]===props.selectedPlantId) || null

    return (
        <ProjectTaxPanel
            initialProject={project}
            {...props}
        />
    )
}

function ProjectTaxPanel({initialProject, updateFormChangeTracker, ...props}) {

    const [selectedTab, setSelectedTab] = useState("energy-community")
    const [
        projectRecords, prepareLogs, updateProjectLog, addToLog,
        deleteFromLog, bulkOpOnLog, mergeAndResetLogs
    ] = useChangeLog(initialProject!==null ? [initialProject] : [], Plant)

    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const hasProjectRecord = projectRecords.length > 0
    const project = hasProjectRecord ? projectRecords[0] : null

    const updateProjectLogProxy = (update) => updateProjectLog(project, update)

    return (
        <ViewPanel
            services={props.services}
            title="Project Tax"
            submitUrl='api/precious/table/plant'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission({
                    changeLog: payload.projectChangelog,
                    checks: []
                })
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetLogs()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    projectChangelog: prepareLogs(),
                }
            }}
        >
            {
                !hasProjectRecord ? 
                <div className="flow-horizontal" >
                    <Text size="xs">This Project seems to not have a project. It may have been deleted.</Text>
                </div>
                :
                <>
                <Tabs active={selectedTab} onTabChange={(_, tabKey) => setSelectedTab(tabKey)} >
                    <Tabs.Tab label="Energy Community" tabKey="energy-community"></Tabs.Tab>
                    <Tabs.Tab label="Low Income Community" tabKey="low-income-community"></Tabs.Tab>
                </Tabs>
                <div className="flow-horizontal" style={{minWidth: "800px", marginTop: "10px"}}>
                    <div
                        className="fill-parent form-instance"
                        style={{border: "solid lightgrey 1px", height: "600px", marginRight: "15px", minWidth: "400px"}}
                    >
                        {selectedTab==="energy-community" && <EnergyCommunityMap />}
                        {selectedTab==="low-income-community" && <LowIncomeCommunityMap />}
                    </div>
                    <div className="flow-vertical" style={{scroll: "auto", width: "350px"}}>
                            <div className="form-instance flow-vertical field-group">
                                <PalantirSelector
                                    label="Domestic Content"
                                    value={project[Plant.columnSchema.domesticContent]}
                                    onChange={(x) => updateProjectLogProxy({[Plant.columnSchema.domesticContent]: x})}
                                    items={booleanOptions}
                                    style={{marginBottom: "15px"}}
                                />
                                <PalantirSelector
                                    label="Prevailing Wage"
                                    value={project[Plant.columnSchema.prevailingWage]}
                                    onChange={(x) => updateProjectLogProxy({[Plant.columnSchema.prevailingWage]: x})}
                                    items={booleanOptions}
                                    style={{marginBottom: "15px"}}
                                />
                                <PalantirTextField
                                    label="ITC Qualifying (%)"
                                    value={project[Plant.columnSchema.itcQualifying]}
                                    InputProps={{
                                        inputComponent: TextMaskITCQualifying
                                    }}
                                    onChange={(x) => updateProjectLogProxy({[Plant.columnSchema.itcQualifying]: x})}
                                    style={{marginBottom: "15px"}}
                                />
                            </div>
                        {selectedTab==="energy-community" && <EnergyCommunitySection project={project} updateProjectLogProxy={updateProjectLogProxy} />}
                        {selectedTab==="low-income-community" && <LowIncomeCommunitySection project={project} updateProjectLogProxy={updateProjectLogProxy} />}
                    </div>
                        
                </div>
                </>
            }
        </ViewPanel>
    )
}

const EnergyCommunitySection = ({project, updateProjectLogProxy}) => {
    return (
        <div className="form-instance">
            <PalantirSelector
                label="Energy Community Qualifying"
                value={project[Plant.columnSchema.energyCommunityQualifying]}
                onChange={(x) => updateProjectLogProxy({[Plant.columnSchema.energyCommunityQualifying]: x})}
                items={booleanOptions}
                style={{marginBottom: "15px"}}
            />
            <PalantirDispatchedTextField
                label="Energy Community Notes"
                value={project[Plant.columnSchema.energyCommunityQualifyingNotes]}
                multiline
                rows={9}
                variant="filled"
                onChange={(x) => updateProjectLogProxy({[Plant.columnSchema.energyCommunityQualifyingNotes]: x})}
                style={{marginBottom: "15px"}}
            />
        </div>
    )
}
const LowIncomeCommunitySection = ({project, updateProjectLogProxy}) => {
    return (
        <div className="form-instance flow-vertical">
            <PalantirSelector
                label="Low-Income Community Qualifying"
                value={project[Plant.columnSchema.lowIncomeCommunityQualifying]}
                onChange={(x) => updateProjectLogProxy({[Plant.columnSchema.lowIncomeCommunityQualifying]: x})}
                items={booleanOptions}
                style={{marginBottom: "15px"}}
            />
            <PalantirDispatchedTextField
                label="Low-Income Community Notes"
                value={project[Plant.columnSchema.lowIncomeCommunityQualifyingNotes]}
                multiline
                rows={9}
                variant="filled"
                onChange={(x) => updateProjectLogProxy({[Plant.columnSchema.lowIncomeCommunityQualifyingNotes]: x})}
            />
        </div>
    )
}

const EnergyCommunityMap = () => {
    return (
        <iframe
            className="fill-parent"
            width="100%"
            height="100%"
            src="https://arevon.maps.arcgis.com/apps/webappviewer/index.html?id=10321c5dbbb44fe28ba986480bfc8ab0"
            frameborder="0"
            allowFullScreen="true"
        >

        </iframe>
    )
}

const LowIncomeCommunityMap = () => {
    return (
        <iframe
            className="fill-parent"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="//www.arcgis.com/apps/Embed/index.html?webmap=d569d79af021465893797d9d59b6cfff&extent=-87.6547,31.3028,-79.5413,35.1027&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light"
        >

        </iframe>
    )
}