import _ from "lodash"
import { format as formatDate } from "date-fns"
import { utcToZonedTime } from 'date-fns-tz'

/**
 * Contains macros for converting specific json data to the required csv format for export
 */

const TIMESTAMP_COL = "Timestamp"

const formatToLocalizedISO = (date, dateFormatter) => {
    var localeFormatted = dateFormatter.format(date)
    return localeFormatted.substring(0, 10) + 'T' + 
        localeFormatted.substring(10 + 1, 19) + "." + 
        localeFormatted.substring(20, 23) + "-" +
        localeFormatted.substring(28, 30) +
        localeFormatted.substring(31)
}

export const convertHighchartsSeriesJSONtoCSV = (highchartsSeries, timeZone) => {

    const dateFormatter = new Intl.DateTimeFormat('sv', {
        timeZone: timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        fractionalSecondDigits: "3",
        timeZoneName: "longOffset"
    })

    var csvHeaders = [TIMESTAMP_COL]
    var csvData = {}
    highchartsSeries.forEach(serie => {
        let serieData = serie.data
        let serieUnits = serie.tooltip?.valueSuffix || "?"
        let serieName = serie.name + ` (${serieUnits.trim()})`
        csvHeaders.push(serieName)

        const processPoint = (ts, v, serieOverride) => {
            let formattedTs = formatToLocalizedISO(new Date(ts), dateFormatter)
            let s = serieOverride || serieName
            if (serieOverride) {
                if (!csvHeaders.includes(serieOverride)) csvHeaders.push(serieOverride)
            }
            formattedTs in csvData ? csvData[formattedTs].data[s] = v : csvData[formattedTs] = {key: ts, data: {[TIMESTAMP_COL]: formattedTs, [s]: v}}
        }

        if (Array.isArray(serieData[0])) {   // [[ts, v], ...]
            serieData.forEach(data => {
                let [unixTs, value] = data
                processPoint(unixTs, value)
            })
        }
        else {
            serieData.forEach(data => {
                processPoint(data.x, data.y)
                if (data.custom?.data) {
                    Object.entries(data.custom.data).forEach(entry => {
                        let [name, config] = entry
                        processPoint(data.x, config.v, `${name} (${config.units})`)
                    })
                }
            })
        }
    })
    const sortedData = _.sortBy(Object.values(csvData), ['key']).map(x => x.data)
    return [csvHeaders, sortedData]
}