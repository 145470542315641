import './Events.css'
import React, {Component} from 'react'

import Panel from '../../panel/Panel'
import DatePicker from '../../date_picker/DateRangePicker'
import StandardDataProvider from '../../data_provider/StandardDataProvider'
import EventsTable from './EventsTable'

import { getStartTime, getEndTime } from '../../../utils/UrlLocation'


class EventsDisplay extends Component {

    constructor(props) {
        super()
        this.state = {
            selectedStartDate: getStartTime(),
            selectedEndDate: getEndTime(),
            loading: 0,
        }
    }

    render() {

        if (!this.props.selectedSiteId) {
            return <Panel className="section-main">Please select a site</Panel>
        }

        var siteTimezone = this.props.services.assetFramework.getSiteTimezone(this.props.selectedSiteId)

        var eventTable = <StandardDataProvider 
                            services={this.props.services}
                            selectedSiteId={this.props.selectedSiteId}
                            selectedPortfolioId={this.props.selectedPortfolioId}
                            selectedStartDate={this.state.selectedStartDate}
                            selectedEndDate={this.state.selectedEndDate}
                            additionalParams={{
                                timezone: siteTimezone,
                            }}
                            queryProps={["selectedSiteId", "selectedStartDate", "selectedEndDate"]}
                            queryUrl={'/api/events/all'}
                            render={(data, dataRequestId) => <EventsTable 
                                                                services={this.props.services}
                                                                siteTimezone={siteTimezone}
                                                                closeEvent={this.closeEvent}
                                                                data={data} 
                                                            />}
                        />
        
        return (
            <div className="section-main flow-vertical fill-parent">
                <div className="flow-horizontal section-header">
                    <div className="fill-parent"></div>
                    <DatePicker 
                        services={this.props.services}
                        startDate={this.state.selectedStartDate}
                        endDate={this.state.selectedEndDate}
                        onValueChange={this.updateSelectedTimeRange}
                        containerProps={{
                            appearance: "subtle",
                            placement: "bottomEnd",
                        }}
                    />
                </div>
                {eventTable}
            </div>
        )
    }

    updateSelectedTimeRange = (start, end) => {
        this.setState({
            selectedStartDate: start,
            selectedEndDate: end
        })
    }

}

export default EventsDisplay