import './FloaterButtonWithPrompt.css'
import Avatar from '@mui/material/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faQuestion, faX } from '@fortawesome/free-solid-svg-icons';

export default function FloaterButtonWithPrompt({onClick, labelContent, avatarContent, ...props}) {

    return (
        <div onClick={onClick} className={"floater-button" + (props.className ? ` ${props.className}` : "")} style={props.style || {}}>
            <Avatar sx={{height: props.height || 14, width: props.width || 14, marginRight: "8px"}}>
                {avatarContent}
            </Avatar>
            {labelContent && 
                <span style={props.labelStyle || {fontSize: "12px"}}>{labelContent}</span>
            }
        </div>
    )
}

export function AddFloaterButtonWithPrompt(props) {

    return (
        <FloaterButtonWithPrompt
            avatarContent={<FontAwesomeIcon icon={faPlus} style={{fontSize: "10px"}} />}
            className="add-record"
            {...props}
        />
    )
}

export function DeleteFloaterButtonWithPrompt(props) {

    return (
        <FloaterButtonWithPrompt
            avatarContent={<FontAwesomeIcon icon={faMinus} style={{fontSize: "10px"}} />}
            className="delete-record"
            {...props}
        />
    )
}