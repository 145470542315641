import './MenuItem.css'
import React, {Component} from 'react'
import AnimateHeight from 'react-animate-height';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown, faInfo } from '@fortawesome/free-solid-svg-icons'
import {Whisper,Popover} from 'rsuite'

import { ITEM_SELECTION_TYPES } from './MenuContainer'


class MenuItem extends Component {

    constructor(props) {
        super()
        this.state = {
            active: props.active,
            isContentHidden: true
        }
    }

    componentDidMount() {
        var shouldContentHidden = this.props.expandTree ? false : !this.checkMenuItemContainsActiveItem(this.props.children)
        this.setState({
            isContentHidden: shouldContentHidden
        })
    }

    toggleContentHidden = () => {
        this.setState({
            isContentHidden: !this.state.isContentHidden
        })
    }

    createItemClass = (itemSelectionTypeIsRadio, itemSelectionTypeIsCheckbox, itemSelectionTypeIsStandard) => {
        var itemClass = 'menu-item'
        if (this.props.active) itemClass+=' active'
        if (!this.props.children) itemClass+=' leaf-node'
        if (itemSelectionTypeIsRadio) itemClass+=' checkbox'
        else if (itemSelectionTypeIsCheckbox) itemClass+=' radio'
        else if (itemSelectionTypeIsStandard) itemClass+=' standard'
        return itemClass
    }

    render() {

        var itemSelectionTypeIsRadio = this.props.itemSelectionType===ITEM_SELECTION_TYPES.radio
        var itemSelectionTypeIsCheckbox = this.props.itemSelectionType===ITEM_SELECTION_TYPES.checkbox
        var itemSelectionTypeIsStandard = !itemSelectionTypeIsRadio && !itemSelectionTypeIsCheckbox

        var menuIcon = this.state.isContentHidden ? faCaretRight : faCaretDown

        var onClick = this.props.selectable ? this.props.onClick : null
        var onItemLabelClick = (this.props.selectable && (itemSelectionTypeIsStandard || itemSelectionTypeIsRadio)) ? this.props.onClick : null

        var menuItemComponent = (
            <div className={this.createItemClass(itemSelectionTypeIsRadio, itemSelectionTypeIsCheckbox, itemSelectionTypeIsStandard)} >
                <div className="item-main-content">
                    {this.props.children &&
                        <FontAwesomeIcon onClick={this.toggleContentHidden} icon={menuIcon} size="lg" fixedWidth />
                    }
                    {this.props.selectable && itemSelectionTypeIsCheckbox &&
                        <input type="checkbox" style={{marginRight: '10px'}} checked={this.props.active} onChange={onClick}></input>
                    }
                    {this.props.selectable && itemSelectionTypeIsRadio &&
                        <input type="radio" style={{marginRight: '10px'}} checked={this.props.active} onChange={onClick} ></input>
                    }
                    <span className="label" onClick={onItemLabelClick}>{this.props.label}</span>
                </div>
                {this.props.meta && this.createMetaDisplay()}
            </div>
        )

        var height = !this.state.isContentHidden ? 'auto' : 0
        return (
            <div className="menu-group">
                {menuItemComponent}
                <AnimateHeight
                    duration={500}
                    height={height}
                >
                    <div style={{marginLeft: '15px'}}>
                        {this.props.children}
                    </div>
                </AnimateHeight>
            </div>
        )
    }

    /**
     * Checks whether this item is or contains an active item somewhere in its subtree.
     * @param {*} item 
     * @returns 
     */
     checkMenuItemContainsActiveItem(item) {
        if (!item) return false
        if (item instanceof Array) {
            return item.map(x => this.checkMenuItemContainsActiveItem(x)).reduce((acc, cur) => acc || cur, false)
        }
        if (item.props.active) return item.props.active

        var itemChildren = item.props.children
        if (!itemChildren) return false
        var flag = itemChildren.map(x => this.checkMenuItemContainsActiveItem(x)).reduce((acc, cur) => acc || cur, false)
        return flag
    }

    createMetaDisplay = () => {
        return <Whisper className="help-panel" placement="right" trigger="click" speaker={this.createMetaSpeaker()} enterable preventOverflow>
            <FontAwesomeIcon icon={faInfo} size="xs" fixedWidth />
        </Whisper>
    }

    createMetaSpeaker = () => {
        var title = ""
        var content = ""
        if (React.isValidElement(this.props.meta)) {
            content = this.props.meta
        }
        else if (typeof(this.props.meta)==="object") {
            title = this.props.label
            content = this.createMiniTable(this.props.meta)
        }

        return <Popover title={title} style={{minWidth: '400px', maxWidth: '400px', maxHeight: '400px', overflowY: 'auto'}}>
            {content}
        </Popover>
    }

    createMiniTable(dataObject) {
        return Object.entries(dataObject).map((entry,idx) => {
            var field = entry[0]
            var value = (entry[1] instanceof Object ? "N/A" : String(entry[1]))
            return (
                <div key={field} className="flow-horizontal" style={{display: "flex", flexDirection: "row", borderTop: "solid 1px lightgrey"}}>
                    <div className="fill-parent">{field}</div>
                    <div className="fill-parent">{value}</div>
                </div>
            )
        })
    }

}

export default MenuItem