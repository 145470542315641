import React, {Component} from 'react'

import AssetSidebar from '../../menu/AssetMenu'
import HomeContent from './HomeContent'

import { getPortfolio, setPortfolio, getSite, setSite } from '../../../utils/UrlLocation'

class HomeTab extends Component {

    constructor(props) {
        super()
        this.state = {
            selectedPortfolioId: getPortfolio(),
            selectedSiteId: getSite(),
        }
    }

    render() {

        return (
            <div className="flow-horizontal fill-parent">
                <AssetSidebar 
                    services={this.props.services} 
                    updateSelectedSite={this.updateSelectedSiteId.bind(this)} 
                    updateSelectedPortfolio={this.updateSelectedPortfolioId.bind(this)}
                    selectedSiteId={this.state.selectedSiteId}
                    selectedPortfolioId={this.state.selectedPortfolioId}
                    className="sidebar"
                    menuProps={{
                        collapsable: true,
                    }}
                />
                <HomeContent
                    key={this.state.selectedSiteId}
                    services={this.props.services}
                    selectedPortfolioId={this.state.selectedPortfolioId}
                    selectedSiteId={this.state.selectedSiteId}
                />
            </div>
        )

    }

    updateSelectedSiteId(id) {
        setSite(id)
        setPortfolio(null)
        this.setState({
            selectedPortfolioId: null,
            selectedSiteId: id,
        })
    }

    updateSelectedPortfolioId(id) {
        setPortfolio(id)
        setSite(null)
        this.setState({
            selectedPortfolioId: id,
            selectedSiteId: null,
        })
    }

}

export default HomeTab