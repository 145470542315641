import './DatabaseApp.css'
import {Component} from 'react'

import { injectServices } from './utils/ServiceInjector'
import PlantMenu from './precious_components/menu/PlantMenu'
import TableMenu from './precious_components/menu/TableMenu'
import TableEditingContainer from './precious_components/TableEditingContainer'
import ViewContainer from './precious_components/ViewContainer'
import { UserMenu } from './precious_components/menu/UserMenu'
import HelpPanel from './components/help/HelpPanel'

import DisplayMode from './precious_components/display_mode/DisplayMode'
import { Alert } from 'rsuite'
import MuiAlert from '@mui/material/Alert';
import axios from 'axios'
import { InverterManagementTab } from './precious_components/InverterWarehouse'
import { PowerBITab } from './precious_components/PowerBI'
import { SnackbarProvider } from 'notistack';


const DEFAULT_TAB = "home"


class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projectPortfolios: [],
            projectPlants: [],
            displayMode: "project",
            editMode: "table",
            selectedPortfolio: null,
            selectedPlant: null,
            selectedTableGroup: null,
            selectedTable: null,
            selectedView: null,
            selectedTab: "home"
        }
    }

    componentDidMount = () => {
        this.fetchAssets()
        this.fetchGoogleMaps()
    }

    render() {

        var servicesInjectedAssets = injectServices(this.props.services, 'assets', {
            refresh: this.fetchAssets,
            getPortfolios: () => this.state.projectPortfolios,
            getPlants: () => this.state.projectPlants,
        })
        servicesInjectedAssets = injectServices(servicesInjectedAssets, 'displayMode', {
            setDisplayModeTable: () => this.setDisplayMode("table")
        })

        var editContainer = (
            this.state.editMode==="table" ? 
            <TableEditingContainer
                services={servicesInjectedAssets}
                displayMode={this.state.displayMode}
                selectedPortfolioId={this.state.selectedPortfolio}
                selectedPlantId={this.state.selectedPlant}
                selectedTableGroup={this.state.selectedTableGroup}
                selectedTable={this.state.selectedTable}
            /> : 
            <HelpPanel
                services={servicesInjectedAssets}
                render={(newServices) =>
                    <ViewContainer
                        services={newServices}
                        selectedPlantId={this.state.selectedPlant}
                        selectedPortfolioId={this.state.selectedPortfolio}
                        selectedView={this.state.selectedView}
                    />
                }
            />
        )

        return (
            <SnackbarProvider maxSnack={3}>
            <div className="flow-horizontal fill-parent">
                <UserMenu
                    services={this.props.services}
                    setTab={(tabName) => this.setState({selectedTab: tabName})}
                />
                {this.state.selectedTab==="home" &&
                    <>
                        <div className="sidebar flow-vertical" style={{minWidth: "250px", maxWidth: "250px"}}>
                            <DisplayMode activeMode={this.state.displayMode} setDisplayMode={this.setDisplayMode} />
                            <TableMenu
                                setSelectedTable={this.setSelectedTable}
                                setSelectedView={this.setSelectedView}
                                setEditMode={this.setEditMode}
                                selectedTable={this.state.selectedTable}
                                selectedView={this.state.selectedView}
                                selectedEditMode={this.state.editMode}
                            />
                        </div>
                        {this.state.displayMode === "project" && 
                        <div className="flow-vertical" style={{overflow: "visible"}}>
                            <div className="plant-menu-top-filler"></div>
                            <PlantMenu
                                portfolios={this.state.projectPortfolios}
                                plants={this.state.projectPlants}
                                setSelectedPlant={this.setSelectedPlant}
                                setSelectedPortfolio={this.setSelectedPortfolio}
                                selectedPortfolioId={this.state.selectedPortfolio}
                                selectedPlantId={this.state.selectedPlant}
                            />
                        </div>}
                        <div className="flow-vertical fill-parent">
                            {editContainer}
                        </div>
                    </>
                }
                {this.state.selectedTab==="warehouse" &&
                    <InverterManagementTab
                        services={servicesInjectedAssets}
                        portfolios={this.state.projectPortfolios}
                        projects={this.state.projectPlants}
                    />
                }
                {this.state.selectedTab==="powerbi" &&
                    <PowerBITab />
                }
            </div>
            </SnackbarProvider>
        );
    }

    fetchAssets = () => {
        axios.get("/api/precious/plants")
        .then((data) => {
            var returnedData = data.data
            this.setState({
                projectPortfolios: returnedData.portfolios,
                projectPlants: returnedData.plants,
            })
        })
        .catch((error) => {
            if (error.response?.status === 401) {
                this.props.services.auth.logout()
            }
            console.error(error)
        })
    }

    setDisplayMode = (mode) => {
        if (mode==="table" && this.state.editMode==="view") {
            Alert.error("Table display mode can not be entered while in view edit mode.")
        }
        else{
            this.setState({
                displayMode: mode,
            })
        }
    }

    fetchGoogleMaps = () => {
        if (!window.google?.maps) {
            const googleMapScript = document.createElement('script');
            googleMapScript.src=`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAP_KEY}&libraries=places`;
            googleMapScript.async = true;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener('load', () => {
                                console.log("loaded google maps")
                            });
        }
    }

    setSelectedPlant = (plant) => {
        const newSelection = plant===this.state.selectedPlant ? null : plant
        this.setState({
            selectedPortfolio: null,
            selectedPlant: newSelection,
        })
    }

    setSelectedPortfolio = (portfolio) => {
        const newSelection = portfolio===this.state.selectedPortfolio ? null : portfolio
        this.setState({
            selectedPortfolio: portfolio,
            selectedPlant: null,
        })
    }

    setSelectedTable = (data) => {
        this.setState({
            selectedTableGroup: data.tableGroup,
            selectedTable: data.table,
        })
    }

    setSelectedView = (view) => {
        this.setState({
            selectedView: view
        })
    }

    setEditMode = (mode) => {
        if (mode==="view" && this.state.displayMode==="table") {
            Alert.info("Display mode has been switched to project.")
            this.setState({
                editMode: mode,
                displayMode: "project"
            })
        }
        else{
            this.setState({
                editMode: mode,
            })
        }
    }

}

export default App