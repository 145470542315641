export function HelpCounterpartyTable(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{props.type}</div>
            <hr></hr>
            <p>
                Instances of {props.type} counterparties.
            </p>
            {props.notes && <p>
                {props.notes}
            </p>}
        </div>
    )
}
export function HelpLLCTable(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{props.type}</div>
            <hr></hr>
            <p>
                Instances of {props.type} LLC.
            </p>
            {props.notes && <p>
                {props.notes}
            </p>}
        </div>
    )
}
export function HelpFundTable(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{"Fund"}</div>
            <hr></hr>
            <p>
                Instances of Fund entities.
            </p>
        </div>
    )
}
export function HelpPortfolioTable(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{"Portfolio"}</div>
            <hr></hr>
            <p>
                Instances of Portfolio entites.
            </p>
        </div>
    )
}
export function HelpEmployeeTable(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{"Employee"}</div>
            <hr></hr>
            <p>
                Employees of Arevon.
            </p>
        </div>
    )
}


// Columns

// Employee
export function HelpEmployeeFirstName(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{"Employee > First Name"}</div>
            <hr></hr>
            <p>
                The Employee's first name.
            </p>
        </div>
    )
}
export function HelpEmployeeLastName(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{"Employee > Last Name"}</div>
            <hr></hr>
            <p>
                The Employee's last name.
            </p>
        </div>
    )
}
export function HelpEmployeeEmail(props) {
    return (
        <div className="palantir-helpnote">
            <div className="title">{"Employee > Email"}</div>
            <hr></hr>
            <p>
                The Employee's email address.
            </p>
        </div>
    )
}