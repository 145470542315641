import {Component} from 'react'

import {Modal,Button} from 'rsuite'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import MenuContainer from '../menu/MenuContainer'



class ModalWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedItems: props.selectedItems,
        };
    }

    submit = () => {
        this.close(false)
    }

    cancel = () => {
        this.close(true)
    }

    close = (cancel) => {
        if (cancel) {
            if (this.props.onClose) this.props.onClose()
        }
        else {
            if (this.props.onSubmit) this.props.onSubmit(this.state.selectedItems)
            if (this.props.onClose) this.props.onClose()
        }
        this.setState({
            show: false
        })
    }

    open = () => {
        this.setState({ show: true });
    }

    render() {
        return (
            <div className="modal-container">
                <FontAwesomeIcon onClick={this.open} icon={faCog} style={{fontSize: '16px'}} fixedWidth className="fa-spin-hover" />

                <Modal show={this.state.show} onHide={() => this.close(true)} >
                    <Modal.Header>
                        <Modal.Title>{this.props.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MenuContainer
                            {...this.props.menuProps}
                            menuSkeleton={this.createSkeleton()}
                            leafNodeClick={this.itemClick}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.submit()} appearance="primary">
                        Ok
                        </Button>
                        <Button onClick={() => this.cancel()} appearance="subtle">
                        Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    createSkeleton = () => {
        return this.props.items.sort().map((x) => this.props.createSkeleton(x, this.state.selectedItems))
    }

    itemClick = (item) => {
        item = item.key
        var newState = this.props.updateState(_.cloneDeep(this.state.selectedItems), item)
        this.setState({
            selectedItems: newState
        })
    }

}

export default ModalWrapper