import './DimensionMenu.css'
import React, {Component} from 'react'

import MenuContainer, { ITEM_SELECTION_TYPES } from '../menu/MenuContainer'

class DimensionMenu extends Component {

    constructor(props) {
        super()
        this.state = {
            selectedDimensions: {},
        }
    }

    render() {

        var dimensionMenuItems = this.props.dimensions.map(x => this.constructDimension(x))
        
        return(
            <MenuContainer
                headerName={this.props.menuTitle || "Dimension Menu"}
                menuSkeleton={dimensionMenuItems} 
                leafNodeClick={this.onDimensionClick}
                className={"dimension-menu" + (this.props.className ? " "+this.props.className : "")}
                itemSelectionType={ITEM_SELECTION_TYPES.checkbox}
                style={this.props.style}
                expandTree={true}
            />
        )
    }

    onDimensionClick = (menuItem) => {
        var [dimensionId, dimensionValue] = menuItem.key.split(".")
        dimensionValue = Number(dimensionValue)
        
        const newDimensionValues = (this.props.selectedDimensionIds || {})[dimensionId] || []
        const selectedValueIndex = newDimensionValues.indexOf(dimensionValue)
        selectedValueIndex===-1 ? newDimensionValues.push(dimensionValue) : newDimensionValues.splice(selectedValueIndex, 1)

        const newDimensionIdSelection = Object.assign({}, this.props.selectedDimensionIds, {[dimensionId]: newDimensionValues})

        this.props.onDimensionClick(newDimensionIdSelection, dimensionId, dimensionValue)
    }

    constructDimension(dimension) {

        var selectedDimensionIds = this.props.selectedDimensionIds || {}
        var dimensionValues = dimension.values

        var dimensionValuesSkeleton = Object.keys(dimensionValues).map(function(dimensionValueIdx) {
            let dimensionValue = dimensionValues[dimensionValueIdx]
            let dimIdKey = String(dimension._id).concat(".", dimensionValueIdx)
            return {
                "key": dimIdKey,
                "label": dimensionValue.name,
                "meta": Object.assign({_id: dimensionValueIdx}, dimensionValue),
                "selectable": true,
                "active": (selectedDimensionIds[dimension._id] || []).includes(Number(dimensionValueIdx)),
                "children": null
            }
        })

        var dimensionSkeleton = {
            "key": dimension._id,
            "label": dimension.name,
            "meta": dimension,
            "selectable": false,
            "children": dimensionValuesSkeleton
        }

        return dimensionSkeleton
    }


}

export default DimensionMenu