import React, { useState } from 'react'

import { PalantirSelector, PalantirDatePicker } from '../../../components/input/SelectPicker'
import { PalantirTextField, makeTextMaskNumber, makeTextMaskPercentage, PalantirDispatchedTextField } from '../../../components/input/Text'
import { Plant } from '../../table_configuration/Project'
import { HelpProjectCOD, HelpProjectStatus } from '../../../components/help/precious/Columns'
import { HelpPrompt } from '../../../components/help/HelpPrompt'
import { SegmentedControl } from '@mantine/core'

const plantSchema = Plant.columnSchema


const PlantDates = React.memo(function(props) {

    const [hasCOD, setHasCOD] = useState(props.plantPlannedCommercialOperationDate ? true : false)
    console.log(hasCOD)

    return (
        <div id="plant-metadata" className="field-group" style={{marginBottom: "30px"}}>
            <div className="header">Dates</div>
            <SegmentedControl
                value={hasCOD ? "has_cod" : "has_not_cod"}
                onChange={(x) => setHasCOD(x==="has_cod" ? true : false)}
                data={[{
                    label: "Project has reached COD", value: "has_cod"
                }, {
                    label: "Project hasn't reached COD", value: "has_not_cod"
                }]}
                style={{marginBottom: "15px", marginLeft: "0px"}}
            />
            <div style={{display: "grid", gridTemplateColumns: "190px 165px 165px 165px", gridRowGap: "20px"}}>
                <div style={{gridRow: 1, gridColumn: 1}}>
                    <HelpPrompt
                        services={props.services}
                        style={{position: "relative", top: "20px", marginRight: "4px", fontSize: "small"}}
                        title="Commercial Operation Date"
                        item={<HelpProjectCOD />}
                    />
                    <PalantirDatePicker
                        label="Memo Approved COD"
                        value={props.plantCommercialOperationDate}
                        onChange={(date) => props.updatePlant({[plantSchema.commercialOperationDate]: date})}
                        style={{width: "150px"}}
                    />
                </div>
                {hasCOD &&
                    <PalantirDatePicker
                        label="Actual COD"
                        value={props.plantPlannedCommercialOperationDate}
                        onChange={(date) => props.updatePlant({[plantSchema.plannedCommercialOperationDate]: date})}
                        style={{width: "150px", gridRow: 1, gridColumn: 2}}
                    />
                }
                {!hasCOD &&
                    <PalantirDatePicker
                        label="Target COD"
                        value={props.plantTargetCommercialOperationDate}
                        onChange={(date) => props.updatePlant({[plantSchema.targetCommercialOperationDate]: date})}
                        style={{width: "150px", gridRow: 1, gridColumn: 2}}
                    />
                }
                {!hasCOD &&
                    <PalantirDatePicker
                        label="Base COD"
                        value={props.plantBaseCommercialOperationDate}
                        onChange={(date) => props.updatePlant({[plantSchema.baseCommercialOperationDate]: date})}
                        style={{width: "150px", gridRow: 1, gridColumn: 3}}
                    />
                }
                {!hasCOD &&
                    <PalantirDatePicker
                        label="Downside COD"
                        value={props.plantDownsideCommercialOperationDate}
                        onChange={(date) => props.updatePlant({[plantSchema.downsideCommercialOperationDate]: date})}
                        style={{width: "150px", gridRow: 1, gridColumn: 4}}
                    />
                }
                

                <div style={{gridRow: 2, gridColumn: 1}}>
                    <PalantirDatePicker
                        label="Memo Approved Financial Close Date"
                        value={props.plantMemoApprovedFinancialCloseDate}
                        onChange={(date) => props.updatePlant({[plantSchema.memoApprovedFinancialCloseDate]: date})}
                        style={{width: "170px"}}
                    />
                </div>
                {hasCOD &&
                    <PalantirDatePicker
                        label="Actual Financial Close Date"
                        value={props.plantActualFinancialCloseDate}
                        onChange={(date) => props.updatePlant({[plantSchema.actualFinancialCloseDate]: date})}
                        style={{width: "150px", gridRow: 2, gridColumn: 2}}
                    />
                }
                {!hasCOD &&
                    <PalantirDatePicker
                        label="Target Financial Close Date"
                        value={props.plantTargetFinancialCloseDate}
                        onChange={(date) => props.updatePlant({[plantSchema.targetFinancialCloseDate]: date})}
                        style={{width: "150px", gridRow: 2, gridColumn: 2}}
                    />
                }
                {!hasCOD &&
                    <PalantirDatePicker
                        label="Base Financial Close Date"
                        value={props.plantBaseFinancialCloseDate}
                        onChange={(date) => props.updatePlant({[plantSchema.baseFinancialCloseDate]: date})}
                        style={{width: "150px", gridRow: 2, gridColumn: 3}}
                    />
                }
                {!hasCOD &&
                    <PalantirDatePicker
                        label="Downside Financial Close Date"
                        value={props.plantDownsideFinancialCloseDate}
                        onChange={(date) => props.updatePlant({[plantSchema.downsideFinancialCloseDate]: date})}
                        style={{width: "150px", gridRow: 2, gridColumn: 4}}
                    />
                }
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        prevProps.plantStage===nextProps.plantStage &&
        prevProps.plantPlannedCommercialOperationDate===nextProps.plantPlannedCommercialOperationDate &&
        prevProps.plantCommercialOperationDate===nextProps.plantCommercialOperationDate &&
        prevProps.plantTargetCommercialOperationDate===nextProps.plantTargetCommercialOperationDate &&
        prevProps.plantBaseCommercialOperationDate===nextProps.plantBaseCommercialOperationDate &&
        prevProps.plantDownsideCommercialOperationDate===nextProps.plantDownsideCommercialOperationDate &&
        prevProps.plantActualFinancialCloseDate===nextProps.plantActualFinancialCloseDate &&
        prevProps.plantMemoApprovedFinancialCloseDate===nextProps.plantMemoApprovedFinancialCloseDate &&
        prevProps.plantTargetFinancialCloseDate===nextProps.plantTargetFinancialCloseDate &&
        prevProps.plantBaseFinancialCloseDate===nextProps.plantBaseFinancialCloseDate &&
        prevProps.plantDownsideFinancialCloseDate===nextProps.plantDownsideFinancialCloseDate
    )
})

export default PlantDates