import {Component, useState, useEffect, useRef} from 'react'

import Panel from '../panel/Panel'
import { Loader } from 'rsuite'
import _ from 'lodash'

import axios from "axios";
import { findClosestEnabledDate } from '@mui/x-date-pickers/internals/utils/date-utils';

class StandardDataProvider extends Component {

    constructor(props) {
        super()
        this.state = {
            data: null,
            dataRequestId: 0,
            dataRequest: null,
            error: false,
            errorMessage: null,
            cancelRequest: null,
        }
        this.autoRefreshTimer = null
    }

    componentDidMount() {
        this.fetchData()
    }

    shouldComponentUpdate() {
        return true
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var curProps = this.props
        var propsToDiff = this.props.queryProps || []   // these are the props specified by parent that, when changed, signify to rerender

        // check to see if auto refresh preference changed
        if (this.props.services.userPreferences?.autoRefresh !== prevProps.services.userPreferences?.autoRefresh) {
            let pref = this.props.services.userPreferences?.autoRefresh
            if (pref) this.setAutoRefresh()
            else this.cancelAutoRefresh()
        }

        /* Does a deep comparison to account for object equality */
        var propsChanged = !propsToDiff.reduce(function(prev, cur) {
            return (_.isEqual(prevProps[cur], curProps[cur])) && prev
        }, true)
        
        // If any of the above props changed make a new data request
        if (propsChanged) {
            this.fetchData()
        }

    }

    
    componentWillUnmount() {
        // Cancel any in flight requests
        if (this.state.cancelRequest) this.state.cancelRequest()
        this.cancelAutoRefresh()
    }

    render() {

        var className = "data-provider"
        className = className + (this.props.messageClassName ? " " + this.props.messageClassName : "")

        if (this.state.error) {
            return <Panel className={className}>{this.state.errorMessage}</Panel>
        }

        if (!this.props.allowEmpty) {
            if (this.state.dataRequest) {
                return <Panel className={className}><Loader size="sm" speed="fast" vertical content="loading.." /></Panel>
            }
            if (!this.state.data) {
                return <Panel className={className}>No data</Panel>
            }
    
            var responseLength = (this.state.data instanceof Array ? this.state.data.length : Object.keys(this.state.data).length)
            if (responseLength === 0) {
                return <Panel className={className}>No data found</Panel>
            }
        }

        return (this.props.render(this.state.data, this.state.dataRequestId, Boolean(this.state.dataRequest), this.fetchData))
    }

    constructRequestBody() {

        var body = {}
        if (this.props.assetFilters) body.assetFilters = this.props.assetFilters
        if (this.props.selectedSiteId) body.siteId = this.props.selectedSiteId
        if (this.props.selectedResolution || this.props.selectedResolution===0) body.resolution = this.props.selectedResolution
        if (this.props.selectedStartDate) body.start = this.props.selectedStartDate
        if (this.props.selectedEndDate) body.end = this.props.selectedEndDate

        if (this.props.selectedSchema) body.schema = this.props.selectedSchema
        if (this.props.selectedTable) body.table = this.props.selectedTable
        Object.assign(body, this.props.additionalParams || {})

        return body

    }

    fetchData = () => {

        var dataRequestId = this.state.dataRequestId+1;
        
        // We are sending a new request so we want to ignore the results from the current in flight request, if any
        if (this.state.cancelRequest) this.state.cancelRequest()
        // If auto refresh is set, cancel it as we are getting new data now
        this.cancelAutoRefresh()
        
        // This token is attached to the new request being sent
        //    It is referenced in the cancelRequest function below
        const sourceCancel = axios.CancelToken.source();

        const axiosRequest = () => {
            if (this.props.requestType==="GET") {
                return axios({
                    method: "get",
                    url: this.props.queryUrl,
                    params: this.constructRequestBody(),
                    cancelToken: sourceCancel.token,
                    dataRequestId: this.state.dataRequestId
                })
            }
            else {
                return axios({
                    method: "post",
                    url: this.props.queryUrl,
                    data: this.constructRequestBody(),
                    cancelToken: sourceCancel.token,
                    dataRequestId: this.state.dataRequestId
                })
            }
        }

        var dataRequest = axiosRequest()
        .then((data) => {

            var returnedData = data.data

            this.setAutoRefresh()

            this.setState({
                data: returnedData,
                dataRequestId: dataRequestId,
                dataRequest: null,
                error: false,
                errorMessage: null,
                cancelRequest: null,
            })
        })
        .catch((error) => {

            // This means the request was cancelled in flight
            //    This request should be thrown out, which is accomplished by not triggering a rerender here
            //    If the request was cancelled manually, the cancel action will trigger a rerender and remove the request ID, this response will be received sometime after so we just want to discard as the user has moved on
            //    If the request was cancelled programatically by a new request, the new request set the state to loading, and will trigger a rerender when received, so we can discard this safely
            if (axios.isCancel(error)) {
                console.log("Data request was cancelled: "+ dataRequestId)
            }
            else {
                // Received unauthorized response; redirect to login page
                if (error.response?.status === 401) {
                    this.props.services.auth.logout()
                    return
                }

                let errorMessage = error.response?.data?.detail || "Sorry an error occurred"

                // Set state to represent unknown error
                console.error(error)
                this.setState({
                    data: null,
                    dataRequestId: dataRequestId,
                    dataRequest: null,
                    error: true,
                    errorMessage: errorMessage,
                    cancelRequest: null,
                })
            }
        })

        // This is stored in the state so that any subsequent requests may easily cancel the request that was just made
        var cancelRequest = (x) => {
            sourceCancel.cancel(x)
        }
        
        // Set state to represent request in progress
        this.setState({
            data: null,
            dataRequestId: dataRequestId,
            dataRequest: dataRequest,
            error: false,
            errorMessage: null,
            cancelRequest: cancelRequest
        })
    }

    cancelAutoRefresh = () => {
        if (this.autoRefreshTimer) {
            clearTimeout(this.autoRefreshTimer)
            this.autoRefreshTimer = null
        }
    }

    setAutoRefresh = () => {
        // set auto refresh on successful query
        if (this.props.services.userPreferences?.autoRefresh && !this.autoRefreshTimer) {
            let timer = 300000   // 5 min default
            this.autoRefreshTimer = setTimeout(() => {
                this.cancelAutoRefresh()
                this.fetchData()
            }, timer)
        }
    }

}

export default StandardDataProvider


export const ChartDataProvider = ({...props}) => {

    let [data, setData, dataIsReady] = useDataProvider()

    if (dataIsReady) {
        return (
            <>
                <props.alwaysContent data={data} />
                <div className="fill-parent">Sorry.</div>
            </>
        )
    }
    else {
        return (
            <>
                <props.alwaysContent data={data} />
                <props.onDataLoadContent data={data} />
            </>
        )
    }

}

export const useDataProvider = ({requestMethod, requestUrl, onUnauthorized, buildRequestBody, autoRefreshEnabledInit=false, autoRefreshDuration=15000, resetDataOnRequest=true}) => {

    let [data, setData] = useState(null)
    let [dataRequestId, setDataRequestId] = useState(0)
    let [dataRequest, setDataRequest] = useState(null)
    let [error, setError] = useState(false)
    let [errorMessage, setErrorMessage] = useState(null)
    const [lastRefreshTs, setLastRefreshTs] = useState(new Date())
    const cancelTokenRef = useRef(null)
    const errorContiguousCt = useRef(0)
    let [safeToUseData, setSafeToUseData] = useState(false)


    const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(autoRefreshEnabledInit)
    const autoRefreshEnabledRef = useRef(autoRefreshEnabledInit)
    const autoRefreshTimerId = useRef(null)

    // Keep a ref so fetch data callback can access current value when determining whether to schedule subsequent job
    // Ref is always updated with current state value
    autoRefreshEnabledRef.current = autoRefreshEnabled

    useEffect(() => {
        return () => {
            tryCancelRequest()
            disableAutoRefresh()
        }
    }, [])



    // This is stored in the state so that any subsequent requests may easily cancel the request that was just made
    const tryCancelRequest = (x) => {
        console.log("Cancelling request", cancelTokenRef.current)
        if (cancelTokenRef.current) cancelTokenRef.current.cancel(x)
    }

    const updateLastRefreshTs = () => {
        setLastRefreshTs(new Date())
    }

    // Functions to handle auto refresh
    const enableAutoRefresh = () => {
        tryCancelRequest()
        setAutoRefreshEnabled(true)
        fetchData()
    }
    const disableAutoRefresh = () => {
        setAutoRefreshEnabled(false)
        clearTimeout(autoRefreshTimerId.current)
        autoRefreshTimerId.current = null
    }
    const scheduleRefresh = (customDuration) => {
        const duration = customDuration || calculateRefreshTimer()
        console.log("refreshing in " + duration)
        clearTimeout(autoRefreshTimerId.current)
        const intervalFn = fetchData
        const timerId = setTimeout(intervalFn, duration)
        autoRefreshTimerId.current = timerId
    }
    const tryScheduleRefresh = () => {
        if (autoRefreshEnabledRef.current) scheduleRefresh()
    }
    const calculateRefreshTimer = () => {
        const errCt = errorContiguousCt.current
        if (errCt < 3) return autoRefreshDuration
        else if (errCt < 5) return Math.max(autoRefreshDuration, 60000)
        else return Math.max(autoRefreshDuration, 60000*5)
    }

    
    const fetchData = () => {

        const newRequestId = dataRequestId+1;
        
        // We are sending a new request so we want to ignore the results from the current in flight request, if any
        tryCancelRequest()
        // If auto refresh is set, cancel it as we are getting new data now
        //this.cancelAutoRefresh()
        
        // This token is attached to the new request being sent
        //    It is referenced in the cancelRequest function below
        const sourceCancel = axios.CancelToken.source();

        let axiosParams
        if (requestMethod==="GET") {
            axiosParams = {
                method: "get",
                url: requestUrl,
                params: buildRequestBody(),
                cancelToken: sourceCancel.token,
                dataRequestId: newRequestId
            }
        }
        else {
            axiosParams = {
                method: "post",
                url: requestUrl,
                data: buildRequestBody(),
                cancelToken: sourceCancel.token,
                dataRequestId: newRequestId
            }
        }

        let request = axios(axiosParams)
        .then((data) => {
            setData(data.data)
            setDataRequestId(newRequestId)
            setDataRequest(null)
            setError(false)
            setErrorMessage(null)
            cancelTokenRef.current = null
            setSafeToUseData(true)
            errorContiguousCt.current = 0
            // if autorefresh enabled, schedule another fetchData call in x seconds
            tryScheduleRefresh()
            updateLastRefreshTs()
        })
        .catch((error) => {

            // This means the request was cancelled in flight
            //    This request should be thrown out, which is accomplished by not triggering a rerender here
            //    If the request was cancelled manually, the cancel action will trigger a rerender and remove the request ID, this response will be received sometime after so we just want to discard as the user has moved on
            //    If the request was cancelled programatically by a new request, the new request set the state to loading, and will trigger a rerender when received, so we can discard this safely
            if (axios.isCancel(error)) {
                console.log("Data request was cancelled: "+ newRequestId)
            }
            else {

                let errorMessage = error.response?.data?.detail || "Sorry an error occurred"

                // Received unauthorized response; redirect to login page
                if (error.response?.status === 401) {
                    if (onUnauthorized) {
                        onUnauthorized()
                        return
                    }
                    else errorMessage = "Session has expired."
                }

                // Set state to represent unknown error
                if (resetDataOnRequest) setData(null)
                setDataRequestId(newRequestId)
                setDataRequest(null)
                setError(true)
                setErrorMessage(errorMessage)
                cancelTokenRef.current = null
                setSafeToUseData(false)
                errorContiguousCt.current+=1
                
                // If auto refresh enabled, check eror history and schedule another run in xyz seconds
                tryScheduleRefresh()
                updateLastRefreshTs()
            }

        })
  
        // Set state to represent request in progress
        if (resetDataOnRequest) setData(null)
        setDataRequestId(newRequestId)
        setDataRequest(request)
        if (resetDataOnRequest) setError(false)
        if (resetDataOnRequest) setErrorMessage(null)
        cancelTokenRef.current = sourceCancel
        setSafeToUseData(false)
    }

    return {
        data: data,
        requestId: dataRequestId,
        isDataReady: !Boolean(dataRequest) && !error && data!==null,
        isLoading: Boolean(dataRequest),
        isError: error,
        errorMessage: errorMessage,
        refreshData: fetchData,
        autoRefreshEnabled: autoRefreshEnabled,
        enableAutoRefresh: enableAutoRefresh,
        disableAutoRefresh: disableAutoRefresh,
        lastRefreshTs: lastRefreshTs
    }
}

export const DataProviderInterceptor = ({data, dataRequestId, isLoading, isError, errorMessage, ...props}) => {
    
    var isData = data!==null

    if (isLoading) {
        return <Panel className="flow-vertical fill-parent" ><Loader size="sm" speed="fast" vertical content="loading.." /></Panel>
    }
    else if (isError) {
        return <Panel className="flow-vertical fill-parent" >{errorMessage}</Panel>
    }
    else if (!isData) {
        return <Panel>No Data.</Panel>
    }
    else {
        return props.children
    }
}