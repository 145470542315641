import {Component} from 'react'

import TagBlueprintConfigTable from './TagBlueprintConfigTable'
import DeviceMenu from '../../../menu/DeviceMenu'

class TagBlueprintConfiguration extends Component {

    constructor(props) {
        super()
        this.state = {
            selectedDeviceId: null,
        }
    }

    render() {

        return (
            <div className="flow-horizontal fill-parent">
                <DeviceMenu 
                    services={this.props.services} 
                    onDeviceClick={this.updateSelectedDeviceId} 
                    selectedSiteId={this.props.selectedSiteId} 
                    selectedDeviceIds={[this.state.selectedDeviceId]}
                    className="sidebar"
                    menuProps={{
                        collapsable: true,
                    }}
                />
                <div className="section-main flow-vertical fill-parent">
                    <TagBlueprintConfigTable services={this.props.services} selectedSiteId={this.props.selectedSiteId} selectedDeviceId={this.state.selectedDeviceId}/>
                </div>
            </div>
        );
        
    }

    updateSelectedDeviceId = (_, id) => {
        this.setState({
            selectedDeviceId: id
        })
    }

}

export default TagBlueprintConfiguration