import { schemas, tables } from '../Constants'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import { Plant } from './Project'
import Table from './BaseTable'
import { makePlantId, makeNotes } from './CommonColumns'
import { contractStatusOptions, makeContractID, makeContractName, buildGetReferencedPlantId } from './Contract'
import { HelpLLCTable } from '../help_panels/Entity'
import { makeLLCId, makeLLCName, makeLLCDescription } from './CommonColumns'
import { injectCounterpartyName, Investor } from './Counterparty'
import { validateEntity, Counterparty } from './Entity'


class CoInvestorPlantLLCTable extends Table {

    constructor() {

        var columnSchema = {
            coInvestorId: "co_investor_id",
            coInvestorName: "co_investor_name",
            description: "description",
        }

        var schemaName = schemas.llc
        var tableName = "co_investor"

        var displayNameSingular = "Project Co-Investor LLC"
        var displayNamePlural = "Project LLCs"

        var pkUidColumn = columnSchema.coInvestorId
        var identifiers = [columnSchema.coInvestorId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var coInvestorLLCRecords = this.findTableObjectRecords(data, this)

        var validateCoInvestorLLC = (coInvestorLLCRecord) => validateEntity(coInvestorLLCRecords, coInvestorLLCRecord, this.columnSchema.coInvestorId, this.columnSchema.coInvestorName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validateCoInvestorLLC,
            validateUpdateItem: validateCoInvestorLLC,
            tableConfig: {
                columns: [
                    makeLLCId(this.columnSchema.coInvestorId),
                    makeLLCName(this.columnSchema.coInvestorName, this.displayNameSingular),
                    makeLLCDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var CoInvestorPlantLLC = new CoInvestorPlantLLCTable()

class PlantCoInvestorJunctionTable extends Table {

    constructor() {

        var columnSchema = {
            coInvestorId: "plant_co_investor_llc_id",
            investorId: "investor_id",
        }

        var schemaName = schemas.llc
        var tableName = "co_investor_junction"

        var displayNameSingular = "Project Co-Investor Link"
        var displayNamePlural = "Project LLC Links"

        var pkUidColumn = null
        var identifiers = [columnSchema.coInvestorId, columnSchema.investorId]
        var dependencies = [CoInvestorPlantLLC, Investor]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = true
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var coInvestorLLCRecords = this.findTableObjectRecords(data, CoInvestorPlantLLC)
        var investorRecords = this.findTableObjectRecords(data, Investor)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var coInvestorIdCol =  CoInvestorPlantLLC.columnSchema.coInvestorId
        var coInvestorNameCol =  CoInvestorPlantLLC.columnSchema.coInvestorName
        var investorIdCol = Investor.columnSchema.investorId
        var investorNameCol = "investorName"

        investorRecords = injectCounterpartyName(investorRecords, counterpartyRecords, investorIdCol, investorNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    {
                        name: "Project Co-Investor LLC",
                        key: this.columnSchema.coInvestorId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(coInvestorLLCRecords, value, coInvestorIdCol, coInvestorNameCol),
                            dropdownItems: buildDropdownItems(coInvestorLLCRecords, coInvestorIdCol, coInvestorNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Investor",
                        key: this.columnSchema.investorId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(investorRecords, value, investorIdCol, investorNameCol),
                            dropdownItems: buildDropdownItems(investorRecords, investorIdCol, investorNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export var PlantCoInvestorJunction = new PlantCoInvestorJunctionTable()

class CoInvestorContractTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            contractName: "contract_name",
            plantId: "plant_id",
            status: "status",
            effectiveDate: "effective_date",
            endDate: "end_date",
            termYears: "term_years",
            sharepointLink: "sharepoint_link",
            notes: "notes",
        }

        var schemaName = schemas.contract
        var tableName = "co_investor"

        var displayNameSingular = "Co-Investor Contract"
        var displayNamePlural = "Contracts"

        var pkUidColumn = columnSchema.contractId
        var identifiers = [columnSchema.contractId]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName,
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = true
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)

        const validateContract = (contract) => {
            if (!contract[this.columnSchema.plantId]) return {pass: false, message: "Please give the contract a plant."}
            if (!contract[this.columnSchema.contractName]) return {pass: false, message: "Please give the contract a unique name."}
            return {pass: true, message: null}
        }

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateContract,
            validateUpdateItem: validateContract,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeContractID(this.columnSchema.contractId),
                    makeContractName(this.columnSchema.contractName),
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                    {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: contractStatusOptions,
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Effective Date",
                        key: this.columnSchema.effectiveDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "End Date",
                        key: this.columnSchema.endDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Term (years)",
                        key: this.columnSchema.termYears,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Sharepoint Folder Link",
                        key: this.columnSchema.sharepointLink,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes, 1),
                ]
            },
        }
    }

}

export var CoInvestorContract = new CoInvestorContractTable()

class ContractCoInvestorCounterpartiesTable extends Table {

    constructor() {

        var columnSchema = {
            contractId: "contract_id",
            plantCoInvestorId: "plant_co_investor_id",
        }

        var schemaName = schemas.contract
        var tableName = "co_investor_counterparties"

        var displayNameSingular = "Contract Co-Investor Link"
        var displayNamePlural = "Contract Links"

        var pkUidColumn = null
        var identifiers = [columnSchema.contractId, columnSchema.plantCoInvestorId]
        var dependencies = [CoInvestorContract, CoInvestorPlantLLC, Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = true
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    getReferencedPlantId = buildGetReferencedPlantId(this, CoInvestorContract)

    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, CoInvestorContract)
        var coInvestorLLCRecords = this.findTableObjectRecords(data, CoInvestorPlantLLC)

        var contractIdCol = CoInvestorContract.columnSchema.contractId
        var contractNameCol = CoInvestorContract.columnSchema.contractName
        var coInvestorIdCol =  CoInvestorPlantLLC.columnSchema.coInvestorId
        var coInvestorNameCol =  CoInvestorPlantLLC.columnSchema.coInvestorName

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            flexGrow: 1,
                            resizable: true,
                        }
                    }, {
                        name: "Project Co-Investor",
                        key: this.columnSchema.plantCoInvestorId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(coInvestorLLCRecords, value, coInvestorIdCol, coInvestorNameCol),
                            dropdownItems: buildDropdownItems(coInvestorLLCRecords, coInvestorIdCol, coInvestorNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            flexGrow: 1,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export var ContractCoInvestorCounterparties = new ContractCoInvestorCounterpartiesTable()