import './ProjectEquipment.css'
import React from 'react'
import { ProjectBESS } from '../../table_configuration/ProjectEquipment'
import { Counterparty } from '../../table_configuration/Entity'
import { BESSIntegrator } from '../../table_configuration/Counterparty'
import { BESSSpec } from "../../table_configuration/EquipmentSpec"
import ViewPanel from '../ViewPanel'
import { generateUUID } from '../../../utils/databaseAppUtils'
import { useChangeLog, injectedChangeLogIdCol } from '../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../hooks/databaseViewFormValidation'
import {
    ProjectEquipmentHeader, ProjectEquipmentGroupId, ProjectEquipmentSpec, ProjectEquipmentCount,
    ProjectEquipmentNotes, ProjectEquipmentStatus, ProjectEquipmentOwnerProvided, ProjectEquipmentEPCContract
} from './ProjectEquipment'
import { AddFloaterButtonWithPrompt } from '../../../components/button/FloaterButtonWithPrompt'
import { 
    PalantirDatePicker,
    PalantirSelector,
    PalantirAutocomplete
} from '../../../components/input/SelectPicker'
import { PalantirTextField } from '../../../components/input/Text'
import { EPCContract } from '../../table_configuration/Contract'
import _ from 'lodash'


export default function ProjectBESSProxy(props) {

    const bessGroupRecords = props.data[ProjectBESS.buildId()].filter(x => x[ProjectBESS.columnSchema.plantId]===props.selectedPlantId)
    const bessSpecRecords = props.data[BESSSpec.buildId()]
    const bessIntegratorRecords = props.data[BESSIntegrator.buildId()]
    const epcContractRecords = props.data[EPCContract.buildId()].filter(x => x[EPCContract.columnSchema.plantId]===props.selectedPlantId)

    return (
        <ProjectBESSPanel
            initialBESSGroupRecords={bessGroupRecords}
            bessSpecRecords={bessSpecRecords}
            epcContractRecords={epcContractRecords}
            bessIntegratorRecords={bessIntegratorRecords}
            {...props}
        />
    )

}

const ProjectBESSPanel = ({initialBESSGroupRecords, bessSpecRecords, bessIntegratorRecords, epcContractRecords, ...props}) => {

    const [
        bessGroupRecords, prepareBessGroupLog,
        updateBESSGroupLog, addToBESSGroupLog, deleteFromBESSGroupLog, bulkOpOnBESSGroupLog, mergeAndResetBESSGroupLog
    ] = useChangeLog(initialBESSGroupRecords, ProjectBESS)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToBESSGroupLogProxy = () => {
        let bessGroup = ProjectBESS.buildNewRecord()
        bessGroup[ProjectBESS.columnSchema.bessGroupId] = generateUUID()
        bessGroup[ProjectBESS.columnSchema.plantId] = props.selectedPlantId
        addToBESSGroupLog(bessGroup)
    }
    const deleteFromBESSGroupLogProxy = (bessGroup) => {
        deleteFromBESSGroupLog(bessGroup)
    }

    const bessGroupComponents = bessGroupRecords.map(bessGroup => {
        return (
            <ProjectBESSForm
                key={bessGroup[ProjectBESS.columnSchema.bessGroupId]}
                bessGroup={bessGroup}
                bessSpecRecords={bessSpecRecords}
                bessIntegratorRecords={bessIntegratorRecords}
                epcContractRecords={epcContractRecords}
                updateBESSGroupLog={(update) => updateBESSGroupLog(bessGroup, update)}
                deleteFromBESSGroupLog={() => deleteFromBESSGroupLogProxy(bessGroup)}
                errors={errors}
                removeErrors={removeErrors}
                services={props.services}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Project BESS Groups"
            submitUrl='api/precious/table/project_bess'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.projectBESSChangelog,
                        checks: [{
                            checkColumn: ProjectBESS.columnSchema.bessSpecId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a bess specification.",
                            path: [
                                {name: ProjectBESS.columnSchema.bessGroupId, type: "eval"},
                                {name: ProjectBESS.buildId(), type: "static"},
                                {name: injectedChangeLogIdCol, type: "eval"}
                            ]
                        }]
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetBESSGroupLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    projectBESSChangelog: prepareBessGroupLog(),
                }
            }}
        >
            {bessGroupComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToBESSGroupLogProxy}
                labelContent="Add new bess group"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

const ProjectBESSForm = React.memo(function({bessGroup, bessSpecRecords, bessIntegratorRecords, epcContractRecords, updateBESSGroupLog, deleteFromBESSGroupLog, errors, removeErrors, services}) {

    const bessGroupId = bessGroup[ProjectBESS.columnSchema.bessGroupId]
    const bessChangeLogId = bessGroup[injectedChangeLogIdCol]
    
    const bessIntegratorId = bessGroup[ProjectBESS.columnSchema.integratorId]
    const bessIntegrator = bessIntegratorRecords.find(x => x[Counterparty.columnSchema.counterpartyId]===bessIntegratorId) || {}

    const bessSpecErrorPath = [bessGroupId, ProjectBESS.buildId(), bessChangeLogId]
    
    return (
        <div className="field-group form-instance project-equipment">
            <ProjectEquipmentHeader
                ProjectEquipmentTable={ProjectBESS}
                deleteFromEquipmentGroupLog={deleteFromBESSGroupLog}
                removeErrorsOnDelete={() => removeErrors([bessGroupId])}
            />
            <div className="flow-horizontal body">
                <div className="flow-vertical vertical-children-spacing equipment-meta-col">
                    <ProjectEquipmentGroupId
                        ProjectEquipmentTable={ProjectBESS}
                        value={bessGroupId}
                    />
                    <ProjectEquipmentStatus
                        equipmentGroup={bessGroup}
                        statusCol={ProjectBESS.columnSchema.status}
                        updateEquipmentGroup={updateBESSGroupLog}
                    />
                    <ProjectEquipmentEPCContract
                        equipmentContractIdCol={EPCContract.columnSchema.contractId}
                        equipmentGroupEPCContractId={bessGroup[ProjectBESS.columnSchema.contractId]}
                        epcContracts={epcContractRecords}
                        updateEquipmentGroup={updateBESSGroupLog}
                        services={services}
                    />
                    <ProjectEquipmentOwnerProvided
                        equipmentGroup={bessGroup}
                        ownerProvidedCol={ProjectBESS.columnSchema.isOwnerProvided}
                        updateEquipmentGroup={updateBESSGroupLog}
                    />
                    <PalantirAutocomplete
                        label="BESS Integrator"
                        value={{label: bessIntegrator[Counterparty.columnSchema.counterpartyName], id: bessIntegrator[Counterparty.columnSchema.counterpartyId]}}
                        options={_.sortBy(bessIntegratorRecords.map((integratorRecord) => {
                            return {
                                label: integratorRecord[Counterparty.columnSchema.counterpartyName],
                                id: integratorRecord[Counterparty.columnSchema.counterpartyId]
                            }
                        }), (x) => x.label)}
                        onUpdate={(x) => {
                            updateBESSGroupLog({[ProjectBESS.columnSchema.integratorId]: x.id})
                        }}
                    />
                    <PalantirSelector
                        label="Enclosure Type"
                        value={bessGroup[ProjectBESS.columnSchema.enclosureType]}
                        onChange={(x) => updateBESSGroupLog({[ProjectBESS.columnSchema.enclosureType]: x})}
                        items={ProjectBESS.options.enclosureType}
                    />
                    <ProjectEquipmentCount
                        label="Container Count"
                        ProjectEquipmentTable={ProjectBESS}
                        equipmentGroup={bessGroup}
                        equipmentGroupCountCol={ProjectBESS.columnSchema.containerCount}
                        updateProjectEquipmentLog={updateBESSGroupLog}
                    />
                    <ProjectEquipmentNotes
                        value={bessGroup[ProjectBESS.columnSchema.notes]}
                        notesColumn={ProjectBESS.columnSchema.notes}
                        updateProjectEquipment={updateBESSGroupLog}
                    />
                </div>
                <div style={{marginRight: "30px"}}>
                    <div className="header">BESS Spec</div>
                    <ProjectEquipmentSpec
                        equipmentGroup={bessGroup}
                        equipmentSpecRecords={bessSpecRecords}
                        updateEquipmentGroupLog={updateBESSGroupLog}
                        EquipmentComponents={[
                            (bessSpec) => <PalantirTextField
                                label="Chemistry"
                                value={bessSpec[BESSSpec.columnSchema.chemistry]}
                                disabled
                                style={{minWidth: "170px", width: "170px"}}
                            />, (bessSpec) => <PalantirTextField
                                label="Energy Capacity (kWh)"
                                value={bessSpec[BESSSpec.columnSchema.energyCapacity]}
                                disabled
                                style={{minWidth: "170px", width: "170px"}}
                            />, (bessSpec) => <PalantirTextField
                                label="Power Capacity (kVa)"
                                value={bessSpec[BESSSpec.columnSchema.powerCapacity]}
                                disabled
                                style={{minWidth: "170px", width: "170px"}}
                            />
                        ]}
                        equipmentType="BESS"
                        projectEquipmentSpecIdCol={ProjectBESS.columnSchema.bessSpecId}
                        equipmentSpecIdCol={BESSSpec.columnSchema.bessSpecId}
                        manufacturerCol={BESSSpec.columnSchema.manufacturer}
                        modelCol={BESSSpec.columnSchema.model}
                        errors={errors}
                        equipmentSpecErrorPath={bessSpecErrorPath}
                        removeEquipmentSpecError={() => {
                            removeErrors(bessSpecErrorPath)
                        }}
                    />
                </div>
                <div className="flow-vertical">
                    <div className="header">Dates</div>
                    <div className="flow-horizontal">
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty Start"
                                value={bessGroup[ProjectBESS.columnSchema.warrantyStartDate]}
                                onChange={(date) => updateBESSGroupLog({[ProjectBESS.columnSchema.warrantyStartDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Current Dates</div>
                            <PalantirDatePicker
                                label="Delivery Start"
                                value={bessGroup[ProjectBESS.columnSchema.deliveryStartDateCurrent]}
                                onChange={(date) => updateBESSGroupLog({[ProjectBESS.columnSchema.deliveryStartDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Delivery End"
                                value={bessGroup[ProjectBESS.columnSchema.deliveryEndDateCurrent]}
                                onChange={(date) => updateBESSGroupLog({[ProjectBESS.columnSchema.deliveryEndDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={bessGroup[ProjectBESS.columnSchema.purchaseOrderDateCurrent]}
                                onChange={(date) => updateBESSGroupLog({[ProjectBESS.columnSchema.purchaseOrderDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Capacity Reservation Order"
                                value={bessGroup[ProjectBESS.columnSchema.capacityReservationOrderDateCurrent]}
                                onChange={(date) => updateBESSGroupLog({[ProjectBESS.columnSchema.capacityReservationOrderDateCurrent]: date})}
                            />
                        </div>
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty End"
                                value={bessGroup[ProjectBESS.columnSchema.warrantyEndDate]}
                                onChange={(date) => updateBESSGroupLog({[ProjectBESS.columnSchema.warrantyEndDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Budgeted Dates</div>
                            <PalantirDatePicker
                                label="Delivery Start"
                                value={bessGroup[ProjectBESS.columnSchema.deliveryStartDateBudgeted]}
                                onChange={(date) => updateBESSGroupLog({[ProjectBESS.columnSchema.deliveryStartDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Delivery End"
                                value={bessGroup[ProjectBESS.columnSchema.deliveryEndDateBudgeted]}
                                onChange={(date) => updateBESSGroupLog({[ProjectBESS.columnSchema.deliveryEndDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={bessGroup[ProjectBESS.columnSchema.purchaseOrderDateBudgeted]}
                                onChange={(date) => updateBESSGroupLog({[ProjectBESS.columnSchema.purchaseOrderDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Capacity Reservation Order"
                                value={bessGroup[ProjectBESS.columnSchema.capacityReservationOrderDateBudgeted]}
                                onChange={(date) => updateBESSGroupLog({[ProjectBESS.columnSchema.capacityReservationOrderDateBudgeted]: date})}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.bessGroup, nextProps.bessGroup) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})