import {Component} from 'react'

class ExpressionEditor extends Component {
    constructor(props) {
        super()
        this.state = {

        }
    }

    componentDidMount() {
        console.log("Mounting express editor")

        window.ace.config.set("basePath", "https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/");
        //window.ace.config.setModuleUrl("ace/theme/ambiance", "https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/theme-ambiance.min.js");
        //window.ace.config.setModuleUrl("ace/mode/python", "https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/mode-python.min.js");
        window.ace.config.setModuleUrl("ace/ext/language_tools", "https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/ext-language_tools.min.js");
        window.ace.require("ace/ext/language_tools");

        var editor = window.ace.edit("python-editor")
        this.editor = editor
        editor.setValue(this.props.expression)
        editor.setTheme("ace/theme/dawn");
        editor.session.setMode("ace/mode/python");
        editor.setShowPrintMargin(false)
        editor.setOption("enableBasicAutocompletion", true)
        editor.setOption("enableLiveAutocompletion", true)

        if (this.props.getEditorRef) this.props.getEditorRef(editor)
    }

    render() {
        return (
            <div id="python-editor" style={{height: '100%'}}></div>
        )
    }
}

export default ExpressionEditor