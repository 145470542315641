import './MarketTab.css'
import React, {Component} from 'react'

import AssetMenu from '../../menu/AssetMenu'
import Navbar from '../../navbar/Navbar'
import SummaryContent from "./summary/SummaryContent";
import RSATContent from './rsat/RSATContent'
import TableContent from './table/TableContent'
import RegulationContent from './regulation/RegulationContent'
import ForecastsContent from './forecasts/ForecastsContent'

import { getPortfolio, setPortfolio, getSite, setSite } from '../../../utils/UrlLocation'

class MarketTab extends Component {

    constructor(props) {
        super()
        this.state = {
            selectedPortfolioId: getPortfolio(),
            selectedSiteId: getSite(),
        }
    }

    componentDidMount() {
        
    }

    render() {

        var services = this.props.services

        return (
            <div className="flow-horizontal hide-overflow fill-parent">
                <AssetMenu 
                    services={this.props.services}
                    updateSelectedSite={this.updateSelectedSiteId.bind(this)} 
                    updateSelectedPortfolio={this.updateSelectedPortfolioId.bind(this)}
                    selectedSiteId={this.state.selectedSiteId}
                    selectedPortfolioId={this.state.selectedPortfolioId}
                    className="sidebar"
                    menuProps={{
                        collapsable: true,
                    }}
                />
                <Navbar 
                    services={services}
                    defaultURL="overview"
                    items={[
                        {
                            label: "Overview",
                            url: "overview",
                            component: SummaryContent,
                            props: {
                                services: services,
                                selectedSiteId: this.state.selectedSiteId
                            }
                        },
                        {
                            label: "Table",
                            url: "table",
                            component: TableContent,
                            props: {
                                services: services,
                                selectedSiteId: this.state.selectedSiteId
                            }
                        },
                        {
                            label: "Regulation",
                            url: "regulation",
                            component: RegulationContent,
                            props: {
                                services: services,
                                selectedSiteId: this.state.selectedSiteId
                            }
                        },
                        {
                            label: "RSAT",
                            url: "rsat",
                            component: RSATContent,
                            props: {
                                services: services,
                                selectedSiteId: this.state.selectedSiteId
                            }
                        },
                        {
                            label: "Forecast",
                            url: "forecast",
                            component: ForecastsContent,
                            props: {
                                services: services,
                                selectedSiteId: this.state.selectedSiteId
                            }
                        }
                    ]}
                />
            </div>
        )
    }

    updateSelectedSiteId(id) {
        setSite(id)
        setPortfolio(null)
        this.setState({
            selectedPortfolioId: null,
            selectedSiteId: id,
        })
    }

    updateSelectedPortfolioId(id) {
        setPortfolio(id)
        setSite(null)
        this.setState({
            selectedPortfolioId: id,
            selectedSiteId: null,
        })
    }

}

export default MarketTab