import './EventModal.css'
import {Component} from 'react'

import {Button, Modal} from 'rsuite'


class EventModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    close = (cancel) => {
        if (cancel) {
            this.setState({
                show: false,
            });
        }
        else {
            this.setState({
                show: false
            })
            if (this.props.onClose) this.props.onClose()
        }
    }

    open = () => {
        this.setState({ show: true });
    }

    render() {
        return (
            <div className="modal-container">
                <div onClick={this.open}>
                    {this.props.children}
                </div>
                {/*<FontAwesomeIcon onClick={this.open} icon={faCog} style={{fontSize: '16px'}} fixedWidth className="fa-spin-hover" />*/}

                <Modal show={this.state.show} onHide={() => this.close(true)}>
                    <Modal.Header>
                        <Modal.Title>Select Your Devices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Event Menu
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.close(false)} appearance="primary">
                        Ok
                        </Button>
                        <Button onClick={() => this.close(true)} appearance="subtle">
                        Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    
    updateSelectedDeviceIds = (id) => {
        var selectedDeviceIds = Object.assign([], this.state.selectedDeviceIds)
        var curIdx = selectedDeviceIds.indexOf(id)
        if (curIdx!==-1) {
            selectedDeviceIds.splice(curIdx, 1)
        }
        else {
            selectedDeviceIds.push(id)
        }
        this.setState({
            selectedDeviceIds: selectedDeviceIds
        })
    }

}

export default EventModal