import { useState } from 'react'
import { Nav, Sidenav } from 'rsuite'

export function OptionNav(props) {

    var [selection, setSelection] = useState(Object.keys(props.options)[0])

    const Display = props.options[selection]

    const NavItems = Object.keys(props.options).map(v => <Nav.Item eventKey={v}>{v}</Nav.Item>)

    return (
        <div className={props.className || ""} style={props.style || {}}>
            <Nav appearance="subtle" activeKey={selection} onSelect={(k) => setSelection(k)} style={props.navStyle || {}}>
                {NavItems}
            </Nav>
            <Display />
        </div>
    )
}

export function SimpleSideNav(props) {

    var [selection, setSelection] = useState(Object.keys(props.options)[0])

    const Display = props.options[selection]

    const NavItems = Object.keys(props.options).map(v => <div eventKey={v}>{v}</div>)

    return (
        <div className="flow-horizontal" style={{flexShrink: 0}}>
            <Sidenav appearance="subtle" activeKey={selection} onSelect={(k) => setSelection(k)} style={{ width: 120 }}>
                <Sidenav.Body>
                    <Nav>
                        {NavItems}
                    </Nav>
                </Sidenav.Body>
            </Sidenav>
            <Display />
        </div>
    )
}