import {Component} from 'react'

import Panel from '../../../panel/Panel'

import RegulationTrackingContainer from './RegulationTrackingContainer'
import RegulationUsageContainer from './RegulationUsageContainer'
import { isSiteBess } from '../../../../utils/assetFrameworkMacros'

class RegulationContent extends Component {

    constructor(props) {
        super()
        this.state = {
            
        }
    }

    render() {

        if (!this.props.selectedSiteId) {
            return <Panel>Please select a site.</Panel>
        }

        const selectedSite = this.props.services.assetFramework.getSites(this.props.selectedSiteId)
        if (!isSiteBess(selectedSite)){
            return <Panel>This dashboard is only available for sites with a battery system.</Panel>
        }

        return (
            <div className="section-main flow-vertical fill-parent overflow-y">
                <RegulationTrackingContainer
                    services={this.props.services}
                    selectedSiteId={this.props.selectedSiteId}
                />
                <RegulationUsageContainer
                    services={this.props.services}
                    selectedSiteId={this.props.selectedSiteId}
                />
            </div>
        )
    }

}

export default RegulationContent