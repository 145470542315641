import './DimensionSelectionModal.css'
import {Component} from 'react'

import {Button, Modal} from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

import GenericForm from '../form/GenericForm'

class GenericFormModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: _.cloneDeep(props.initialFields)
        };
    }

    close = (cancel) => {
        if (cancel) {
            this.setState({
                show: false,
                data: this.props.initialFields || {}
            });
        }
        else {
            this.setState({
                show: false
            })
            if (this.props.onClose) this.props.onClose(this.state.data)
        }
    }
    
    open = () => {
        this.setState({ show: true });
    }

    render() {
        return (
            <div className="modal-container">
                <FontAwesomeIcon onClick={this.open} icon={faCog} style={{fontSize: '16px'}} fixedWidth className="fa-spin-hover" />

                <Modal show={this.state.show} onHide={() => this.close(true)}>
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GenericForm initialFields={this.state.data} onChange={this.setData} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.close(false)} appearance="primary">
                    Ok
                    </Button>
                    <Button onClick={() => this.close(true)} appearance="subtle">
                    Cancel
                    </Button>
                </Modal.Footer>
                </Modal>
            </div>
        );
    }

    setData = (data) => {
        this.setState({
            data: data.map(x => x.value)
        })
    }

}

  export default GenericFormModal