import { useEffect, useState } from "react"
import { PalantirTextField, PalantirDispatchedTextField } from "../../../../components/input/Text"
import { PalantirAutocomplete, PalantirBooleanSelector, PalantirDatePicker, PalantirSelector, } from "../../../../components/input/SelectPicker"
import { useChangeLog } from "../../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../../hooks/databaseViewFormValidation"
import ViewPanel from "../../ViewPanel"
import Alerter from "../../../../components/alerter/Alerter"
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from "../../../../components/button/FloaterButtonWithPrompt"
import { Text } from "@mantine/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight, faExclamation, faLink, faSquareUpRight, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { PalantirButton } from "../../../../components/button/Button"
import { ErrorRND, RNDErrorInstance, ViewFormError } from "../../../../utils/databaseAppUtils"
import { validateHTTPURL } from "../../../../utils/MiscUtils"
import { Switch } from '@mantine/core';
import _ from "lodash"
import { EnvironmentalAndPermittingChecklist, EnvironmentalAndPermittingChecklistEntry, EnvironmentalAndPermittingChecklistItem, Plant } from "../../../table_configuration/Project"
import { generateUUID } from "../../../../utils/databaseAppUtils"


const buildHeirarchy = (item, checklistItems) => {
    const children = _.sortBy(checklistItems.filter(x => x[EnvironmentalAndPermittingChecklistItem.columnSchema.parentId]===item[EnvironmentalAndPermittingChecklistItem.columnSchema.oid]), EnvironmentalAndPermittingChecklistItem.columnSchema.sortOrder).map(x => buildHeirarchy(x, checklistItems))
    return {
        item: item,
        children: children
    }
}

const buildChecklistRecord = (fields) => {
    const record = EnvironmentalAndPermittingChecklist.buildNewRecord()
    return Object.assign(record, fields)
}
const buildChecklistEntryRecord = (fields) => {
    const record = EnvironmentalAndPermittingChecklistEntry.buildNewRecord()
    record[EnvironmentalAndPermittingChecklistEntry.columnSchema.oid] = generateUUID()
    return Object.assign(record, fields)
}
const filterChecklistItemsToShowOnAdd = (checklistItems, checklistEntryItems) => {
    return checklistItems.filter(x => {
        const entry = checklistEntryItems.find(y => y[EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]===x[EnvironmentalAndPermittingChecklistItem.columnSchema.oid])
        return !entry || !entry[EnvironmentalAndPermittingChecklistEntry.columnSchema.required]
    })
}

export default function EnvironmentalAndPermittingChecklistPanelProxy(props) {

    const checklist = props.data[EnvironmentalAndPermittingChecklist.buildId()].find(x => x[EnvironmentalAndPermittingChecklist.columnSchema.projectId]===props.selectedPlantId)
    const checklistId = checklist ? checklist[EnvironmentalAndPermittingChecklist.columnSchema.oid] : undefined
    const checklistItems = props.data[EnvironmentalAndPermittingChecklistItem.buildId()]
    const checklistEntries = checklist ? props.data[EnvironmentalAndPermittingChecklistEntry.buildId()].filter(x => x[EnvironmentalAndPermittingChecklistEntry.columnSchema.checklistId]===checklistId) : []

    return (
        <ProjectTDSCPanel
            initialChecklist={checklist}
            initialChecklistEntries={checklistEntries}
            checklistItems={checklistItems}
            {...props}
        />
    )
}

function ProjectTDSCPanel({initialChecklist, initialChecklistEntries, checklistItems, ...props}) {

    const [
        checklists, prepareChecklistLogs, updateChecklistLog, addToChecklistLog,
        deleteFromChecklistLog, bulkOpOnChecklistLog, mergeAndResetChecklistLogs
    ] = useChangeLog(initialChecklist ? [initialChecklist] : [], EnvironmentalAndPermittingChecklist)
    const [
        checklistEntries, prepareChecklistEntryLogs, updateChecklistEntryLog, addToChecklistEntryLog,
        deleteFromChecklistEntryLog, bulkOpOnChecklistEntryLog, mergeAndResetChecklistEntryLogs
    ] = useChangeLog(initialChecklistEntries, EnvironmentalAndPermittingChecklistEntry)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()
    const [isEdit, setIsEdit] = useState(false)

    /* There will always be at most 1 checklist per project */
    const checklist = checklists[0]

    const checkAnyParentIsRequired = (checklistItem, level=0) => {
        if (!checklistItem) return false
        const parentId = checklistItem[EnvironmentalAndPermittingChecklistItem.columnSchema.parentId]
        if (!parentId) {
            const entry = checklistEntries.find(x => x[EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]===checklistItem[EnvironmentalAndPermittingChecklistItem.columnSchema.oid]) || {}
            return level===0 ? true : entry[EnvironmentalAndPermittingChecklistEntry.columnSchema.required]
        }
        const parentItem = checklistItems.find(x => x[EnvironmentalAndPermittingChecklistItem.columnSchema.oid]===parentId)
        const parentEntry = checklistEntries.find(x => x[EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]===parentItem[EnvironmentalAndPermittingChecklistItem.columnSchema.oid])
        if (parentEntry[EnvironmentalAndPermittingChecklistEntry.columnSchema.required]) return true
        return checkAnyParentIsRequired(parentEntry, level+1)
    }
    /* Add new checklist items to the existing checklist. Only require them if they are template items and have any parent that is required. */
    useEffect(() => {
        // Add new template items as entries
        if (!checklist) return
        const adds = []
        checklistItems.forEach(checklistItem => {
            const entry = checklistEntries.find(y => y[EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]===checklistItem[EnvironmentalAndPermittingChecklistItem.columnSchema.oid])
            if (!entry) {
                // Check if any parent items are required, if not, set this one to not required
                const anyParentIsRequired = checkAnyParentIsRequired(checklistItem)
                const checklistEntry = buildChecklistEntryRecord({
                    [EnvironmentalAndPermittingChecklistEntry.columnSchema.checklistId]: checklist[EnvironmentalAndPermittingChecklist.columnSchema.oid],
                    [EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]: checklistItem[EnvironmentalAndPermittingChecklistItem.columnSchema.oid],
                    [EnvironmentalAndPermittingChecklistEntry.columnSchema.required]: checklistItem[EnvironmentalAndPermittingChecklistItem.columnSchema.isTemplateItem] ? (anyParentIsRequired ? true : false) : false
                })
                adds.push(checklistEntry)
            }
        })
        addToChecklistEntryLog(adds)
    }, [])

    /* Creating item here just sets to required */
    const onCreateChecklistEntry = (itemId) => {
        const checklistEntry = checklistEntries.find(x => x[EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]===itemId)
        updateChecklistEntryLog(checklistEntry, {[EnvironmentalAndPermittingChecklistEntry.columnSchema.required]: true})
    }
    /* Deleting checklist entries just makes them as unrequired and hidden from user */
    const deleteChecklistEntry = (checklistEntry) => {
        const childrenIds = findChildrenIds(checklistEntry[EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId])
        const checklistEntriesToDelete = checklistEntries.filter(x => childrenIds.includes(x[EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]))

        const itemErrorPaths = childrenIds.map(x => [EnvironmentalAndPermittingChecklistEntry.buildId(), x, EnvironmentalAndPermittingChecklistEntry.columnSchema.status])
        removeErrors(...itemErrorPaths)

        bulkOpOnChecklistEntryLog([{method: "update", changes: checklistEntriesToDelete.map(x => [x, {[EnvironmentalAndPermittingChecklistEntry.columnSchema.required]: false}])}])
    }
    const findChildrenIds = (checklistItemId) => {
        const directChildrenIds = checklistItems.filter(x => x[EnvironmentalAndPermittingChecklistItem.columnSchema.parentId]===checklistItemId).map(x => x[EnvironmentalAndPermittingChecklistItem.columnSchema.oid])
        const allChildrenIds = directChildrenIds.map(x => findChildrenIds(x)).flat()
        return [checklistItemId].concat(directChildrenIds).concat(allChildrenIds)
    }

    var ctr = 2
    const createChecklistItemRowComponent = (checklistItem, level=0) => {
        
        const gridIdx = ctr
        ctr+=1

        const checklistItemId = checklistItem[EnvironmentalAndPermittingChecklistItem.columnSchema.oid]
        const checklistItemName = checklistItem[EnvironmentalAndPermittingChecklistItem.columnSchema.name]
        let checklistEntry = checklistEntries.find(x => x[EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]===checklistItemId)

        const childrenItems = _.sortBy(checklistItems.filter(x => x[EnvironmentalAndPermittingChecklistItem.columnSchema.parentId]===checklistItem[EnvironmentalAndPermittingChecklistItem.columnSchema.oid]), EnvironmentalAndPermittingChecklistItem.columnSchema.sortOrder)
        const hasChildren = childrenItems.length > 0
        const margin = (level+1)*20 + (!hasChildren ? 22 : 0)
        const width = 300 - margin - (hasChildren ? 22 : 0) - (isEdit ? 22 : 0)
        const childrenComponents = childrenItems.map(x => createChecklistItemRowComponent(x, level+1))
        if (isEdit) {
            const idx = ctr
            ctr+=1
            childrenComponents.push(
                <AddComponent
                    idx={idx}
                    checklistItems={filterChecklistItemsToShowOnAdd(childrenItems, checklistEntries)}
                    onAdd={(x) => onCreateChecklistEntry(x)}
                    margin={margin}
                    width={width}
                />
            )
        }

        const itemErrorPath = [EnvironmentalAndPermittingChecklistEntry.buildId(), checklistItemId, EnvironmentalAndPermittingChecklistEntry.columnSchema.status]

        let updateRecord
        // If there is no entry for this item and it is a part of the template we should trigger a creation on save instead of an update
        if (!checklistEntry) {
            // This is a temporary record that is just used for displaying the prefilled fields. The final record is created in the callback below when the user updates this record.
            const tmpChecklistEntry = buildChecklistEntryRecord({
                [EnvironmentalAndPermittingChecklistEntry.columnSchema.checklistId]: checklist[EnvironmentalAndPermittingChecklist.columnSchema.oid],
                [EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]: checklistItemId,
                [EnvironmentalAndPermittingChecklistEntry.columnSchema.required]: checklistItem[EnvironmentalAndPermittingChecklistItem.columnSchema.isTemplateItem]
            })

            updateRecord = (fields) => {
                const newRecord = Object.assign(tmpChecklistEntry, fields)
                addToChecklistEntryLog(newRecord)
                removeErrors(itemErrorPath)
            }
            checklistEntry = tmpChecklistEntry
        }
        else {
            updateRecord = (fields) => {
                updateChecklistEntryLog(checklistEntry, fields)
                removeErrors(itemErrorPath)
            }
        }

        const itemError = errors.get(...itemErrorPath)
        const itemErrorProps = itemError ? {error: true, helperText: itemError.getMessage()} : {}

        const anyVisibleChildren = childrenItems.map(x => {
            const entry = checklistEntries.find(y => y[EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]===x[EnvironmentalAndPermittingChecklistItem.columnSchema.oid])
            return entry ? entry[EnvironmentalAndPermittingChecklistEntry.columnSchema.required] : false
        }).reduce((prev, cur) => prev||cur, false)

        if (!checklistEntry[EnvironmentalAndPermittingChecklistEntry.columnSchema.required]) return
        return (
            <ChecklistItem
                key={checklistItemId}
                idx={gridIdx}
                checklistItemName={checklistItemName}
                checklistEntry={checklistEntry}
                updateRecord={updateRecord}
                level={level}
                statusErrorProps={itemErrorProps}
                isEdit={isEdit}
                onDelete={() => deleteChecklistEntry(checklistEntry)}
            >
                {(isEdit || anyVisibleChildren) ? childrenComponents : []}
            </ChecklistItem>
        )
    }
    const createNewChecklist = () => {
        const inserts = []

        const newChecklist = buildChecklistRecord({
            [EnvironmentalAndPermittingChecklist.columnSchema.oid]: generateUUID(),
            [EnvironmentalAndPermittingChecklist.columnSchema.projectId]: props.selectedPlantId,
            [EnvironmentalAndPermittingChecklist.columnSchema.name]: props.selectedPlant[Plant.columnSchema.plantName]
        })
        const newChecklistId = newChecklist[EnvironmentalAndPermittingChecklist.columnSchema.oid]
        
        checklistItems.forEach((item, idx) => {
            inserts.push(buildChecklistEntryRecord({
                [EnvironmentalAndPermittingChecklistEntry.columnSchema.checklistId]: newChecklistId,
                [EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId]: item[EnvironmentalAndPermittingChecklistItem.columnSchema.oid],
                [EnvironmentalAndPermittingChecklistEntry.columnSchema.required]: item[EnvironmentalAndPermittingChecklistItem.columnSchema.isTemplateItem],
                [EnvironmentalAndPermittingChecklistEntry.columnSchema.status]: item[EnvironmentalAndPermittingChecklistItem.columnSchema.isTemplateItem] ? "Not Started" : null
            }))
        })
        addToChecklistLog(newChecklist)
        addToChecklistEntryLog(inserts)
    }
    const constructItemHeirarchy = () => {
        const topLevelChecklistItems = _.sortBy(checklistItems.filter(x => x[EnvironmentalAndPermittingChecklistItem.columnSchema.parentId]===null), EnvironmentalAndPermittingChecklistItem.columnSchema.sortOrder)
        const x = topLevelChecklistItems.map(x => createChecklistItemRowComponent(x))
        if (isEdit) {
            const width = 300 - 22 - 22
            return x.concat([
                <AddComponent
                    idx={ctr}
                    checklistItems={filterChecklistItemsToShowOnAdd(topLevelChecklistItems, checklistEntries)}
                    onAdd={(x) => onCreateChecklistEntry(x)}
                    width={width}
                />
            ])
        }
        return x
    }


    return (
        <ViewPanel
            services={props.services}
            title="Environmental and Permitting Checklist"
            submitUrl='api/precious/table/environmental_and_permitting_checklist'
            verifySubmit={(payload) => {
                verifyChangelogSubmission({
                    changeLog: payload.checklistChangeLog,
                    checks: []
                }, {
                    changeLog: payload.checklistEntryChangeLog,
                    checks: [{
                        checkFunction: (x) => x[EnvironmentalAndPermittingChecklistEntry.columnSchema.required] ? x[EnvironmentalAndPermittingChecklistEntry.columnSchema.status] : true,
                        errMessage: "Please select a status.",
                        path: [
                            {name: EnvironmentalAndPermittingChecklistEntry.buildId(), type: "static"},
                            {name: EnvironmentalAndPermittingChecklistEntry.columnSchema.itemId, type: "eval"},
                            {name: EnvironmentalAndPermittingChecklistEntry.columnSchema.status, type: "static"},
                        ]
                    }]
                })
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                mergeAndResetChecklistLogs()
                mergeAndResetChecklistEntryLogs()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    checklistChangeLog: prepareChecklistLogs(),
                    checklistEntryChangeLog: prepareChecklistEntryLogs(),
                }
            }}
        >
            {!checklist ?
            <div className="flow-vertical">
                <div>This project has no existing checklist.</div>
                <AddFloaterButtonWithPrompt
                    onClick={() => {
                        createNewChecklist()
                    }}
                    labelContent="Click to add."
                    style={{float: "right", marginTop: "4px"}}
                    height={14}
                    width={14}
                />
            </div> :
            <div>
                <Switch
                    checked={isEdit}
                    onChange={(e) => setIsEdit(e.currentTarget.checked)}
                    label="Edit Heirarchy"
                    style={{marginBottom: "10px"}}
                />
                <div className="form-instance" style={{minWidth: "1100px"}}>
                    <div style={{display: "grid", gridTemplateColumns: "300px 120px 150px", gridTemplateRows: "50px auto", columnGap: "20px", rowGap: "0px"}}>
                        <div style={{gridRow: 1, gridColumn: 1}}><Text size="lg" style={{marginLeft: "22px", borderBottom: "solid grey 1px"}}>Item</Text></div>
                        <div style={{gridRow: 1, gridColumn: 2}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Status</Text></div>
                        <div style={{gridRow: 1, gridColumn: 3}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Document Link</Text></div>
                        <div style={{gridRow: 1, gridColumn: 4}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Comments</Text></div>
                        {constructItemHeirarchy()}
                    </div>
                </div>
            </div>}
        </ViewPanel>
    )
}

const ChecklistItem = ({idx, checklistEntry, checklistItemName, updateRecord, level, children, statusErrorProps, isEdit, onDelete}) => {

    const [showChildren, setShowChildren] = useState(false)
    const toggleShowChildren = () => {
        setShowChildren((x) => !x)
    }

    const status = checklistEntry[EnvironmentalAndPermittingChecklistEntry.columnSchema.status]
    const comments = checklistEntry[EnvironmentalAndPermittingChecklistEntry.columnSchema.comments]
    const documentLink = checklistEntry[EnvironmentalAndPermittingChecklistEntry.columnSchema.documentLink]
    const documentLinkIsValid = validateHTTPURL(documentLink)


    const hasChildren = children.length > 0
    const margin = level*20 + (!hasChildren ? 22 : 0)
    const width = 300 - margin - (hasChildren ? 22 : 0) - (isEdit ? 22 : 0)
    
    return (
        <>
            <div style={{gridColumn: 1, gridRow: idx, marginLeft: `${margin}px`}}>
                {isEdit && <DeleteFloaterButtonWithPrompt
                    onClick={onDelete}
                    style={{position: "relative", top: "6px"}}
                    height={14}
                    width={14}
                />}
                {hasChildren && <FontAwesomeIcon onClick={toggleShowChildren} icon={showChildren ? faAngleDown : faAngleRight} style={{marginRight: "12px", width: "10px", position: "relative", top: "6px"}} />}
                <PalantirTextField
                    value={checklistItemName}
                    disabled
                    style={{width: `${width}px`, marginBottom: "5px"}}
                />
            </div>
            <PalantirSelector
                value={status}
                items={EnvironmentalAndPermittingChecklistEntry.options.status}
                onChange={(x) => updateRecord({[EnvironmentalAndPermittingChecklistEntry.columnSchema.status]: x})}
                style={{gridColumn: 2, gridRow: idx, minWidth: "120px"}}
                {...statusErrorProps}
            />
            <div className="flow-horizontal" style={{gridColumn: 3, gridRow: idx}}>
                <PalantirDispatchedTextField
                    value={documentLink}
                    onChange={(x) => updateRecord({[EnvironmentalAndPermittingChecklistEntry.columnSchema.documentLink]: x})}
                    variant="standard"
                    style={{flexGrow: 1}}
                />
                {documentLink && 
                    (documentLinkIsValid ? 
                        <a href={documentLink} target="__blank"><FontAwesomeIcon icon={faUpRightFromSquare} style={{position: "relative", top: "10px", marginLeft: "8px"}} /></a> :
                        <FontAwesomeIcon icon={faExclamation} color="red" style={{position: "relative", top: "12px", right: "2px", width: "8px", marginLeft: "10px"}} />
                    )
                }
            </div>
            <PalantirDispatchedTextField
                value={comments}
                onChange={(x) => updateRecord({[EnvironmentalAndPermittingChecklistEntry.columnSchema.comments]: x})}
                variant="standard"
                style={{flexGrow: 1, gridColumn: 4, gridRow: idx}}
            />
            {showChildren && children}
        </>
    )
}

const AddComponent = ({idx, checklistItems, onAdd, margin, width}) => {

    const [selectedItem, setSelectedItem] = useState(null)
    const itemId = selectedItem ? selectedItem[EnvironmentalAndPermittingChecklistItem.columnSchema.oid] : null
    const itemName = selectedItem ? selectedItem[EnvironmentalAndPermittingChecklistItem.columnSchema.name] : null

    return (
        <div className="flow-horizontal" style={{gridRow: idx, gridColumn: 1, marginLeft: `${margin}px`, marginBottom: "15px"}}>
            <AddFloaterButtonWithPrompt
                onClick={() => {
                    if (itemId) {
                        onAdd(itemId);
                        setSelectedItem(null)
                    }
                }}
                height={14}
                width={14}
            />
            <PalantirAutocomplete
                value={{label: itemName, id: itemId}}
                options={checklistItems.map(item => {
                    return {
                        label: item[EnvironmentalAndPermittingChecklistItem.columnSchema.name], id: item[EnvironmentalAndPermittingChecklistItem.columnSchema.oid], item: item
                    }
                })}
                onUpdate={(value) => setSelectedItem(value.item)}
                style={{width: `${width}px`}}
            />
        </div>
    )
}