import React, { useState } from 'react'

import { Toggle, Button } from 'rsuite'

export const UserPrefContext = React.createContext(getPreferences())

export default function Preferences(props) {

    const [state, setState] = useState(getPreferences())

    var setAutoRefresh = (checked, e) => {
        let newState = Object.assign({}, getPreferences(), {autoRefresh: checked})
        //setPreferences(newState)
        setState(newState)
    }

    return (
        <div>
            <div className="flow-horizontal" style={{width: "200px", alignItems: "center"}}>
                <div style={{textAlign: "center", flexShrink: 0, marginRight: ""}}>Auto Refresh</div>
                <div className="fill-parent"></div>
                <Toggle defaultChecked={state.autoRefresh} onChange={setAutoRefresh} />
            </div>
            <Button onClick={() => {
                setPreferences(state)
                props.onSubmit()
                props.setPreferences(state)
            }}>
                Apply
            </Button>
        </div>
    )
}

function setPreferences(prefs) {
    localStorage.setItem('userPreferences', JSON.stringify(prefs));
}
export function getPreferences() {
    let prefs = JSON.parse(localStorage.getItem('userPreferences') || "{}") //
    return prefs
}