import './Events.css'
import React, {Component} from 'react'

import AssetSidebar from '../../menu/AssetMenu'
import EventsDisplay from './EventsDisplay'

import { getPortfolio, setPortfolio, getSite, setSite } from '../../../utils/UrlLocation'


class EventsTab extends Component {

    constructor(props) {
        super()
        this.state = {
            selectedPortfolioId: getPortfolio(),
            selectedSiteId: getSite(),
        }
    }

    render() {        
        return (
            <div className="flow-horizontal fill-parent">
                <AssetSidebar 
                    services={this.props.services} 
                    updateSelectedSite={this.updateURLSelectedSiteId.bind(this)} 
                    updateSelectedPortfolio={this.updateURLSelectedPortfolioId.bind(this)}
                    selectedSiteId={this.state.selectedSiteId}
                    selectedPortfolioId={this.state.selectedPortfolioId}
                    className="sidebar"
                    menuProps={{
                        collapsable: true,
                    }}
                />
                <EventsDisplay services={this.props.services} selectedSiteId={this.state.selectedSiteId} />
            </div>
        )
    }

    /////////////////
    // Helper Methods
    /////////////////

    updateURLSelectedSiteId(id) {
        setSite(id)
        setPortfolio(null)
        this.updateSelectedSiteId()
    }

    updateURLSelectedPortfolioId(id) {
        setPortfolio(id)
        setSite(null)
        this.updateSelectedPortfolioId()
    }

    updateSelectedPortfolioId() {
        this.setState({
            selectedPortfolioId: getPortfolio(),
            selectedSiteId: null,
        })
    }

    updateSelectedSiteId() {
        var selectedSiteId = getSite()
        this.setState({
            selectedPortfolioId: null,
            selectedSiteId: selectedSiteId,
        })
    }


}

export default EventsTab