import { schemas } from '../Constants'
import Table from './BaseTable'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import { makeEntityId, makeEntityName, makeEntityDescription, makeNotes, booleanOptions } from './CommonColumns'
import { InverterSpec } from './EquipmentSpec'
import { Plant } from './Project'
import { injectCounterpartyName, ServiceProvider } from './Counterparty'
import { Contact, Counterparty } from './Entity'


class WarehouseTable extends Table {

    constructor() {

        var columnSchema = {
            warehouseId: "warehouse_id",
            warehouseName: "warehouse_name",
            description: "description"
        }

        var schemaName = "test"
        var tableName = "warehouse"

        var displayNameSingular = "Warehouse"
        var displayNamePlural = "Warehouses"

        var pkUidColumn = columnSchema.warehouseId
        var identifiers = [columnSchema.warehouseId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }

    buildTableProps = () => {
        return {
            itemSchema: this.buildNewRecord(),
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.warehouseId),
                    makeEntityName(this.columnSchema.warehouseName, this.displayNameSingular),
                    makeEntityDescription(this.columnSchema.description, this.displayNameSingular)
                ]
            },
        }
    }

}
export const Warehouse = new WarehouseTable()

class InventoryInverterTable extends Table {

    constructor() {

        var columnSchema = {
            inverterId: "inverter_id",
            projectId: "project_id",
            purchaseOrderNumber: "purchase_order_number",
            inverterSpecId: "inverter_spec_id",
            serialNumber: "serial_number",
            ITCPercent: "itc__percent",
            warehouseId: "warehouse_id",
            purchasePriceDollar: "purchase_price__dollar"
        }

        var schemaName = "test"
        var tableName = "inverter_warehousing"

        var displayNameSingular = "Inverter Warehousing"
        var displayNamePlural = "Inverter Warehousing"

        var pkUidColumn = columnSchema.inverterId
        var identifiers = [columnSchema.inverterId]
        var dependencies = [Plant, InverterSpec, Warehouse]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
    }

    getReferencedPlantId(record, data) {
        return record[this.columnSchema.projectId]
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var inverterSpecRecords = this.findTableObjectRecords(data, InverterSpec)
        var warehouseRecords = this.findTableObjectRecords(data, Warehouse)

        return {
            itemSchema: this.buildNewRecord(),
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.inverterId),
                    {
                        name: "Plant",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                            dropdownItems: buildDropdownItems(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                            fixed: null,
                        },
                    }, {
                        name: "Inverter Spec",
                        key: this.columnSchema.inverterSpecId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(inverterSpecRecords, value, InverterSpec.columnSchema.inverterSpecId, [InverterSpec.columnSchema.manufacturer, InverterSpec.columnSchema.model]),
                            dropdownItems: buildDropdownItems(inverterSpecRecords, InverterSpec.columnSchema.inverterSpecId, [InverterSpec.columnSchema.manufacturer, InverterSpec.columnSchema.model]),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                            fixed: null,
                        },
                    }, {
                        name: "Warehouse",
                        key: this.columnSchema.warehouseId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(warehouseRecords, value, Warehouse.columnSchema.warehouseId, Warehouse.columnSchema.warehouseName),
                            dropdownItems: buildDropdownItems(warehouseRecords, Warehouse.columnSchema.warehouseId, Warehouse.columnSchema.warehouseName),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                            fixed: null,
                        },
                    }, {
                        name: "Purchase Order Number",
                        key: this.columnSchema.purchaseOrderNumber,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Purchase Price ($)",
                        key: this.columnSchema.purchasePriceDollar,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "Serial Number",
                        key: this.columnSchema.serialNumber,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }, {
                        name: "ITC (%)",
                        key: this.columnSchema.ITCPercent,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                        }
                    }
                ]
            },
        }
    }

}
export const InventoryInverter = new InventoryInverterTable()

export const buildGetReferencedPlantId = (sourceTable) => {
    return (record, data) => {
        //var contractRecords = sourceTable.findTableObjectRecords(data, sourceTable)
        var selectedProjectId = record[sourceTable.columnSchema.projectId]
        return selectedProjectId
    }
}

class PreConMetStationTable extends Table {

    constructor() {

        var columnSchema = {
            metStationId: "met_station_id",
            projectId: "project_id",
            serviceProviderId: "service_provider_id",
            contactId: "contact_id",
            commissioningDate: "commissioning_date",
            decommissioningDate: "decommissioning_date",
            latitude: "latitude",
            longitude: "longitude",
            measurementsGHI: "measurements_ghi",
            measurementsPOA: "measurements_poa",
            measurementsPOATilt: "measurements_poa_tilt__deg",
            measurementsDIF: "measurements_dif",
            measurementsTempAmb: "measurements_temp_amb",
            measurementsRelHumidity: "measurements_rel_humidity",
            measurementsWindSpeed: "measurements_wind_speed",
            measurementsWindDirection: "measurements_wind_direction",
            measurementsSoiling: "measurements_soiling",
            measurementsSoilingTilt: "measurements_soiling_tilt__deg",
            measurementsAlbedo: "measurements_albedo",
            notes: "notes"
        }

        var schemaName = "equipment"
        var tableName = "precon_met_station"

        var displayNameSingular = "PreConstruction Met Station"
        var displayNamePlural = "PreConstruction Met Stations"

        var pkUidColumn = columnSchema.metStationId
        var identifiers = [columnSchema.metStationId]
        var dependencies = [Plant, ServiceProvider, Contact]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = true
    }

    getReferencedPlantId = buildGetReferencedPlantId(this)

    buildTableProps = (data) => {

        const projectRecords = this.findTableObjectRecords(data, Plant)
        var serviceProviderRecords = this.findTableObjectRecords(data, ServiceProvider)
        const contactRecords = this.findTableObjectRecords(data, Contact)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        const projectIdCol = Plant.columnSchema.plantId
        const projectNameCol = Plant.columnSchema.plantName
        const serviceProviderIdCol = ServiceProvider.columnSchema.serviceProviderId
        const serviceProviderNameCol = "injectedServiceProviderName"
        const contactIdCol = Contact.columnSchema.contactId
        const contactNameCol = Contact.columnSchema.name

        serviceProviderRecords = injectCounterpartyName(serviceProviderRecords, counterpartyRecords, serviceProviderIdCol, serviceProviderNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.metStationId),
                    {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(projectRecords, value, projectIdCol, projectNameCol),
                            dropdownItems: buildDropdownItems(projectRecords, projectIdCol, projectNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                            fixed: null,
                        },
                    }, {
                        name: "Service Provider",
                        key: this.columnSchema.serviceProviderId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(serviceProviderRecords, value, serviceProviderIdCol, serviceProviderNameCol),
                            dropdownItems: buildDropdownItems(serviceProviderRecords, serviceProviderIdCol, serviceProviderNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                            fixed: null,
                        },
                    }, {
                        name: "Contact",
                        key: this.columnSchema.contactId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contactRecords, value, contactIdCol, contactNameCol),
                            dropdownItems: (record) => {
                                let filteredRecords = contactRecords
                                .filter(x => !x[Contact.columnSchema.counterpartyId] || x[Contact.columnSchema.counterpartyId]===record[this.columnSchema.serviceProviderId])
                                return buildDropdownItems(filteredRecords, contactIdCol, contactNameCol)
                            },
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                            fixed: null,
                        },
                    }, {
                        name: "Commissioning Date",
                        key: this.columnSchema.commissioningDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Decommissioning Date",
                        key: this.columnSchema.decommissioningDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Latitude",
                        key: this.columnSchema.latitude,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Longitude",
                        key: this.columnSchema.longitude,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement GHI",
                        key: this.columnSchema.measurementsGHI,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement POA",
                        key: this.columnSchema.measurementsPOA,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement POA Tilt (deg)",
                        key: this.columnSchema.measurementsPOATilt,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement DIF",
                        key: this.columnSchema.measurementsDIF,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement Temp Amb",
                        key: this.columnSchema.measurementsTempAmb,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement Humidity",
                        key: this.columnSchema.measurementsRelHumidity,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement Wind Speed",
                        key: this.columnSchema.measurementsWindSpeed,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement Wind Direction",
                        key: this.columnSchema.measurementsWindDirection,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement Soiling",
                        key: this.columnSchema.measurementsSoiling,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement Soiling Tilt (deg)",
                        key: this.columnSchema.measurementsSoilingTilt,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    }, {
                        name: "Measurement Albedo",
                        key: this.columnSchema.measurementsAlbedo,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true
                        }
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const PreConMetStation = new PreConMetStationTable()