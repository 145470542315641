import { schemas } from '../Constants'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import { HelpInverterTable } from '../help_panels/ProjectEquipment'
import { Plant } from './Project'
import { EPCContract } from './Contract'
import { EPC } from './Counterparty'
import { InverterSpec, ModuleSpec, TransformerSpec, BESSSpec, TrackerSpec } from './EquipmentSpec'
import { makeNotes, makePlantId, booleanOptions } from './CommonColumns'
import Table from './BaseTable'
import { BESSIntegrator, injectCounterpartyName } from './Counterparty'
import { Counterparty } from './Entity'


const validateProjectEquipment = (record, plantIdCol, equipmentSpecIdCol, equipmentType) => {
    if (!record[plantIdCol]) return {pass: false, message: "Please select a plant."}
    if (!record[equipmentSpecIdCol]) return {pass: false, message: `Please select a ${equipmentType} model.`}

    // TODO: ensure plant and inverter model are unique
    return {pass: true, message: null}
}

const buildFilterEPCContractDropdownItems = (ProjectEquipmentTable, contractRecords) => {
    return (projectEquipmentRecord) => {
        let contractPlantId = projectEquipmentRecord[ProjectEquipmentTable.columnSchema.plantId]
        let filteredContracts = contractRecords.filter(x => x[EPCContract.columnSchema.plantId]===contractPlantId)
        return filteredContracts.map(x => Object({label: x[EPCContract.columnSchema.contractName], value: x[EPCContract.columnSchema.contractId]}))
    }
}

export const equipmentStatusOptions = [{
    label: "Not Ordered", value: "Not Ordered"
}, {
    label: "Unallocated", value: "Unallocated"
}, {
    label: "PO Issued", value: "PO Issued"
}, {
    label: "Delivered", value: "Delivered"
}, {
    label: "Inventory", value: "Inventory"
}, {
    label: "Inventory - Safe Harbor", value: "Inventory - Safe Harbor"
}, {
    label: "MSA Executed", value: "MSA Executed"
}]


class ProjectInverterTable extends Table {

    constructor() {

        var columnSchema = {
            inverterGroupId: "inverter_group_id",
            plantId: "plant_id",
            inverterSpecId: "inverter_spec_id",
            contractId: "contract_id",
            inverterCount: "inverter_count",
            status: "status",
            isOwnerProvided: "is_owner_provided",
            itcRate: "itc_rate__percent",
            deliveryStartDateCurrent: "delivery_start_date__current",
            deliveryEndDateCurrent: "delivery_end_date__current",
            purchaseOrderDateCurrent: "purchase_order_date__current",
            deliveryStartDateBudgeted: "delivery_start_date__budgeted",
            deliveryEndDateBudgeted: "delivery_end_date__budgeted",
            purchaseOrderDateBudgeted: "purchase_order_date__budgeted",
            warrantyStartDate: "warranty_start_date",
            warrantyEndDate: "warranty_end_date",
            notes: "notes"
        }

        var schemaName = schemas.projectEquipment
        var tableName = "inverter"

        var displayNameSingular = "Inverter"
        var displayNamePlural = "Inverters"

        var pkUidColumn = columnSchema.inverterGroupId
        var identifiers = [columnSchema.inverterGroupId]
        var dependencies = [Plant, InverterSpec, EPCContract]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
    }

    buildHelpPanel = HelpInverterTable

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var inverterSpecRecords = this.findTableObjectRecords(data, InverterSpec)
        var contractRecords = this.findTableObjectRecords(data, EPCContract)

        var inverterSpecIdCol = InverterSpec.columnSchema.inverterSpecId
        var inverterSpecManufacturerCol = InverterSpec.columnSchema.manufacturer
        var inverterSpecModelCol = InverterSpec.columnSchema.model
        var contractIdCol = EPCContract.columnSchema.contractId
        var contractNameCol = EPCContract.columnSchema.contractName

        const validateProjectInverter = (record) => validateProjectEquipment(record, this.columnSchema.plantId, this.columnSchema.inverterSpecId, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateProjectInverter,
            validateUpdateItem: validateProjectInverter,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName), 
                    {
                        name: "Inverter Model",
                        key: this.columnSchema.inverterSpecId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(inverterSpecRecords, value, inverterSpecIdCol, [inverterSpecManufacturerCol, inverterSpecModelCol]),
                            dropdownItems: buildDropdownItems(inverterSpecRecords, inverterSpecIdCol, [inverterSpecManufacturerCol, inverterSpecModelCol])
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: equipmentStatusOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "EPC Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildFilterEPCContractDropdownItems(this, contractRecords)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Inverter Count",
                        key: this.columnSchema.inverterCount,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Owner Provided",
                        key: this.columnSchema.isOwnerProvided,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "ITC Rate (%)",
                        key: this.columnSchema.itcRate,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Delivery Start (Current)",
                        key: this.columnSchema.deliveryStartDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery Start (Budgeted)",
                        key: this.columnSchema.deliveryStartDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery End (Current)",
                        key: this.columnSchema.deliveryEndDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery End (Budgeted)",
                        key: this.columnSchema.deliveryEndDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    },  {
                        name: "Purchase Order (Current)",
                        key: this.columnSchema.purchaseOrderDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Purchase Order (Budgeted)",
                        key: this.columnSchema.purchaseOrderDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty Start",
                        key: this.columnSchema.warrantyStartDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty End",
                        key: this.columnSchema.warrantyEndDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const ProjectInverter = new ProjectInverterTable()

class ProjectModuleTable extends Table {

    constructor() {

        var columnSchema = {
            moduleGroupId: "module_group_id",
            plantId: "plant_id",
            moduleSpecId: "module_spec_id",
            contractId: "contract_id",
            moduleCount: "module_count",
            status: "status",
            isOwnerProvided: "is_owner_provided",
            stringSize: "string_size",
            stringCount: "string_count",
            gcr: "gcr",
            deliveryStartDateCurrent: "delivery_start_date__current",
            deliveryEndDateCurrent: "delivery_end_date__current",
            purchaseOrderDateCurrent: "purchase_order_date__current",
            deliveryStartDateBudgeted: "delivery_start_date__budgeted",
            deliveryEndDateBudgeted: "delivery_end_date__budgeted",
            purchaseOrderDateBudgeted: "purchase_order_date__budgeted",
            warrantyStartDate: "warranty_start_date",
            warrantyEndDate: "warranty_end_date",
            notes: "notes"
        }

        var schemaName = schemas.projectEquipment
        var tableName = "module"

        var displayNameSingular = "Module"
        var displayNamePlural = "Modules"

        var pkUidColumn = columnSchema.moduleGroupId
        var identifiers = [columnSchema.moduleGroupId]
        var dependencies = [Plant, ModuleSpec, EPCContract]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var moduleSpecRecords = this.findTableObjectRecords(data, ModuleSpec)
        var contractRecords = this.findTableObjectRecords(data, EPCContract)

        var moduleSpecIdCol = ModuleSpec.columnSchema.moduleSpecId
        var moduleSpecManufacturerCol = ModuleSpec.columnSchema.manufacturer
        var moduleSpecModelCol = ModuleSpec.columnSchema.model
        var contractIdCol = EPCContract.columnSchema.contractId
        var contractNameCol = EPCContract.columnSchema.contractName

        const validateProjectModule = (record) => validateProjectEquipment(record, this.columnSchema.plantId, this.columnSchema.moduleSpecId, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateProjectModule,
            validateUpdateItem: validateProjectModule,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                    {
                        name: "Module Model",
                        key: this.columnSchema.moduleSpecId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(moduleSpecRecords, value, moduleSpecIdCol, [moduleSpecManufacturerCol, moduleSpecModelCol]),
                            dropdownItems: buildDropdownItems(moduleSpecRecords, moduleSpecIdCol, [moduleSpecManufacturerCol, moduleSpecModelCol])
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: equipmentStatusOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "EPC Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildFilterEPCContractDropdownItems(this, contractRecords)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Module Count",
                        key: this.columnSchema.moduleCount,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Owner Provided",
                        key: this.columnSchema.isOwnerProvided,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "String Size",
                        key: this.columnSchema.stringSize,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "String Count",
                        key: this.columnSchema.stringCount,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "GCR",
                        key: this.columnSchema.gcr,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Delivery Start (Current)",
                        key: this.columnSchema.deliveryStartDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery Start (Budgeted)",
                        key: this.columnSchema.deliveryStartDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery End (Current)",
                        key: this.columnSchema.deliveryEndDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery End (Budgeted)",
                        key: this.columnSchema.deliveryEndDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    },  {
                        name: "Purchase Order (Current)",
                        key: this.columnSchema.purchaseOrderDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Purchase Order (Budgeted)",
                        key: this.columnSchema.purchaseOrderDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty Start",
                        key: this.columnSchema.warrantyStartDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty End",
                        key: this.columnSchema.warrantyEndDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const ProjectModule = new ProjectModuleTable()

class ProjectTransformerTable extends Table {

    constructor() {

        var columnSchema = {
            transformerGroupId: "transformer_group_id",
            plantId: "plant_id",
            transformerSpecId: "transformer_spec_id",
            contractId: "contract_id",
            transformerCount: "transformer_count",
            status: "status",
            isOwnerProvided: "is_owner_provided",
            deliveryEndDateCurrent: "delivery_end_date__current",
            purchaseOrderDateCurrent: "purchase_order_date__current",
            deliveryEndDateBudgeted: "delivery_end_date__budgeted",
            purchaseOrderDateBudgeted: "purchase_order_date__budgeted",
            warrantyStartDate: "warranty_start_date",
            warrantyEndDate: "warranty_end_date",
            notes: "notes"
        }

        var schemaName = schemas.projectEquipment
        var tableName = "transformer"

        var displayNameSingular = "Transformer"
        var displayNamePlural = "Transformers"

        var pkUidColumn = columnSchema.transformerGroupId
        var identifiers = [columnSchema.transformerGroupId]
        var dependencies = [Plant, TransformerSpec, EPCContract]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var transformerSpecRecords = this.findTableObjectRecords(data, TransformerSpec)
        var contractRecords = this.findTableObjectRecords(data, EPCContract)

        var transformerSpecIdCol = TransformerSpec.columnSchema.transformerSpecId
        var contractIdCol = EPCContract.columnSchema.contractId
        var contractNameCol = EPCContract.columnSchema.contractName

        const transformerSpecLabelCols = [TransformerSpec.columnSchema.manufacturer, TransformerSpec.columnSchema.ratingRealMV, TransformerSpec.columnSchema.windingsRealkV]
        const transformerSpecFormatter = `$0 $1-$2`

        const validateProjectTransformer= (record) => validateProjectEquipment(record, this.columnSchema.plantId, this.columnSchema.transformerSpecId, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateProjectTransformer,
            validateUpdateItem: validateProjectTransformer,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                    {
                        name: "Transformer Model",
                        key: this.columnSchema.transformerSpecId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(transformerSpecRecords, value, transformerSpecIdCol, transformerSpecLabelCols, {formatter: transformerSpecFormatter}),
                            dropdownItems: buildDropdownItems(transformerSpecRecords, transformerSpecIdCol, transformerSpecLabelCols, {formatter: transformerSpecFormatter})
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: equipmentStatusOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "EPC Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildFilterEPCContractDropdownItems(this, contractRecords)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Transformer Count",
                        key: this.columnSchema.transformerCount,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Owner Provided",
                        key: this.columnSchema.isOwnerProvided,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Delivery End (Current)",
                        key: this.columnSchema.deliveryEndDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery End (Budgeted)",
                        key: this.columnSchema.deliveryEndDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    },  {
                        name: "Purchase Order (Current)",
                        key: this.columnSchema.purchaseOrderDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Purchase Order (Budgeted)",
                        key: this.columnSchema.purchaseOrderDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty Start",
                        key: this.columnSchema.warrantyStartDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty End",
                        key: this.columnSchema.warrantyEndDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const ProjectTransformer = new ProjectTransformerTable()

class ProjectBESSTable extends Table {

    constructor() {

        var columnSchema = {
            bessGroupId: "bess_group_id",
            plantId: "plant_id",
            bessSpecId: "bess_spec_id",
            contractId: "contract_id",
            integratorId: "integrator_id",
            enclosureType: "enclosure_type",
            containerCount: "container_count",
            status: "status",
            isOwnerProvided: "is_owner_provided",
            deliveryStartDateCurrent: "delivery_start_date__current",
            deliveryEndDateCurrent: "delivery_end_date__current",
            purchaseOrderDateCurrent: "purchase_order_date__current",
            capacityReservationOrderDateCurrent: "capacity_reservation_order_date__current",
            deliveryStartDateBudgeted: "delivery_start_date__budgeted",
            deliveryEndDateBudgeted: "delivery_end_date__budgeted",
            purchaseOrderDateBudgeted: "purchase_order_date__budgeted",
            capacityReservationOrderDateBudgeted: "capacity_reservation_order_date__budgeted",
            warrantyStartDate: "warranty_start_date",
            warrantyEndDate: "warranty_end_date",
            notes: "notes"
        }

        var schemaName = schemas.projectEquipment
        var tableName = "bess"

        var displayNameSingular = "BESS"
        var displayNamePlural = "BESS"

        var pkUidColumn = columnSchema.bessGroupId
        var identifiers = [columnSchema.bessGroupId]
        var dependencies = [Plant, BESSIntegrator, BESSSpec, EPCContract]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            enclosureType: [{
                label: "Containerized", value: "Containerized"
            }, {
                label: "Warehouse", value: "Warehouse"
            }]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var bessSpecRecords = this.findTableObjectRecords(data, BESSSpec)
        var contractRecords = this.findTableObjectRecords(data, EPCContract)
        var integratorRecords = this.findTableObjectRecords(data, BESSIntegrator)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var bessSpecIdCol = BESSSpec.columnSchema.bessSpecId
        var bessSpecManufacturerCol = BESSSpec.columnSchema.manufacturer
        var bessSpecModelCol = BESSSpec.columnSchema.model
        var contractIdCol = EPCContract.columnSchema.contractId
        var contractNameCol = EPCContract.columnSchema.contractName

        var integratorIdCol = BESSIntegrator.columnSchema.bessIntegratorId
        var integratorNameCol = "integratorName"

        integratorRecords = injectCounterpartyName(integratorRecords, counterpartyRecords, integratorIdCol, integratorNameCol)

        const validateProjectBESS= (record) => validateProjectEquipment(record, this.columnSchema.plantId, this.columnSchema.bessSpecId, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateProjectBESS,
            validateUpdateItem: validateProjectBESS,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                    {
                        name: "BESS Specification",
                        key: this.columnSchema.bessSpecId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(bessSpecRecords, value, bessSpecIdCol, [bessSpecManufacturerCol, bessSpecModelCol]),
                            dropdownItems: buildDropdownItems(bessSpecRecords, bessSpecIdCol, [bessSpecManufacturerCol, bessSpecModelCol])
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: equipmentStatusOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "EPC Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildFilterEPCContractDropdownItems(this, contractRecords)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Integrator",
                        key: this.columnSchema.integratorId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(integratorRecords, value, integratorIdCol, integratorNameCol),
                            dropdownItems: buildDropdownItems(integratorRecords, integratorIdCol, integratorNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Enclosure Type",
                        key: this.columnSchema.enclosureType,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.enclosureType
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Container Count",
                        key: this.columnSchema.containerCount,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Owner Provided",
                        key: this.columnSchema.isOwnerProvided,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Delivery Start (Current)",
                        key: this.columnSchema.deliveryStartDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery Start (Budgeted)",
                        key: this.columnSchema.deliveryStartDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery End (Current)",
                        key: this.columnSchema.deliveryEndDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery End (Budgeted)",
                        key: this.columnSchema.deliveryEndDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    },  {
                        name: "Purchase Order (Current)",
                        key: this.columnSchema.purchaseOrderDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Purchase Order (Budgeted)",
                        key: this.columnSchema.purchaseOrderDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Capacity Reservation Order (Current)",
                        key: this.columnSchema.capacityReservationOrderDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                        }
                    }, {
                        name: "Capacity Reservation Order (Budgeted)",
                        key: this.columnSchema.capacityReservationOrderDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                        }
                    }, {
                        name: "Warranty Start",
                        key: this.columnSchema.warrantyStartDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty End",
                        key: this.columnSchema.warrantyEndDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const ProjectBESS = new ProjectBESSTable()

class ProjectTrackerTable extends Table {

    constructor() {

        var columnSchema = {
            trackerGroupId: "tracker_group_id",
            plantId: "plant_id",
            trackerSpecId: "tracker_spec_id",
            contractId: "contract_id",
            trackingType: "tracking_type",
            status: "status",
            isOwnerProvided: "is_owner_provided",
            trackerSize: "tracker_size",
            deliveryStartDateCurrent: "delivery_start_date__current",
            deliveryEndDateCurrent: "delivery_end_date__current",
            purchaseOrderDateCurrent: "purchase_order_date__current",
            deliveryStartDateBudgeted: "delivery_start_date__budgeted",
            deliveryEndDateBudgeted: "delivery_end_date__budgeted",
            purchaseOrderDateBudgeted: "purchase_order_date__budgeted",
            warrantyStartDate: "warranty_start_date",
            warrantyEndDate: "warranty_end_date",
            notes: "notes"
        }

        var schemaName = schemas.projectEquipment
        var tableName = "tracker"

        var displayNameSingular = "Tracker"
        var displayNamePlural = "Trackers"

        var pkUidColumn = columnSchema.trackerGroupId
        var identifiers = [columnSchema.trackerGroupId]
        var dependencies = [Plant, TrackerSpec, EPCContract]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            trackingType: [{
                label: "Fixed", value: "Fixed"
            }, {
                label: "HSAT", value: "Horizontal Single Axis Tracking"
            }]
        }
    }

    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var trackerSpecRecords = this.findTableObjectRecords(data, TrackerSpec)
        var contractRecords = this.findTableObjectRecords(data, EPCContract)

        var trackerSpecIdCol = TrackerSpec.columnSchema.trackerSpecId
        var trackerSpecManufacturerCol = TrackerSpec.columnSchema.manufacturer
        var trackerSpecModelCol = TrackerSpec.columnSchema.model
        var contractIdCol = EPCContract.columnSchema.contractId
        var contractNameCol = EPCContract.columnSchema.contractName

        const validateProjectTracker= (record) => validateProjectEquipment(record, this.columnSchema.plantId, this.columnSchema.trackerSpecId, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            validateNewItem: validateProjectTracker,
            validateUpdateItem: validateProjectTracker,
            validateDeleteItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makePlantId(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                    {
                        name: "Tracker Specification",
                        key: this.columnSchema.trackerSpecId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(trackerSpecRecords, value, trackerSpecIdCol, [trackerSpecManufacturerCol, trackerSpecModelCol]),
                            dropdownItems: buildDropdownItems(trackerSpecRecords, trackerSpecIdCol, [trackerSpecManufacturerCol, trackerSpecModelCol])
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: equipmentStatusOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "EPC Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildFilterEPCContractDropdownItems(this, contractRecords)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Tracking Type",
                        key: this.columnSchema.trackingType,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: this.options.trackingType
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Owner Provided",
                        key: this.columnSchema.isOwnerProvided,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Tracker Size",
                        key: this.columnSchema.trackerSize,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Delivery Start (Current)",
                        key: this.columnSchema.deliveryStartDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery Start (Budgeted)",
                        key: this.columnSchema.deliveryStartDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery End (Current)",
                        key: this.columnSchema.deliveryEndDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery End (Budgeted)",
                        key: this.columnSchema.deliveryEndDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    },  {
                        name: "Purchase Order (Current)",
                        key: this.columnSchema.purchaseOrderDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Purchase Order (Budgeted)",
                        key: this.columnSchema.purchaseOrderDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty Start",
                        key: this.columnSchema.warrantyStartDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty End",
                        key: this.columnSchema.warrantyEndDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const ProjectTracker = new ProjectTrackerTable()

class ProjectBreakerTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            projectId: "project_id",
            status: "status",
            breakerCount: "breaker_count",
            isOwnerProvided: "is_owner_provided",
            purchaseOrderDateCurrent: "purchase_order_date__current",
            purchaseOrderDateBudgeted: "purchase_order_date__budgeted",
            deliveryEndDateCurrent: "delivery_end_date__current",
            deliveryEndDateBudgeted: "delivery_end_date__budgeted",
            warrantyStartDate: "warranty_start_date",
            warrantyEndDate: "warranty_end_date",
            notes: "notes"
        }

        var schemaName = schemas.projectEquipment
        var tableName = "breaker"

        var displayNameSingular = "Breaker"
        var displayNamePlural = "Breakers"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )
        this.initOptions()
    }

    initOptions = () => {
        this.options = {
            trackingType: [{
                label: "Fixed", value: "Fixed"
            }, {
                label: "HSAT", value: "Horizontal Single Axis Tracking"
            }]
        }
    }

    buildTableProps = (data) => {

        const projectRecords = this.findTableObjectRecords(data, Plant)
        const projectIdCol = Plant.columnSchema.plantId
        const projectNameCol = Plant.columnSchema.plantName

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(projectRecords, value, projectIdCol, projectNameCol),
                            dropdownItems: buildDropdownItems(projectRecords, projectIdCol, projectNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Status",
                        key: this.columnSchema.status,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: equipmentStatusOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Breaker Count",
                        key: this.columnSchema.breakerCount,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Owner Provided",
                        key: this.columnSchema.isOwnerProvided,
                        cellType: "dropdown",
                        cellOptions: {
                            dropdownItems: booleanOptions
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Delivery End (Current)",
                        key: this.columnSchema.deliveryEndDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Delivery End (Budgeted)",
                        key: this.columnSchema.deliveryEndDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    },  {
                        name: "Purchase Order (Current)",
                        key: this.columnSchema.purchaseOrderDateCurrent,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                        }
                    }, {
                        name: "Purchase Order (Budgeted)",
                        key: this.columnSchema.purchaseOrderDateBudgeted,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty Start",
                        key: this.columnSchema.warrantyStartDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    }, {
                        name: "Warranty End",
                        key: this.columnSchema.warrantyEndDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 220,
                        }
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const ProjectBreaker = new ProjectBreakerTable()