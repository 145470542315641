import './MenuContainer.css'
import React, {Component} from 'react'

import { reflowHighcharts } from '../../utils/Highcharts'
import {filter as fuzzyFilter} from 'fuzzaldrin'
import { Input } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch, faCheckDouble, faEraser } from '@fortawesome/free-solid-svg-icons'

import MenuItem from './MenuItem'
import { PalantirPeripheralsDropdownMenu } from '../dropdown_menu/DropdownMenu'
import { Text } from '@mantine/core'

export const ITEM_SELECTION_TYPES = {
    standard: "standard",
    checkbox: "checkbox",
    radio: "radio"
}


export const MenuContainerFunction = ({menuSkeleton, headerName, peripheralsOptions, collapsable, searchable, itemSelectionType, className, style, bodyStyles, includeQuickSelect}) => {

}

class MenuContainer extends Component {

    constructor(props) {
        super()
        this.state = {
            collapsed: false,
            searchValue: "",
        }
        this.className = "pltr-menu flow-vertical"
        this.defaultStyle = {}
        this.peripheralOptionsRef = React.createRef()
    }

    render() {

        var className = this.props.className ? this.className.concat(" ", this.props.className) : this.className
        var styles = Object.assign({}, this.defaultStyle, this.props.style || {})

        if (this.state.collapsed) {
            className+=" collapsed"
            return (
                <div className={className} style={styles}>
                    <div className="collapse-toggle" onClick={this.toggleCollapse}>
                        <FontAwesomeIcon 
                            icon={faBars}
                            size="lg"
                            fixedWidth
                            rotation={90}
                        />
                    </div>
                </div>
            )
        }
        var hasPeripherals = this.props.searchable || this.props.peripheralsConfig?.enabled
        var menuItems = this.props.menuSkeleton.map(x => this.constructMenuBody(x))

        if (hasPeripherals) className+=" add-peripherals"

        return (
            <div className={className} style={styles}>
                <div className='flow-horizontal menu-header'>
                    {this.props.collapsable && <span className="collapse-toggle" onClick={this.toggleCollapse}><FontAwesomeIcon icon={faBars} size="lg" fixedWidth /></span>}
                    <div className="label fill-parent">{this.props.headerName}</div>
                </div>
                {hasPeripherals && <div className="flow-horizontal menu-peripherals">
                    {this.props.searchable && <FontAwesomeIcon className="pltr-search-icon" icon={faSearch} size="1x" fixedWidth />}
                    {this.props.searchable && <Input classPrefix="pltr-input pltr-search" onChange={this.setSearch} placeholder="Search..." />}
                    {this.props.peripheralsConfig?.enabled && this.buildPeripheralsDropdown()}
                </div>}
                <div className="menu-body fill-parent overflow-y" style={this.props.bodyStyles || {}}>
                    {this.props.includeQuickSelect &&
                        <QuickSelection items={this.props.quickSelectItems || []} />
                    }
                    {menuItems}
                </div>
            </div>
        )
    }

    /**
     * 
     * @param {*} item 
     * @param {Boolean} forceRender used to force a parent node to render children when a node in its subtree should be rendered
     * @returns 
     */
    constructMenuBody(item, forceRender=false) {

        const itemKey = item.key
        const itemLabel = item.label
        const itemMetadata = item.meta
        const itemChildren = item.children
        const itemCanBeSelected = item.selectable
        const itemIsSelected = item.active
        const itemExpanded = item.expandTree

        // Item is a leaf node
        if (!itemChildren) {
            // Don't render if not forced to and this item does not match search value
            if (!forceRender && fuzzyFilter([itemLabel], this.state.searchValue).length===0) return null
            return <MenuItem 
                        onClick={() => this.props.leafNodeClick(item)}
                        key={itemKey}
                        label={itemLabel}
                        meta={itemMetadata}
                        active={itemIsSelected}
                        selectable={itemCanBeSelected}
                        itemSelectionType={this.props.itemSelectionType}
                    />
        }

        // Check if this item matches search condition
        // We want to use isMatch to force render the whole subtree
        var isMatch = true
        if (this.state.searchValue && !forceRender) {
            isMatch = fuzzyFilter([itemLabel], this.state.searchValue).length > 0
        }

        // Recursively build component tree for all of the components children and render in a MenuList component
        const itemChildrenComponents = itemChildren.map(x => this.constructMenuBody(x, isMatch))

        // determine whether to render based on multiple conditions: whether force is specified, if any children match search criteria, if the group item itself matches search
        if (this.state.searchValue) {
            const anyChildrenVisible = itemChildrenComponents.reduce((prev, cur) => prev || cur, false)
            if (!forceRender && !anyChildrenVisible && !isMatch) return null
        }

        return <MenuItem 
                    onClick={() => this.props.parentNodeClick(item)}
                    key={itemKey} 
                    label={itemLabel} 
                    meta={itemMetadata} 
                    active={itemIsSelected}
                    selectable={itemCanBeSelected}
                    itemSelectionType={this.props.itemSelectionType}
                    expandTree={this.props.expandTree || itemExpanded}
                >
                    {itemChildrenComponents}
                </MenuItem>
    }

    buildPeripheralsDropdown = () => {

        const options = []
        if (!this.props.peripheralsConfig?.disableCheckAllOption) options.push({
            key: "Check All",
            callback: this.props.onFillSelection,
            content: <div><FontAwesomeIcon icon={faCheckDouble} size="sm" fixedWidth />Check All</div>
        })
        if (!this.props.peripheralsConfig?.disableUncheckAllOption) options.push({
            name: "Uncheck All",
            callback: this.props.onClearSelection,
            content: <div><FontAwesomeIcon icon={faEraser} size="sm" fixedWidth />Uncheck All</div>
        })
        if (this.props.peripheralsConfig.options) options.push(...this.props.peripheralsConfig.options)

        return (
            <PalantirPeripheralsDropdownMenu
                options={options}
            />
        )
    }

    toggleCollapse = () => {
        requestAnimationFrame(() => {
            this.setState({
                collapsed: !this.state.collapsed
            })
        })

        reflowHighcharts()
    }

    setSearch = (value) => {
        this.setState({
            searchValue: value,
        })
    }

}

export default MenuContainer


const QuickSelection = ({items}) => {
    return (
        <div
            style={{borderBottom: "solid var(--border-light) 1px", padding: "5px 0", marginBottom: "5px"}}
        >
            <MenuItem 
                label="Quick Selection"
                
                active={false}
                selectable={false}
                expandTree={true}
            >
                {items.map(x => {
                    return (
                        <MenuItem
                            onClick={x.onClick}
                            key={x.label}
                            label={x.label}
                            active={x.active}
                            meta={x.meta}
                            selectable={true}
                            itemSelectionType={ITEM_SELECTION_TYPES.radio}
                        />            
                    )
                })}
            </MenuItem>
        </div>
    )
    return (
        <div style={{marginLeft: "17px"}}>
            <Text size="sm" weight={500}>Quick Selection</Text>
            <div style={{marginLeft: "25px"}}>All Megapacks</div>
        </div>
    )       
}