import { useEffect, useState } from "react"
import { Plant, TechnicalChecklist, TechnicalChecklistItems } from "../../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField } from "../../../components/input/Text"
import { PalantirBooleanSelector, PalantirSelector, } from "../../../components/input/SelectPicker"
import { useChangeLog } from "../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../hooks/databaseViewFormValidation"
import ViewPanel from "../ViewPanel"
import Alerter from "../../../components/alerter/Alerter"
import { AddFloaterButtonWithPrompt } from "../../../components/button/FloaterButtonWithPrompt"
import { Alert, SegmentedControl, Text, Tooltip } from "@mantine/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight, faExclamation, faHandSparkles, faLink, faMagicWandSparkles, faQuestionCircle, faSquareUpRight, faStar, faStarHalfStroke, faUpRightFromSquare, faWandSparkles } from "@fortawesome/free-solid-svg-icons"
import { PalantirButton } from "../../../components/button/Button"
import { ErrorRND, generateUUID, RNDErrorInstance, ViewFormError } from "../../../utils/databaseAppUtils"
import { groupBy, validateHTTPURL } from "../../../utils/MiscUtils"
import { StageMilestoneMatrix, StageMilestoneMatrixItem, StageMilestoneMatrixEntry, StageMilestoneMatrixCategorySummaryEntry } from "../../table_configuration/Development"
import _ from "lodash"


const buildStageMilestoneMatrixEntryRecord = (fields) => {
    const newMatrixEntry = StageMilestoneMatrixEntry.buildNewRecord()
    return Object.assign(newMatrixEntry, fields)
}
const buildStageMilestoneMatrixSummaryEntryRecord = (fields) => {
    const newMatrixEntry = StageMilestoneMatrixCategorySummaryEntry.buildNewRecord()
    return Object.assign(newMatrixEntry, fields)
}


export default function StageMilestoneMatrixPanelProxy(props) {
    
    const projectMatrix = props.data[StageMilestoneMatrix.buildId()].find(x => x[StageMilestoneMatrix.columnSchema.projectId]===props.selectedPlantId)
    const matrixEntries = projectMatrix ? props.data[StageMilestoneMatrixEntry.buildId()].filter(x => x[StageMilestoneMatrixEntry.columnSchema.matrixId]===projectMatrix[StageMilestoneMatrix.columnSchema.oid]) : []
    const matrixCategorySummaryEntries = projectMatrix ? props.data[StageMilestoneMatrixCategorySummaryEntry.buildId()].filter(x => x[StageMilestoneMatrixCategorySummaryEntry.columnSchema.matrixId]===projectMatrix[StageMilestoneMatrix.columnSchema.oid]) : []
    const matrixItems = props.data[StageMilestoneMatrixItem.buildId()]

    const matrixItemsByStage = Object.assign(Object.fromEntries(StageMilestoneMatrixItem.options.stage.map(x => [x.value, []])), groupBy(matrixItems, StageMilestoneMatrixItem.columnSchema.stage))
    

    return (
        <StageMilestoneMatrixPanel
            initialProjectMatrix={projectMatrix}
            initialMatrixEntries={matrixEntries}
            intialCategorySummaryMatrixEntries={matrixCategorySummaryEntries}
            matrixItems={matrixItems}
            matrixItemsByStage={matrixItemsByStage}
            {...props}
        />
    )
}

function StageMilestoneMatrixPanel({initialProjectMatrix, initialMatrixEntries, intialCategorySummaryMatrixEntries, matrixItems, matrixItemsByStage, updateFormChangeTracker, ...props}) {

    const [newEntries, setNewEntries] = useState([])
    const [selectedStage, setSelectedStage] = useState(StageMilestoneMatrixItem.options.stage[0].value)
    const [
        matrixRecords, prepareMatrixLogs, updateMatrixLog, addToMatrixLog,
        deleteFromMatrixLog, bulkOpOnMatrixLog, mergeAndResetMatrixLogs
    ] = useChangeLog(initialProjectMatrix ? [initialProjectMatrix] : [], StageMilestoneMatrix)
    const [
        matrixEntryRecords, prepareMatrixEntryLogs, updateMatrixEntryLog, addToMatrixEntryLog,
        deleteFromMatrixEntryLog, bulkOpOnMatrixEntryLog, mergeAndResetMatrixEntryLogs
    ] = useChangeLog(initialMatrixEntries, StageMilestoneMatrixEntry)
    const [
        matrixCSEntryRecords, prepareMatrixCSEntryLogs, updateMatrixCSEntryLog, addToMatrixCSEntryLog,
        deleteFromMatrixCSEntryLog, bulkOpOnMatrixCSEntryLog, mergeAndResetMatrixCSEntryLogs
    ] = useChangeLog(intialCategorySummaryMatrixEntries, StageMilestoneMatrixCategorySummaryEntry)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()
    
    const matrix = matrixRecords.length > 0 ? matrixRecords[0] : null
    const matrixId = matrix ? matrix[StageMilestoneMatrix.columnSchema.oid] : null

    const matrixItemsFilteredStage = matrixItemsByStage[selectedStage] || []


    useEffect(() => {
        // IF there is no matrix then we don't need to create missing matrix entries
        if (!matrix) return

        // Ensure each item has an existing entry in the matrix
        const itemIds = matrixItems.map(x => x[StageMilestoneMatrixItem.columnSchema.oid])
        const entryIds = new Set(matrixEntryRecords.map(x => x[StageMilestoneMatrixEntry.columnSchema.itemId]))
        const missingEntryItemIds = itemIds.filter(x => !entryIds.has(x))
        if (missingEntryItemIds.length > 0) {
            const newEntryRecords = missingEntryItemIds.map(itemId => {
                return buildStageMilestoneMatrixEntryRecord({
                    [StageMilestoneMatrixEntry.columnSchema.oid]: generateUUID(),
                    [StageMilestoneMatrixEntry.columnSchema.matrixId]: matrixId,
                    [StageMilestoneMatrixEntry.columnSchema.itemId]: itemId,
                    [StageMilestoneMatrixEntry.columnSchema.status]: "Not Started"
                })
            })
            addToMatrixEntryLog(newEntryRecords)
            setNewEntries(missingEntryItemIds)
        }

        // Ensure matrix has a summary item for each category
        const summaryEntryCategories = new Set(matrixCSEntryRecords.map(x => x[StageMilestoneMatrixCategorySummaryEntry.columnSchema.category]))
        const missingSummaryEntryItemCategories = StageMilestoneMatrixItem.options.category.map(x => x.value).filter(x => !summaryEntryCategories.has(x))
        if (missingSummaryEntryItemCategories.length > 0) {
            const newSummaryEntryRecords = missingSummaryEntryItemCategories.map(category => {
                return buildStageMilestoneMatrixSummaryEntryRecord({
                    [StageMilestoneMatrixCategorySummaryEntry.columnSchema.oid]: generateUUID(),
                    [StageMilestoneMatrixCategorySummaryEntry.columnSchema.matrixId]: matrixId,
                    [StageMilestoneMatrixCategorySummaryEntry.columnSchema.category]: category
                })
            })
            addToMatrixCSEntryLog(newSummaryEntryRecords)
        }
    }, [])

    const addMatrix = () => {
        const newMatrix = StageMilestoneMatrixEntry.buildNewRecord()
        newMatrix[StageMilestoneMatrix.columnSchema.oid] = generateUUID()
        newMatrix[StageMilestoneMatrix.columnSchema.projectId] = props.selectedPlantId
        newMatrix[StageMilestoneMatrix.columnSchema.name] = props.selectedPlant[Plant.columnSchema.plantName] + " Matrix"

        const newMatrixId = newMatrix[StageMilestoneMatrix.columnSchema.oid]

        const newEntryRecords = matrixItems.map(item => {
            return buildStageMilestoneMatrixEntryRecord({
                [StageMilestoneMatrixEntry.columnSchema.oid]: generateUUID(),
                [StageMilestoneMatrixEntry.columnSchema.matrixId]: newMatrixId,
                [StageMilestoneMatrixEntry.columnSchema.itemId]: item[StageMilestoneMatrixItem.columnSchema.oid],
                [StageMilestoneMatrixEntry.columnSchema.status]: "Not Started"
            })
        })
        const newSummaryEntryRecords = StageMilestoneMatrixItem.options.category.map(category => {
            return buildStageMilestoneMatrixSummaryEntryRecord({
                [StageMilestoneMatrixCategorySummaryEntry.columnSchema.oid]: generateUUID(),
                [StageMilestoneMatrixCategorySummaryEntry.columnSchema.matrixId]: newMatrixId,
                [StageMilestoneMatrixCategorySummaryEntry.columnSchema.category]: category.value
            })
        })
        addToMatrixLog(newMatrix)
        addToMatrixEntryLog(newEntryRecords)
        addToMatrixCSEntryLog(newSummaryEntryRecords)
        setNewEntries(matrixItems.map(x => x[StageMilestoneMatrixItem.columnSchema.oid]))
    }

    const categorizeMatrixEntries = (items) => {

        const itemsByCategory = {"Unknown": []}
        // Add all category options to the category dict
        StageMilestoneMatrixItem.options.category.forEach(x => itemsByCategory[x.value] = [])
    
        items.forEach(item => {
            const category = item[StageMilestoneMatrixItem.columnSchema.category]
            if (category in itemsByCategory) itemsByCategory[category].push(item)
            else itemsByCategory["Unknown"].push(item)
        })
        if (itemsByCategory["Unknown"].length===0) delete itemsByCategory["Unknown"]
        return itemsByCategory
    }

    const buildAllCategoryComponents = (categoryStructure) => {
        const components = Object.entries(categoryStructure).map(entry => {
            return buildCategoryComponent(entry[0], entry[1])
        })
        return components
    }

    let gridIdx = 2
    const buildCategoryComponent = (category, items) => {

        let categoryHasNewItem = false
        const categoryIdx = gridIdx
        gridIdx+=1

        const itemComponents = items.map(item => {
            
            const itemIdx = gridIdx
            gridIdx+=1
            const itemId = item[StageMilestoneMatrixItem.columnSchema.oid]
            const itemName = item[StageMilestoneMatrixItem.columnSchema.name]
            const itemDescription = item[StageMilestoneMatrixItem.columnSchema.description]

            // The corresponding entry in the matrix
            let entryRecord = matrixEntryRecords.find(x => x[StageMilestoneMatrixEntry.columnSchema.itemId]===itemId)
            
            // Entry has not yet been created so will skip for now. The entry will be created in useEffect hook on mount.
            if (!entryRecord) return null

            const isNewItem = newEntries.includes(itemId)
            categoryHasNewItem = categoryHasNewItem || isNewItem

            return (
                <MatrixEntryItem
                    key={itemId}
                    idx={itemIdx}
                    isNewItem={isNewItem}
                    itemName={itemName}
                    itemDescription={itemDescription}
                    entryRecord={entryRecord}
                    updateRecord={(update) => updateMatrixEntryLog(entryRecord, update)}
                />
            )
        })

        const numChildItems = items.length

        return (
            <MatrixCategoryItem
                category={category}
                idx={categoryIdx}
                numChildItems={numChildItems}
                hasNewItem={categoryHasNewItem}
            >
                {itemComponents.length > 0 ? itemComponents : null}
            </MatrixCategoryItem>
        )
    }

    const categorizedMatrixEntries = categorizeMatrixEntries(matrixItemsFilteredStage)
    const categoryComponents = buildAllCategoryComponents(categorizedMatrixEntries)


    return (
        <ViewPanel
            services={props.services}
            title="Stage Milestone Matrix"
            submitUrl='api/precious/table/stage_milestone_matrix'
            verifySubmit={(payload) => {
                verifyChangelogSubmission({
                    changeLog: payload.matrixChangeLog,
                    checks: []
                }, {
                    changeLog: payload.matrixEntryChangeLog,
                    checks: []
                }, {
                    changeLog: payload.matrixSummaryEntryChangeLog,
                    checks: []
                })
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                mergeAndResetMatrixLogs()
                mergeAndResetMatrixEntryLogs()
                mergeAndResetMatrixCSEntryLogs()
                setNewEntries([])
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                const matrixItemIdMap = Object.fromEntries(matrixItems.map(x => [x[StageMilestoneMatrixItem.columnSchema.oid], x]))
                const stageAndStatusProxies = matrixEntryRecords.map(x => {
                    const item = matrixItemIdMap[x[StageMilestoneMatrixEntry.columnSchema.itemId]] || {}
                    return {
                        itemStage: item[StageMilestoneMatrixItem.columnSchema.stage],
                        entryStatus: x[StageMilestoneMatrixEntry.columnSchema.status]
                    }
                })
                const stageCompletions = {}
                Object.entries(_.groupBy(stageAndStatusProxies, "itemStage")).forEach(z => {
                    const [stage, filteredStageAndStatusProxies] = z
                    const completedCount = filteredStageAndStatusProxies.filter(x => x.entryStatus==="Completed").length
                    const totalCount = filteredStageAndStatusProxies.length
                    const completionPercentage = completedCount / totalCount
                    // 80% Completion required to move on to next stage
                    stageCompletions[stage] = completionPercentage >= 0.8
                })

                let currentWorkingStage = null
                for (let stageOption of StageMilestoneMatrixItem.options.stage) {
                    const stage = stageOption.value
                    currentWorkingStage = stage
                    const stageCompleted = stageCompletions[stage]
                    // Stage completion is cumulative, so all previous stages need to be completed for a stage to be considered complete
                    if (!stageCompleted) break
                }
                
                return {
                    matrixChangeLog: prepareMatrixLogs([{method: "update", changes: [[matrix, {[StageMilestoneMatrix.columnSchema.stage]: currentWorkingStage}]]}]),
                    matrixEntryChangeLog: prepareMatrixEntryLogs(),
                    matrixSummaryEntryChangeLog: prepareMatrixCSEntryLogs()
                }
            }}
        >
            {!matrix ?
                <div className="flow-vertical">
                    <div>This project has no Stage Milestone Matrix.</div>
                    <AddFloaterButtonWithPrompt
                        onClick={addMatrix}
                        labelContent="Click to add."
                        style={{float: "right", marginTop: "4px"}}
                        height={14}
                        width={14}
                    />
                </div>
            :
            <div>
                <div className="form-instance" style={{minWidth: "900px"}}>
                    {newEntries.length > 0 && 
                        <div style={{marginBottom: "10px"}}>
                            <Alert>
                                <p>The matrix template contains items that this matrix does not, so new entries for these items have been created for you below and are marked with a
                                <FontAwesomeIcon icon={faStar} color="green" size="md" fixedWidth style={{marginLeft: "4px"}} />
                                .</p>
                                <p>Note that these new entries have not yet been saved.</p>
                            </Alert>
                        </div>
                    }
                    <Text size="lg" weight={500} style={{display: "inline"}}>Select Stage</Text>
                    <Text size="sm" weight={500} style={{display: "inline", marginLeft: "auto", float: "right"}}>Project is currently in stage '{matrix[StageMilestoneMatrix.columnSchema.stage]}'</Text>
                    <Text size="xs" color="grey">*Only milestones for the selected stage will be shown in the table below.</Text>
                    <SegmentedControl
                        value={selectedStage}
                        onChange={setSelectedStage}
                        data={StageMilestoneMatrixItem.options.stage.concat({label: "Summary", value: "Summary"})}
                    />
                    
                    {selectedStage==="Summary" ?
                        <SummaryItems
                            summaryRecords={matrixCSEntryRecords}
                            update={updateMatrixCSEntryLog}
                            style={{marginTop: "20px"}}
                        />
                        :
                        <div style={{display: "grid", gridTemplateColumns: "1fr 200px", gridTemplateRows: "50px auto", columnGap: "20px", marginTop: "15px"}}>
                            <div style={{gridRow: 1, gridColumn: 1}}><Text size="lg" style={{marginLeft: "0px", borderBottom: "solid grey 1px"}}>Milestone</Text></div>
                            <div style={{gridRow: 1, gridColumn: 2}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Status</Text></div>
                            {categoryComponents}
                        </div>
                    }
                </div>
            </div>}
        </ViewPanel>
    )
}

const SummaryItems = ({summaryRecords, update}) => {

    const orderedSummaryItems = []
    // If there is item with category that no longer exists, it will be left out here.
    StageMilestoneMatrixItem.options.category.forEach(category => {
        const match = summaryRecords.find(x => x[StageMilestoneMatrixCategorySummaryEntry.columnSchema.category]===category.value)
        if (match) orderedSummaryItems.push(match)
    })

    const items = orderedSummaryItems.map(record => {
        return (
            <MatrixCategoryItem
                category={record[StageMilestoneMatrixCategorySummaryEntry.columnSchema.category]}
            >
                <PalantirDispatchedTextField
                    value={record[StageMilestoneMatrixCategorySummaryEntry.columnSchema.comments]}
                    onChange={(x) => update(record, {[StageMilestoneMatrixCategorySummaryEntry.columnSchema.comments]: x})}
                    variant="filled"
                    multiline
                    minRows={3}
                    style={{marginRight: "20px", marginBottom: "20px", width: "100%"}}
                />
            </MatrixCategoryItem>
        )
    })

    return (
        <div
            style={{marginTop: "20px"}}
        >
            {items}
        </div>
    )
}

const MatrixCategoryItem = ({idx, category, hasNewItem, numChildItems, children}) => {

    const [showChildren, setShowChildren] = useState(false)
    const toggleShowChildren = () => {
        setShowChildren((x) => !x)
    }

    const hasChildren = Boolean(children)

    return (
        <>
            <div
                className="flow-horizontal"
                onClick={toggleShowChildren}
                style={{gridColumnStart: 1, gridColumnEnd: 5, gridRow: idx, marginLeft: "0px", backgroundColor: "#c6e2ff", padding: "4px 10px", borderRadius: "3px", marginBottom: "10px", cursor: "pointer"}}
            >
                {hasChildren && <FontAwesomeIcon size="lg" icon={showChildren ? faAngleDown : faAngleRight} style={{marginRight: "8px", width: "10px", position: "relative", top: "7px"}} />}
                <Text size="lg" weight={500}>{category}</Text>
                {numChildItems!==undefined && <Text style={{marginLeft: "4px", position: "relative", top: "1px"}}>({numChildItems})</Text>}
                {hasNewItem && <FontAwesomeIcon icon={faStar} color="green" size="md" fixedWidth style={{position: "relative", left: "6px", top: "8px"}} />}
            </div>
            {showChildren && children}
        </>
    )
}

const MatrixEntryItem = ({entryRecord, isNewItem, itemName, itemDescription, idx, updateRecord}) => {

    const status = entryRecord[StageMilestoneMatrixEntry.columnSchema.status]
    
    return (
        <>
            <div style={{gridRow: idx, gridColumn: 1, marginLeft: `0px`, display: "flex", alignItems: "top", marginBottom: "10px"}}>
                {isNewItem && <FontAwesomeIcon icon={faStar} color="green" size="md" fixedWidth style={{position: "relative", right: "12px", top: "7px"}} />}
                {itemDescription && 
                    <Tooltip position="bottom" placement="end" label={itemDescription} style={{position: "relative", right: "8px", top: "5px"}} width={200} wrapLines>
                        <FontAwesomeIcon icon={faQuestionCircle} size="md" fixedWidth />
                    </Tooltip>
                }
                <PalantirTextField
                    value={itemName}
                    disabled
                    style={{width: "100%"}}
                />
            </div>
            <PalantirSelector
                value={status}
                items={StageMilestoneMatrixEntry.options.status}
                onChange={(newStatus) => updateRecord({[StageMilestoneMatrixEntry.columnSchema.status]: newStatus})}
                style={{gridRow: idx, gridColumn: 2, minWidth: "120px", marginBottom: "10px"}}
            />
        </>
    )
}