import { useState } from 'react';

import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

export const PalantirPeripheralsDropdownMenu = ({options}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <FontAwesomeIcon 
                icon={faEllipsisV}
                size="lg"
                fixedWidth
                onClick={handleClick}
            />
            <MuiMenu
                id="peripheral-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {options.map(option =>
                    <MuiMenuItem 
                        key={option.key}
                        onClick={() => {
                            handleClose()
                            option.callback()
                        }}
                    >
                        {option.content}
                    </MuiMenuItem>
                )}
            </MuiMenu>
        </div>
    );
}