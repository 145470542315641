export const schemas = {
    counterparty: "counterparty",
    llc: "llc",
    llcPlant: "llc_plant",
    entity: "entity",
    project: "project",
    projectEquipment: "project_equipment",
    projectCounterparty: "project_counterparty",
    equipment: "equipment",
    equipmentSpec: "equipment_spec",
    contract: "contract",
    warranty: "warranty",
    development: "development",
    dbo: "dbo"
}