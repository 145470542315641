import {Component} from 'react'

import { formatDate } from '../../../../utils/Dates'
import { startOfDay, endOfDay } from 'date-fns'
import Highcharts from 'highcharts'

import DatePicker from '../../../date_picker/DateRangePicker'
import ResolutionDropdown from '../../../resolution_dropdown/ResolutionDropdown'
import Panel from '../../../panel/Panel'
import RevenueContainer from './RevenueContainer'
import PriceContainer from './PriceContainer'
import AwardContainer from './AwardContainer'
import KPIContainer from './KPIContainer'
import { isSiteBess } from '../../../../utils/assetFrameworkMacros'

var animationLimit = 500

class SummaryContent extends Component {

    constructor(props) {
        super()
        this.state = {
            selectedResolution: 3600,
            selectedStartDate: formatDate(startOfDay(new Date())),
            selectedEndDate: formatDate(endOfDay(new Date())),
        }

        let self = this
        this.chartOptions = {
            legend: {
                floating: false,
                verticalAlign: 'top',
                align: 'right',
            },
            plotOptions: {
                series: {
                    animationLimit: animationLimit,
                    point: {
                        events: {
                            mouseOver: function(e) {
                                let point = this
                                self.showHoverContentAllCharts(point)
                            }
                        }
                    },
                    events: {
                        mouseOut: function(e) {
                            let point = this
                            self.hideHoverContentAllCharts(point)
                        },
                    },
                    zoneAxis: 'x',
                    zones: [
                        {value: Date.now(), dashStyle: "solid"},
                        {dashStyle: "ShortDot"}
                    ]
                }
            },
            xAxis: {
                events: {
                    setExtremes: function(e) {
                        var chart = this.chart
                        self.setExtremesAllCharts(chart, e)
                    }
                }
            },
        }
    }

    render() {

        if (!this.props.selectedSiteId) {
            return <Panel>Please select a site</Panel>
        }

        // Tab is only available for sites with a battery
        const selectedSite = this.props.services.assetFramework.getSites(this.props.selectedSiteId)
        if (!isSiteBess(selectedSite)){
            return <Panel>This dashboard is only available for sites with a battery</Panel>
        }

        var selectionProps = {
            selectedSiteId: this.props.selectedSiteId,
            selectedStartDate: this.state.selectedStartDate,
            selectedEndDate: this.state.selectedEndDate,
            selectedResolution: this.state.selectedResolution,
        }

        //var KPIDataProvider = this.wrapDataProvider('/api/market/kpis', this.kpiRenderer, false)

        return (
            <div className="section-main flow-vertical fill-parent overflow-y">
                <div className="section-header flow-horizontal hide-overflow flex-fixed" style={{marginBottom: '10px'}}>
                    <div className="title">Market Summary</div>
                    <div className="fill-parent"></div>
                    <ResolutionDropdown 
                        selectedResolution={this.state.selectedResolution}
                        onSelectItem={this.updateSelectedResolution}
                        resolutionOptions={["raw", "1H", "1D"]}
                        containerProps={{
                            appearance: "subtle"
                        }}
                    />
                    <DatePicker 
                        services={this.props.services}
                        startDate={this.state.selectedStartDate}
                        endDate={this.state.selectedEndDate}
                        onValueChange={this.updateSelectedDates.bind(this)}
                        containerProps={{
                            appearance: "subtle",
                            placement: 'bottomEnd'
                        }}
                    />
                </div>
                <div className="flow-horizontal overflow-visible fill-parent" style={{marginBottom: "20px", minHeight: '300px'}}>
                    <div className="flow-vertical overflow-visible" style={{width: '400px', minWidth: '300px', marginRight: '20px'}}>
                        <KPIContainer services={this.props.services} {...selectionProps} />
                    </div>
                    <RevenueContainer services={this.props.services} chartOptions={this.chartOptions} {...selectionProps} />
                </div>
                <div className="flow-horizontal overflow-visible" style={{minHeight: '400px'}}>
                    <div className="flow-vertical overflow-visible" style={{width: "49.5%", marginRight: '0.5%'}}>
                        <PriceContainer services={this.props.services} chartOptions={this.chartOptions} {...selectionProps} />
                    </div>
                    <div className="flow-vertical overflow-visible" style={{width: "49.5%", marginLeft: '0.5%'}}>
                        <AwardContainer services={this.props.services} chartOptions={this.chartOptions} {...selectionProps} />
                    </div>
                </div>
            </div>
        )
    }

    updateSelectedResolution = (res) => {
        this.setState({
            selectedResolution: res
        })
    }

    updateSelectedDates(start, end) {
        this.setState({
            selectedStartDate: start,
            selectedEndDate: end
        })
    }

    showHoverContentAllCharts(hoveredPoint) {
        Highcharts.charts.forEach(chart => {
            if (!chart) return
            if (chart.index===hoveredPoint.series.chart.index) return   // Don't modify the chart being hovered

            let matchingPoints = []
            chart.series.forEach(series => {
                series.points.forEach(point => {
                    if (point.index===hoveredPoint.index) {
                        //point.setState('hover')
                        matchingPoints.push(point)
                    }
                })
            })
            if (matchingPoints.length > 0) {
                chart.tooltip.refresh(matchingPoints)
                chart.xAxis[0].drawCrosshair(null, matchingPoints[0])
            }
        })
    }

    hideHoverContentAllCharts(point) {
        Highcharts.charts.forEach(chart => {
            if (!chart) return
            chart.tooltip.hide()
            chart.xAxis[0].hideCrosshair()
        })
    };

    setExtremesAllCharts(originalChart, e) {
        if (e.trigger==="setExtremesAllCharts") return   // exit when the calling function is self, otherwise stack overflow
        Highcharts.charts.forEach(chart => {
            if (!chart) return
            if (chart.index===originalChart.index) return   // don't modify the triggering chart
            if (chart.xAxis[0].setExtremes) { // It is null while updating
                chart.xAxis[0].setExtremes(e.min, e.max, undefined, (chart.pointCount <= animationLimit), {
                    trigger: "setExtremesAllCharts"
                });
            }
        })
    }

}

export default SummaryContent