import {Component} from 'react'

import Panel from '../../../panel/Panel'

import RegulationSetpointAdherenceContainer from './RegulationSetpointAdherenceContainer'
import AvailabilitySummaryContainer from './AvailabilitySummaryContainer'
import { isSiteBess } from '../../../../utils/assetFrameworkMacros'

class RegulationContent extends Component {

    constructor(props) {
        super()
        this.state = {
            
        }
    }

    componentDidMount() {
        console.log("Regulation content mounted")
    }

    render() {

        if (!this.props.selectedSiteId) {
            return <Panel>Please select a site</Panel>
        }

        // Tab is only available for sites with a battery
        const selectedSite = this.props.services.assetFramework.getSites(this.props.selectedSiteId)
        if (!isSiteBess(selectedSite)){
            return <Panel>This dashboard is only available for sites with a battery system.</Panel>
        }

        return (
            <div className="section-main flow-horizontal fill-parent overflow-y">
                <div className="flow-vertical fill-parent" style={{width: "300px", minWidth: "300px", marginRight: "10px"}}>
                    <AvailabilitySummaryContainer
                        services={this.props.services} 
                        selectedSiteId={this.props.selectedSiteId} 
                        style={{maxHeight: "270px", marginBottom: "10px"}} 
                    />
                    <div className="section-panel fill-parent"></div>
                </div>
                <RegulationSetpointAdherenceContainer
                    services={this.props.services}
                    selectedSiteId={this.props.selectedSiteId}
                />
            </div>
        )
    }

}

export default RegulationContent