import {Component} from 'react'

import HelpPanel from '../../../help/HelpPanel'
import Panel from '../../../panel/Panel'
import { isSiteBess } from '../../../../utils/assetFrameworkMacros'

import ForecastViewContainer from './ForecastViewContainer'

class ForecastsContent extends Component {

    constructor(props) {
        super()
        this.state = {
            
        }
    }

    componentDidMount() {
        console.log("Forecasts content mounted")
    }

    render() {

        if (!this.props.selectedSiteId) {
            return <Panel>Please select a site</Panel>
        }

        // Tab is only available for sites with a battery
        var selectedSite = this.props.services.assetFramework.getSites(this.props.selectedSiteId)
        if (!isSiteBess(selectedSite)){
            return <Panel>This dashboard is only available for sites with a battery</Panel>
        }

        return (
            <HelpPanel
                services={this.props.services}
                render={(newServices) =>
                    <ForecastViewContainer 
                        services={newServices}
                        selectedSiteId={this.props.selectedSiteId}
                    />
                }
            />
        )
    }

}

export default ForecastsContent