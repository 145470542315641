import './TextCell.css'

import { useInputDispatch } from '../../../hooks/inputDispatch'

import { Table, Input, Alert } from 'rsuite'
import { useEffect, useRef } from 'react'
const {Cell} = Table

export default function EditCell({rowData, dataIdKey, dataKey, onChange, cellOptions, isEditingFunction, ...props}) {

    var dispatch = useInputDispatch(300)

    const ref = useRef()

    
    var itemId = rowData[dataIdKey]
    
    var isEditing = isEditingFunction(itemId)
    
    var value = rowData[dataKey]

    useEffect(() => {
        if (ref.current) {
            if (String(value)!=ref.current.value) {
                ref.current.value = value
            }
        }
    }, [value])

    var className = ""
    if (isEditing) className += ' table-content-editing'

    return (
        <Cell {...props} className={className} >
            {isEditing ? (
                <div>
                    <input
                        ref={ref}
                        className="rs-input"
                        defaultValue={value}
                        type={cellOptions.type || "text"}
                        onChange={event => {
                            if (cellOptions.transformNewValue) {
                                try {
                                    let updateObject = cellOptions.transformNewValue(event.target.value, dataKey, rowData)
                                    if (onChange) onChange(rowData[dataIdKey], Object.keys(updateObject), Object.values(updateObject));
                                }
                                catch (e) {
                                    console.log(e)
                                    Alert.error(`
                                    Failed to process side effect on instance ${dataKey} change.
                                    ${e}
                                    `, 8000)
                                    if (onChange) dispatch(() => onChange(itemId, dataKey, event.target.value));
                                }
                            }
                            else {
                                if (onChange) dispatch(() => onChange(itemId, dataKey, event.target.value));
                            }
                        }}
                    />
                </div>
            ) : (
                <span className="table-content-edit-span">{value}</span>
            )}
        </Cell>
    );
};