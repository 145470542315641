import { HelpEntityId, HelpEntityName, HelpEntityDescription } from '../help_panels/General'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'


export function makeCounterpartyId(cpRecords, key, cpIdCol, cpNameCol, props={}) {
    return {
        name: "Counterparty",
        key: key,
        cellType: "dropdown",
        cellOptions: {
            searchable: true,
            transformDisplayValue: (value) => transformDisplayValue(cpRecords, value, cpIdCol, cpNameCol),
            dropdownItems: buildDropdownItems(cpRecords, cpIdCol, cpNameCol),
        },
        filterable: true,
        sortable: true,
        colProps: Object.assign({
            width: 300,
            resizable: true,
        }, props),
    }
}

export function makeCounterpartyDescription(key, entityType) {
    return makeEntityDescription(key, entityType)
}

export function makeLLCId(key) {
    return makeEntityId(key)
}
export function makeLLCName(key, entityType) {
    return makeEntityName(key, entityType, 200)
}
export function makeLLCDescription(key, entityType) {
    return makeEntityDescription(key, entityType)
}

export function makePlantId (plantRecords, plantIdCol, plantNameCol, fixed=true) {
    return {
        name: "Plant",
        key: plantIdCol,
        cellType: "dropdown",
        cellOptions: {
            searchable: true,
            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, plantIdCol, plantNameCol),
            dropdownItems: buildDropdownItems(plantRecords, plantIdCol, plantNameCol),
        },
        filterable: true,
        sortable: true,
        colProps: {
            width: 300,
            resizable: true,
            fixed: fixed ? "left" : null,
        },
    }
}

export const makeNotes = (key, grow=0) => {
    return {
        name: "Notes",
        key: key,
        cellType: "editable",
        filterable: false,
        sortable: false,
        colProps: {
            width: 300,
            flexGrow: grow,
            resizable: true,
        }
    }
}


/*
    General Purpose column factory functions for entities.
*/
export function makeEntityId(key, colProps={}) {
    return {
        name: "ID",
        key: key,
        cellType: "readOnly",
        filterable: true,
        sortable: true,
        colProps: Object.assign({
            width: 100,
            resizable: true,
        }, colProps),
        info: HelpEntityId,
    }
}

export function makeEntityName(key, entityType, width) {
    return {
        name: "Name",
        key: key,
        cellType: "editable",
        filterable: true,
        sortable: true,
        colProps: {
            width: width,
            resizable: true,
        },
        info: () => <HelpEntityName type={entityType} />,
    }
}

export function makeEntityDescription(key, entityType) {
    return {
        name: "Description",
        key: key,
        cellType: "editable",
        filterable: false,
        sortable: false,
        colProps: {
            flexGrow: 1,
        },
        info: () => <HelpEntityDescription type={entityType} />,
    }
}

export const booleanOptions = [{
    label: "True",
    value: true,
}, {
    label: "False",
    value: false,
}, {
    label: "Unknown",
    value: null,
}]