import React, {Component} from 'react'

import MonitorContent from './MonitorDashboardList'
import AssetSidebar, { useAssetSelectionState } from '../../menu/AssetMenu'

const HomeTab = ({services}) => {

    const [selectedPortfolioId, selectedSiteId, updatePortfolioId, updateSiteId] = useAssetSelectionState()

    return (
        <div className="flow-horizontal fill-parent">
            <AssetSidebar 
                services={services}
                updateSelectedSite={updateSiteId} 
                updateSelectedPortfolio={updatePortfolioId}
                selectedSiteId={selectedSiteId}
                selectedPortfolioId={selectedPortfolioId}
                className="sidebar"
                menuProps={{
                    collapsable: true,
                }}
            />
            <div className="section-main flow-vertical fill-parent">
                <MonitorContent
                    services={services}
                />
            </div>
        </div>
    )
}

export default HomeTab