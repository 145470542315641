import './UserMenu.css'
import {Component} from 'react'

import { Tooltip, Whisper } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faRightFromBracket, faTable, faCube, faArrowLeft, faWarehouse, faFolderTree, faClipboard, faFolder, faChartArea } from '@fortawesome/free-solid-svg-icons'
import { contractStatusOptions } from '../table_configuration/Contract';


export const UserMenu = (props) => {

    return (
        <div className="user-menu-sidebar flow-vertical" >

            <MenuItem
                item={<a href="/"><FontAwesomeIcon icon={faArrowLeft} size="lg" className="fa-icon" /></a>}
                tooltip={createTooltip("Return to application selection")}
            />
            
            <Divider />
                <MenuItem
                    item={<FontAwesomeIcon onClick={() => props.setTab("home")} icon={faHome} size="lg" className="fa-icon" />}
                    tooltip={createTooltip("Home")}
                />
            <Divider />

            <MenuItem
                item={<FontAwesomeIcon
                        onClick={() => props.setTab("powerbi")}
                        icon={faChartArea}
                        size="lg"
                        className="fa-icon"
                />}
                tooltip={createTooltip("Power BI")}
            />
            <MenuItem
                item={<FontAwesomeIcon
                        onClick={() => props.setTab("warehouse")}
                        icon={faWarehouse}
                        size="lg"
                        className="fa-icon"
                />}
                tooltip={createTooltip("Inventory Management")}
            />
            <div className="fill-parent"></div>

            <Divider />
            <MenuItem
                item={<FontAwesomeIcon onClick={props.services.auth.logout} icon={faRightFromBracket} size="lg" className="fa-icon" style={{marginBottom: "30px"}} />}
                tooltip={createTooltip("Logout")}
            />
        </div>
    )

}

const MenuItem = ({item, tooltip, style={}}) => {
    return (
        <Whisper placement="right" trigger="hover" speaker={tooltip}>
            <div className="action-cell" style={style}>
                {item}
            </div>
        </Whisper>
    )
}

const Divider = () => <hr style={{width: "60%", marginBottom: "0px"}}></hr>

const createTooltip = (message) => {
    return (
        <Tooltip>{message}</Tooltip>
    )
}