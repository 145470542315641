import './TagMenu.css'
import React, {Component} from 'react'

import _ from 'lodash'
import ChartOptionButton from '../button/ChartOptionButton'

import MenuContainer from '../menu/MenuContainer'

class MenuSubmitWrapper extends Component {

    constructor(props) {
        super()
        this.state = {
            selectedItems: props.selectedItems
        }
    }

    submit = () => {
        this.close(false)
    }

    cancel = () => {
        this.close(true)
    }

    close = (cancel) => {
        if (cancel) {
            this.props.onClose()
        }
        else {
            this.props.onSubmit(this.state.selectedItems)
            this.props.onClose()
        }
    }

    render() {
        return(
            <div>
                <div>
                    <MenuContainer
                        {...this.props.menuProps}
                        menuSkeleton={this.createSkeleton()}
                        leafNodeClick={this.itemClick}
                        searchable
                        peripheralsOptions
                        onClearSelection={this.clearSelection}
                        onFillSelection={this.fillSelection}
                        bodyStyles={{maxHeight: "200px", overflowY: "auto"}}
                    />
                </div>
                <div style={{marginTop: "10px"}}>
                    <ChartOptionButton appearance="primary" onClick={() => this.submit()} style={{marginRight: "10px"}} >
                        Ok
                    </ChartOptionButton>
                    <ChartOptionButton appearance="secondary" onClick={() => this.cancel()} >
                        Cancel
                    </ChartOptionButton>
                </div>
            </div>
        )
    }

    fillSelection = () => {
        this.setState({
            selectedItems: _.cloneDeep(this.props.items)
        })
    }

    clearSelection = () => {
        this.setState({
            selectedItems: [],
        })
    }

    createSkeleton = () => {
        var items = this.props.items
        if (!this.props.customSort) items = items.sort()
        return items.map((x) => this.props.createSkeleton(x, this.state.selectedItems))
    }

    itemClick = (item) => {
        var newState = this.props.updateState(_.cloneDeep(this.state.selectedItems), item.key)
        this.setState({
            selectedItems: newState
        })
    }

}

export default MenuSubmitWrapper