export const TAG_TYPES = {
    source: "source",
    interval: "interval",
    calculated: "calculated",

    // Legacy, should be removed
    raw: "raw"
}
export const SERVICES = {
    intervalTags: "interval_tags"
}

export const DEVICE_TYPES = {
    tenaskaBess: 33,
    tenaskaSolar: 39,
    tenaskaBessForecast: 35
}

export const SITE_TECHNOLOGIES = {
    solar: "solar",
    wind: "wind",
    standAloneStorage: "stand_alone_storage",
    hybridSolarStorage: "hybrid_solar_storage",
}