import {Component} from 'react'

import CRUDTable from '../../../table/CRUDTable'
import Panel from '../../../panel/Panel'
import {cobjectTableConfig} from '../../../table/config_templates/cobject'
import { transformDisplayValue } from '../../../../utils/databaseAppUtils'
import _ from "lodash"


class TemplateConfigTable extends Component {

    constructor(props) {
        super()

        this.state = {
            
        }
    }

    render() {

        if (!this.props.selectedDeviceId) {
            return <Panel className="">Please select a device</Panel>
        }

        const allTags = _.sortBy(this.props.services.assetFramework.getTags(), "name")

        var device = this.props.services.assetFramework.getDevices().find(x => x._id===this.props.selectedDeviceId) || {}
        var tags = device.tags || []

        return (
            <CRUDTable 
                services={this.props.services}
                initialItems={tags}
                itemType="blueprint template"
                itemSchema={this.getTagBlueprintSchema()}
                flattenItem={this.flattenTagBlueprint}
                structureItem={this.structureTagBlueprint}
                buildRequestBody={this.buildRequestBody.bind(this)}
                tableConfig={{
                    columns: [
                        {
                            name: "Template",
                            key: "template_id",
                            cellType: "dropdown",
                            cellOptions: {
                                transformDisplayValue: (value) => transformDisplayValue(allTags, value, "_id", "name"),
                                dropdownItems: allTags.map(x => Object({label: x.name, value: x._id}))
                            },
                            colProps: {
                                width: 250,
                                resizable: true,
                                fixed: "left",
                            }
                        },
                        {
                            name: "Type",
                            key: "type",
                            cellType: "dropdown",
                            cellOptions: {
                                dropdownItems: [{
                                    label: "raw",
                                    value: "raw",
                                }, {
                                    label: "calculated",
                                    value: "calculated",
                                }],
                                transformNewValue: (newValue, newValueColumn, record, items) => {
                                    if (newValue==="raw") {
                                        return {
                                            [newValueColumn]: newValue,
                                            cobjectInherit: null,
                                            cobjectType: null,
                                            cobjectFunction: null,
                                            cobjectCode: null,
                                            cobjectRange: null,
                                            cobjectRangedDataAccessMethod: null,
                                        }
                                    }
                                    else if (newValue==="calculated") {
                                        return {
                                            [newValueColumn]: newValue,
                                            cobjectInherit: false,
                                            cobjectType: "standard",
                                            cobjectFunction: "evaluate",
                                            cobjectCode: "",
                                            cobjectRange: [0,0],
                                            cobjectRangedDataAccessMethod: null,
                                        }
                                    }
                                    else return {[newValueColumn]: newValue}
                                }
                            },
                            colProps: {
                                width: 150,
                                resizable: true,
                            }
                        },
                        {
                            colGroup: true,
                            header: "Evaluation",
                            columns: [
                                cobjectTableConfig.inherit(),
                                cobjectTableConfig.type(),
                                cobjectTableConfig.function(),
                                cobjectTableConfig.code({services: this.props.services, selectedSiteId: this.props.selectedSiteId}),
                                cobjectTableConfig.range(),
                                cobjectTableConfig.rangedDataAccessMethod(),
                            ]
                        },
                    ]
                }}
                endpoints={{
                    create: "/api/tags/blueprint/create",
                    update: "/api/tags/blueprint/update",
                    delete: "/api/tags/blueprint/delete"
                }}
                onCreateSuccess={(newTag) => {
                    console.log(newTag)
                    this.props.services.assetFramework.addDeviceTag(this.props.selectedDeviceId, newTag)
                }}
                onUpdateSuccess={(newTag) => {
                    this.props.services.assetFramework.updateDeviceTag(this.props.selectedDeviceId, newTag)
                }}
                onDeleteSuccess={(deletedTag) => {
                    this.props.services.assetFramework.deleteDeviceTag(this.props.selectedDeviceId, deletedTag.template_id)
                }}
                validateNewItem={this.validateNewItem}
                validateUpdateItem={this.validateUpdateItem}
            />
        );
        
    }

    buildRequestBody(requestType, {updatedItem, originalItem, deletedItem, createdItem}) {
        switch (requestType) {
            case "delete":
                return {
                    deviceId: this.props.selectedDeviceId,
                    deletedRecord: deletedItem
                }
            case "update":
                return {
                    deviceId: this.props.selectedDeviceId,
                    originalRecord: originalItem,
                    newRecord: updatedItem
                }
            case "create":
                return {
                    deviceId: this.props.selectedDeviceId,
                    createdRecord: createdItem
                }
            default:
                return {}
        }
    }

    getTagBlueprintSchema = () => {
        var schema = {
            template_id: null,
            type: "raw"
        }
        return schema
    }

    flattenTagBlueprint = (tag) => {
        return {
            template_id: tag.template_id,
            type: tag.type,
            
            cobjectInherit: tag.cobject?.inherit,
            cobjectType: tag.cobject?.type,
            cobjectFunction: tag.cobject?.function,
            cobjectCode: tag.cobject?.code,
            cobjectRange: tag.cobject?.range,
            cobjectRangedDataAccessMethod: tag.cobject?.ranged_data_access_method,
        }
    }

    structureTagBlueprint = (tagFlat) => {
        return {
            type: tagFlat.type,
            template_id: tagFlat.template_id,

            cobject: {
                inherit: tagFlat.cobjectInherit,
                type: tagFlat.cobjectType,
                function: tagFlat.cobjectFunction,
                code: tagFlat.cobjectCode,
                range: tagFlat.cobjectRange,
                ranged_data_access_method: tagFlat.cobjectRangedDataAccessMethod
            },
        }
    }

    validateNewItem = (item) => {
        if (!item.template_id) {
            return {pass: false, message: "Please select a tag template."}
        }
        if (!item.type) {
            return {pass: false, message: "Please select a type."}
        }
        return {pass: true}
    }
    
    validateUpdateItem = (item) => {
        if (!item.template_id) {
            return {pass: false, message: "Please select a tag template."}
        }
        if (!item.type) {
            return {pass: false, message: "Please select a type."}
        }
        return {pass: true}
    }

}

export default TemplateConfigTable