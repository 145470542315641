import './EventsTable.css'
import React, {Component} from 'react'

import _ from 'lodash'
import axios from 'axios'
import { Table, Drawer, Button, Toggle, Popover, Whisper, Alert, Dropdown } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown, faFilter, faEllipsisH } from '@fortawesome/free-solid-svg-icons'

import MenuWrapper from '../../menu/MenuWrapper'
import EventModal from '../../modal/EventModal'

const { Column, HeaderCell, Cell } = Table;


class EventsTable extends Component {

    constructor(props) {
        super()
        this.heightOffset = 170
        this.state = {
            innerHeight: window.innerHeight - this.heightOffset,
            showOpenEvents: true,
            sortColumn: null,
            sortType: null,
            filters: {},
            loading: false,
        }
    }

    handleResize = () => {
        this.setState({
            innerHeight: window.innerHeight-this.heightOffset,
        })
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    filterEvent = (event) => {
        if (!this.state.showOpenEvents && event.status === "open") return false

        for (let filterKey of Object.keys(this.state.filters)) {
            let filteredValues = this.state.filters[filterKey]
            if (!filteredValues) continue
            if (!filteredValues.includes(event[filterKey])) return false
        }
        return true
    }
    

    render() {

        var allEvents = this.props.data.map(x => this.flattenEvent(x))
        var events = allEvents.filter(x => this.filterEvent(x))

        const {sortColumn, sortType} = this.state

        if (sortColumn && sortType) {
            events = events.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (x==="" && sortType==="asc") return 1
                if (y==="" && sortType==="asc") return 1
                let compare = (x < y ? 1 : -1);
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return compare;
                } else {
                    return compare*-1;
                }
            });
        }

        return (
            <div className="section-panel events-table fill-parent">
                <div className="section-header flow-horizontal fill-parent">
                    <div className="fill-parent"></div>
                    <div style={{flexShrink: 0, marginRight: '10px', fontWeight: 'bold'}}>Show Open Events</div>
                    <Toggle
                        checked={this.state.showOpenEvents}
                        onChange={this.toggleShowOpenEvents}
                    />
                </div>
                <Table
                    data={events}
                    hover={false}
                    loading={this.state.loading}
                    height={this.state.innerHeight}
                    defaultSortType={'desc'}
                    sortColumn={this.state.sortColumn}
                    sortType={this.state.sortType}
                    onSortColumn={this.handleSortColumn}
                >
                <Column width={100} resizable sortable>
                    <HeaderCell>Options</HeaderCell>
                    <this.OptionsCell dataKey="_id" />
                </Column>

                <Column width={230} resizable sortable>
                    <this.FilterHeaderCell title="Event Id" allItems={allEvents} />
                    <this.EventModalCell dataKey="_id" />
                </Column>
        
                <Column width={150} resizable>
                    <this.FilterHeaderCell title="Event Code" allItems={allEvents} />
                    <this.OpenEventMetadataDrawerCell dataKey="eventCode" />
                </Column>

                <Column width={100} resizable>
                    <this.FilterHeaderCell title="Site" allItems={allEvents} />
                    <Cell dataKey="siteName" />
                </Column>

                <Column width={200} sortable resizable>
                    <this.FilterHeaderCell title="Device Path" allItems={allEvents} />
                    <Cell dataKey="devicePath" />
                </Column>

                <Column width={100}>
                    <this.FilterHeaderCell title="Status" allItems={allEvents} />
                    <this.ImageCell dataKey="status" />
                </Column>
        
                <Column width={100}>
                    <this.FilterHeaderCell title="Duration" allItems={allEvents} />
                    <Cell dataKey="duration" />
                </Column>
        
                <Column width={150} resizable >
                <this.FilterHeaderCell title="Opened At" allItems={allEvents} />
                    <Cell dataKey="openedAt" />
                </Column>
        
                <Column width={150} resizable>
                    <this.FilterHeaderCell title="Closed At" allItems={allEvents} />
                    <Cell dataKey="closedAt" />
                </Column>

                <Column width={150} resizable>
                    <this.FilterHeaderCell title="Blueprint Id" allItems={allEvents} />
                    <this.OpenEventMetadataDrawerCell dataKey="blueprintId" />
                </Column>
                </Table>

                <Drawer backdrop={false} show={this.state.drawer} onHide={this.closeDrawer} size='xs'>
                    <Drawer.Header>
                        <Drawer.Title>Event Type Metadata</Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                        {this.constructEventDrawer()}
                    </Drawer.Body>
                    <Drawer.Footer>
                        <Button onClick={this.closeDrawer} appearance="subtle">
                        Cancel
                        </Button>
                    </Drawer.Footer>
                </Drawer>
            </div>
        );
    }

    flattenEvent = (event) => {

        let durationSeconds = event.duration
        let duration = (typeof(durationSeconds)==="number" ? new Date(durationSeconds * 1000).toISOString().substr(11, 8) : "")

        let openedTs = event.opened_ts+"z"
        let openedTsLocalized = new Date(openedTs).toLocaleString('en-US', {timeZone: this.props.siteTimezone, hour12: false})

        let closedTs = (event.closed_ts ? event.closed_ts+"z" : "")
        let closedTsLocalized = (closedTs ? new Date(closedTs).toLocaleString('en-US', {timeZone: this.props.siteTimezone, hour12: false}) : "")

        var blueprint = this.props.services.assetFramework.getEventBlueprints().find(x => x._id === event.blueprint) || {}
        var blueprintTemplateId = blueprint.template

        var template = this.props.services.assetFramework.getEventTemplates().find(x => x._id === blueprintTemplateId) || {}
        var templateCode = template.code

        return {
            _id: event._id,
            key: event._id,
            siteName: event.site_name,
            devicePath: event.device_path,
            status: event.status,
            blueprintId: event.blueprint,
            openedAt: openedTsLocalized,
            closedAt: closedTsLocalized,
            duration: duration,
            eventCode: templateCode
        }
    }

    structureEvent = (eventFlat) => {
        // Only need to return fields that can be updated
        return {
            _id: eventFlat._id,

        }
    }

    constructEventDrawer = () => {
        var blueprint = this.props.services.assetFramework.getEventBlueprints().find(x => x._id===this.state.selectedBlueprintId) || {}
        var template = this.props.services.assetFramework.getEventTemplates().find(x => x._id===blueprint.template)
        //console.log(blueprint)

        var blueprintTable = this.constructBlueprintTable(blueprint)
        var templateTable = this.constructTemplateTable(template)

        return (
            <div className="flow-vertical">
                <div className="minimal-table-header">Event Blueprint</div>
                {blueprintTable}
                <div className="minimal-table-header" style={{marginTop: '10px'}}>Event Template</div>
                {templateTable}
            </div>
        )
    }

    constructBlueprintTable = (blueprint) => {
        if (!blueprint) return this.constructMinimalTable([])
        var data = {
            "Blueprint Id": blueprint._id,
            "Expression": blueprint.expression,
            "Site": blueprint.assets?.site,
            "Devices": blueprint.assets?.devices,
            "Notification": blueprint.notification?.enabled,
            "Notification Method": blueprint.notification?.method,
            "Notification Emails - Primary": (blueprint.notification?.emails?.primary || []).join(", "),
            "Notification Emails - Secondary": (blueprint.notification?.emails?.secondary || []).join(", "),
        }
        return this.constructMinimalTable(data)
    }

    constructTemplateTable = (template) => {
        if (!template) return this.constructMinimalTable([])
        var data = {
            "Event Code": template.code,
            "Event Description": template.description,
            "Default Expression": template.expression,
        }
        return this.constructMinimalTable(data)
    }

    constructMinimalTable = (data) => {
        return (
            <table className="event-meta-table minimal-table">
                <tbody>
                    <tr>
                        <th colSpan="1">Key</th>
                        <th colspan="1">Value</th>
                    </tr>
                    {Object.keys(data).map(k => {
                        return (
                            <tr>
                                <td colSpan={1}>{k}</td>
                                <td colSpan={1}>{String(data[k])}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    closeEvent = (event) => {
        var eventId = event._id
        var bid = event.blueprintId
        axios.post(
            "/api/events/close", {
                eventId: eventId,
                blueprintId: bid,
            }
        ).then((response) => {
            let closedEvent = response.data.event
            console.log(closedEvent)
            let closedEventId = closedEvent._id
            let eventIdx = this.props.data.findIndex(x => x._id === closedEventId)
            this.props.data[eventIdx] = closedEvent
            this.setState({
                loading: false,
            })
        }).catch((error) => {
            console.log(error)
            Alert.error("Error closing event " + String(eventId), 0)
            this.setState({
                loading: false,
            })
        })
    }

    ///////////////
    // Custom Cells
    ///////////////

    createSpeaker = (event) => {
        var eventIsOpen = event.status === "open"
        return (
            <Popover>
                <Dropdown.Menu>
                    {eventIsOpen && <Dropdown.Item eventKey={1} onSelect={() => {this.setState({loading: true}); this.closeEvent(event)}}>Close</Dropdown.Item>}
                </Dropdown.Menu>
            </Popover>
        )
    }

    OptionsCell = ({ rowData, dataKey, onClick, ...props }) => {
        const speaker = this.createSpeaker(rowData)
        return (
            <Cell {...props}>
                <Whisper placement="bottomStart" trigger="click" speaker={speaker}>
                    <FontAwesomeIcon className="options-icon" icon={faEllipsisH} />
                </Whisper>
            </Cell>
        );
    };

    ImageCell = ({ rowData, dataKey, ...props }) => {
        var label = rowData[dataKey]
        return <Cell {...props}>
          <div className={`event-status-label ${label}`}>{label}</div>
        </Cell>
    }

    EventModalCell = ({ rowData, dataKey, ...props }) => {
        var label = rowData[dataKey]
        return <Cell {...props}>
            <EventModal>{label}</EventModal>
        </Cell>
    }

    OpenEventMetadataDrawerCell = ({ rowData, dataKey, ...props }) => {
        var label = rowData[dataKey]
        return <Cell {...props} className="open-drawer-link" onClick={() => this.openDrawer(rowData.blueprintId)} style={{cursor: 'pointer'}}>
          {label}
        </Cell>
    }

    FilterHeaderCell = ({title, allItems, filterable=true, sortable=true,  ...props}) => {

        let dataKey = props.dataKey

        // These are the values belonging to the specified column
        let data = _.uniq(allItems.map(x => x[dataKey]))
        
        let selectedData = data
        let columnFilters = this.state.filters[dataKey]
        if (columnFilters) {   // null or empty filter implies no filter
            selectedData = data.filter(x => columnFilters.includes(x))
        }

        // Ref to close filter menu on close
        const triggerRef = React.createRef()
        const close = () => triggerRef.current.close();

        const ItemMenu = <MenuWrapper
            createSkeleton={(item, selectedItems) => {
                let value = item
                return {
                    "key": value,
                    "label": value,
                    "selectable": true,
                    "active": selectedItems.includes(item),
                }
            }}
            onSubmit={(selectedItems) => {
                var filters = this.state.filters
                filters[props.dataKey] = data.length === selectedItems.length ? null : selectedItems
                this.setState({
                    filters: filters
                })
            }}
            onClose={() => close()}
            items={data}
            selectedItems={selectedData}
            updateState={(curState, newItem) => {
                let newState;
                let selectedItemIndex = curState.indexOf(newItem)
                if (selectedItemIndex !== -1) {
                    curState.splice(selectedItemIndex, 1)
                    newState = curState
                }
                else {
                    curState.push(newItem)
                }
                return newState
            }}
            menuProps={{
                headerName: "Filter",
                retrievePayloadFunction: (x) => x.key,
                className: "",
                includeCheckbox: true,
            }}
        />

        let itemMenuPopup = <Popover title={this.props.label} style={{minWidth: '400px', maxWidth: '400px', maxHeight: '400px', overflowY: 'auto'}}>
                                {ItemMenu}
                            </Popover>

        let nextSortOrder = (this.state.sortType === "asc" ? "desc" : "asc")
        let sortIcon = faSort
        let isSorted = false
        if (this.state.sortColumn === props.dataKey) {
            sortIcon = (this.state.sortType === "asc" ? faSortUp : faSortDown)
            isSorted = true
        }

        return (
            <HeaderCell {...props} >
                <span>
                    <span>{title}</span>
                    {filterable && <span style={{marginLeft: '4px'}}>
                        <Whisper placement="bottom" trigger="click" speaker={itemMenuPopup} ref={triggerRef} enterable preventOverflow>
                            <FontAwesomeIcon 
                                className={"fa-icon filter clickable" + (this.state.filters[props.dataKey] ? " active" : "")}
                                size="sm" 
                                icon={faFilter}
                            />
                        </Whisper>
                    </span>}
                    {sortable && <span style={{marginLeft: '6px'}}>
                        <FontAwesomeIcon 
                            onClick={() => this.handleSortColumn(props.dataKey, nextSortOrder)} 
                            className={"fa-icon sort clickable" + (isSorted ? " active" : "")}
                            size="1x" 
                            icon={sortIcon} 
                        />
                    </span>}
                </span>
            </HeaderCell>
        )
    }

    ///////////////////
    // State Management
    ///////////////////

    toggleShowOpenEvents = () => {
        this.setState({
            showOpenEvents: !this.state.showOpenEvents
        })
    }

    handleSortColumn = (sortColumn, sortType) => {
        if (this.state.sortColumn!==sortColumn) sortType = "asc"
        this.setState({
            sortColumn: sortColumn,
            sortType: sortType,
        });
    }

    closeDrawer = () => {
        this.setState({
            drawer: false,
            selectedBlueprintId: null,
        })
    }

    openDrawer = (blueprintId) => {
        this.setState({
            drawer: true,
            selectedBlueprintId: blueprintId,
        })
    }

}

export default EventsTable