import './App.css';
import 'rsuite/lib/styles/index.less';

import React, {Component} from 'react';

import Navbar from './components/navbar/Navbar'
import HomeTab from './components/tabs/home/HomeTab'
import HistoryTab from './components/tabs/history/History'
import MonitorTab from './components/tabs/monitor/MonitorTab'
import MarketTab from './components/tabs/market/MarketTab'
import EventsTab from './components/tabs/events/Events'
import ConfigureTab from './components/tabs/configure/ConfigureTab'
import Panel from './components/panel/Panel'

import { injectServices } from './utils/ServiceInjector'
import { formatDate } from './utils/Dates';
import { list2Dict } from './utils/MiscUtils';
import { getStartTime, setStartTime, getEndTime, setEndTime, getResolution, setResolution } from './utils/UrlLocation'
import { startOfDay, endOfDay } from 'date-fns'
import axios from "axios";


class App extends Component {

    constructor(props) {
        super()
        this.state = {
            isAssetFrameworkLoaded: false,
            errorLoadingAssetFramework: false,
            assetFramework: {
                portfolios: null,
                sites: null,
                devices: null,
                deviceTypes: null,
                deviceGroups: null,
                tags: null,
                alarms: null,
                dimensions: null,
            }
        }
    }

    componentDidMount() {

        var _this = this

        this.setDefaultURLParams()

        // Fetch asset framework and update state
        axios.get('/api/asset_framework')
        .then(function(data) {
            _this.setAssetFramework(data.data)   // Update the af api before the state because renderings depend on the af api
            _this.setState({
                isAssetFrameworkLoaded: true,
                errorLoadingAssetFramework: false,
            })
        })
        .catch(error => {
            if (error.response?.status === 401) {
                this.props.services.auth.logout()
            }
            else {
                _this.setState({
                    isAssetFrameworkLoaded: false,
                    errorLoadingAssetFramework: true,
                })
                console.log(error)
            }
        })

    }
  
    render() {

        if (this.state.errorLoadingAssetFramework) {
            return <Panel>Failed to load assets</Panel>
        }
        if (!this.state.isAssetFrameworkLoaded) {
            return <Panel className=''>Loading</Panel>
        }
        
        var servicesInjectedAssetFramework = this.buildAssetFrameworkServices()

        return (
            <div className="activity-listener flow-vertical fill-parent">
                <Navbar 
                    services={servicesInjectedAssetFramework}
                    includePeripherals
                    defaultURL="home"
                    items={[
                        {
                            label: "Home",
                            url: "home",
                            component: HomeTab,
                            props: {
                                services: servicesInjectedAssetFramework,
                            }
                        },
                        {
                            label: "Monitor",
                            url: "monitor",
                            component: MonitorTab,
                            props: {
                                services: servicesInjectedAssetFramework,
                            }
                        },
                        {
                            label: "Data",
                            url: "history",
                            component: HistoryTab,
                            props: {
                                services: servicesInjectedAssetFramework,
                            }
                        },
                        {
                            label: "Market",
                            url: "market",
                            component: MarketTab,
                            props: {
                                services: servicesInjectedAssetFramework,
                            }
                        },
                        {
                            label: "Events",
                            url: "events",
                            component: EventsTab,
                            props: {
                                services: servicesInjectedAssetFramework,
                            }
                        },
                        {
                            label: "Botman",
                            url: "configure",
                            component: ConfigureTab,
                            props: {
                                services: servicesInjectedAssetFramework,
                            }
                        },
                    ]}
                />
            </div>
        );
    }

    buildAssetFrameworkServices() {
        return injectServices(this.props.services, 'assetFramework', {
            getPortfolios: this.getPortfolios,
            getSites: this.getSites,
            getDevices: this.getDevices,
            getDeviceTypes: this.getDeviceTypes,
            getDeviceGroup: this.getDeviceGroup,
            getDeviceGroups: this.getDeviceGroups,
            getDeviceGroupDeviceIds: this.getDeviceGroupDeviceIds,
            getTags: this.getTags,
            getDimensions: this.getDimensions,
            getAlarms: this.getAlarms,
            getEventBlueprints: this.getEventBlueprints,
            getEventTemplates: this.getEventTemplates,

            addEventTemplate: this.addEventTemplate,
            deleteEventTemplate: this.deleteEventTemplate,
            updateEventTemplate: this.updateEventTemplate,

            addEventBlueprint: this.addEventBlueprint,
            deleteEventBlueprint: this.deleteEventBlueprint,
            updateEventBlueprint: this.updateEventBlueprint,

            addDeviceTag: this.addDeviceTag,
            updateDeviceTag: this.updateDeviceTag,
            deleteDeviceTag: this.deleteDeviceTag,

            getSiteTimezone: this.getSiteTimezone,
        })
    }

    getPortfolios = (format="raw") => {
        //return this.getAssetFrameworkItem('portfolios', format)
        return this.state.assetFramework.portfolios
    }

    getSites = (siteId) => {
        var sites = this.state.assetFramework.sites
        if (siteId) {
            sites = sites.find(x => x._id===siteId) || {}
        }
        return sites
    }

    getDevices = (siteId, deviceIds) => {
        var allDevices = this.state.assetFramework.devices
        return allDevices.filter(x => {
            return (
                (siteId!==undefined ? x.site_id===siteId : true) &&
                (deviceIds!==undefined ? deviceIds.includes(x._id) : true)
            )
        })
    }

    getDevice = (deviceId) => {
        var allDevices = this.state.assetFramework.devices
        return allDevices.find(x => deviceId===x._id)
    }

    getDeviceGroups = (siteId) => {
        if (siteId) return this.state.assetFramework.deviceGroups.filter(x => x.site_id===siteId)
        else return this.state.assetFramework.deviceGroups
    }

    getDeviceGroup = (deviceGroupId) => {
        return this.state.assetFramework.deviceGroups.find(x => x._id===deviceGroupId)
    }

    getDeviceGroupDeviceIds = (deviceGroup) => {
        if (deviceGroup?.device_type_id) {
            return this.getDevices(deviceGroup.site_id).filter(x => x.device_type===deviceGroup.device_type_id)
        }
        else return this.getDevices(undefined, deviceGroup?.device_ids) || []
    }

    getDeviceTypes = () => {
        return this.state.assetFramework.deviceTypes
    }

    getTags = () => {
        return this.state.assetFramework.tags
    }

    getAlarms = () => {
        return this.state.assetFramework.alarms
    }

    getDimensions = () => {
        return this.state.assetFramework.dimensions
    }

    getSiteTimezone = (siteId) => {
        return this.getSites(siteId)?.meta?.timezone
    }

    getEventBlueprints = () => {
        return this.state.assetFramework.eventBlueprints
    }

    getEventTemplates = () => {
        return this.state.assetFramework.eventTemplates
    }

    addEventTemplate = (newTemplate) => {
        this.addAssetFrameworkItem(newTemplate, "eventTemplates")
    }

    deleteEventTemplate = (deletedItem) => {
        this.deleteAssetFrameworkItem(deletedItem, "eventTemplates")
    }

    updateEventTemplate = (updatedTemplate) => {
        this.updateAssetFrameworkItem(updatedTemplate, "eventTemplates")
    }

    addEventBlueprint = (newBlueprint) => {
        this.addAssetFrameworkItem(newBlueprint, "eventBlueprints")
    }

    deleteEventBlueprint = (deletedItem) => {
        this.deleteAssetFrameworkItem(deletedItem, "eventBlueprints")
    }

    updateEventBlueprint = (updatedBlueprint) => {
        console.log(updatedBlueprint)
        this.updateAssetFrameworkItem(updatedBlueprint, "eventBlueprints")
    }

    
    
    getAssetFrameworkItem = (afItemKey, format) => {
        if (format==="raw") return this.assetFramework[afItemKey].raw
        else if (format==="dict") return this.assetFramework[afItemKey].dictionary
        else throw `Format must be one of 'raw', 'dict'. Got '${format}'.`
    }

    addAssetFrameworkItem = (newItem, itemKey) => {
        console.log(newItem, itemKey, this.state.assetFramework)
        this.state.assetFramework[itemKey].push(newItem)
        console.log(newItem, itemKey, this.state.assetFramework)
    }

    deleteAssetFrameworkItem = (deletedItem, itemKey) => {
        var deletedItemId = deletedItem._id
        var items = this.state.assetFramework[itemKey]
        var curIdx = items.findIndex(x => x._id===deletedItemId)
        if (curIdx===-1) return false
        deletedItem = items.splice(curIdx, 1)
        return deletedItem
    }

    updateAssetFrameworkItem = (updatedItem, itemKey) => {
        var items = this.state.assetFramework[itemKey]
        var itemId = updatedItem._id
        var curIdx = items.findIndex(x => x._id===itemId)
        if (curIdx===-1) return false
        items[curIdx] = updatedItem
    }

    addDeviceTag = (deviceId, tag) => {
        const device = this.getDevice(deviceId)
        if (!device) return false
        device.tags.push(tag)
        return true
    }

    updateDeviceTag = (deviceId, tag) => {
        const device = this.getDevice(deviceId)
        if (!device) return false
        const tagIdx = device.tags.findIndex(x => x.template_id===tag.template_id)
        if (tagIdx===-1) return false
        device.tags[tagIdx] = tag
        return true
    }

    deleteDeviceTag = (deviceId, tagId) => {
        const device = this.getDevice(deviceId)
        if (!device) return false
        const tagIdx = device.tags.findIndex(x => x.template_id===tagId)
        if (tagIdx===-1) return false
        device.tags.splice(tagIdx, 1)
        return true
    }

    setAssetFramework(assetFramework) {

        /*const assetFramework = {
            portfolios: {
                raw: assetFramework.portfolios,
                dictionary: list2Dict(assetFramework.portfolios)
            },
            sites: {
                raw: assetFramework.sites,
                dictionary: list2Dict(assetFramework.sites)
            },
            devices: {
                raw: assetFramework.devices,
                dictionary: list2Dict(assetFramework.devices)
            },
            deviceTypes: {
                raw: assetFramework.device_types,
                dictionary: list2Dict(assetFramework.device_types)
            },
            tags: {
                raw: assetFramework.tags,
                dictionary: list2Dict(assetFramework.tags)
            },
            alarms: {
                raw: assetFramework.alarms,
                dictionary: list2Dict(assetFramework.alarms)
            },
            dimensions: {
                raw: assetFramework.dimensions,
                dictionary: list2Dict(assetFramework.dimensions)
            },
            eventBlueprints: {
                raw: assetFramework.event_blueprints,
                dictionary: list2Dict(assetFramework.event_blueprints)
            },
            eventTemplates: {
                raw: assetFramework.event_templates,
                dictionary: list2Dict(assetFramework.event_templates)
            },
        }*/

        this.setState({
            isAssetFrameworkLoaded: true,
            assetFramework: {
                portfolios: assetFramework.portfolios,
                sites: assetFramework.sites,
                devices: assetFramework.devices,
                deviceGroups: assetFramework.device_groups,
                deviceTypes: assetFramework.device_types,
                tags: assetFramework.tags,
                alarms: assetFramework.alarms,
                dimensions: assetFramework.dimensions,
                eventBlueprints: assetFramework.event_blueprints,
                eventTemplates: assetFramework.event_templates,
            }
        })
    }

    setDefaultURLParams() {

        var defaultStartDate = formatDate(startOfDay(new Date()))
        var defaultEndDate = formatDate(endOfDay(new Date()))
        var defaultResolution = 0

        var selectedStartDate = getStartTime();
        if (!selectedStartDate) setStartTime(defaultStartDate);

        var selectedEndDate = getEndTime();
        if (!selectedEndDate) setEndTime(defaultEndDate);

        var selectedResolution = getResolution();
        if (!selectedResolution) setResolution(defaultResolution);

    }

}

export default App;