import { schemas } from '../Constants'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import { HelpInverterTable } from '../help_panels/ProjectEquipment'
import { Plant } from './Project'
import { EPCContract, makeContractName } from './Contract'
import { EPC, OEM } from './Counterparty'
import { InverterSpec, ModuleSpec, TransformerSpec, BESSSpec, TrackerSpec } from './EquipmentSpec'
import { makeNotes, makePlantId, booleanOptions, makeEntityId } from './CommonColumns'
import Table from './BaseTable'
import { BESSIntegrator, injectCounterpartyName } from './Counterparty'
import { Counterparty } from './Entity'
import {
    sub as subDate, add as addDate,
    subMilliseconds, subSeconds, subMinutes, subHours, subDays, subWeeks, subMonths, subQuarters, subYears,
    addMilliseconds, addSeconds, addMinutes, addHours, addDays, addWeeks, addMonths, addQuarters, addYears
} from 'date-fns'
import { avatarGroupClasses } from '@mui/material'


class EquipmentPaymentStructureTemplateTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            templateName: "template_name",
            OEMId: "oem_id",
            notes: "notes"
        }

        var schemaName = "payment"
        var tableName = "equipment_payment_structure_template"

        var displayNameSingular = "Equipment Payment Structure Template"
        var displayNamePlural = "Equipment Payment Structure Templates"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [OEM]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }


    buildTableProps = (data) => {

        var OEMRecords = this.findTableObjectRecords(data, OEM)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var OEMIdCol = OEM.columnSchema.oemId
        var OEMNameCol = "OEMName"
        
        OEMRecords = injectCounterpartyName(OEMRecords, counterpartyRecords, OEMIdCol, OEMNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid),
                    {
                        name: "Template Name",
                        key: this.columnSchema.templateName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "OEM",
                        key: this.columnSchema.OEMId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(OEMRecords, value, OEMIdCol, OEMNameCol),
                            dropdownItems: buildDropdownItems(OEMRecords, OEMIdCol, OEMNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const EquipmentPaymentStructureTemplate = new EquipmentPaymentStructureTemplateTable()

class EquipmentPaymentRuleTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            paymentStructureTemplateId: "payment_structure_template_id",
            milestone: "milestone",
            processingOrder: "processing_order",
            dueDateDescription: "due_date_description",
            dueDateFormula: "due_date_formula",
            amountDescription: "amount_description",
            amountFormula: "amount_formula"
        }

        var schemaName = "payment"
        var tableName = "equipment_payment_rule"

        var displayNameSingular = "Equipment Payment Rule"
        var displayNamePlural = "Equipment Payment Rules"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [EquipmentPaymentStructureTemplate]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }


    buildTableProps = (data) => {

        var templateRecords = this.findTableObjectRecords(data, EquipmentPaymentStructureTemplate)

        var templateIdCol = EquipmentPaymentStructureTemplate.columnSchema.oid
        var templateNameCol = EquipmentPaymentStructureTemplate.columnSchema.templateName
        

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid),
                    {
                        name: "Equipment Payment Structure Template",
                        key: this.columnSchema.paymentStructureTemplateId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(templateRecords, value, templateIdCol, templateNameCol),
                            dropdownItems: buildDropdownItems(templateRecords, templateIdCol, templateNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        }
                    }, {
                        name: "Milestone",
                        key: this.columnSchema.milestone,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Order",
                        key: this.columnSchema.processingOrder,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 150,
                            resizable: true,
                        }
                    }, {
                        name: "Due Date Description",
                        key: this.columnSchema.dueDateDescription,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Due Date Formula",
                        key: this.columnSchema.dueDateFormula,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Amount Description",
                        key: this.columnSchema.amountDescription,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Amount Formula",
                        key: this.columnSchema.amountFormula,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export const EquipmentPaymentRule = new EquipmentPaymentRuleTable()

class EquipmentPurchaseContractTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            contractName: "contract_name",
            projectId: "project_id",
            OEMId: "oem_id",
            paymentStructureTemplateId: "payment_structure_template_id",
            initialDeliveryDate: "initial_delivery_date",
            totalPurchaseAmountDollars: "total_purchase_amount__dollars",
            notes: "notes"
        }

        var schemaName = schemas.contract
        var tableName = "equipment_purchase"

        var displayNameSingular = "Equipment Purchase Contract"
        var displayNamePlural = "Equipment Purchase Contracts"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [Plant, OEM, EquipmentPaymentStructureTemplate]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }


    buildTableProps = (data) => {

        var plantRecords = this.findTableObjectRecords(data, Plant)
        var templateRecords = this.findTableObjectRecords(data, EquipmentPaymentStructureTemplate)
        var OEMRecords = this.findTableObjectRecords(data, OEM)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var templateIdCol = EquipmentPaymentStructureTemplate.columnSchema.oid
        var templateNameCol = EquipmentPaymentStructureTemplate.columnSchema.templateName
        var OEMIdCol = OEM.columnSchema.oemId
        var OEMNameCol = "OEMName"
        
        OEMRecords = injectCounterpartyName(OEMRecords, counterpartyRecords, OEMIdCol, OEMNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid, {fixed: true}),
                    {
                        name: "Contract Name",
                        key: this.columnSchema.contractName,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Project",
                        key: this.columnSchema.projectId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(plantRecords, value, Plant.columnSchema.plantId, Plant.columnSchema.plantName),
                            dropdownItems: buildDropdownItems(plantRecords, Plant.columnSchema.plantId, Plant.columnSchema.plantName)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        }
                    }, {
                        name: "OEM",
                        key: this.columnSchema.OEMId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(OEMRecords, value, OEMIdCol, OEMNameCol),
                            dropdownItems: buildDropdownItems(OEMRecords, OEMIdCol, OEMNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        }
                    }, {
                        name: "Payment Structure Template",
                        key: this.columnSchema.paymentStructureTemplateId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(templateRecords, value, templateIdCol, templateNameCol),
                            dropdownItems: buildDropdownItems(templateRecords, templateIdCol, templateNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        }
                    }, {
                        name: "Initial Delivery Date",
                        key: this.columnSchema.initialDeliveryDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 350,
                            resizable: true,
                        }
                    }, {
                        name: "Total Purchase Amount ($)",
                        key: this.columnSchema.totalPurchaseAmountDollars,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const EquipmentPurchaseContract = new EquipmentPurchaseContractTable()

class EquipmentDeliveryPaymentMilestoneTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            contractId: "contract_id",
            paymentRuleId: "payment_rule_id",
            milestone: "milestone",
            dueDate: "due_date",
            amountDollars: "amount__dollars",
            notes: "notes"
        }

        var schemaName = schemas.contract
        var tableName = "equipment_delivery_payment_milestones"

        var displayNameSingular = "Equipment Delivery Payment Milestone"
        var displayNamePlural = "Equipment Delivery Payment Milestones"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [EquipmentPurchaseContract, EquipmentPaymentRule]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )
        this.allowProjectDisplay = false
    }


    buildTableProps = (data) => {

        var contractRecords = this.findTableObjectRecords(data, EquipmentPurchaseContract)
        var paymentRuleRecords = this.findTableObjectRecords(data, EquipmentPaymentRule)

        var contractIdCol = EquipmentPurchaseContract.columnSchema.oid
        var contractNameCol = EquipmentPurchaseContract.columnSchema.contractName
        var paymentRuleIdCol = EquipmentPaymentRule.columnSchema.oid
        var paymentRuleNameCol = EquipmentPaymentRule.columnSchema.milestone
        
        return {
            itemSchema: this.buildNewRecord(),
            buildRequestBody: this.buildRequestBody.bind(this),
            tableConfig: {
                columns: [
                    makeEntityId(this.columnSchema.oid),
                    {
                        name: "Contract",
                        key: this.columnSchema.contractId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contractRecords, value, contractIdCol, contractNameCol),
                            dropdownItems: buildDropdownItems(contractRecords, contractIdCol, contractNameCol)
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        }
                    }, {
                        name: "Payment Rule",
                        key: this.columnSchema.paymentRuleId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(paymentRuleRecords, value, paymentRuleIdCol, paymentRuleNameCol),
                            dropdownItems: (record) => {
                                const contractId = record[this.columnSchema.contractId]
                                const contract = contractRecords.find(x => x[contractIdCol]===contractId)
                                if (!contract) return []
                                const paymentStructureId = contract[EquipmentPurchaseContract.columnSchema.paymentStructureTemplateId]
                                const filteredPaymentRules = paymentRuleRecords.filter(x => x[EquipmentPaymentRule.columnSchema.paymentStructureTemplateId]===paymentStructureId)
                                return buildDropdownItems(filteredPaymentRules, paymentRuleIdCol, paymentRuleNameCol)
                            },
                            transformNewValue: (newValue, newValueColumn, record, items) => {
                                const baseChange = {[newValueColumn]: newValue}

                                const contractId = record[this.columnSchema.contractId]
                                const contract = contractRecords.find(x => x[contractIdCol]===contractId)
                                if (!contract) return baseChange
                                
                                const paymentRuleId = newValue
                                const paymentRule = paymentRuleRecords.find(x => x[paymentRuleIdCol]===paymentRuleId)
                                if (!paymentRule) return baseChange

                                const result = processPaymentRule(contract, paymentRule, items)
                                Object.assign(baseChange, result)
                                return baseChange
                            }
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 300,
                            resizable: true,
                        }
                    }, {
                        name: "Milestone",
                        key: this.columnSchema.milestone,
                        cellType: "editable",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Due Date",
                        key: this.columnSchema.dueDate,
                        cellType: "date",
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    }, {
                        name: "Amount ($)",
                        key: this.columnSchema.amountDollars,
                        cellType: "editable",
                        cellOptions: {
                            type: "number"
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 250,
                            resizable: true,
                        }
                    },
                    makeNotes(this.columnSchema.notes)
                ]
            },
        }
    }

}
export const EquipmentDeliveryPaymentMilestone = new EquipmentDeliveryPaymentMilestoneTable()



export const processPaymentRule = (contract, paymentRule, paymentMilestones) => {
    
    const paymentRuleDueDateFormula = paymentRule[EquipmentPaymentRule.columnSchema.dueDateFormula]
    const paymentRuleAmountFormula = paymentRule[EquipmentPaymentRule.columnSchema.amountFormula]

    const contractInitialDeliveryDate = contract[EquipmentPurchaseContract.columnSchema.initialDeliveryDate]
    const formattedContractInitialDeliveryDate = contractInitialDeliveryDate ? `new Date("${contractInitialDeliveryDate}${typeof(contractInitialDeliveryDate)==="string" ? 'T00:00:00' : ''}")` : null
    const context = {
        contract: {
            paymentMilestones: Object.fromEntries(paymentMilestones.filter(x => x[EquipmentDeliveryPaymentMilestone.columnSchema.contractId]===contract[EquipmentPurchaseContract.columnSchema.oid]).map(paymentMilestone => {
                const dueDate = paymentMilestone[EquipmentDeliveryPaymentMilestone.columnSchema.dueDate]
                const formattedDueDate = dueDate ? `new Date("${dueDate}${typeof(dueDate)==="string" ? 'T00:00:00' : ''}")` : null
                return [
                    paymentMilestone[EquipmentDeliveryPaymentMilestone.columnSchema.paymentRuleId], {
                    amountDollars: paymentMilestone[EquipmentDeliveryPaymentMilestone.columnSchema.amountDollars],
                    dueDate: formattedDueDate
                }]
            })),
            totalPurchaseAmountDollars: contract[EquipmentPurchaseContract.columnSchema.totalPurchaseAmountDollars],
            initialDeliveryDate: formattedContractInitialDeliveryDate
        }
    }

    const change = {}

    if (paymentRuleAmountFormula) {
        const templatedValues = paymentRuleAmountFormula.match(/{{.*?}}/g)
        let nullFieldFlag = false
        const amountFormulaSubbed = templatedValues.reduce((prev, cur) => {
            const expression = cur.slice(2, -2)
            const result = executeFunction(wrapFunctionExpression(expression), context)
            if (result===null) nullFieldFlag = true   // If any field is null don't evaluate the 
            return prev.replace(cur, result)
        }, paymentRuleAmountFormula)
        if (!nullFieldFlag) {
            const milestoneAmount = executeFunction(wrapFunctionExpression(amountFormulaSubbed))
            change[EquipmentDeliveryPaymentMilestone.columnSchema.amountDollars] = milestoneAmount
        }
    }
    if (paymentRuleDueDateFormula) {
        const templatedValues = paymentRuleDueDateFormula.match(/{{.*?}}/g)
        let nullFieldFlag = false
        const dueDateFormulaSubbed = templatedValues.reduce((prev, cur) => {
            const expression = cur.slice(2, -2)
            const result = executeFunction(wrapFunctionExpression(expression), context)
            if (result===null) nullFieldFlag = true   // If any field is null don't evaluate the 
            return prev.replace(cur, result)
        }, paymentRuleDueDateFormula)
        if (!nullFieldFlag) {
            const milestoneDueDate = executeFunction(wrapFunctionExpression(dueDateFormulaSubbed), {
                subDate, subMilliseconds, subSeconds, subMinutes, subHours, subDays, subWeeks, subMonths, subQuarters, subYears,
                addDate, addMilliseconds, addSeconds, addMinutes, addHours, addDays, addWeeks, addMonths, addQuarters, addYears
            })
            change[EquipmentDeliveryPaymentMilestone.columnSchema.dueDate] = milestoneDueDate
        }
    }
    return change
}

const executeFunction = (body, functionArgs={}) => {
    return new Function(...Object.keys(functionArgs), body)(...Object.values(functionArgs))
}
const wrapFunctionExpression = (code) => {
    return `"use strict"; return (${code})`
}