import './AppSelector.css'
import {Component} from 'react'
import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

import App from '../../App';
import DatabaseApp from '../../DatabaseApp'


class AppSelector extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    render() {

        var user = this.props.services.auth.user
        var isSuperuser = user.is_superuser
        var user_perms = user.permissions || []
        var palantir_access = user_perms.includes("app_access_palantir") || isSuperuser
        var database_access = user_perms.includes("app_access_database") || isSuperuser

        var content = palantir_access || database_access ? <div className="flow-horizontal fill-parent application-list">
            {palantir_access && <a href="palantir" className="app-choice">Palantir</a>}
            {database_access && <a href="precious" className="app-choice">Database</a>}
        </div> : <div className="flow-horizontal fill-parent application-list" ><span className="no-apps">Sorry you don't have access to any applications at this time.</span></div>

        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact>
                        <div className="flow-vertical" style={{overflow: "auto"}}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "150px", fontSize: "40px"}}>Select an Application</div>
                            <div style={{textAlign: "center", marginBottom: "20px", fontSize: "1.8em"}}>OR</div>
                            <div className="logout" onClick={this.props.services.auth.logout} style={{textAlign: "center"}}>
                                <span style={{marginRight: "5px"}}>Logout</span>
                                <FontAwesomeIcon icon={faRightFromBracket} size="lg" className="fa-icon" />
                            </div>
                            {content}
                        </div>
                    </Route>
                    <Route path="/precious">
                        <DatabaseApp services={this.props.services} />
                    </Route>
                    <Route path="/palantir">
                        <App services={this.props.services} />
                    </Route>
                </Switch>
            </BrowserRouter>
        )
    }
}

export default AppSelector