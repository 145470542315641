import {Component} from 'react'

import DatePicker from '../../date_picker/DateRangePicker'
import DateTimePicker from '../../date_picker/DateTimePicker'
import ResolutionDropdown from '../../resolution_dropdown/ResolutionDropdown'
import StandardDataProvider from '../../data_provider/StandardDataProvider'
import Panel from '../../panel/Panel'
import { formatDate } from '../../../utils/Dates'
import BaseChart from '../../charts/BaseChart'

import { getStartTime, setStartTime, getEndTime, setEndTime, getResolution, setResolution } from '../../../utils/UrlLocation'
import { constructAssetFiltersFromSelections } from '../../../utils/AssetFilter'

export const HistoryContainer1 = () => {



}

class HistoryContainer extends Component {

    constructor(props) {
        super()

        var nowFloor = new Date()
        nowFloor.setMinutes(0)
        nowFloor.setSeconds(0)
        nowFloor.setMilliseconds(0)
        nowFloor = formatDate(nowFloor)

        this.state = {
            selectedStartDate: getStartTime(),
            selectedEndDate: getEndTime(),
            selectedResolution: getResolution(),
            selectedForecastTime: nowFloor,
        }
    }

    render() {

        // Tag must be selected to render this content
        if (this.props.selectedSourceTagIds.length===0 && this.props.selectedIntervalTagIds.length===0) {
            return <Panel>Please select a tag</Panel>
        }

        const assetFrameworkAPI = this.props.services.assetFramework

        var devices = []
        const selectedDeviceIds = this.props.selectedDeviceGroupId ? this.props.services.assetFramework.getDeviceGroupDeviceIds(this.props.services.assetFramework.getDeviceGroup(this.props.selectedDeviceGroupId)).map(x => x._id) : this.props.selectedDeviceIds
        var devices = assetFrameworkAPI.getDevices().filter(x => x.site_id===this.props.selectedSiteId).filter(x => selectedDeviceIds.includes(x._id))

        const unqDeviceTypeIds = new Set(devices.map(x => x.device_type))
        const unqDeviceTypes = assetFrameworkAPI.getDeviceTypes().filter(x => unqDeviceTypeIds.has(x._id))
        var includeForecast = unqDeviceTypes.reduce((prev, cur) => prev||cur.config?.forecast, false)

        try {
            var assetFilters = constructAssetFiltersFromSelections(
                assetFrameworkAPI.getSites(),
                assetFrameworkAPI.getDeviceTypes(),
                devices,
                this.props.selectedSiteId,
                {
                    source: this.props.selectedSourceTagIds,
                    interval: this.props.selectedIntervalTagIds,
                },
                {
                    source: this.props.selectedSourceDimensionIds,
                    interval: this.props.selectedIntervalDimensionIds,
                },
                (includeForecast ? this.state.selectedForecastTime : null)
            )
        }
        catch (error) {
            console.log(error)
            return <Panel>{error.message}</Panel>
        }

        console.log(assetFilters)

        var chartProviderProps = {
            services: this.props.services,
            selectedSiteId: this.props.selectedSiteId,
            selectedDeviceIds: this.props.selectedDeviceIds,
            selectedSourceTagIds: this.props.selectedSourceTagIds,
            selectedSourceDimensionIds: this.props.selectedSourceDimensionIds,
            selectedIntervalTagIds: this.props.selectedIntervalTagIds,
            selectedIntervalDimensionIds: this.props.selectedIntervalDimensionIds,
            selectedStartDate: this.state.selectedStartDate,
            selectedEndDate: this.state.selectedEndDate,
            selectedResolution: this.state.selectedResolution,
        }

        var historicalChartProvider = <StandardDataProvider
                                            {...chartProviderProps}
                                            queryUrl={'/api/history'}
                                            assetFilters={assetFilters}
                                            queryProps={["assetFilters", "selectedStartDate", "selectedEndDate", "selectedResolution"]}
                                            render={(data, dataRequestId) => this.constructHighchart(data, dataRequestId)}
                                            
                                        />

        return (
            <div className="flow-vertical fill-parent">
                <div className="flow-horizontal" style={{flexShrink: 0}} >
                    <div className="option">
                        <div className="label">Time Range</div>
                        <DatePicker
                            services={this.props.services}
                            startDate={this.state.selectedStartDate}
                            endDate={this.state.selectedEndDate}
                            onValueChange={this.updateSelectedTimeRange.bind(this)}
                            containerProps={{
                                appearance: "subtle",
                                style: {
                                    "marginLeft": "10px",
                                    "marginRight": "10px",
                                }
                            }}
                        />
                    </div>
                    <div className="option">
                        <div className="label">Resolution</div>
                        <ResolutionDropdown 
                            onSelectItem={this.updateSelectedResolution.bind(this)} 
                            resolutionOptions={["raw", "5m", "15m", "1H", "1D"]} 
                            selectedResolution={this.state.selectedResolution}
                            containerProps={{
                                appearance: "subtle"
                            }}
                        />
                    </div>
                    <div className="fill-parent"></div>
                    {includeForecast && <div className="option" style={{position: "relative", right: "25px"}}>
                        <div className="label">Forecast Time</div>
                        <DateTimePicker 
                            services={this.props.services}
                            date={this.state.selectedForecastTime}
                            ranges={[]}
                            onValueChange={this.updateSelectedForecastTime.bind(this)}
                            appearance="subtle"
                            placement="bottomEnd"
                        />
                    </div>}
                </div>
                
                {historicalChartProvider}
                
            </div>
        )
    }

    constructHighchart(data, dataRequestId) {
        return (
            <BaseChart
                services={this.props.services}
                timezone={this.props.services.assetFramework.getSites().find(x => x._id===this.props.selectedSiteId).meta.timezone}
                dataIsRaw={true}
                data={data}
                dataRequestId={dataRequestId}
                dataExportTitle={`Historical ${this.state.selectedStartDate} - ${this.state.selectedEndDate}`}
                compareData
            />
        )
    }

    updateSelectedTimeRange(start, end) {

        //start = this.props.services.dates.formatDate(start)
        //end = this.props.services.dates.formatDate(end)

        setStartTime(start)
        setEndTime(end)
        this.setState({
            selectedStartDate: start,
            selectedEndDate: end
        })
        //this.fetchData()
    }

    updateSelectedResolution(resolution) {
        setResolution(resolution)
        this.setState({
            selectedResolution: resolution
        })
        //this.fetchData()
    }

    updateSelectedForecastTime(date) {
        this.setState({
            selectedForecastTime: date
        })
    }

}

export default HistoryContainer