import { injectCounterpartyName } from "../../table_configuration/Counterparty"
import { injectedChangeLogIdCol } from "../../../hooks/changeLog"

/**
 * 
 * @param {*} contractRecords all contract records
 * @param {*} contractCounterpartyRecords all contract counterparty records
 * @param {*} counterpartyRecords all counterparty records
 * @param {*} plantId plant ID to filter contracts by
 * @param {*} contractIdCol column name of ID in contract records
 * @param {*} contractPlantIdCol column name of plant ID in contract records
 * @param {*} contractCounterpartyIdCol column name of contract ID contract counterparty records
 * @param {*} counterpartyIdCol column name of counterparty ID in contract counterparty table
 * @param {*} counterpartyNameCol key to use for the injected counterparty name into contract counterparty records
 * @returns 
 */
export const getContractCounterpartyData = (contractRecords, contractCounterpartyRecords, counterpartyRecords, plantId, contractIdCol, contractPlantIdCol, contractCounterpartyIdCol, contractCounterpartyCounterpartyIdCol, counterpartyNameCol) => {
    const [filteredContractRecords, filteredContractIds] = prepareContractRecords(contractRecords, plantId, contractIdCol, contractPlantIdCol)
    const initialPlantContractCounterparties = prepareContractCounterpartyRecords(filteredContractIds, contractCounterpartyRecords, counterpartyRecords, contractCounterpartyIdCol, contractCounterpartyCounterpartyIdCol, counterpartyNameCol)
    return [filteredContractRecords, initialPlantContractCounterparties]
}

/**
 * Filters input contracts belonging to specified plant.
 * @param {*} contractRecords 
 * @param {*} plantId 
 * @param {*} contractIdCol 
 * @param {*} contractPlantIdCol 
 * @returns 
 */
export const prepareContractRecords = (contractRecords, plantId, contractIdCol, contractPlantIdCol) => {
    const filteredContractRecords = contractRecords.filter(contract => contract[contractPlantIdCol]===plantId)
    const contractIds = filteredContractRecords.map(x => x[contractIdCol])
    return [filteredContractRecords, contractIds]
}

/**
 * Adds counterparty name fields to the input contract counterparty records and filters records belonging to specified contract
 * @param {*} validContractIds 
 * @param {*} contractCounterpartyRecords 
 * @param {*} counterpartyRecords 
 * @param {*} contractCounterpartyIdCol 
 * @param {*} contractCounterpartyCounterpartyIdCol
 * @param {*} counterpartyNameCol 
 * @returns 
 */
export const prepareContractCounterpartyRecords = (validContractIds, contractCounterpartyRecords, counterpartyRecords, contractCounterpartyIdCol, contractCounterpartyCounterpartyIdCol, counterpartyNameCol) => {
    const allContractCounterparties = injectCounterpartyName(contractCounterpartyRecords, counterpartyRecords, contractCounterpartyCounterpartyIdCol, counterpartyNameCol)
    const initialPlantContractCounterparties = allContractCounterparties.filter(record => validContractIds.includes(record[contractCounterpartyIdCol]))
    return initialPlantContractCounterparties
}

export const validateCreateContractCounterparty = (contractCounterpartyClass, contractCounterpartyIdCol, record, newRecord) => {
    console.log(contractCounterpartyClass, record, newRecord)
    // Ensure any previously added counterparty fields in the same contract have been asigned a counterparty
    if (record[contractCounterpartyClass.columnSchema.contractId]===newRecord[contractCounterpartyClass.columnSchema.contractId]) {
        return record[contractCounterpartyIdCol]!==null
    }
    return true
}


export const buildContractNameErrorConfig = (ContractTable) => {
    return {
        checkColumn: ContractTable.columnSchema.contractName,
        checkFunction: "nullCheck",
        errMessage: "Please give the contract a name.",
        path: buildContractFieldErrorPath(ContractTable, ContractTable.columnSchema.contractName)
    }
}
export const buildContractFieldErrorPath = (ContractTable, colName) => {
    return [
        {name: ContractTable.columnSchema.contractId, type: "eval"},
        {name: colName, type: "static"},
    ]
}
export const buildContractDependencyErrorPath = (DependencyTable, colName) => {
    return [
        {name: DependencyTable.columnSchema.contractId, type: "eval"},
        {name: DependencyTable.buildId(), type: "static"},
        {name: injectedChangeLogIdCol, type: "eval"},
        {name: colName, type: "static"},
    ]
}