import './CustomRenderCell.css'

import { Table } from 'rsuite'
const { Cell } = Table

export default function CustomRenderCell({ rowData, dataIdKey, dataKey, onChange, render, isEditingFunction, transformDisplayValueFunction, ...props }) {

    var itemId = rowData[dataIdKey]

    var isEditing = isEditingFunction(itemId)

    var value = rowData[dataKey]
    var displayValue = transformDisplayValueFunction(value)
    
    return (
        <Cell {...props} className={isEditing ? 'table-content-editing external-edit-cell' : 'external-edit-cell'}>
            <span>{displayValue}</span>
            {isEditing &&
                render(rowData, ({newData, dataId=itemId, colKey=dataKey}) => onChange(dataId, colKey, newData), value)
            }
        </Cell>
    );
};