import { useState } from 'react'
import _ from 'lodash'

export const TabPanel = (props) => {
    const { children, visitedTabValues, selectedTabValue, tabValue, ...other } = props;
  
    return (
        <div
            style={{display: tabValue===selectedTabValue ? "block" : "none"}}
            className="flow-vertical"
            {...other}
        >
            {visitedTabValues.has(tabValue) && children}
        </div>
    );
}

export const useTabCache = () => {
    const [selectedTabValue, setSelectedTabValue] = useState(0);
    const [visitedTabValues, setVisitedTabValues] = useState(new Set([0]))
    const addVisitedTab = (tabValue) => {
        if (!visitedTabValues.has(tabValue)) {
            let newSet = _.cloneDeep(visitedTabValues)
            newSet.add(tabValue)
            setVisitedTabValues(newSet)
        }
    }
    return [selectedTabValue, visitedTabValues, addVisitedTab, setSelectedTabValue]
}