import {Component} from 'react'

import DatePicker from '../../../date_picker/DateRangePicker'
import Panel from '../../../panel/Panel'
import StandardDataProvider from '../../../data_provider/StandardDataProvider'

import { formatDate } from '../../../../utils/Dates'
import { startOfDay, endOfDay } from 'date-fns'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { Whisper, Tooltip } from 'rsuite'

import MarketTable from './MarketTable'

class SummaryContent extends Component {

    constructor(props) {
        super()
        this.state = {
            selectedStartDate: formatDate(startOfDay(new Date())),
            selectedEndDate: formatDate(endOfDay(new Date())),
        }
    }

    componentDidMount() {
        console.log("Summary content mounted")
    }

    render() {

        if (!this.props.selectedSiteId) {
            return <Panel className="section-main">Please select a site</Panel>
        }

        // Tab is only available for sites with a battery
        var selectedSite = this.props.services.assetFramework.getSites().find(x => x._id===this.props.selectedSiteId)
        if (!["stand_alone_storage", "hybrid_solar_plus_storage", "storage", "hybrid_solar_storage"].includes(selectedSite.meta.technology)){
            return <Panel>This dashboard is only available for sites with a battery</Panel>
        }

        var MarketTableDataProvider = <StandardDataProvider
                                            services={this.props.services}
                                            selectedSiteId={this.props.selectedSiteId}
                                            selectedStartDate={this.state.selectedStartDate}
                                            selectedEndDate={this.state.selectedEndDate}
                                            queryProps={["selectedSiteId", "selectedStartDate", "selectedEndDate"]}
                                            queryUrl={'/api/market/table'}
                                            render={(data) => <MarketTable services={this.props.services} data={data} />}
                                        />

        const tooltip = <Tooltip>
                            <p style={{textAlign: 'left'}}>Table header numbers represent hours of the day. Note that these are hours <i>ending</i>.</p>
                            <p style={{textAlign: 'left'}}>All values are averaged over the time interval, except for Revenue which is summed.</p>
                            <p style={{textAlign: 'left'}}>Note that you may select a date range greater than 1 day, in which values will be averaged over the range.</p>
                            <div style={{marginTop: '10px'}}>
                                <div style={{display: 'flex', margin: '0 0 5px 0'}}>
                                    <span style={{backgroundColor: 'var(--light)', height: '20px', width: '40px', display: 'block', marginRight: '5px'}}></span><span>Forecast</span>
                                </div>
                                <div style={{display: 'flex', margin: '0 0 5px 0'}}>
                                    <span style={{backgroundColor: 'var(--accent)', height: '20px', width: '40px', display: 'block', marginRight: '5px'}}></span><span>Current</span>
                                </div>
                                <div style={{display: 'flex', margin: '0 0 5px 0'}}>
                                    <span style={{backgroundColor: 'var(--light-dark)', height: '20px', width: '40px', display: 'block', marginRight: '5px'}}></span><span>Actual</span>
                                </div>
                            </div>
                        </Tooltip>

        return (
            <div className="section-main flow-vertical fill-parent">
                <div class="flow-horizontal flex-fixed" style={{alignItems: 'center'}}>
                    <div className="fill-parent"></div>
                    <span style={{flexShrink: 0, fontSize: '1.4em'}}>Market Table</span>
                    <div className="fill-parent"></div>
                </div>
                <div className="flow-horizontal flex-fixed" style={{alignItems: 'center'}}>
                    <div className="fill-parent"></div>
                    <DatePicker 
                        services={this.props.services}
                        startDate={this.state.selectedStartDate}
                        endDate={this.state.selectedEndDate}
                        onValueChange={this.updateSelectedDates.bind(this)}
                    />
                    <div className="fill-parent"></div>
                    <Whisper placement="leftStart" trigger="hover" speaker={tooltip}>
                        <AiOutlineQuestionCircle size={'3em'} />
                    </Whisper>
                </div>
                {MarketTableDataProvider}
            </div>
        )
    }

    updateSelectedDates(start, end) {
        this.setState({
            selectedStartDate: start,
            selectedEndDate: end
        })
    }

}

export default SummaryContent