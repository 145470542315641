import { Plant, TechnicalDueDiligence } from "../../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from "../../../components/input/Text"
import { PalantirBooleanSelector, PalantirDatePicker, PalantirSelector, } from "../../../components/input/SelectPicker"
import { useChangeLog } from "../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../hooks/databaseViewFormValidation"
import ViewPanel from "../ViewPanel"
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from "../../../components/button/FloaterButtonWithPrompt"
import { Text } from "@mantine/core"
import { HelpPrompt } from "../../../components/help/HelpPrompt"
import { HelpDryBulbTemp, HelpRainfall, HelpSnowLoad, HelpWindLoad, HelpSeismic, HelpFlood, HelpWetlands, HelpBiological, HelpProjectCOD, HelpProjectFinancialClose, HelpProjectFNTP, HelpProjectTermConversion } from "../../../components/help/precious/Columns"
import { EPCContract, OfftakeContract } from "../../table_configuration/Contract"


export default function ProjectMilestoneDatesPanelProxy(props) {

    const selectedProject = props.selectedPlant
    const epcContracts = props.data[EPCContract.buildId()].filter(x => x[EPCContract.columnSchema.plantId]===props.selectedPlantId)
    const offtakeContracts = props.data[OfftakeContract.buildId()].filter(x => x[OfftakeContract.columnSchema.plantId]===props.selectedPlantId)

    return (
        <ProjectTDDPanel
            initialProject={selectedProject}
            initialEPCContracts={epcContracts}
            initialOfftakecontracts={offtakeContracts}
            {...props}
        />
    )
}

function ProjectTDDPanel({initialProject, initialEPCContracts, initialOfftakecontracts, ...props}) {

    const [
        projects, prepareProjectLog, updateProjectLog, addToProjectLog,
        deleteFromProjectLog, bulkOpOnProjectLog, mergeAndResetProjectLog
    ] = useChangeLog(initialProject!==null ? [initialProject] : [], Plant)
    const [
        EPCContracts, prepareEPCContractLog, updateEPCContractLog, addToEPCContractLog,
        deleteFromEPCContractLog, bulkOpOnEPCContractLog, mergeAndResetEPCContractLog
    ] = useChangeLog(initialEPCContracts, EPCContract)
    const [
        OfftakeContracts, prepareOfftakeContractLog, updateOfftakeContractLog, addToOfftakeContractLog,
        deleteFromOfftakeContractLog, bulkOpOnOfftakeContractLog, mergeAndResetOfftakeContractLog
    ] = useChangeLog(initialOfftakecontracts, EPCContract)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    // Projects length is always 1
    const project = projects[0]

    const updateProject = (update) => updateProjectLog(project, update)

    return (
        <ViewPanel
            services={props.services}
            title="Milestone Dates"
            submitUrl='api/precious/table/project_milestone_dates'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission({
                    changeLog: payload.projectLog,
                    checks: []
                }, {
                    changeLog: payload.EPCContractLog,
                    checks: []
                }, {
                    changeLog: payload.OfftakeContractLog,
                    checks: []
                })
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetProjectLog()
                mergeAndResetEPCContractLog()
                mergeAndResetOfftakeContractLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    projectLog: prepareProjectLog(),
                    EPCContractLog: prepareEPCContractLog(),
                    OfftakeContractLog: prepareOfftakeContractLog()
                }
            }}
        >
            <div className="flow-vertical form-instance" style={{minWidth: "600px"}}>
                <Text weight={500} mb={10} size="xl" style={{}}>Project</Text>
                <div className="flow-horizontal" style={{marginBottom: "15px"}}>
                    <PalantirTextField
                        label="Project Name"
                        value={project[Plant.columnSchema.plantName]}
                        disabled
                        style={{flexShrink: 0, marginBottom: "5px", marginRight: "15px"}}
                    />
                    <PalantirTextField
                        label="Project Status"
                        value={project[Plant.columnSchema.stage]}
                        disabled
                        style={{flexShrink: 0, marginBottom: "5px", marginRight: "5px"}}
                    />
                </div>
                <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                    <DatesSegment>
                        <div className="flow-horizontal">
                            <Text weight={500} mb={10} size="lg" style={{}}>COD</Text>
                            <HelpPrompt
                                services={props.services}
                                style={{fontSize: "14px", position: "relative", top: "3px", left: "4px"}}
                                title={"COD"}
                                item={<HelpProjectCOD />}
                            />
                        </div>
                        <PalantirDatePicker
                            label="Memo Approved COD"
                            value={project[Plant.columnSchema.commercialOperationDate]}
                            onChange={(date) => updateProject({[Plant.columnSchema.commercialOperationDate]: date})}
                            style={{marginBottom: "5px"}}
                        />
                        <PalantirDatePicker
                            label="p10 COD"
                            value={project[Plant.columnSchema.targetCommercialOperationDate]}
                            onChange={(date) => updateProject({[Plant.columnSchema.targetCommercialOperationDate]: date})}
                            style={{marginBottom: "5px"}}
                        />
                        <PalantirDatePicker
                            label="p50 COD"
                            value={project[Plant.columnSchema.baseCommercialOperationDate]}
                            onChange={(date) => updateProject({[Plant.columnSchema.baseCommercialOperationDate]: date})}
                            style={{marginBottom: "5px"}}
                        />
                        <PalantirDatePicker
                            label="p90 COD"
                            value={project[Plant.columnSchema.downsideCommercialOperationDate]}
                            onChange={(date) => updateProject({[Plant.columnSchema.downsideCommercialOperationDate]: date})}
                            style={{marginBottom: "5px"}}
                        />
                        <PalantirDatePicker
                            label="Actual COD"
                            value={project[Plant.columnSchema.plannedCommercialOperationDate]}
                            onChange={(date) => updateProject({[Plant.columnSchema.plannedCommercialOperationDate]: date})}
                            style={{marginBottom: "5px"}}
                        />
                        <Text color="dimmed" size="xs" mb={5}>*Below dates belong to an Offtake Contract</Text>
                        {OfftakeContracts.map(contract => {
                            return (
                                <div style={{display: "flex", flexGrow: 1}}>
                                    <PalantirTextField
                                        label="Offtake Contract Name"
                                        value={contract[OfftakeContract.columnSchema.contractName]}
                                        disabled
                                        style={{flexShrink: 0, marginBottom: "5px", marginRight: "5px"}}
                                    />
                                    <PalantirDatePicker
                                        label="Contractual COD"
                                        value={contract[OfftakeContract.columnSchema.contractualCOD]}
                                        onChange={(date) => updateOfftakeContractLog(contract, {[OfftakeContract.columnSchema.contractualCOD]: date})}
                                        style={{flexGrow: 1}}
                                    />
                                </div>
                            )
                        })}
                    </DatesSegment>
                    <DatesSegment>
                        <div className="flow-horizontal">
                            <Text weight={500} mb={10} size="lg" style={{}}>Financial Close</Text>
                            <HelpPrompt
                                services={props.services}
                                style={{fontSize: "14px", position: "relative", top: "3px", left: "4px"}}
                                title={"Financial Close"}
                                item={<HelpProjectFinancialClose />}
                            />
                        </div>
                        <PalantirDatePicker
                            label="Memo Approved FC"
                            value={project[Plant.columnSchema.memoApprovedFinancialCloseDate]}
                            onChange={(date) => updateProject({[Plant.columnSchema.memoApprovedFinancialCloseDate]: date})}
                            style={{marginBottom: "5px"}}
                        />
                        <PalantirDatePicker
                            label="p10 FC"
                            value={project[Plant.columnSchema.targetFinancialCloseDate]}
                            onChange={(date) => updateProject({[Plant.columnSchema.targetFinancialCloseDate]: date})}
                            style={{marginBottom: "5px"}}
                        />
                        <PalantirDatePicker
                            label="p50 FC"
                            value={project[Plant.columnSchema.baseFinancialCloseDate]}
                            onChange={(date) => updateProject({[Plant.columnSchema.baseFinancialCloseDate]: date})}
                            style={{marginBottom: "5px"}}
                        />
                        <PalantirDatePicker
                            label="p90 FC"
                            value={project[Plant.columnSchema.downsideFinancialCloseDate]}
                            onChange={(date) => updateProject({[Plant.columnSchema.downsideFinancialCloseDate]: date})}
                            style={{marginBottom: "5px"}}
                        />
                        <PalantirDatePicker
                            label="Actual FC"
                            value={project[Plant.columnSchema.actualFinancialCloseDate]}
                            onChange={(date) => updateProject({[Plant.columnSchema.actualFinancialCloseDate]: date})}
                            style={{marginBottom: "5px"}}
                        />
                    </DatesSegment>
                    <DatesSegment>
                        <div className="flow-horizontal">
                            <Text weight={500} mb={10} size="lg" style={{}}>Term Conversion</Text>
                            <HelpPrompt
                                services={props.services}
                                style={{fontSize: "14px", position: "relative", top: "3px", left: "4px"}}
                                title={"Term Conversion"}
                                item={<HelpProjectTermConversion />}
                            />
                        </div>
                        <PalantirDatePicker
                            label="p50 TC"
                            value={project[Plant.columnSchema.termConversionP50]}
                            onChange={(date) => updateProject({[Plant.columnSchema.termConversionP50]: date})}
                            style={{marginBottom: "5px"}}
                        />
                        <PalantirDatePicker
                            label="p90 TC"
                            value={project[Plant.columnSchema.termConversionP90]}
                            onChange={(date) => updateProject({[Plant.columnSchema.termConversionP90]: date})}
                            style={{marginBottom: "5px"}}
                        />
                    </DatesSegment>
                    <DatesSegment>
                        <div className="flow-horizontal">
                            <Text weight={500} mb={10} size="lg" style={{}}>FNTP</Text>
                            <HelpPrompt
                                services={props.services}
                                style={{fontSize: "14px", position: "relative", top: "3px", left: "4px"}}
                                title={"FNTP"}
                                item={<HelpProjectFNTP />}
                            />
                        </div>
                        <Text color="dimmed" size="xs" mb={5}>*Below dates belong to an EPC Contract</Text>
                        {EPCContracts.map(contract => {
                            return (
                                <div style={{display: "flex"}}>
                                    <PalantirTextField
                                        label="EPC Contract Name"
                                        value={contract[EPCContract.columnSchema.contractName]}
                                        disabled
                                        style={{flexShrink: 0, marginRight: "5px"}}
                                    />
                                    <PalantirDatePicker
                                        label="Current FNTP"
                                        value={contract[EPCContract.columnSchema.fntpCurrent]}
                                        onChange={(date) => updateEPCContractLog(contract, {[EPCContract.columnSchema.fntpCurrent]: date})}
                                        style={{flexShrink: 0, marginRight: "5px"}}
                                    />
                                    <PalantirDatePicker
                                        label="Base (Budgeted) FNTP"
                                        value={contract[EPCContract.columnSchema.fntpBudgeted]}
                                        onChange={(date) => updateEPCContractLog(contract, {[EPCContract.columnSchema.fntpBudgeted]: date})}
                                        style={{flexShrink: 0}}
                                    />
                                </div>
                            )
                        })}
                    </DatesSegment>
                </div>
            </div>
        </ViewPanel>
    )
}

const DatesSegment = ({...props}) => {
    return (
        <div className="flow-vertical wrap" style={{minWidth: "220px", paddingRight: "10px", marginRight: "10px", marginBottom: "15px"}}>
            {props.children}
        </div>
    )
}