import { useState } from "react"
import { TechnicalChecklist, TechnicalChecklistItems } from "../../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField } from "../../../components/input/Text"
import { PalantirBooleanSelector, PalantirDatePicker, PalantirSelector, } from "../../../components/input/SelectPicker"
import { useChangeLog } from "../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../hooks/databaseViewFormValidation"
import ViewPanel from "../ViewPanel"
import Alerter from "../../../components/alerter/Alerter"
import { AddFloaterButtonWithPrompt } from "../../../components/button/FloaterButtonWithPrompt"
import { Text } from "@mantine/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight, faExclamation, faLink, faSquareUpRight, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { PalantirButton } from "../../../components/button/Button"
import { ErrorRND, RNDErrorInstance, ViewFormError } from "../../../utils/databaseAppUtils"
import { validateHTTPURL } from "../../../utils/MiscUtils"
import _ from "lodash"
import { convertToUTC, formatDate } from "../../../utils/Dates"


const buildHeirarchy = (item, TDSCItems) => {
    let children = _.sortBy(TDSCItems.filter(x => x.parent===item.id), TechnicalChecklistItems.columnSchema.sortOrder).map(x => buildHeirarchy(x, TDSCItems))
    return {
        item: item,
        children: children
    }
}

const buildTDSCRecord = (fields) => {
    const newTDSC = TechnicalChecklist.buildNewRecord()
    return Object.assign(newTDSC, Object.assign(fields, {[TechnicalChecklist.columnSchema.lastUpdatedAt]: new Date()}))
}

export default function ProjectTechnicalDatasiteCheckPanelProxy(props) {

    const projectTDSC = props.data[TechnicalChecklist.buildId()].filter(x => x[TechnicalChecklist.columnSchema.projectId]===props.selectedPlantId)
    const TDSCItems = props.data[TechnicalChecklistItems.buildId()]

    const itemHeirarchy = _.sortBy(TDSCItems.filter(x => x.parent===null), TechnicalChecklistItems.columnSchema.sortOrder).map(x => buildHeirarchy(x, TDSCItems))

    return (
        <ProjectTDSCPanel
            initialProjectTDSC={projectTDSC}
            TDSCItems={TDSCItems}
            itemHeirarchy={itemHeirarchy}
            {...props}
        />
    )
}

function ProjectTDSCPanel({initialProjectTDSC, TDSCItems, itemHeirarchy, updateFormChangeTracker, ...props}) {

    const [
        projectTDSCRecords, prepareLogs, updateLog, addToLog,
        deleteFromLog, bulkOpOnLog, mergeAndResetLogs
    ] = useChangeLog(initialProjectTDSC, TechnicalChecklist)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()
    const [defaultStatus, setDefaultStatus] = useState(null)
    const [overrideExistingStatus, setOverrideExistingStatus] = useState(false)

    var ctr = 2
    const makeItem = (heirarchyItem, level=0) => {

        const gridIdx = ctr
        ctr+=1
        const item = heirarchyItem.item
        const itemId = item[TechnicalChecklistItems.columnSchema.id]
        const itemName = item[TechnicalChecklistItems.columnSchema.name]
        let tdscRecord = projectTDSCRecords.find(x => x[TechnicalChecklist.columnSchema.itemId]===itemId)

        const itemErrorPath = [TechnicalChecklist.buildId(), itemId, TechnicalChecklist.columnSchema.status]

        let updateRecord
        // If there is no record for this item we should trigger a creation on update instead of an update
        if (!tdscRecord) {
            // This is a temporary record that is just used for displaying the prefilled fields. The final record is created in the callback below when the user updates this record.
            tdscRecord = buildTDSCRecord({
                [TechnicalChecklist.columnSchema.projectId]: props.selectedPlantId,
                [TechnicalChecklist.columnSchema.itemId]: itemId,
                [TechnicalChecklist.columnSchema.required]: item[TechnicalChecklistItems.columnSchema.parent] ? false : true
            })

            updateRecord = (fields) => {
                const newRecord = Object.assign(tdscRecord, fields, {[TechnicalChecklist.columnSchema.lastUpdatedAt]: new Date()})
                addToLog(newRecord)
                removeErrors(itemErrorPath)
            }
        }
        else {
            updateRecord = (fields) => {
                updateLog(tdscRecord, Object.assign(fields, {[TechnicalChecklist.columnSchema.lastUpdatedAt]: new Date()}))
                removeErrors(itemErrorPath)
            }
        }

        const itemError = errors.get(...itemErrorPath)
        const itemErrorProps = itemError ? {error: true, helperText: itemError.getMessage()} : {}

        const childrenComponents = heirarchyItem.children.map(x => makeItem(x, level+1))

        return (
            <TDSCItem
                key={itemId}
                idx={gridIdx}
                itemName={itemName}
                tdscRecord={tdscRecord}
                updateRecord={updateRecord}
                level={level}
                statusErrorProps={itemErrorProps}
            >
                {childrenComponents}
            </TDSCItem>
        )
    }
    const addItemsToLog = () => {
        const inserts = []
        const updates = []
        const errPathsToRemove = []
        
        TDSCItems.forEach((item, idx) => {
            const matchingRecord = projectTDSCRecords.find(x => x[TechnicalChecklist.columnSchema.itemId]===item[TechnicalChecklistItems.columnSchema.id])
            if (!matchingRecord) {
                inserts.push(buildTDSCRecord({
                    [TechnicalChecklist.columnSchema.projectId]: props.selectedPlantId,
                    [TechnicalChecklist.columnSchema.itemId]: item[TechnicalChecklistItems.columnSchema.id],
                    [TechnicalChecklist.columnSchema.required]: item[TechnicalChecklistItems.columnSchema.parent] ? false : true,
                    [TechnicalChecklist.columnSchema.status]: defaultStatus
                }))
                errPathsToRemove.push([TechnicalChecklist.buildId(), item[TechnicalChecklistItems.columnSchema.id]])
            }
            else if (!matchingRecord[TechnicalChecklist.columnSchema.status] || (overrideExistingStatus && matchingRecord[TechnicalChecklist.columnSchema.status]!==defaultStatus)) {
                updates.push([matchingRecord, {[TechnicalChecklist.columnSchema.status]: defaultStatus, [TechnicalChecklist.columnSchema.lastUpdatedAt]: new Date()}])
                errPathsToRemove.push([TechnicalChecklist.buildId(), item[TechnicalChecklistItems.columnSchema.id], TechnicalChecklist.columnSchema.status])
            }
        })

        if (inserts.length > 0 || updates.length > 0) {
            removeErrors(...errPathsToRemove)
            bulkOpOnLog([{
                method: "update",
                changes: updates
            }, {
                method: "insert",
                changes: inserts
            }])
        }
        else {
            Alerter.inform(`
            No changes could be made based on the selected conditions.
            `, 6000)
        }
    }
    const TDSCComponents = itemHeirarchy.map(x => makeItem(x))


    return (
        <ViewPanel
            services={props.services}
            title="Technical Datasite Checklist"
            submitUrl='api/precious/table/technical_datasite_checklist'
            verifySubmit={(payload) => {
                let newErrors = new ErrorRND()
                TDSCItems.forEach(item => {
                    const match = projectTDSCRecords.find(x => x[TechnicalChecklist.columnSchema.itemId]===item[TechnicalChecklistItems.columnSchema.id])
                    if (!match) newErrors.add(new RNDErrorInstance("Please select a status"), TechnicalChecklist.buildId(), item[TechnicalChecklistItems.columnSchema.id], TechnicalChecklist.columnSchema.status)
                })
                if (!newErrors.empty()) {
                    setErrors(newErrors)
                    throw new ViewFormError("Please correct the errors.")
                }
                verifyChangelogSubmission({
                    changeLog: payload.tdscChangeLog,
                    checks: [{
                        checkColumn: TechnicalChecklist.columnSchema.status,
                        checkFunction: "nullCheck",
                        errMessage: "Please select a status.",
                        path: [
                            {name: TechnicalChecklist.buildId(), type: "static"},
                            {name: TechnicalChecklist.columnSchema.itemId, type: "eval"},
                            {name: TechnicalChecklist.columnSchema.status, type: "static"},
                        ]
                    }]
                })
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                mergeAndResetLogs()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    tdscChangeLog: prepareLogs(),
                }
            }}
        >
            {projectTDSCRecords.length===0 ?
            <div className="flow-vertical">
                <div>This project has no Technical Datasite Checklist.</div>
                <AddFloaterButtonWithPrompt
                    onClick={() => {
                        addItemsToLog()
                    }}
                    labelContent="Click to add."
                    style={{float: "right", marginTop: "4px"}}
                    height={14}
                    width={14}
                />
            </div> :
            <div>
                <PalantirButton
                    disabled={!defaultStatus}
                    onClick={addItemsToLog}
                    style={{minWidth: "180px", marginRight: "10px"}}
                >
                    Set all item statuses to
                </PalantirButton>
                <PalantirSelector
                    value={defaultStatus}
                    items={TechnicalChecklist.options.status}
                    onChange={(x) => setDefaultStatus(x)}
                    style={{marginTop: "7px"}}
                />
                <PalantirBooleanSelector
                    checked={overrideExistingStatus}
                    onChange={x => setOverrideExistingStatus(x)}
                    label="Override existing items."
                />
                <div className="form-instance" style={{minWidth: "1100px"}}>
                    <div style={{display: "grid", gridTemplateColumns: "300px 75px 120px 150px 150px", gridTemplateRows: "50px auto", columnGap: "20px"}}>
                        <div style={{gridRow: 1, gridColumn: 1}}><Text size="lg" style={{marginLeft: "22px", borderBottom: "solid grey 1px"}}>Item</Text></div>
                        <div style={{gridRow: 1, gridColumn: 2}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Required</Text></div>
                        <div style={{gridRow: 1, gridColumn: 3}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Status</Text></div>
                        <div style={{gridRow: 1, gridColumn: 4}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Document Link</Text></div>
                        <div style={{gridRow: 1, gridColumn: 5}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Last Update</Text></div>
                        <div style={{gridRow: 1, gridColumn: 6}}><Text size="lg" style={{borderBottom: "solid grey 1px"}}>Comments</Text></div>
                        {TDSCComponents}
                    </div>
                </div>
            </div>}
        </ViewPanel>
    )
}

const TDSCItem = ({tdscRecord, idx, itemName, updateRecord, level, children, statusErrorProps}) => {

    const [showChildren, setShowChildren] = useState(false)
    const toggleShowChildren = () => {
        setShowChildren((x) => !x)
    }

    const required = tdscRecord[TechnicalChecklist.columnSchema.required]
    const status = tdscRecord[TechnicalChecklist.columnSchema.status]
    const comments = tdscRecord[TechnicalChecklist.columnSchema.comments]
    const documentLink = tdscRecord[TechnicalChecklist.columnSchema.documentLink]
    const documentLinkIsValid = validateHTTPURL(documentLink)
    
    let lastUpdatedAt = tdscRecord[TechnicalChecklist.columnSchema.lastUpdatedAt]
    if (typeof(lastUpdatedAt)==="string") lastUpdatedAt = new Date(lastUpdatedAt+"Z")
    if (isNaN(lastUpdatedAt)) lastUpdatedAt = null
    if (lastUpdatedAt!=null || lastUpdatedAt!=undefined) lastUpdatedAt = formatDate(lastUpdatedAt)

    const hasChildren = children.length > 0
    const margin = level*20 + (!hasChildren ? 22 : 0)
    const width = 300 - margin - (hasChildren ? 22 : 0)
    
    return (
        <>
            <div style={{gridColumn: 1, gridRow: idx, marginLeft: `${margin}px`}}>
                {hasChildren && <FontAwesomeIcon onClick={toggleShowChildren} icon={showChildren ? faAngleDown : faAngleRight} style={{marginRight: "12px", width: "10px", position: "relative", top: "6px"}} />}
                <PalantirTextField
                    value={itemName}
                    disabled
                    style={{width: `${width}px`}}
                />
            </div>
            <PalantirBooleanSelector 
                label=""
                checked={required}
                onChange={(newRequired) => updateRecord({[TechnicalChecklist.columnSchema.required]: newRequired})}
                style={{gridColumn: 2, gridRow: idx}}
            />
            <PalantirSelector
                value={status}
                items={TechnicalChecklist.options.status}
                onChange={(newStatus) => updateRecord({[TechnicalChecklist.columnSchema.status]: newStatus})}
                style={{gridColumn: 3, gridRow: idx, minWidth: "120px"}}
                {...statusErrorProps}
            />
            <div className="flow-horizontal" style={{gridColumn: 4, gridRow: idx}}>
                <PalantirDispatchedTextField
                    value={documentLink}
                    onChange={(newLink) => updateRecord({[TechnicalChecklist.columnSchema.documentLink]: newLink})}
                    variant="standard"
                    style={{flexGrow: 1}}
                />
                {documentLink && 
                    (documentLinkIsValid ? 
                        <a href={documentLink} target="__blank"><FontAwesomeIcon icon={faUpRightFromSquare} style={{position: "relative", top: "10px", marginLeft: "8px"}} /></a> :
                        <FontAwesomeIcon icon={faExclamation} color="red" style={{position: "relative", top: "12px", right: "2px", width: "8px", marginLeft: "10px"}} />
                    )
                }
            </div>
            <PalantirTextField
                disabled
                value={lastUpdatedAt}
                onChange={(newComments) => updateRecord({[TechnicalChecklist.columnSchema.lastUpdatedAt]: newComments})}
                variant="standard"
                style={{gridColumn: 5, gridRow: idx}}
            />
            <PalantirDispatchedTextField
                value={comments}
                onChange={(newComments) => updateRecord({[TechnicalChecklist.columnSchema.comments]: newComments})}
                variant="standard"
                style={{flexGrow: 1, gridColumn: 6, gridRow: idx}}
            />
            {showChildren && children}
        </>
    )
}