import { useState, useRef } from 'react'
import { ErrorRND, RNDErrorInstance, ViewFormError } from '../utils/databaseAppUtils'

export const useDBViewFormValidation = () => {

    const [errors, setErrors] = useState(new ErrorRND())

    const removeErrors = (...cmds) => {
        // A 1d array should be treated as the first element in a 2d array, because a 2d array is expected
        if (cmds.length > 0 && !(cmds[0] instanceof Array)) {
            cmds = [cmds]
        }

        var newErrors = errors.clone()
        cmds.forEach(args => {
            newErrors = newErrors.remove(...args)
        })
        setErrors(newErrors)
    }
    const addErrors = (...cmds) => {
        // A 1d array should be treated as the first element in a 2d array, because a 2d array is expected
        if (cmds.length > 0 && !(cmds[0] instanceof Array)) {
            cmds = [cmds]
        }

        var newErrors = errors.clone()
        cmds.forEach(args => {
            newErrors = newErrors.add(...args)
        })
        setErrors(newErrors)
    }

    const applyCheckFunction = (value, checkFunction) => {
        // Check for alias usage
        if (typeof(checkFunction)==="string") {
            if (checkFunction==="nullCheck") checkFunction = (x) => x!==null && x!==undefined && x!==""
        }
        return checkFunction(value)
    }

    /*
    Accepts a number of submissions.
    Each submission should be an iterable of the form [Table, changeLog, columnIds, errMessage, pathIds]
    Table: TableClass of input changelog
    changeLog: changeLog to verify
    columnIds [Array|String]: verify these column value(s) are present in the changeLog records
    errMessage String: error message to display to user
    pathIds [Array|String]: field names to map to a new record that has been flagged with an error. These fields will be pulled from the record and added to the error heirarchy to identify the specific record with an error.
    */
    const verifyChangelogSubmission = (...submissions) => {

        // If current errors is not empty, init new errors off of current
        //if () throw new ViewFormError("Please fix all errors before submitting.")

        let newErrors = errors.empty() ? new ErrorRND() : errors.clone()

        // Make sure a change was made
        //verifyPayloadNotEmpty(...submissions.map(x => x[1]))
        var allChangeLogsEmpty = true

        submissions.forEach(submission => {
            let submissionChangeLog = submission.changeLog
            let submissionChecks = submission.checks

            allChangeLogsEmpty = allChangeLogsEmpty && submissionChangeLog.length===0

            submissionChangeLog.forEach(change => {
                // If something is wrong with a deleted record we shouldn't care and the user has no way to fix the error since the record is gone.
                // Client may want to perform its own validation on deleted records to enforce integrity constraints, but that is not supported here.
                if (change.instruction==="delete") return
                let newRecord = change.newRecord

                submissionChecks.forEach(check => {
                    let checkValue = check.checkColumn ? newRecord[check.checkColumn] : newRecord   // Use the record itself as input if a col value is not specified
                    let checkResult = applyCheckFunction(checkValue, check.checkFunction)
                    if (!checkResult) {
                        newErrors.add(new RNDErrorInstance(check.errMessage), ...check.path.map(pathConfig => {
                            if (pathConfig.type==="static") return pathConfig.name
                            if (pathConfig.type==="eval") return newRecord[pathConfig.name]
                            else throw "Path configuration field 'type' must be one of (static, eval)."
                        }))
                    }
                })
            })
        })

        if (allChangeLogsEmpty) throw new ViewFormError("No changes detected.")

        if (!newErrors.empty()) {
            setErrors(newErrors)
            throw new ViewFormError("Please fix all errors before submitting.")
        }
        return true
    }

    return [
        errors,
        addErrors,
        removeErrors,
        setErrors,
        verifyChangelogSubmission,
        () => setErrors(new ErrorRND()) // reset errors
    ]

}