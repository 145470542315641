import { schemas } from '../Constants'
import Table from './BaseTable'
import { transformDisplayValue, buildDropdownItems } from '../../utils/databaseAppUtils'
import { HelpLLCTable } from '../help_panels/Entity'
import { makeLLCId, makeLLCName, makeLLCDescription } from './CommonColumns'
import { injectCounterpartyName, OandM, Bank, Investor } from './Counterparty'
import { validateEntity, Counterparty, Address, Contact } from './Entity'


class LandownerLLCTable extends Table {

    constructor() {

        var columnSchema = {
            landownerLLCId: "landowner_llc_id",
            landownerLLCName: "landowner_llc_name",
            address: "address",
            description: "description",
        }

        var schemaName = schemas.llc
        var tableName = "landowner"

        var displayNameSingular = "Landowner LLC"
        var displayNamePlural = "Landowners"

        var pkUidColumn = columnSchema.landownerLLCId
        var identifiers = [columnSchema.landownerLLCId]
        var dependencies = [Address]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var landownerLLCRecords = this.findTableObjectRecords(data, this)

        const addressRecords = this.findTableObjectRecords(data, Address)
        const addressIdCol = Address.columnSchema.oid
        const addressNamecols = [Address.columnSchema.addressLine1, Address.columnSchema.addressLine2, Address.columnSchema.zipcode, Address.columnSchema.city, Address.columnSchema.state]

        var validateLandownerLLC = (landownerLLCRecord) => validateEntity(landownerLLCRecords, landownerLLCRecord, this.columnSchema.landownerLLCId, this.columnSchema.landownerLLCName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validateLandownerLLC,
            validateUpdateItem: validateLandownerLLC,
            tableConfig: {
                columns: [
                    makeLLCId(this.columnSchema.landownerLLCId),
                    makeLLCName(this.columnSchema.landownerLLCName, this.displayNameSingular),
                    {
                        name: "Address",
                        key: this.columnSchema.address,
                        cellType: "dropdown",
                        cellOptions: {
                            transformDisplayValue: (value) => transformDisplayValue(addressRecords, value, addressIdCol, addressNamecols),
                            dropdownItems: buildDropdownItems(addressRecords, addressIdCol, addressNamecols),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        },
                    },
                    makeLLCDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var LandownerLLC = new LandownerLLCTable()

class LandownerLLCContactsTable extends Table {

    constructor() {

        var columnSchema = {
            oid: "oid",
            landownerLLCId: "landowner_llc_id",
            contactId: "contact_id",
            notes: "notes"
        }

        var schemaName = schemas.llc
        var tableName = "landowner_contacts"

        var displayNameSingular = "Landowner LLC Contact"
        var displayNamePlural = "Landowner LLC Contacts"

        var pkUidColumn = columnSchema.oid
        var identifiers = [columnSchema.oid]
        var dependencies = [LandownerLLC, Contact]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildTableProps = (data) => {

        var landownerLLCRecords = this.findTableObjectRecords(data, LandownerLLC)
        var contactRecords = this.findTableObjectRecords(data, Contact)

        var landownerLLCIdCol =  LandownerLLC.columnSchema.landownerLLCId
        var landownerLLCNameCol =  LandownerLLC.columnSchema.landownerLLCName
        var contactIdCol = Contact.columnSchema.contactId
        var contactNameCol = Contact.columnSchema.name

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    {
                        name: "LLC",
                        key: this.columnSchema.landownerLLCId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(landownerLLCRecords, value, landownerLLCIdCol, landownerLLCNameCol),
                            dropdownItems: buildDropdownItems(landownerLLCRecords, landownerLLCIdCol, landownerLLCNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Contact",
                        key: this.columnSchema.contactId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(contactRecords, value, contactIdCol, contactNameCol),
                            dropdownItems: buildDropdownItems(contactRecords, contactIdCol, contactNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export var LandownerLLCContacts = new LandownerLLCContactsTable()

class OandMLLCTable extends Table {

    constructor() {

        var columnSchema = {
            oandmLLCId: "oandm_llc_id",
            oandmLLCName: "oandm_llc_name",
            description: "description",
        }

        var schemaName = schemas.llc
        var tableName = "oandm"

        var displayNameSingular = "O&M LLC"
        var displayNamePlural = "O&Ms"

        var pkUidColumn = columnSchema.oandmLLCId
        var identifiers = [columnSchema.oandmLLCId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var oandmLLCRecords = this.findTableObjectRecords(data, this)

        var validateLegalOandm = (oandmLLCRecord) => validateEntity(oandmLLCRecords, oandmLLCRecord, this.columnSchema.oandmLLCId, this.columnSchema.oandmLLCName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validateLegalOandm,
            validateUpdateItem: validateLegalOandm,
            tableConfig: {
                columns: [
                    makeLLCId(this.columnSchema.oandmLLCId),
                    makeLLCName(this.columnSchema.oandmLLCName, this.displayNameSingular),
                    makeLLCDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var OandMLLC = new OandMLLCTable()




class PlantLLCTable extends Table {

    constructor() {

        var columnSchema = {
            plantLLCId: "plant_llc_id",
            plantLLCName: "plant_llc_name",
            description: "description",
        }

        var schemaName = schemas.llcPlant
        var tableName = "plant"

        var displayNameSingular = "Plant LLC"
        var displayNamePlural = "Plants"

        var pkUidColumn = columnSchema.plantLLCId
        var identifiers = [columnSchema.plantLLCId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var plantLLCRecords = this.findTableObjectRecords(data, this)

        var validatePlantLLC = (plantLLCRecord) => validateEntity(plantLLCRecords, plantLLCRecord, this.columnSchema.plantLLCId, this.columnSchema.plantLLCName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validatePlantLLC,
            validateUpdateItem: validatePlantLLC,
            tableConfig: {
                columns: [
                    makeLLCId(this.columnSchema.plantLLCId),
                    makeLLCName(this.columnSchema.plantLLCName, this.displayNameSingular),
                    makeLLCDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var PlantLLC = new PlantLLCTable()

class OandMJunctionTable extends Table {

    constructor() {

        var columnSchema = {
            oandmLLCId: "oandm_llc_id",
            oandmCounterpartyId: "oandm_counterparty_id",
        }

        var schemaName = schemas.llc
        var tableName = "oandm_junction"

        var displayNameSingular = "O&M Link"
        var displayNamePlural = "O&M Links"

        var pkUidColumn = null
        var identifiers = [columnSchema.oandmLLCId, columnSchema.oandmCounterpartyId]
        var dependencies = [OandMLLC, OandM]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var oandmLLCRecords = this.findTableObjectRecords(data, OandMLLC)
        var oandmRecords = this.findTableObjectRecords(data, OandM)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var oandmLLCIdCol =  OandMLLC.columnSchema.oandmLLCId
        var oandmLLCNameCol =  OandMLLC.columnSchema.oandmLLCName
        var oandmIdCol = OandM.columnSchema.oandmId
        var oandmNameCol = "oandmName"

        oandmRecords = injectCounterpartyName(oandmRecords, counterpartyRecords, oandmIdCol, oandmNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    {
                        name: "LLC",
                        key: this.columnSchema.oandmLLCId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(oandmLLCRecords, value, oandmLLCIdCol, oandmLLCNameCol),
                            dropdownItems: buildDropdownItems(oandmLLCRecords, oandmLLCIdCol, oandmLLCNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Counterparty",
                        key: this.columnSchema.oandmCounterpartyId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(oandmRecords, value, oandmIdCol, oandmNameCol),
                            dropdownItems: buildDropdownItems(oandmRecords, oandmIdCol, oandmNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export var OandMJunction = new OandMJunctionTable()

class BankLLCTable extends Table {

    constructor() {

        var columnSchema = {
            bankLLCId: "bank_llc_id",
            bankLLCName: "bank_llc_name",
            description: "description",
        }

        var schemaName = schemas.llc
        var tableName = "bank"

        var displayNameSingular = "Bank LLC"
        var displayNamePlural = "Bank LLCs"

        var pkUidColumn = columnSchema.bankLLCId
        var identifiers = [columnSchema.bankLLCId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var bankLLCRecords = this.findTableObjectRecords(data, this)

        var validateBankLLC = (bankLLCRecord) => validateEntity(bankLLCRecords, bankLLCRecord, this.columnSchema.bankLLCId, this.columnSchema.bankLLCName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validateBankLLC,
            validateUpdateItem: validateBankLLC,
            tableConfig: {
                columns: [
                    makeLLCId(this.columnSchema.bankLLCId),
                    makeLLCName(this.columnSchema.bankLLCName, this.displayNameSingular),
                    makeLLCDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var BankLLC = new BankLLCTable()

class BankJunctionTable extends Table {

    constructor() {

        var columnSchema = {
            bankLLCId: "bank_llc_id",
            bankCounterpartyId: "bank_counterparty_id",
        }

        var schemaName = schemas.llc
        var tableName = "bank_junction"

        var displayNameSingular = "Bank Link"
        var displayNamePlural = "Bank Links"

        var pkUidColumn = null
        var identifiers = [columnSchema.bankLLCId, columnSchema.bankCounterpartyId]
        var dependencies = [BankLLC, Bank]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural,
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var bankLLCRecords = this.findTableObjectRecords(data, BankLLC)
        var bankCounterpartyRecords = this.findTableObjectRecords(data, Bank)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)
        
        var bankLLCIdCol =  BankLLC.columnSchema.bankLLCId
        var bankLLCNameCol =  BankLLC.columnSchema.bankLLCName
        var bankCounterpartyIdCol = Bank.columnSchema.bankId
        var bankCounterpartyNameCol = "bankName"

        bankCounterpartyRecords = injectCounterpartyName(bankCounterpartyRecords, counterpartyRecords, bankCounterpartyIdCol, bankCounterpartyNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    {
                        name: "Banking LLC",
                        key: this.columnSchema.bankLLCId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(bankLLCRecords, value, bankLLCIdCol, bankLLCNameCol),
                            dropdownItems: buildDropdownItems(bankLLCRecords, bankLLCIdCol, bankLLCNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            flexGrow: 1,
                        }
                    }, {
                        name: "Bank Counterparty",
                        key: this.columnSchema.bankCounterpartyId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(bankCounterpartyRecords, value, bankCounterpartyIdCol, bankCounterpartyNameCol),
                            dropdownItems: buildDropdownItems(bankCounterpartyRecords, bankCounterpartyIdCol, bankCounterpartyNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            flexGrow: 1,
                        }
                    }
                ]
            },
        }
    }

}
export var BankJunction = new BankJunctionTable()

class InvestorLLCTable extends Table {

    constructor() {

        var columnSchema = {
            investorId: "investment_llc_id",
            investorName: "investment_llc_name",
            description: "description",
        }

        var schemaName = schemas.llc
        var tableName = "investor"

        var displayNameSingular = "Investor LLC"
        var displayNamePlural = "Investor LLCs"

        var pkUidColumn = columnSchema.investorId
        var identifiers = [columnSchema.investorId]
        var dependencies = []
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var investorLLCRecords = this.findTableObjectRecords(data, this)

        var validateInvestorLLC = (coInvestorLLCRecord) => validateEntity(investorLLCRecords, coInvestorLLCRecord, this.columnSchema.investorId, this.columnSchema.investorName, this.displayNameSingular)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: validateInvestorLLC,
            validateUpdateItem: validateInvestorLLC,
            tableConfig: {
                columns: [
                    makeLLCId(this.columnSchema.investorId),
                    makeLLCName(this.columnSchema.investorName, this.displayNameSingular),
                    makeLLCDescription(this.columnSchema.description, this.displayNameSingular),
                ]
            },
        }
    }

}
export var InvestorLLC = new InvestorLLCTable()

class InvestorJunctionTable extends Table {

    constructor() {

        var columnSchema = {
            investmentLLCId: "investment_llc_id",
            investorId: "investor_counterparty_id",
        }

        var schemaName = schemas.llc
        var tableName = "investor_junction"

        var displayNameSingular = "Investor Link"
        var displayNamePlural = "Investor Links"

        var pkUidColumn = null
        var identifiers = [columnSchema.investmentLLCId, columnSchema.investorId]
        var dependencies = [InvestorLLC, Investor]
        
        super(
            schemaName, tableName, 
            columnSchema,
            displayNameSingular, displayNamePlural, 
            identifiers, dependencies, pkUidColumn
        )

        this.allowProjectDisplay = false
    }

    buildHelpPanel = () => <HelpLLCTable type={this.displayNameSingular} />

    buildTableProps = (data) => {

        var investmentLLCRecords = this.findTableObjectRecords(data, InvestorLLC)
        var investorRecords = this.findTableObjectRecords(data, Investor)
        var counterpartyRecords = this.findTableObjectRecords(data, Counterparty)

        var investmentLLCIdCol =  InvestorLLC.columnSchema.investorId
        var investmentLLCNameCol =  InvestorLLC.columnSchema.investorName
        var investorIdCol = Investor.columnSchema.investorId
        var investorNameCol = "investorName"

        investorRecords = injectCounterpartyName(investorRecords, counterpartyRecords, investorIdCol, investorNameCol)

        return {
            itemSchema: this.buildNewRecord(),
            flattenItem: null,
            structureItem: null,
            buildRequestBody: this.buildRequestBody.bind(this),
            validateNewItem: null,
            validateUpdateItem: null,
            tableConfig: {
                columns: [
                    {
                        name: "Investment LLC",
                        key: this.columnSchema.investmentLLCId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(investmentLLCRecords, value, investmentLLCIdCol, investmentLLCNameCol),
                            dropdownItems: buildDropdownItems(investmentLLCRecords, investmentLLCIdCol, investmentLLCNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }, {
                        name: "Investor",
                        key: this.columnSchema.investorId,
                        cellType: "dropdown",
                        cellOptions: {
                            searchable: true,
                            transformDisplayValue: (value) => transformDisplayValue(investorRecords, value, investorIdCol, investorNameCol),
                            dropdownItems: buildDropdownItems(investorRecords, investorIdCol, investorNameCol),
                        },
                        filterable: true,
                        sortable: true,
                        colProps: {
                            width: 200,
                            resizable: true,
                        }
                    }
                ]
            },
        }
    }

}
export var InvestorJunction = new InvestorJunctionTable()