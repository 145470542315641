import './SliderCell.css'

import { useInputDispatch } from '../../../hooks/inputDispatch'

import { Table, Slider } from 'rsuite'
const { Cell } = Table

export default function SliderCell({ rowData, dataIdKey, dataKey, onChange, cellOptions, isEditingFunction, ...props }) {

    var dispatch = useInputDispatch(10)

    var itemId = rowData[dataIdKey]

    var isEditing = isEditingFunction(itemId)

    var value = rowData[dataKey]
    var displayValue = (!value ? "Disabled" : String(value)+` ${cellOptions.label}`)   // display label format eg. 6 hours

    var styles = (isEditing ? {textAlign: "center", position: "relative", top: "-5px"} : {})

    return (
        <Cell {...props} className={isEditing ? 'table-content-editing' : ''}>
            <div className="table-content-edit-span" style={styles}>{displayValue}</div>
            {isEditing && 
                <Slider 
                    defaultValue={value}
                    max={cellOptions.max}
                    min={cellOptions.min}
                    step={cellOptions.step || 1}
                    tooltip={false}
                    onChange={event => {
                        if (event===value) return
                        if (onChange) dispatch(() => onChange(rowData.pltr_table_id, dataKey, event))
                    }}
                />
            }
        </Cell>
    );
};