import { Paper, Skeleton, Card, Text } from '@mantine/core';
import { Card as PltrCard, CardHeaderWithMenu as PltrCardHeaderWithMenu, CardBody as PltrCardBody } from '../../panelv2/Panel2';
import { format as formatDate, isValid as isDateValid } from 'date-fns/esm';

import { AutoRefreshInfo, AutoRefreshToggle } from './AutoRefresh';
import { useDataProvider } from '../../data_provider/StandardDataProvider';
import { useEffect } from 'react';
import { DataLoadingError } from './Common';


const Widget1 = ({}) => {

    const {data, requestId, isDataReady, isLoading, isError, errorMessage, lastRefreshTs, refreshData, autoRefreshEnabled, enableAutoRefresh, disableAutoRefresh} = useDataProvider({
        requestMethod: "GET",
        requestUrl: "/api/monitor/dashboard",
        buildRequestBody: () => Object({"monitorDashboardId": 1}),
        resetDataOnRequest: false
    })

    useEffect(() => {
        //refreshData()
        enableAutoRefresh()
    }, [])

    return (
        <PltrCard>
            <PltrCardHeaderWithMenu
                label="BESS Realtime Overview"
                inlineComponents={
                    <AutoRefreshInfo
                        lastRefreshTs={lastRefreshTs}
                        isLoading={isLoading}
                        isError={isError}
                        autoRefreshEnabled={autoRefreshEnabled}
                    />
                }
                menuItems={
                    <Paper p="sm">
                        <AutoRefreshToggle
                            autoRefreshEnabled={autoRefreshEnabled}
                            enableAutoRefresh={enableAutoRefresh}
                            disableAutoRefresh={disableAutoRefresh}
                        />
                    </Paper>
                }
            />
            <PltrCardBody>
                {isError && <DataLoadingError message={errorMessage} mb={15} />}
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                    {data && data.projectPanelData.map(panelData => {
                            return (
                                <SiteSummaryCard
                                    key={panelData.panelId}
                                    data={panelData}
                                />
                            )
                    })}
                    {!data && !isError && isLoading && <DataLoadingSkeleton />}
                </div>
            </PltrCardBody>
        </PltrCard>
    )
}

const DataLoadingSkeleton = () => {
    return (
        <>
            <Skeleton height="210px" width="200px" mr="15px" />
            <Skeleton height="210px" width="200px" mr="15px" />
            <Skeleton height="210px" width="200px" mr="15px" />
        </>
    )
}

const SiteSummaryCard = ({data}) => {

    return (
        <Card withBorder style={{minWidth: "275px", maxWidth: "400px", marginRight: "15px", marginBottom: "15px", flexShrink: 0}}>
            <h5 style={{marginBottom: "10px"}}>{data.projectName}</h5>
            {data.metrics.map(metricData => {
                return (
                    <SummaryCardMetric
                        name={metricData.name}
                        timestamp={metricData.timestamp}
                        value={metricData.value}
                        units={metricData.units}
                    />
                )
            })}
        </Card>
    )
}

const SummaryCardMetric = ({name, timestamp, value, units}) => {

    const timestampDate = new Date(timestamp)
    const formattedValue = typeof(value) === "number" ? value.toLocaleString(undefined, {maximumFractionDigits: 2}) : null

    return (
        <div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", paddingRight: "0px"}}>
                <Text>{formattedValue}</Text>
                <Text size="xs" color="dimmed" component="i" style={{marginLeft: "4px"}}>{units}</Text>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between"}}>
                <Text size="xs" color="dimmed">{name}</Text>
                <Text size="xs" color="dimmed">{isDateValid(timestampDate) ? formatDate(timestampDate, "h:mm:ss b, LLL d") : null}</Text>
            </div>
        </div>
    )
}

export default Widget1