import './ProjectEquipment.css'
import React from 'react'
import { ProjectTracker } from "../../table_configuration/ProjectEquipment"
import { TrackerSpec } from "../../table_configuration/EquipmentSpec"
import ViewPanel from '../ViewPanel'
import { generateUUID } from '../../../utils/databaseAppUtils'
import { useChangeLog, injectedChangeLogIdCol } from '../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../hooks/databaseViewFormValidation'
import {
    ProjectEquipmentHeader, ProjectEquipmentGroupId, ProjectEquipmentSpec, ProjectEquipmentNotes,
    ProjectEquipmentStatus, ProjectEquipmentOwnerProvided, ProjectEquipmentEPCContract
} from './ProjectEquipment'
import { AddFloaterButtonWithPrompt } from '../../../components/button/FloaterButtonWithPrompt'
import { 
    PalantirDatePicker,
    PalantirSelector
} from '../../../components/input/SelectPicker'
import { EPCContract } from '../../table_configuration/Contract'
import _ from 'lodash'
import { makeTextMaskNumber, PalantirTextField } from '../../../components/input/Text'


const TextMaskInteger = makeTextMaskNumber({scale: 0})

export default function ProjectTrackerProxy(props) {

    const trackerGroupRecords = props.data[ProjectTracker.buildId()].filter(x => x[ProjectTracker.columnSchema.plantId]===props.selectedPlantId)
    const trackerSpecRecords = props.data[TrackerSpec.buildId()]
    const epcContractRecords = props.data[EPCContract.buildId()].filter(x => x[EPCContract.columnSchema.plantId]===props.selectedPlantId)

    return (
        <ProjectTrackerPanel
            initialTrackerGroupRecords={trackerGroupRecords}
            trackerSpecRecords={trackerSpecRecords}
            epcContractRecords={epcContractRecords}
            validateCreateTrackerGroup={(record, newRecord) => true}
            {...props}
        />
    )

}

const ProjectTrackerPanel = ({initialTrackerGroupRecords, trackerSpecRecords, epcContractRecords, ...props}) => {

    const [
        trackerGroupRecords, prepareTrackerGroupLog,
        updateTrackerGroupLog, addToTrackerGroupLog, deleteFromTrackerGroupLog, bulkOpOnTrackerGroupLog, mergeAndResetTrackerGroupLog
    ] = useChangeLog(initialTrackerGroupRecords, ProjectTracker)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToTrackerGroupLogProxy = () => {
        let trackerGroup = ProjectTracker.buildNewRecord()
        trackerGroup[ProjectTracker.columnSchema.trackerGroupId] = generateUUID()
        trackerGroup[ProjectTracker.columnSchema.plantId] = props.selectedPlantId
        addToTrackerGroupLog(trackerGroup)
    }
    const deleteFromTrackerGroupLogProxy = (trackerGroup) => {
        deleteFromTrackerGroupLog(trackerGroup)
    }

    const trackerGroupComponents = trackerGroupRecords.map(trackerGroup => {
        return (
            <ProjectTrackerForm
                key={trackerGroup[ProjectTracker.columnSchema.trackerGroupId]}
                trackerGroup={trackerGroup}
                trackerSpecRecords={trackerSpecRecords}
                epcContractRecords={epcContractRecords}
                updateTrackerGroupLog={(update) => updateTrackerGroupLog(trackerGroup, update)}
                deleteFromTrackerGroupLog={() => deleteFromTrackerGroupLogProxy(trackerGroup)}
                errors={errors}
                removeErrors={removeErrors}
                services={props.services}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Project Tracker Groups"
            submitUrl='api/precious/table/project_tracker'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.projectTrackerChangelog,
                        checks: [{
                            checkColumn: ProjectTracker.columnSchema.trackerSpecId,
                            checkFunction: "nullCheck",
                            errMessage: "Please select a tracker specification.",
                            path: [
                                {name: ProjectTracker.columnSchema.trackerGroupId, type: "eval"},
                                {name: ProjectTracker.buildId(), type: "static"},
                                {name: injectedChangeLogIdCol, type: "eval"}
                            ]
                        }]
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetTrackerGroupLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    projectTrackerChangelog: prepareTrackerGroupLog(),
                }
            }}
        >
            {trackerGroupComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToTrackerGroupLogProxy}
                labelContent="Add new tracker group"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

const ProjectTrackerForm = React.memo(function({trackerGroup, trackerSpecRecords, epcContractRecords, updateTrackerGroupLog, deleteFromTrackerGroupLog, errors, removeErrors, services}) {

    const trackerGroupId = trackerGroup[ProjectTracker.columnSchema.trackerGroupId]
    const trackerChangeLogId = trackerGroup[injectedChangeLogIdCol]
    
    const trackerSpec = trackerSpecRecords.find(x => x[TrackerSpec.columnSchema.trackerSpecId]===trackerGroup[ProjectTracker.columnSchema.trackerSpecId]) || {}

    const trackerSpecErrorPath = [trackerGroupId, ProjectTracker.buildId(), trackerChangeLogId]
    
    return (
        <div className="field-group form-instance project-equipment">
            <ProjectEquipmentHeader
                ProjectEquipmentTable={ProjectTracker}
                deleteFromEquipmentGroupLog={deleteFromTrackerGroupLog}
                removeErrorsOnDelete={() => removeErrors([trackerGroupId])}
            />
            <div className="flow-horizontal body">
                <div className="flow-vertical vertical-children-spacing equipment-meta-col">
                    <ProjectEquipmentGroupId
                        ProjectEquipmentTable={ProjectTracker}
                        value={trackerGroupId}    
                    />
                    <ProjectEquipmentStatus
                        equipmentGroup={trackerGroup}
                        statusCol={ProjectTracker.columnSchema.status}
                        updateEquipmentGroup={updateTrackerGroupLog}
                    />
                    <ProjectEquipmentEPCContract
                        equipmentContractIdCol={EPCContract.columnSchema.contractId}
                        equipmentGroupEPCContractId={trackerGroup[ProjectTracker.columnSchema.contractId]}
                        epcContracts={epcContractRecords}
                        updateEquipmentGroup={updateTrackerGroupLog}
                        services={services}
                    />
                    <ProjectEquipmentOwnerProvided
                        equipmentGroup={trackerGroup}
                        ownerProvidedCol={ProjectTracker.columnSchema.isOwnerProvided}
                        updateEquipmentGroup={updateTrackerGroupLog}
                    />
                    <PalantirSelector
                        label="Tracking Type"
                        value={trackerGroup[ProjectTracker.columnSchema.trackingType]}
                        onChange={(x) => updateTrackerGroupLog({[ProjectTracker.columnSchema.trackingType]: x})}
                        items={ProjectTracker.options.trackingType}
                        style={{marginBottom: "20px"}}
                    />
                    <ProjectEquipmentNotes
                        value={trackerGroup[ProjectTracker.columnSchema.notes]}
                        notesColumn={ProjectTracker.columnSchema.notes}
                        updateProjectEquipment={updateTrackerGroupLog}
                    />
                    <PalantirTextField
                        label="Tracker Size"
                        value={trackerGroup[ProjectTracker.columnSchema.trackerSize]}
                        InputProps={{inputComponent: TextMaskInteger}}
                        onChange={(x) => updateTrackerGroupLog({[ProjectTracker.columnSchema.trackerSize]: x})}
                        style={{width: "200px", marginBottom: "15px", marginRight: "15px"}}
                    />
                </div>
                <div style={{marginRight: "30px"}}>
                    <div className="header">Tracker Spec</div>
                    <ProjectEquipmentSpec
                        equipmentGroup={trackerGroup}
                        equipmentSpecRecords={trackerSpecRecords}
                        updateEquipmentGroupLog={updateTrackerGroupLog}
                        equipmentType="Tracker"
                        projectEquipmentSpecIdCol={ProjectTracker.columnSchema.trackerSpecId}
                        equipmentSpecIdCol={TrackerSpec.columnSchema.trackerSpecId}
                        manufacturerCol={TrackerSpec.columnSchema.manufacturer}
                        modelCol={TrackerSpec.columnSchema.model}
                        errors={errors}
                        equipmentSpecErrorPath={trackerSpecErrorPath}
                        removeEquipmentSpecError={() => {
                            removeErrors(trackerSpecErrorPath)
                        }}
                    />
                </div>
                <div className="flow-vertical">
                    <div className="header">Dates</div>
                    <div className="flow-horizontal">
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty Start"
                                value={trackerGroup[ProjectTracker.columnSchema.warrantyStartDate]}
                                onChange={(date) => updateTrackerGroupLog({[ProjectTracker.columnSchema.warrantyStartDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Current Dates</div>
                            <PalantirDatePicker
                                label="Delivery Start"
                                value={trackerGroup[ProjectTracker.columnSchema.deliveryStartDateCurrent]}
                                onChange={(date) => updateTrackerGroupLog({[ProjectTracker.columnSchema.deliveryStartDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Delivery End"
                                value={trackerGroup[ProjectTracker.columnSchema.deliveryEndDateCurrent]}
                                onChange={(date) => updateTrackerGroupLog({[ProjectTracker.columnSchema.deliveryEndDateCurrent]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={trackerGroup[ProjectTracker.columnSchema.purchaseOrderDateCurrent]}
                                onChange={(date) => updateTrackerGroupLog({[ProjectTracker.columnSchema.purchaseOrderDateCurrent]: date})}
                            />
                        </div>
                        <div className="flow-vertical project-equipment-dates">
                            <PalantirDatePicker
                                label="Warranty End"
                                value={trackerGroup[ProjectTracker.columnSchema.warrantyEndDate]}
                                onChange={(date) => updateTrackerGroupLog({[ProjectTracker.columnSchema.warrantyEndDate]: date})}
                                style={{marginBottom: "30px"}}
                            />
                            <div className="header">Budgeted Dates</div>
                            <PalantirDatePicker
                                label="Delivery Start"
                                value={trackerGroup[ProjectTracker.columnSchema.deliveryStartDateBudgeted]}
                                onChange={(date) => updateTrackerGroupLog({[ProjectTracker.columnSchema.deliveryStartDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Delivery End"
                                value={trackerGroup[ProjectTracker.columnSchema.deliveryEndDateBudgeted]}
                                onChange={(date) => updateTrackerGroupLog({[ProjectTracker.columnSchema.deliveryEndDateBudgeted]: date})}
                            />
                            <PalantirDatePicker
                                label="Purchase Order"
                                value={trackerGroup[ProjectTracker.columnSchema.purchaseOrderDateBudgeted]}
                                onChange={(date) => updateTrackerGroupLog({[ProjectTracker.columnSchema.purchaseOrderDateBudgeted]: date})}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.trackerGroup, nextProps.trackerGroup) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})