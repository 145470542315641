import {Component} from 'react'

import { injectServices } from '../utils/ServiceInjector'
import ViewFormRenderer from './ViewFormRenderer'
import StandardDataProvider from '../components/data_provider/StandardDataProvider'
import Panel from '../components/panel/Panel'

// Flag to track updates made. If true, an update has been made to the data source
//    and will be refetched when the user selects a different plant
var updateFlag = false


class ViewContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    shouldComponentUpdate(prevProps, prevState) {
        if (this.props.selectedView !== prevProps.selectedView) return true
        if (this.props.selectedPortfolioId !== prevProps.selectedPortfolioId) return true
        if (this.props.selectedPlantId !== prevProps.selectedPlantId) return true
        return false
    } 

    render() {

        if (!this.props.selectedView) {
            return <Panel className=" section-panel fill-parent">Please select a view.</Panel>
        }
        
        // Using the viewkey as data provider key will cause the provider to remount when it changes
        // the view form renderer should not remount when plant changes as it tracks update state across all plants
        return (
            <StandardDataProvider
                key={this.props.selectedView.key}
                services={this.props.services}
                requestType="GET"
                queryUrl={this.props.selectedView.endpoint}
                render={(data, dataRequestId, loading, refreshData) => {
                    let servicesInjected = injectServices(this.props.services, 'data', {
                        refresh: refreshData,
                    })
                    return <ViewFormRenderer
                        key={9}
                        services={servicesInjected}
                        data={data || {}}
                        selectedPlantId={this.props.selectedPlantId}
                        selectedPortfolioId={this.props.selectedPortfolioId}
                        selectedView={this.props.selectedView}
                        dataRequestId={dataRequestId}
                        loading={loading}
                    />}}
            />
        )
    }
}

export default ViewContainer