import './DropdownCell.css'

import { useRef } from 'react'
import { PalantirSelector } from '../../input/SelectPicker'
import { Table, SelectPicker, Alert, } from 'rsuite'
const { Cell } = Table

export default function DropDownCell({ rowData, dataIdKey, dataKey, onChange, cellOptions, items, isEditingFunction, transformDisplayValueFunction, forceUpdateFunction, pageLength, ...props }) {

    var itemId = rowData[dataIdKey]

    var isEditing = isEditingFunction(itemId)

    var value = rowData[dataKey]
    var displayValue = transformDisplayValueFunction(value, rowData)

    var dropdownItems = []
    // Options can be created by a function, which returns the list of options
    if (cellOptions.dropdownItems instanceof Function) {
        // Diffs are applied because the options returned by function can be affected by uncommitted changes
        dropdownItems = cellOptions.dropdownItems(rowData)
    }
    // Options can be provided as a list
    else if (cellOptions.dropdownItems instanceof Array) {
        dropdownItems = cellOptions.dropdownItems
    }

    var placement = props.rowIndex % pageLength >= pageLength-7 ? "topStart" : "bottomStart"

    //if (props.rowIndex <= 3) placement = "bottomStart"

    return (
        <Cell {...props} className={isEditing ? 'table-content-editing' : ''}>
            {isEditing ? (
                <SelectPicker
                    value={value}
                    style={{maxHeight: "100px"}}
                    data={dropdownItems}
                    searchable={cellOptions.searchable}
                    cleanable={true}
                    placement={placement}
                    onChange={event => {
                        if (cellOptions.transformNewValue) {
                            try {
                                let updateObject = cellOptions.transformNewValue(event, dataKey, rowData, items)
                                if (onChange) onChange(rowData[dataIdKey], Object.keys(updateObject), Object.values(updateObject));
                            }
                            catch (e) {
                                console.log(e)
                                Alert.error(`
                                Failed to process side effect on instance ${dataKey} change.
                                ${e}
                                `, 8000)
                                if (onChange) onChange(rowData[dataIdKey], dataKey, event);
                            }
                        }
                        else {
                            if (onChange) onChange(rowData[dataIdKey], dataKey, event);
                        }
                    }}
                />
            ) : <span className="table-content-edit-span">{displayValue}</span>
            }
        </Cell>
    );
};