import DatePicker from '../date_picker/DateRangePicker'
import DataExporter from '../data_exporter/DataExporter'


export const TopBarVariant1 = ({title, isDataReady, startDate, endDate, setDateRange, generateDownloadableData, data, DatePickerProps={}, createContent}) => {
    return (
        <div className="flow-horizontal" style={{alignItems: "end", flexShrink: 0, overflowX: "auto"}}>
            <div style={{alignSelf: "start"}}>
                <div className="section-header" style={{marginRight: "20px", marginBottom: 0}}>
                    <div className="title">{title}</div>
                </div>
                <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    onValueChange={setDateRange}
                    {...DatePickerProps}
                />
            </div>
            {createContent ? createContent(isDataReady, data) : <div style={{marginLeft: "auto"}}></div>}
            {isDataReady && <DataExporter
                title={"Data"}
                generateData={generateDownloadableData}
                IconProps={{style: {marginLeft: "10px", position: "relative", top: "2px"}}}
            />}
        </div>
    )
}

const defaultStyleTopBar2 = {alignItems: "center", flexShrink: 0}
export const TopBarVariant2 = ({title, isDataReady, startDate, endDate, setDateRange, generateDownloadableData, createContent, data, DatePickerProps={}, ...props}) => {
    return (
        <div className="flow-horizontal" style={props.style ? Object.assign({}, defaultStyleTopBar2, props.style) : defaultStyleTopBar2} >
            <div className="section-header" style={{marginRight: "20px", marginBottom: 0}}>
                <div className="title">{title}</div>
            </div>
            <div style={{marginLeft: "auto"}}></div>
            <DatePicker
                startDate={startDate}
                endDate={endDate}
                onValueChange={setDateRange}
                {...DatePickerProps}
            />
            {createContent ? <div className="flow-horizontal fill-parent" style={{"overflowX": "auto"}}>{createContent(isDataReady, data)}</div>: null}
            <DataExporter
                title={"Data"}
                generateData={generateDownloadableData}
                IconProps={{style: {marginBottom: "0px"}}}
            />
        </div>
    )
}