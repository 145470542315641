import './ActionCell.css'

import { Table, Button } from "rsuite";

const {Cell} = Table

export default function ActionCell({ rowData, dataIdKey, onClick, isEditingFunction, ...props }) {

    var dataId = rowData[dataIdKey]

    var isEditing = isEditingFunction(dataId)
    var primaryActionText = (isEditing ? 'Save' : 'Edit')

    return (
        <Cell {...props} >
            <div className="action-cell">
                <Button appearance="link" onClick={() => onClick(dataId, false)}>
                    {primaryActionText}
                </Button>
                {isEditing && 
                    <Button appearance="link" onClick={() => onClick(dataId, true)} style={{marginLeft: '5px'}}>
                        Cancel
                    </Button>
                }
            </div>
        </Cell>
    );
};