import { OpportunityRegister, RiskRegister, WBSCode } from "../../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from "../../../components/input/Text"
import { PalantirAutocomplete, PalantirBooleanSelector, PalantirDatePicker, PalantirMultiSelector, PalantirSelector, } from "../../../components/input/SelectPicker"
import { useChangeLog } from "../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../hooks/databaseViewFormValidation"
import ViewPanel from "../ViewPanel"
import { generateUUID } from "../../../utils/databaseAppUtils"
import { CardHeaderWithMenu } from "../../../components/panelv2/Panel2"
import { OEM } from "../../table_configuration/Counterparty"
import { useState } from "react"
import { DialogProvider, useDialog } from "../../../components/dialog/DialogProvider"
import { DialogContentText } from "@mui/material"
import { PalantirBasicCRUDMenu } from "../../../components/menuv2/Menu"
import { Text, SegmentedControl, Badge, Button } from "@mantine/core"
import { Counterparty } from "../../table_configuration/Entity"
import _ from 'lodash'
import { booleanOptions } from "../../table_configuration/CommonColumns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { HelpContractOwnerProvidedEquipment, HelpRiskRegisterBudgetedCostImpact } from "../../../components/help/precious/Columns"


const TextMaskNumber = makeTextMaskNumber({
    scale: 2
})
const TextMaskInt = makeTextMaskNumber({
    scale: 0
})


export default function RiskRegisterPanelProxy(props) {

    const projectRiskRegister = props.data[RiskRegister.buildId()].filter(x => x[RiskRegister.columnSchema.projectId]===props.selectedPlantId)
    const projectOpportunityRegister = props.data[OpportunityRegister.buildId()].filter(x => x[OpportunityRegister.columnSchema.projectId]===props.selectedPlantId)
    const counterparties = _.sortBy(props.data[Counterparty.buildId()], Counterparty.columnSchema.counterpartyName)
    const WBSCodes = props.data[WBSCode.buildId()]

    return (
        <DialogProvider>
        <ProjectTDDPanel
            initialRiskRegisterRecords={projectRiskRegister}
            initialOpportunityRegisterRecords={projectOpportunityRegister}
            counterparties={counterparties}
            WBSCodes={WBSCodes}
            {...props}
        />
        </DialogProvider>
    )
}

function ProjectTDDPanel({initialRiskRegisterRecords, initialOpportunityRegisterRecords, counterparties, WBSCodes, updateFormChangeTracker, ...props}) {

    const [menuSelectionType, setMenuSelectionType] = useState("risks")
    const isRisksSelected = menuSelectionType==="risks"
    const isOpportunitiesSelected = menuSelectionType==="opportunities"
    const [
        records, prepareLog, updateLog, addToLog,
        deleteFromLog, bulkOpOnLog, mergeAndResetLog, resetLog, popLog
    ] = useChangeLog(initialRiskRegisterRecords, RiskRegister)
    const [
        opRecords, prepareOpLog, updateOpLog, addToOpLog,
        deleteFromOpLog, bulkOpOnOpLog, mergeAndResetOpLog, resetOpLog, popOpLog
    ] = useChangeLog(initialOpportunityRegisterRecords, RiskRegister)
    const [isCreating, setIsCreating] = useState(false)
    
    const [selectedRiskId, setSelectedRiskId] = useState(null)
    const selectedRisk = selectedRiskId ? records.find(x => x[RiskRegister.columnSchema.oid]===selectedRiskId) : null
    const [selectedOpportunityId, setSelectedOpportunityId] = useState(null)
    const selectedOpportunity = selectedOpportunityId ? opRecords.find(x => x[OpportunityRegister.columnSchema.oid]===selectedOpportunityId) : null

    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()
    const [openDialog, closeDialog] = useDialog();

    const updateLogProxy = (update) => updateLog(selectedRisk, update)
    const updateOpLogProxy = (update) => updateOpLog(selectedOpportunity, update)

    /* Functions to create a new risk/opportunity */
    const addToLogProxy = () => {
        const newRiskRegister = RiskRegister.buildNewRecord()
        const riskId = generateUUID()
        newRiskRegister[RiskRegister.columnSchema.oid] = riskId
        newRiskRegister[RiskRegister.columnSchema.projectId] = props.selectedPlantId
        newRiskRegister[RiskRegister.columnSchema.isCritical] = "false"
        setSelectedRiskId(riskId)
        bulkOpOnLog([{
            method: "clear"
        }, {
            method: "insert", changes: [newRiskRegister]
        }])
    }
    const addToOpLogProxy = () => {
        const newOpRegister = OpportunityRegister.buildNewRecord()
        const opId = generateUUID()
        newOpRegister[OpportunityRegister.columnSchema.oid] = opId
        newOpRegister[OpportunityRegister.columnSchema.projectId] = props.selectedPlantId
        setSelectedOpportunityId(opId)
        bulkOpOnOpLog([{
            method: "clear"
        }, {
            method: "insert", changes: [newOpRegister]
        }])
    }

    const create = (addFn, log) => {
        const create = () => {
            addFn()
            resetErrors()
            setIsCreating(true)
        }
        if (isCreating) {
            window.alert("You may only add one at a time.")
            return
        }
        if (log.length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    create()
                }
            });
        }
        else create()
    }
    const createOpportunity = () => {
        create(addToOpLogProxy, prepareOpLog())
    }
    const createRisk = () => {
        create(addToLogProxy, prepareLog())
    }

    /* Functions to select a new risk/opportunity */
    const selectRisk = (record) => {
        const riskId = record[RiskRegister.columnSchema.oid]
        select(riskId, selectedRiskId, setSelectedRiskId, prepareLog())
    }
    const selectOpportunity = (record) => {
        const opId = record[OpportunityRegister.columnSchema.oid]
        select(opId, selectedOpportunityId, setSelectedOpportunityId, prepareOpLog())
    }
    const select = (recordId, selectedRecordId, setSelectedId, log) => {
        if (recordId===selectedRecordId) return
        tryPurgeChanges(log, () => setSelectedId(recordId))
    }
    const tryPurgeChanges = (log, callback) => {
        const purge = () => {
            if (callback) callback()
            resetLog()
            resetOpLog()
            resetErrors()
            setIsCreating(false)
        }
        if (log.length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    purge()
                }
            });
        }
        else {
            purge()
        }
    }

    /* Functions to delete a risk/opportunity */
    const deleteRisk = (callback) => {
        deleteGeneric(callback, "Risk", selectedRisk, selectedRiskId, setSelectedRiskId, deleteFromLog, popLog)
    }
    const deleteOpportunity = (callback) => {
        deleteGeneric(callback, "Opportunity", selectedOpportunity, selectedOpportunityId, setSelectedOpportunityId, deleteFromOpLog, popOpLog)
    }
    const deleteGeneric = (callback, type, selectedRecord, selectedRecordId, setSelectedId, deleteFromLog, popFromLog) => {
        openDialog({
            title: "Watch out!",
            body: <DialogContentText>Are you sure you want to delete the selected {type}?</DialogContentText>,
            onAccept: () => {
                closeDialog()
                setSelectedId(null)
                deleteFromLog(selectedRecord)
                setTimeout(() => {
                    callback({
                        onFail: () => {
                            resetErrors()
                            setSelectedId(selectedRecordId)
                            popFromLog()
                        }
                    })
                }, 100)
            }
        });
    }

    return (
        <div className="flow-horizontal fill-parent" style={{overflow: "auto"}}>
            <PalantirBasicCRUDMenu
                titleComponent={
                    <SegmentedControl
                        value={menuSelectionType}
                        onChange={(x) => {
                            tryPurgeChanges(isRisksSelected ? prepareLog() : prepareOpLog(), () => setMenuSelectionType(x))
                        }}
                        data={[{
                            label: "Risks", value: "risks"
                        }]}
                    />
                }
                selectedId={isRisksSelected ? selectedRiskId : selectedOpportunityId}
                records={isRisksSelected ? records : opRecords}
                recordIdCol={isRisksSelected ? RiskRegister.columnSchema.oid : OpportunityRegister.columnSchema.oid}
                recordNameCol={isRisksSelected ? RiskRegister.columnSchema.riskName : OpportunityRegister.columnSchema.opportunityName}
                iconFn={(record) => {
                    const recordStatus = isRisksSelected ? record[RiskRegister.columnSchema.status] : record[OpportunityRegister.columnSchema.status]
                    const isClosed = recordStatus === "Closed"
                    if (isClosed) return <FontAwesomeIcon icon={faCircle} color="green" />
                    else return <FontAwesomeIcon icon={faCircle} color="red" />
                }}
                onSelect={(record) => {
                    isRisksSelected ? selectRisk(record) : selectOpportunity(record)
                }}
                onAdd={() => {
                    isRisksSelected ? createRisk() : createOpportunity()
                }}
                style={{marginRight: "20px", marginTop: "25px", width: "225px", flexShrink: 0}}
            />
            <ViewPanel
                services={props.services}
                submitUrl='api/precious/table/risk_register'
                verifySubmit={(payload) => {
                    return verifyChangelogSubmission(
                        {
                            changeLog: payload.riskChangeLog,
                            checks: [{
                                checkColumn: RiskRegister.columnSchema.estimatedCostImpact,
                                checkFunction: "nullCheck",
                                errMessage: "Budgeted Cost Impact is required.",
                                path: [
                                    {name: RiskRegister.columnSchema.oid, type: "eval"},
                                    {name: RiskRegister.columnSchema.estimatedCostImpact, type: "static"}
                                ]
                            }, {
                                checkColumn: RiskRegister.columnSchema.WBSCodeId,
                                checkFunction: "nullCheck",
                                errMessage: "WBS Code is required.",
                                path: [
                                    {name: RiskRegister.columnSchema.oid, type: "eval"},
                                    {name: RiskRegister.columnSchema.WBSCodeId, type: "static"}
                                ]
                            }]
                        }, {
                            changeLog: payload.opportunityChangeLog,
                            checks: []
                        }
                    )
                }}
                onSubmitSuccess={(response, requestPayload) => {
                    props.handleUpdate(false)
                    resetErrors()
                    mergeAndResetLog()
                    mergeAndResetOpLog()
                    setIsCreating(false)
                }}
                showDelete={!isCreating && ((isRisksSelected && selectedRiskId) || (isOpportunitiesSelected && selectedOpportunityId))}
                onDelete={isRisksSelected ? deleteRisk : deleteOpportunity}
                onSubmitError={null}
                buildSubmitPayload={() => {
                    return {
                        riskChangeLog: prepareLog(),
                        opportunityChangeLog: prepareOpLog()
                    }
                }}
                style={{minWidth: "440px"}}
            >
                {isRisksSelected &&
                    (selectedRisk ? <RiskRegisterContent
                        key={selectedRiskId}
                        services={props.services}
                        risk={selectedRisk}
                        counterparties={counterparties}
                        WBSCodes={WBSCodes}
                        updateRisk={updateLogProxy}
                        errors={errors}
                        removeErrors={removeErrors}
                    /> : <div className="flow-vertical" style={{textAlign: "center"}}>Please select a Risk.</div>)
                }
                {isOpportunitiesSelected &&
                    (selectedOpportunity ? <OpportunityRegisterContent
                        key={selectedOpportunityId}
                        services={props.services}
                        opportunity={selectedOpportunity}
                        counterparties={counterparties}
                        WBSCodes={WBSCodes}
                        updateOpportunity={updateOpLogProxy}
                        errors={errors}
                        removeErrors={removeErrors}
                    /> : <div className="flow-vertical" style={{textAlign: "center"}}>Please select an Opportunity.</div>)
                }
            </ViewPanel>
        </div>
    )
}

const OpportunityRegisterContent = ({services, opportunity, updateOpportunity, counterparties, WBSCodes, errors, removeErrors}) => {

    const displayClosedSection = opportunity[OpportunityRegister.columnSchema.status]==="Closed"

    const closeOpportunitySideEffects = () => {
        return {
            [OpportunityRegister.columnSchema.closedDate]: new Date(),
            [OpportunityRegister.columnSchema.finalCostImpact]: opportunity[OpportunityRegister.columnSchema.costExactImpact],
            [OpportunityRegister.columnSchema.finalScheduleImpact]: opportunity[OpportunityRegister.columnSchema.scheduleImpactExactWeeks]
        }
    }

    return (
        <div className="form-instance2">
            <CardHeaderWithMenu
                label="Opportunity"
            />
            <div className="body flow-vertical">
                <IDSection
                    type="Opportunity"
                    record={opportunity}
                    idCol={OpportunityRegister.columnSchema.oid}
                    nameCol={OpportunityRegister.columnSchema.opportunityName}
                    update={updateOpportunity}
                />
                <MetaSection
                    record={opportunity}
                    update={updateOpportunity}
                    statusCol={OpportunityRegister.columnSchema.status}
                    boardApprovalCol={OpportunityRegister.columnSchema.boardApproval}
                    closedDateCol={OpportunityRegister.columnSchema.closedDate}
                    resolutionDetailsCol={OpportunityRegister.columnSchema.resolutionDetails}
                    audienceLevelCol={OpportunityRegister.columnSchema.audienceLevel}
                    statusOptions={OpportunityRegister.options.status}
                    audienceLevelOptions={OpportunityRegister.options.audienceLevel}
                    closeSideEffects={closeOpportunitySideEffects}
                />
                {displayClosedSection &&
                    <ClosedSection
                        record={opportunity}
                        update={updateOpportunity}
                        closedDateCol={OpportunityRegister.columnSchema.closedDate}
                        finalCostImpactCol={OpportunityRegister.columnSchema.finalCostImpact}
                        finalScheduleImpactCol={OpportunityRegister.columnSchema.finalScheduleImpact}
                        resolutionDetailsCol={OpportunityRegister.columnSchema.resolutionDetails}
                    />
                }
                <EntitySection
                    type="Opportunity"
                    update={updateOpportunity}
                    record={opportunity}
                    ownerCompanyIdCol={OpportunityRegister.columnSchema.opportunityOwnerCompanyId}
                    counterparties={counterparties}
                    itemLeadCol={OpportunityRegister.columnSchema.opportunityItemLead}
                />
                <WBSCodeSection
                    record={opportunity}
                    update={updateOpportunity}
                    WBSCodes={WBSCodes}
                    idCol={OpportunityRegister.columnSchema.oid}
                    WBSCodeCol={OpportunityRegister.columnSchema.WBSCodeId}
                    errors={errors}
                    removeErrors={removeErrors}
                />
                <hr style={{width: "100%"}}></hr>
                <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                    <CostImpact
                        services={services}
                        type="opportunity"
                        record={opportunity}
                        update={updateOpportunity}
                        extremeCaseCostImpactCol={OpportunityRegister.columnSchema.bestCaseCostImpact}
                        estimatedCostImpactCol={OpportunityRegister.columnSchema.estimatedCostImpact}
                        probabilityCol={OpportunityRegister.columnSchema.probability}
                        probabilityOptions={OpportunityRegister.options.probability}
                        costImpactRatingCol={OpportunityRegister.columnSchema.costImpactRating}
                        costImpactRatingOptions={OpportunityRegister.options.costImpactRating}
                        idCol={OpportunityRegister.columnSchema.oid}
                        errors={errors}
                        removeErrors={removeErrors}
                    />
                    <ScheduleImpact
                        record={opportunity}
                        update={updateOpportunity}
                        scheduleImpactExactWeeksCol={OpportunityRegister.columnSchema.scheduleImpactExactWeeks}
                        scheduleImpactLowWeeksCol={OpportunityRegister.columnSchema.scheduleImpactLowWeeks}
                        scheduleImpactHighWeeksCol={OpportunityRegister.columnSchema.scheduleImpactHighWeeks}
                    />
                </div>
                <hr style={{width: "100%", marginRight: "auto"}}></hr>
                
                
                <PalantirDispatchedTextField
                    label="Opportunity Description"
                    value={opportunity[OpportunityRegister.columnSchema.opportunityDescription]}
                    onChange={(x) => updateOpportunity({[OpportunityRegister.columnSchema.opportunityDescription]: x})}
                    multiline
                    rows={5}
                    variant="filled"
                    style={{marginRight: "20px", marginBottom: "20px", flexGrow: 1}}
                />
                <PalantirDatePicker
                    label="Target Resolution Date"
                    value={opportunity[OpportunityRegister.columnSchema.targetResolutionDate]}
                    onChange={(date) => updateOpportunity({[OpportunityRegister.columnSchema.targetResolutionDate]: date})}
                    style={{marginRight: "20px", marginBottom: "20px", maxWidth: "220px"}}
                />
                <PalantirDispatchedTextField
                    label="Realization Plan"
                    value={opportunity[OpportunityRegister.columnSchema.realizationPlan]}
                    onChange={(x) => updateOpportunity({[OpportunityRegister.columnSchema.realizationPlan]: x})}
                    multiline
                    rows={5}
                    variant="filled"
                    style={{marginRight: "20px", marginBottom: "20px", flexGrow: 1}}
                />
            </div>
        </div>
    )
}

const RiskRegisterContent = ({services, risk, updateRisk, createRisk, deleteRisk, counterparties, WBSCodes, errors, removeErrors}) => {

    const displayClosedSection = risk[RiskRegister.columnSchema.status]==="Closed"

    const closeRiskSideEffects = () => {
        return {
            [RiskRegister.columnSchema.closedDate]: new Date(),
            [RiskRegister.columnSchema.finalCostImpact]: risk[RiskRegister.columnSchema.costExactImpact],
            [RiskRegister.columnSchema.finalScheduleImpact]: risk[RiskRegister.columnSchema.scheduleImpactExactWeeks]
        }
    }

    return (
        <div className="form-instance2">
            <CardHeaderWithMenu
                label="Risk"
            />
            <div className="body flow-vertical">
                <IDSection
                    type="Risk"
                    record={risk}
                    idCol={RiskRegister.columnSchema.oid}
                    nameCol={RiskRegister.columnSchema.riskName}
                    update={updateRisk}
                />
                <MetaSection
                    record={risk}
                    update={updateRisk}
                    statusCol={RiskRegister.columnSchema.status}
                    categoryCol={RiskRegister.columnSchema.category}
                    isCriticalCol={RiskRegister.columnSchema.isCritical}
                    boardApprovalCol={RiskRegister.columnSchema.boardApproval}
                    closedDateCol={RiskRegister.columnSchema.closedDate}
                    resolutionDetailsCol={RiskRegister.columnSchema.resolutionDetails}
                    audienceLevelCol={RiskRegister.columnSchema.audienceLevel}
                    statusOptions={RiskRegister.options.status}
                    categoryOptions={RiskRegister.options.category}
                    audienceLevelOptions={RiskRegister.options.audienceLevel}
                    closeSideEffects={closeRiskSideEffects}
                />
                {displayClosedSection &&
                    <ClosedSection
                        record={risk}
                        update={updateRisk}
                        closedDateCol={RiskRegister.columnSchema.closedDate}
                        finalCostImpactCol={RiskRegister.columnSchema.finalCostImpact}
                        finalScheduleImpactCol={RiskRegister.columnSchema.finalScheduleImpact}
                        resolutionDetailsCol={RiskRegister.columnSchema.resolutionDetails}
                    />
                }
                <EntitySection
                    type="Risk"
                    update={updateRisk}
                    record={risk}
                    ownerCompanyIdCol={RiskRegister.columnSchema.riskOwnerCompanyId}
                    counterparties={counterparties}
                    itemLeadCol={RiskRegister.columnSchema.riskItemLead}
                />
                <WBSCodeSection
                    record={risk}
                    update={updateRisk}
                    WBSCodes={WBSCodes}
                    idCol={RiskRegister.columnSchema.oid}
                    WBSCodeCol={RiskRegister.columnSchema.WBSCodeId}
                    errors={errors}
                    removeErrors={removeErrors}
                />
                <hr style={{width: "100%"}}></hr>
                <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                    <CostImpact
                        services={services}
                        type="risk"
                        record={risk}
                        update={updateRisk}
                        extremeCaseCostImpactCol={RiskRegister.columnSchema.worstCaseCostImpact}
                        estimatedCostImpactCol={RiskRegister.columnSchema.estimatedCostImpact}
                        probabilityCol={RiskRegister.columnSchema.probability}
                        probabilityOptions={RiskRegister.options.probability}
                        costImpactRatingCol={RiskRegister.columnSchema.costImpactRating}
                        costImpactRatingOptions={RiskRegister.options.costImpactRating}
                        idCol={RiskRegister.columnSchema.oid}
                        errors={errors}
                        removeErrors={removeErrors}
                    />
                    <ScheduleImpact
                        record={risk}
                        update={updateRisk}
                        scheduleImpactExactWeeksCol={RiskRegister.columnSchema.scheduleImpactExactWeeks}
                        scheduleImpactLowWeeksCol={RiskRegister.columnSchema.scheduleImpactLowWeeks}
                        scheduleImpactHighWeeksCol={RiskRegister.columnSchema.scheduleImpactHighWeeks}
                    />
                </div>
                <hr style={{width: "100%", marginRight: "auto"}}></hr>
                
                
                <PalantirDispatchedTextField
                    label="Risk Description"
                    value={risk[RiskRegister.columnSchema.riskDescription]}
                    onChange={(x) => updateRisk({[RiskRegister.columnSchema.riskDescription]: x})}
                    multiline
                    rows={5}
                    variant="filled"
                    style={{marginRight: "20px", marginBottom: "20px", flexGrow: 1}}
                />
                <PalantirDatePicker
                    label="Target Resolution Date"
                    value={risk[RiskRegister.columnSchema.targetResolutionDate]}
                    onChange={(date) => updateRisk({[RiskRegister.columnSchema.targetResolutionDate]: date})}
                    style={{marginRight: "20px", marginBottom: "20px", maxWidth: "220px"}}
                />
                <PalantirDispatchedTextField
                    label="Mitigation Plan"
                    value={risk[RiskRegister.columnSchema.mitigationPlan]}
                    onChange={(x) => updateRisk({[RiskRegister.columnSchema.mitigationPlan]: x})}
                    multiline
                    rows={5}
                    variant="filled"
                    style={{marginRight: "20px", marginBottom: "20px", flexGrow: 1}}
                />
            </div>
        </div>
    )
}

const WBSCodeSection = ({record, update, WBSCodes, WBSCodeCol, idCol, errors, removeErrors}) => {

    const recordWBSCodeId = record[WBSCodeCol]
    const WBSCodeRecord = WBSCodes.find(x => x[WBSCode.columnSchema.oid]===recordWBSCodeId) || {}
    const codeId = WBSCodeRecord[WBSCode.columnSchema.oid]
    const codeName = codeId ? `${WBSCodeRecord[WBSCode.columnSchema.code]} - ${WBSCodeRecord[WBSCode.columnSchema.description]}` : ""

    const WBSCodeErrorPath = [record[idCol], WBSCodeCol]

    const WBSCodeError = errors.get(...WBSCodeErrorPath)
    const WBSCodeProps = WBSCodeError ? {error: true, helperText: WBSCodeError.getMessage()} : {}

    return (
        <PalantirAutocomplete
            label="WBS Code"
            value={{label: codeName, id: codeId}}
            options={WBSCodes.map(code => {
                return {
                    id: code[WBSCode.columnSchema.oid],
                    label: `${code[WBSCode.columnSchema.code]} - ${code[WBSCode.columnSchema.description]}`
                }
            })}
            onUpdate={(value) => {              
                removeErrors(WBSCodeErrorPath)
                update({[WBSCodeCol]: value.id})
            }}
            style={{marginRight: "20px", marginBottom: "20px", width: "250px", maxWidth: "250px"}}
            InputProps={{...WBSCodeProps}}
        />
    )
}
const IDSection = ({type, record, idCol, nameCol, update}) => {
    return (
        <div className="flex" style={{flexWrap: "wrap"}}>
            <PalantirTextField
                label={`${type} ID`}
                value={record[idCol]}
                disabled
                style={{marginRight: "20px", marginBottom: "20px", width: "300px"}}
            />
            <PalantirDispatchedTextField
                label="Name"
                value={record[nameCol]}
                onChange={(x) => {
                    update({[nameCol]: x})
                }}
                style={{marginRight: "20px", marginBottom: "20px", width: "300px"}}
            />
        </div>
    )
}
const ClosedSection = ({record, update, closedDateCol, finalCostImpactCol, finalScheduleImpactCol, resolutionDetailsCol}) => {
    return (
        <div className="flow-vertical" style={{flexWrap: "wrap"}}>
            <div className="flow-horizontal">
                <PalantirDatePicker
                    label="Closed Date"
                    value={record[closedDateCol]}
                    onChange={(x) => update({[closedDateCol]: x})}
                    style={{marginRight: "20px", marginBottom: "20px", width: "200px"}}
                />
                <PalantirTextField
                    label="Final Cost Impact ($)"
                    value={record[finalCostImpactCol]}
                    onChange={(x) => update({[finalCostImpactCol]: x})}
                    InputProps={{inputComponent: TextMaskNumber}}
                    style={{marginRight: "20px", marginBottom: "20px", width: "200px"}}
                />
                <PalantirTextField
                    label="Final Schedule Impact (weeks)"
                    value={record[finalScheduleImpactCol]}
                    onChange={(x) => update({[finalScheduleImpactCol]: x})}
                    InputProps={{inputComponent: TextMaskNumber}}
                    style={{marginRight: "20px", marginBottom: "20px", width: "200px"}}
                />
            </div>
            <PalantirDispatchedTextField
                label="Resolution Details"
                value={record[resolutionDetailsCol]}
                onChange={(x) => update({[resolutionDetailsCol]: x})}
                variant="filled"
                multiline
                rows={3}
                style={{marginRight: "20px", marginBottom: "20px", width: "300px"}}
            />
        </div>
    )
}
const MetaSection = ({record, update, statusCol, categoryCol, isCriticalCol, boardApprovalCol, resolutionDetailsCol, closedDateCol, audienceLevelCol, statusOptions, categoryOptions, audienceLevelOptions, closeSideEffects}) => {
    return (
        <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
            <PalantirSelector
                label="Status"
                value={record[statusCol]}
                items={statusOptions}
                onChange={(x) => {
                    const updates = {[statusCol]: x, [resolutionDetailsCol]: null, [closedDateCol]: null}
                    if (x==="Closed") Object.assign(updates, closeSideEffects())
                    update(updates)
                }}
                style={{marginRight: "20px", marginBottom: "20px", width: "200px"}}
            />
            <PalantirSelector
                label="Category"
                value={record[categoryCol]}
                items={categoryOptions}
                onChange={(x) => update({[categoryCol]: x})}
                style={{marginRight: "20px", marginBottom: "20px", width: "200px"}}
            />
            <PalantirSelector
                label="Is Severe"
                value={record[isCriticalCol]}
                items={booleanOptions}
                onChange={(x) => update({[isCriticalCol]: x})}
                style={{marginRight: "20px", marginBottom: "20px", width: "200px"}}
            />
            {/*<PalantirSelector
                label="Board Approval"
                value={record[boardApprovalCol]}
                items={booleanOptions}
                onChange={(x) => update({[boardApprovalCol]: x})}
                style={{marginRight: "20px", marginBottom: "20px", width: "200px"}}
            />
            <PalantirMultiSelector
                label="Audience Level"
                value={record[audienceLevelCol]}
                items={audienceLevelOptions}
                onChange={(x) => update({[audienceLevelCol]: x})}
                style={{marginBottom: "20px", width: "200px"}}
            />*/}
        </div>
    )
}
const EntitySection = ({type, update, record, ownerCompanyIdCol, counterparties, itemLeadCol}) => {

    const ownerCompanyId = record[ownerCompanyIdCol]
    const ownerCompany = counterparties.find(x =>x[Counterparty.columnSchema.counterpartyId]===ownerCompanyId)
    const ownerCompanyName = ownerCompany ? ownerCompany[Counterparty.columnSchema.counterpartyName] : null

    return (
        <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
            <PalantirAutocomplete
                label={`${type} Owner Company`}
                value={{label: ownerCompanyName, id: ownerCompanyId}}
                options={counterparties.map(cp => {
                    return {
                        label: cp[Counterparty.columnSchema.counterpartyName], id: cp[Counterparty.columnSchema.counterpartyId]
                    }
                })}
                onUpdate={(value) => {                        
                    update({[ownerCompanyIdCol]: value.id})
                }}
                style={{marginRight: "20px", marginBottom: "20px", width: "250px", maxWidth: "250px"}}
            />
            <PalantirDispatchedTextField
                label={`${type} Item Lead`}
                value={record[itemLeadCol]}
                onChange={(x) => update({[itemLeadCol]: x})}
                style={{marginRight: "20px", marginBottom: "20px", width: "250px"}}
            />
        </div>
    )
}

const costImpactRatingColorCalculator = (rating) => {
    if (rating===0) return "blue"
    else if (rating===1) return "teal"
    else if (rating===2) return "lime"
    else if (rating===3) return "yellow"
    else if (rating===4) return "orange"
    else if (rating===5) return "red"
    else return "black"
}
const opportunityCostImpactRatingColorCalculator = (rating) => {
    if (rating===0) return "red"
    else if (rating===1) return "orange"
    else if (rating===2) return "yellow"
    else if (rating===3) return "lime"
    else if (rating===4) return "teal"
    else if (rating===5) return "blue"
    else return "black"
}

const CostImpact = ({services, type, record, update, extremeCaseCostImpactCol, estimatedCostImpactCol, probabilityCol, probabilityOptions, costImpactRatingCol, costImpactRatingOptions, idCol, errors, removeErrors}) => {

    const BudgetedCostImpactErrorPath = [record[idCol], estimatedCostImpactCol]
    const BudgetedCostImpactError = errors.get(...BudgetedCostImpactErrorPath)
    const BudgetedCostImpactProps = BudgetedCostImpactError ? {error: true, helperText: BudgetedCostImpactError.getMessage()} : {}

    const costImpactCalculator = type==="risk" ? costImpactRatingColorCalculator : opportunityCostImpactRatingColorCalculator
    return (
        <div className="flow-vertical" style={{marginTop: "20px", marginRight: "80px", marginBottom: "20px"}}>
            <div className="flow-horizontal" style={{alignItems: "center", marginBottom: "6px"}}>
                <Text size="lg" weight={500} style={{marginRight: "6px"}}>Cost Impact</Text>
                <Badge variant="filled" color={costImpactCalculator(record[costImpactRatingCol])}>{record[costImpactRatingCol]}</Badge>
            </div>
            <PalantirTextField
                label={`${type==="risk" ? "Worst" : "Best"} Case Cost Impact ($)`}
                value={record[extremeCaseCostImpactCol]}
                onChange={(x) => {
                    const estimatedCostImpact = x * (record[probabilityCol]/5)
                    const option = costImpactRatingOptions.find(y => estimatedCostImpact>=y.low && y.high ? estimatedCostImpact<y.high : true)
                    update({
                        [extremeCaseCostImpactCol]: x,
                        [estimatedCostImpactCol]: estimatedCostImpact,
                        [costImpactRatingCol]: option ? option.value : null
                    })
                }}
                InputProps={{
                    inputComponent: TextMaskNumber
                }}
                style={{width: "250px", marginTop: "15px"}}
            />
            <PalantirSelector
                label="Probability"
                value={record[probabilityCol]}
                items={probabilityOptions}
                onChange={(x) => {
                    const estimatedCostImpact = record[extremeCaseCostImpactCol] * (x/5)
                    const option = costImpactRatingOptions.find(y => estimatedCostImpact>=y.low && y.high ? estimatedCostImpact<y.high : true)
                    update({
                        [probabilityCol]: x,
                        [estimatedCostImpactCol]: estimatedCostImpact,
                        [costImpactRatingCol]: option ? option.value : null
                    })
                }}
                style={{marginTop: "15px", width: "200px"}}
            />
            <PalantirTextField
                services={services}
                label="Budgeted Cost Impact ($)"
                value={record[estimatedCostImpactCol]}
                onChange={(x) => {
                    const option = costImpactRatingOptions.find(y => x>=y.low && y.high ? x<y.high : true)
                    update({
                        [estimatedCostImpactCol]: x,
                        [costImpactRatingCol]: option ? option.value : null
                    })
                    removeErrors(BudgetedCostImpactErrorPath)
                }}
                HelpProps={{
                    title: "Risk/Opportunity Register - Budgeted Cost Impact ($)",
                    item: <HelpRiskRegisterBudgetedCostImpact />,
                }}
                InputProps={{
                    inputComponent: TextMaskNumber
                }}
                {...BudgetedCostImpactProps}
                style={{width: "250px", marginTop: "15px"}}
            />
        </div>
    )
}
const ScheduleImpact = ({record, update, scheduleImpactExactWeeksCol, scheduleImpactLowWeeksCol, scheduleImpactHighWeeksCol}) => {
    
    const [duartionImpactType, setDurationImpactType] = useState(() => {
        if (record[scheduleImpactExactWeeksCol]) return "exact"
        else if (record[scheduleImpactLowWeeksCol] && record[scheduleImpactHighWeeksCol]) return "range"
        return "exact"
    })

    return (
        <div className="flow-vertical" style={{marginTop: "20px"}}>
            <Text size="lg" weight={500} style={{marginBottom: "6px"}}>Schedule Impact</Text>
            <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                <div className="flow-vertical">
                    <Text size="md">Duration</Text>
                    <SegmentedControl
                        value={duartionImpactType}
                        onChange={setDurationImpactType}
                        data={[
                            { label: 'Exact', value: 'exact' },
                            { label: 'Range', value: 'range' },
                        ]}
                        style={{width: "fit-content", marginBottom: "15px"}}
                    />
                    {duartionImpactType==="exact" &&
                        <PalantirTextField
                            label="Exact (weeks)"
                            value={record[scheduleImpactExactWeeksCol]}
                            onChange={(x) => update({
                                [scheduleImpactExactWeeksCol]: x,
                                [scheduleImpactLowWeeksCol]: null,
                                [scheduleImpactHighWeeksCol]: null

                            })}
                            InputProps={{
                                inputComponent: TextMaskInt
                            }}
                            style={{marginRight: "20px", marginBottom: "20px", width: "150px"}}
                        />
                    }
                    {duartionImpactType==="range" &&
                        <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                            <PalantirTextField
                                label="Lowside (weeks)"
                                value={record[scheduleImpactLowWeeksCol]}
                                onChange={(x) => update({
                                    [scheduleImpactLowWeeksCol]: x,
                                    [scheduleImpactExactWeeksCol]: null

                                })}
                                InputProps={{
                                    inputComponent: TextMaskInt
                                }}
                                style={{marginRight: "20px", marginBottom: "20px", width: "150px"}}
                            />
                            <PalantirTextField
                                label="Highside (weeks)"
                                value={record[scheduleImpactHighWeeksCol]}
                                onChange={(x) => update({
                                    [scheduleImpactHighWeeksCol]: x,
                                    [scheduleImpactExactWeeksCol]: null
                                })}
                                InputProps={{
                                    inputComponent: TextMaskInt
                                }}
                                style={{marginRight: "20px", marginBottom: "20px", width: "150px"}}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}