import React, { useState, useRef, useEffect } from "react"
import { useSnackbar  } from 'notistack';
import axios from "axios";
import _ from "lodash"
import { Popover, Whisper, Toggle } from 'rsuite';
import {
    Checkbox,
    Group,
    Button,
    Avatar,
    LoadingOverlay,
    Text,
    TransferList,
    TransferListData } from '@mantine/core';
import { useChangeLog } from "../hooks/changeLog";

//import { parseServerErrorResponse } from "../utils/serverResponseHandlers";
//import { makeSnackbarDismissAction } from "../utils/snackbar";
import MenuContainer from "../components/menu/MenuContainer";
import { Portfolio } from "./table_configuration/Entity";
import { Plant } from "./table_configuration/Project";
import { InverterSpec } from "./table_configuration/EquipmentSpec";
import { PalantirButton } from "../components/button/Button";
import { Panel } from "rsuite";
import { InventoryInverter } from "./table_configuration/Equipment";


const parseServerErrorResponse = () => {
    return {
        message: "Server error."
    }
}

export const InverterManagementTab = ({...props}) => {

    const [inverters, setInverters] = useState(null)
    const [inverterSpecMap, setInverterSpecMap] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const refreshInverters = () => {
        axios.get("/precious/inventory/inverter")
        .then((response) => {
            setInverters(response.data["test.inverter_warehousing"])
            setInverterSpecMap(Object.fromEntries(response.data["equipment_spec.inverter"].map(x => {
                return [x[InverterSpec.columnSchema.inverterSpecId], x]
            })))
        })
        .catch((error) => {
            let proxyResponse = parseServerErrorResponse(error, "Failed to fetch Inverters.")
            enqueueSnackbar(proxyResponse.message, {
                //action: makeSnackbarDismissAction({closeSnackbar}),
                autoHideDuration: 6000,
                variant: "error"
            })
        })
    }

    useEffect(() => {
        refreshInverters()
    }, [])

    if (!inverters) {
        return <Panel>Loading</Panel>
    }
    return (
        <InverterManagement
            initialInverters={inverters}
            inverterSpecMap={inverterSpecMap}
            {...props}
        />
    )

}

const InverterManagement = ({services, initialInverters, inverterSpecMap, portfolios, projects}) => {

    const [inverterRecords, prepareLog, updateLog, addToLog, deleteFromLog, bulkOpOnLog, mergeAndResetLog, resetLog] = useChangeLog(initialInverters, InventoryInverter)
    const [selectedLeftProjectId, setSelectedLeftProjectId] = useState(null)
    const [selectedRightProjectId, setSelectedRightProjectId] = useState(null)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false)

    const [leftFilters, setLeftFilters] = useState({inverter_spec_id: new Set()})
    const [rightFilters, setRightFilters] = useState({inverter_spec_id: new Set()})

    const makeInverters = (projectId, _filters) => {

        return inverterRecords.filter(x => {
            return x.project_id===projectId && (_filters.inverter_spec_id.size===0 ? true : _filters.inverter_spec_id.has(x.inverter_spec_id))
        }).map(x => {
            const inverterSpecId = x.inverter_spec_id
            const inverterSpec = inverterSpecMap[inverterSpecId] || {}
            return {
                label: x.serial_number,
                value: x.inverter_id,
                manufacturer: inverterSpec.manufacturer,
                model: inverterSpec.model
            }
        })
    }

    let transferListData = [makeInverters(selectedLeftProjectId, leftFilters), makeInverters(selectedRightProjectId, rightFilters)]
    const leftLength = transferListData[0].length
    const rightLength = transferListData[1].length
    return (
        <div className="fill-parent" >
            <div className="flow-horizontal" style={{padding: "20px"}}>
                <EquipmentGroupMenu
                    portfolios={portfolios}
                    projects={projects}
                    selectedProjectId={selectedLeftProjectId}
                    onChange={(projectId) => {
                        if (projectId===selectedLeftProjectId) setSelectedLeftProjectId(null)
                        else setSelectedLeftProjectId(projectId)
                    }}
                />
                <div className="flow-vertical fill-parent">
                    {loading && <LoadingOverlay visible={loading} overlayBlur={5} />}
                    <div className="flow-horizontal" style={{justifyContent: "space-between", height: "50px"}}>
                        <FilterThing
                            inverterRecords={inverterRecords}
                            inverterSpecMap={inverterSpecMap}
                            filters={leftFilters}
                            setFilters={setLeftFilters}
                        />
                        <FilterThing
                            inverterRecords={inverterRecords}
                            inverterSpecMap={inverterSpecMap}
                            filters={rightFilters}
                            setFilters={setRightFilters}
                        />
                    </div>
                    <TransferList
                        titles={[
                            selectedLeftProjectId ? projects.find(x => x.plant_id===selectedLeftProjectId)?.plant_name+` (${leftLength} items)` : `Unassigned (${leftLength} items)`,
                            selectedRightProjectId ? projects.find(x => x.plant_id===selectedRightProjectId)?.plant_name+` (${rightLength} items)` : `Unassigned (${rightLength} items)`
                        ]}
                        value={transferListData}
                        nothingFound="No equipment assigned."
                        itemComponent={TransferListItemComponent}
                        breakpoint="sm"
                        limit={50}
                        listHeight="500px"
                        onChange={(changedData) => {
                            if (selectedLeftProjectId===selectedRightProjectId) {
                                enqueueSnackbar("Can't assign equipment to the same entity.", {
                                    //action: makeSnackbarDismissAction({closeSnackbar}),
                                    //autoHideDuration: 3000,
                                    variant: "error"
                                })
                                return
                            }
                            let originalLeftValues = new Set(transferListData[0].map(x => x.value))
                            let newLeftValues = new Set(changedData[0].map(x => x.value))
                            let transferedToLeftValues = new Set([...newLeftValues].filter(x => !originalLeftValues.has(x)))   // add to left and remove from right
                            let transferedFromLeftValues = new Set([...originalLeftValues].filter(x => !newLeftValues.has(x)))   // add to right and remove from left

                            const changes = []
                            transferedToLeftValues.forEach(inverterId => {
                                const inverter = inverterRecords.find(x => x.inverter_id===inverterId)
                                changes.push([inverter, {project_id: selectedLeftProjectId}])
                                /*changes.push({
                                    method: "update",
                                    changes: [inverter, {project_id: selectedLeftProjectId}]
                                })*/
                            })
                            transferedFromLeftValues.forEach(inverterId => {
                                const inverter = inverterRecords.find(x => x.inverter_id===inverterId)
                                changes.push([inverter, {project_id: selectedRightProjectId}])
                                /*changes.push({
                                    method: "update",
                                    changes: [inverter, {project_id: selectedRightProjectId}]
                                })*/
                            })
                            bulkOpOnLog([{
                                method: "update",
                                changes: changes
                            }])
                        }}
                        style={{flexGrow: 1, margin: "0 20px"}}
                        searchPlaceholder="Search for equipment..."
                        filter={(query, item) =>
                            item.label.toLowerCase().includes(query.toLowerCase().trim()) ||
                            item.value.toLowerCase().includes(query.toLowerCase().trim()) ||
                            item.manufacturer.toLowerCase().includes(query.toLowerCase().trim()) ||
                            item.model.toLowerCase().includes(query.toLowerCase().trim())
                        }
                    />
                </div>
                <EquipmentGroupMenu
                    portfolios={portfolios}
                    projects={projects}
                    selectedProjectId={selectedRightProjectId}
                    onChange={(projectId) => {
                        if (projectId===selectedRightProjectId) setSelectedRightProjectId(null)
                        else setSelectedRightProjectId(projectId)
                    }}
                />
            </div>
            <div className="flow-horizontal">
                <Text size="xs" color="dimmed" style={{flexShrink: 0, marginLeft: "10px"}}>*Maximum items displayed in one list is 50.</Text>
                <div className="fill-parent"></div>
                <PalantirButton onClick={resetLog} style={{marginRight: "15px"}}>Reset</PalantirButton>
                <PalantirButton
                    style={{marginRight: "15px"}}
                    onClick={() => {
                        axios.post("/precious/inventory/inverter", {inverterChangelog: prepareLog()})
                        .then((response) => {
                            mergeAndResetLog()
                            enqueueSnackbar("Successfully assigned inverters.", {
                                //action: makeSnackbarDismissAction({closeSnackbar}),
                                autoHideDuration: 3000,
                                variant: "success"
                            })
                            setLoading(false)
                        })
                        .catch((error) => {
                            let proxyResponse = parseServerErrorResponse(error, "Failed to reassign Inverters.")
                            enqueueSnackbar(proxyResponse.message, {
                                //action: makeSnackbarDismissAction({closeSnackbar}),
                                autoHideDuration: 6000,
                                variant: "error"
                            })
                            setLoading(false)
                        })
                        setLoading(true)
                    }}
                >
                    Save
                </PalantirButton>
            </div>
        </div>
    )
}

const FilterThing = ({...props}) => {

    const whisperRef = useRef();

    return (
        <Whisper
            ref={whisperRef}
            trigger="click"
            placement="bottomLeft"
            speaker={<FilterMenu {...props} close={() => whisperRef.current.close()} />}
        >
            <Button appearance="subtle">{"Filters"}</Button>
        </Whisper>
    )
}
const FilterMenu = React.forwardRef(({close, inverterRecords, inverterSpecMap, filters, setFilters, ...props}, ref) => {

    const [_filters, _setFilters] = useState(filters)
    const updateFilters = (id, filtered) => {
        const newSpecFilter = new Set([..._filters.inverter_spec_id])
        if (filtered) newSpecFilter.add(id)
        else newSpecFilter.delete(id)
        _setFilters({inverter_spec_id: newSpecFilter})
    }

    var uniqueInverterSpecs = Array.from(new Set(_.uniq(inverterRecords.map(x => x.inverter_spec_id))))
    uniqueInverterSpecs = uniqueInverterSpecs.map(x => {
        let spec = inverterSpecMap[x] || {}
        return {label: `${spec.manufacturer} - ${spec.model}`, value: x}
    })

    return (
        <Popover title="Filters" {...props}>
            <div style={{minWidth: "200px", maxHeight: "600px", overflow: "auto"}}>
                <Text weight={500} style={{textDecoration: "underline"}} >Equipment Models</Text>
                {uniqueInverterSpecs.map(x => {
                    return (
                        <div className="flow-horizontal" style={{marginBottom: "5px"}}>
                            <Checkbox size="xs" checked={_filters.inverter_spec_id.has(x.value)} onChange={(e) => updateFilters(x.value, e.currentTarget.checked)} sx={{marginRight: "10px"}} />
                            <div style={{flexShrink: 0}}>
                                <Text size="xs" weight={400}>
                                    {x.label}
                                </Text>
                            </div>
                        </div>
                    )
                })}
                <Button size="xs" appearance="subtle" color="green" style={{marginTop: "10px"}} onClick={() => {setFilters(_filters); close()}} >Save</Button>
                <Button size="xs" appearance="subtle" color="gray" style={{marginLeft: "10px"}} onClick={close} >Cancel</Button>
            </div>
        </Popover>
    )
})

const TransferListItemComponent = ({data, selected}) => (
    <Group noWrap>
        <div style={{ flex: 1 }}>
            <Text size="sm" weight={500}>
                {data.manufacturer} - {data.model}
            </Text>
            <Text size="xs" color="dimmed" weight={400}>
                {data.value}
            </Text>
            <Text size="xs" color="dimmed" weight={200}>
                {data.label}
            </Text>
        </div>
        <Checkbox checked={selected} onChange={() => {}} tabIndex={-1} sx={{ pointerEvents: 'none' }} />
    </Group>
);

const EquipmentGroupMenu = ({portfolios, projects, selectedProjectId, onChange}) => {
    return (
        <MenuContainer
            menuSkeleton={buildTableHeirarchy(portfolios, projects, selectedProjectId)}
            headerName={"Projects"}
            leafNodeClick={(menuItem) => onChange(menuItem.key)}
            className="plant-menu sidebar fill-parent"
            searchable
            collapsable
            style={{height: "500px", backgroundColor: "white"}}
        />
    )
}
 

const buildTableHeirarchy = (portfolios, projects, selectedProjectId) => {

    portfolios = _.sortBy(portfolios, x => x[Portfolio.columnSchema.portfolioName])
    return portfolios.map(portfolio => {

        let portfolioId = portfolio[Portfolio.columnSchema.portfolioId]
        let portfolioName = portfolio[Portfolio.columnSchema.portfolioName]

        let filteredProjects = _.sortBy(projects.filter(x => x[Plant.columnSchema.portfolioId]===portfolioId), x => x[Plant.columnSchema.plantName])
        let projectSkeletons = filteredProjects.map(project => {

            let projectId = project[Plant.columnSchema.plantId]
            let projectName = project[Plant.columnSchema.plantName]

            return {
                key: projectId,
                label: projectName,
                selectable: true,
                active: selectedProjectId===projectId,
                children: null
            }

        })

        return {
            key: portfolioId,
            label: portfolioName,
            selectable: false,
            children: projectSkeletons
        }

    })

}
