import './SectionHeader.css'
import {Component} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

class SectionHeader extends Component {
    constructor(props) {
        super()

    }

    render() {
        return (
            <div className="section-header flow-horizontal">
                <div className="title">{this.props.sectionTitle}</div>
                <div className="help-icon" onClick={() => this.props.services.help.setHelpConfig(this.props.sectionTitle, this.props.sectionDescription)} >
                    <FontAwesomeIcon icon={faQuestion} size="sm" fixedWidth />
                </div>
                {this.props.children}
            </div>
        )
    }
}

export default SectionHeader