import './DateRangePicker.css'
import 'rsuite/dist/styles/rsuite-default.css'
import React, { Component, useState } from 'react'
import { DateRangePicker } from 'rsuite';
//import { DateRangePicker } from '@mantine/dates';
import { Popover } from '@mantine/core';
import { formatDate } from '../../utils/Dates'
import { 
    getStartTime as getStartDateUrlParam,
    setStartTime as setStartDateUrlParam,
    getEndTime as getEndDateUrlParam,
    setEndTime as setEndDateUrlParam
} from '../../utils/UrlLocation'
import { startOfDay, endOfDay , addDays, previousSunday, startOfMonth, endOfMonth, sub } from 'date-fns'


export const useDateRangeSelectionState = ({useUrlParam=true, initStartDate, initEndDate}) => {

    const [startDate, setStartDate] = useState(() => {
        return useUrlParam ? getStartDateUrlParam() : formatDate(startOfDay(initStartDate || new Date()))
    })
    const [endDate, setEndDate] = useState(() => {
        return useUrlParam ? getStartDateUrlParam() : formatDate(endOfDay(initEndDate || new Date()))
    })

    const updateDateRange = (newStartDate, newEndDate) => {
        if (useUrlParam) {
            setStartDateUrlParam(newStartDate)
            setEndDateUrlParam(newEndDate)
        }
        setStartDate(newStartDate)
        setEndDate(newEndDate)
    }

    return [
        startDate, endDate,
        updateDateRange
    ]

}

const SuperDateRangePicker = React.memo(({startDate, endDate, onValueChange, containerProps={}, ranges=allRangesWithTomorrow}) =>{

    return (
        <DateRangePicker
            defaultValue={[new Date(startDate), new Date(endDate)]}
            ranges={ranges}
            onChange={(values) => {
                let [start, end] = [values[0], values[1]]
                end = endOfDay(end)
        
                start = formatDate(start)
                end = formatDate(end)
                onValueChange(start, end)
            }}
            cleanable={false}
            placement="auto"
            appearance="subtle"
            {...containerProps}
        />
    )

}, (prevProps, nextProps) => {
    return (
        prevProps.startDate===nextProps.startDate &&
        prevProps.endDate===nextProps.endDate
    )
})

export default SuperDateRangePicker


export const TomorrowRange = {
    label: 'Tomorrow',
    value: [startOfDay(addDays(new Date(), 1)), endOfDay(addDays(new Date(), 1))]
}
export const TodayRange = {
    label: 'Today',
    value: [startOfDay(new Date()), endOfDay(new Date())]
}
export const YesterdayRange = {
    label: 'Yesterday',
    value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
}
export const Last3DaysRange = {
    label: 'Last 3 Days',
    value: [startOfDay(addDays(new Date(), -2)), endOfDay(new Date())]
}
export const Last7DaysRange = {
    label: 'Last 7 Days',
    value: [startOfDay(addDays(new Date(), -6)), endOfDay(new Date())]
}
export const WeekToDateRange = {
    label: 'Week to Date',
    value: [startOfDay(addDays(previousSunday(new Date()), 1)), endOfDay(new Date())]
}
export const MonthToDateRange = {
    label: 'Month-To-Date',
    value: [startOfMonth(new Date()), endOfDay(new Date())]
}
export const LastMonthRange = {
    label: 'Last Month',
    value: [startOfMonth(sub(new Date(), {months: 1})), endOfMonth(sub(new Date(), {months: 1}))]
}

export const allRangesWithTomorrow = [TomorrowRange, TodayRange, YesterdayRange, Last3DaysRange, Last7DaysRange, WeekToDateRange, MonthToDateRange, LastMonthRange]
export const shortRangesWithTomorrow = [TomorrowRange, TodayRange, YesterdayRange, Last3DaysRange, Last7DaysRange]
export const allRanges = [TodayRange, YesterdayRange, Last3DaysRange, Last7DaysRange, WeekToDateRange, MonthToDateRange, LastMonthRange]
export const shortRanges = [TodayRange, YesterdayRange, Last3DaysRange, Last7DaysRange]
export const longRanges = [Last7DaysRange, WeekToDateRange, MonthToDateRange, LastMonthRange]