import React, {Component} from 'react'

import AssetMenu from '../../menu/AssetMenu'
import Navbar from '../../navbar/Navbar'
import EventBlueprintConfiguration from './events/EventBlueprintConfiguration'
import EventTemplateConfiguration from './events/EventTemplateConfiguration'
import TagBlueprintConfiguration from './tags/TagBlueprintConfiguration'

import { getPortfolio, setPortfolio, getSite, setSite } from '../../../utils/UrlLocation'


class ConfigureTab extends Component {

    constructor(props) {
        super()
        this.state = {
            selectedPortfolioId: getPortfolio(),
            selectedSiteId: getSite(),
        }
    }

    componentDidMount() {
        
    }

    render() {

        return (
            <div className="flow-horizontal fill-parent">
                <AssetMenu 
                    services={this.props.services} 
                    updateSelectedSite={this.updateSelectedSiteId.bind(this)} 
                    updateSelectedPortfolio={this.updateSelectedPortfolioId.bind(this)}
                    selectedSiteId={this.state.selectedSiteId}
                    selectedPortfolioId={this.state.selectedPortfolioId}
                    className="sidebar"
                    menuProps={{
                        collapsable: true
                    }}
                />
                <Navbar 
                    services={this.props.services}
                    defaultURL="event_blueprints"
                    items={[
                        {
                            label: "Event Blueprints",
                            url: "event_blueprints",
                            component: EventBlueprintConfiguration,
                            props: {
                                key: this.state.selectedSiteId,
                                services: this.props.services,
                                selectedSiteId: this.state.selectedSiteId
                            }
                        },
                        {
                            label: "Event Templates",
                            url: "event_templates",
                            component: EventTemplateConfiguration,
                            props: {
                                key: this.state.selectedSiteId,
                                services: this.props.services,
                                selectedSiteId: this.state.selectedSiteId
                            }
                        },
                        {
                            label: "Tag Blueprints",
                            url: "tag_blueprints",
                            component: TagBlueprintConfiguration,
                            props: {
                                key: this.state.selectedSiteId,
                                services: this.props.services,
                                selectedSiteId: this.state.selectedSiteId
                            }
                        },
                    ]}
                />
            </div>
        )
    }

    updateSelectedSiteId(id) {
        setSite(id)
        setPortfolio(null)
        this.setState({
            selectedPortfolioId: null,
            selectedSiteId: id,
        })
    }

    updateSelectedPortfolioId(id) {
        setPortfolio(id)
        setSite(null)
        this.setState({
            selectedPortfolioId: id,
            selectedSiteId: null,
        })
    }

}

export default ConfigureTab