import { Fund, Portfolio, Employee, Counterparty, Contact, Address } from './table_configuration/Entity'
import { 
    Bank, Developer, EPC, Interconnection, Investor, Owner,
    AssetManager, OandM, OEM, Offtake, Operator, SchedulingCoordinator, Permitter, BESSIntegrator, ServiceProvider, Landowner
} from './table_configuration/Counterparty'
import {
    PlantLLC, LandownerLLC, LandownerLLCContacts, OandMLLC, OandMJunction,
    BankLLC, BankJunction, InvestorLLC, InvestorJunction,
} from './table_configuration/LLC'
import {
    Plant, PersonnelProjectRelationships, PersonnelRelationshipContactLevels, Personnel, Ownership, GovernmentAffairs, Compliance, ProjectIDs, IDType,
    TechnicalChecklist, TechnicalChecklistItems, RiskRegister, OpportunityRegister,
    TemporalProjectPricingType, TemporalProjectPricingBlocks, TemporalProjectPricingPrices,
    BusinessCategory, LessonLearned, LessonLearnedInstances, WBSCode, ProjectBudgeting,
    EnvironmentalAndPermittingChecklist, EnvironmentalAndPermittingChecklistItem, EnvironmentalAndPermittingChecklistEntry
} from './table_configuration/Project'
import { ProjectInverter, ProjectModule, ProjectTransformer, ProjectBESS, ProjectTracker, ProjectBreaker } from './table_configuration/ProjectEquipment'
import { 
    DebtPartnerContract, ContractDebtPartnerCounterparties, DebtPartnerPlantLLC, PlantDebtPartnerJunction
} from './table_configuration/DebtPartner'
import {
    TaxEquityLLC, TaxEquityLLCBanks, 
    TaxEquityContract, TaxEquityContractCounterparties,
} from './table_configuration/TaxEquityPartner'
import {
    CoInvestorPlantLLC, PlantCoInvestorJunction, 
    CoInvestorContract, ContractCoInvestorCounterparties,
} from './table_configuration/CoInvestor'
import { 
    DeveloperContract, DeveloperCounterpartiesContract, EPCContract, EPCCounterpartiesContract, 
    InterconnectionContract, InterconnectionCounterpartiesContract, LandContract, LandLLCsContract, LandContractLandowners, LandContractCounterparties,
    LTSAContract, LTSACounterpartiesContract,
    OandMContract, OandMContractFeeSchedule, OandMContractScopes, OandMCounterpartiesContract, 
    OfftakeContract, OfftakeCreditRating, OfftakeCounterpartiesContract, OfftakeSCCounterpartiesContract,
    PermittingContract, PermittingDatesContract,
} from './table_configuration/Contract'
import { OfftakeContractOffer } from './table_configuration/ContractOffer'
import { InverterSpec, ModuleSpec, TransformerSpec, BESSSpec, TrackerSpec } from './table_configuration/EquipmentSpec'
import { InverterWarranty, ModuleWarranty, TransformerWarranty } from './table_configuration/Warranty'
import { PreConMetStation, InventoryInverter, Warehouse } from './table_configuration/Equipment'
import { EquipmentPurchaseContract, EquipmentDeliveryPaymentMilestone, EquipmentPaymentStructureTemplate, EquipmentPaymentRule } from './table_configuration/Payment'
import { InterconnectionExpectedCosts, InterconnectionStudyPhasePayment, InterconnectionStudyPhasePaymentEntityMapping, InterconnectionQueueSubmissionWindow, InterconnectionNetworkUpgrades } from './table_configuration/Interconnection'
import { StageMilestoneMatrix, StageMilestoneMatrixItem, StageMilestoneMatrixEntry, StageMilestoneMatrixCategorySummaryEntry } from './table_configuration/Development'
import { ReportType, Report, ReportCommentTopic, ReportRelevantCommentTopics, ReportComment } from './table_configuration/Reporting'


// Tables

export const equipmentTables = [PreConMetStation]
export const inventoryTables = [InventoryInverter, Warehouse]
export const counterpartyTables = [
    AssetManager, Bank, BESSIntegrator, Developer, EPC, Interconnection, Investor,
    OandM, OEM, Offtake, Operator, Owner, Permitter, SchedulingCoordinator, ServiceProvider, Landowner
]
export const llcTables = [
    PlantLLC, LandownerLLC, LandownerLLCContacts, OandMLLC, OandMJunction, BankLLC, BankJunction, InvestorLLC, InvestorJunction,
]
export const entityTables = [Fund, Portfolio, Counterparty, Employee, Contact, Address]
export const projectTables = [Plant, PersonnelProjectRelationships, PersonnelRelationshipContactLevels, Personnel, Ownership, GovernmentAffairs, Compliance, ProjectIDs, ProjectBudgeting]
export const projectEquipmentTables = [ProjectInverter, ProjectModule, ProjectTransformer, ProjectBESS, ProjectTracker, ProjectBreaker]
export const equipmentSpecTables = [InverterSpec, ModuleSpec, TransformerSpec, BESSSpec, TrackerSpec]
export const warrantyTables = [InverterWarranty, ModuleWarranty, TransformerWarranty]
export const contractTables = [
    DeveloperContract, DeveloperCounterpartiesContract,
    EPCContract, EPCCounterpartiesContract,
    InterconnectionContract, InterconnectionCounterpartiesContract,
    LandContract, LandLLCsContract, LandContractLandowners, LandContractCounterparties,
    LTSAContract, LTSACounterpartiesContract,
    OandMContract, OandMContractFeeSchedule, OandMContractScopes, OandMCounterpartiesContract,
    OfftakeContract, OfftakeCreditRating, OfftakeCounterpartiesContract, OfftakeSCCounterpartiesContract,
    PermittingContract, PermittingDatesContract,
    EnvironmentalAndPermittingChecklist, EnvironmentalAndPermittingChecklistItem, EnvironmentalAndPermittingChecklistEntry
]
export const contractOfferTables = [OfftakeContractOffer]
export const debtPartnerTables = [
    DebtPartnerContract, ContractDebtPartnerCounterparties, DebtPartnerPlantLLC, PlantDebtPartnerJunction,
]
export const taxEquityPartnerTables = [
    TaxEquityContract, TaxEquityContractCounterparties,
    TaxEquityLLC, TaxEquityLLCBanks,
]
export const coInvestorTables = [
    CoInvestorContract, ContractCoInvestorCounterparties,
    CoInvestorPlantLLC,
    PlantCoInvestorJunction,
]
export const equipmentContractTables = [
    EquipmentPurchaseContract, EquipmentDeliveryPaymentMilestone, EquipmentPaymentStructureTemplate, EquipmentPaymentRule
]
export const developmentEngineeringTables = [
    TechnicalChecklist, TechnicalChecklistItems, RiskRegister, OpportunityRegister
]
export const interconnectionTables = [InterconnectionExpectedCosts, InterconnectionStudyPhasePayment, InterconnectionStudyPhasePaymentEntityMapping, InterconnectionQueueSubmissionWindow, InterconnectionNetworkUpgrades]
export const developmentTables = [StageMilestoneMatrix, StageMilestoneMatrixEntry, StageMilestoneMatrixItem, StageMilestoneMatrixCategorySummaryEntry]
export const projectPricingTables = [TemporalProjectPricingType, TemporalProjectPricingBlocks, TemporalProjectPricingPrices]
export const generalBusinessTables = [BusinessCategory, WBSCode, IDType]
export const lessonLearnedTables = [LessonLearned, LessonLearnedInstances]
export const reportingTables = [ReportType, Report, ReportCommentTopic, ReportRelevantCommentTopics, ReportComment]


// Schemas

export const tableGroups = [{
        displayName: "Counterparties",
        tables: counterpartyTables,
    }, {
        displayName: "LLCs",
        tables: llcTables,
    }, {
        displayName: "Entities",
        tables: entityTables,
    }, {
        displayName: "General Business",
        tables: generalBusinessTables
    }, {
        displayName: "Project",
        tables: projectTables,
    }, {
        displayName: "Project Equipment",
        tables: projectEquipmentTables,
    }, {
        displayName: "Equipment Specification",
        tables: equipmentSpecTables,
    }, {
        displayName: "Equipment Warranties",
        tables: warrantyTables,
    }, {
        displayName: "Contracts",
        tables: contractTables,
    }, {
        displayName: "Contract Offers",
        tables: contractOfferTables,
    }, {
        displayName: "Debt Partners",
        tables: debtPartnerTables,
    }, {
        displayName: "Tax Equity Partners",
        tables: taxEquityPartnerTables,
    }, {
        displayName: "Co-Investors",
        tables: coInvestorTables,
    }, {
        displayName: "Development Engineering",
        tables: developmentEngineeringTables
    }, {
        displayName: "Development",
        tables: developmentTables
    }, {
        displayName: "Interconnection",
        tables: interconnectionTables
    }, {
        displayName: "Inventory",
        tables: inventoryTables
    }, {
        displayName: "Equipment",
        tables: equipmentTables
    }, {
        displayName: "Equipment Contract",
        tables: equipmentContractTables
    }, {
        displayName: "Project Pricing",
        tables: projectPricingTables
    }, {
        displayName: "Lessons Learned",
        tables: lessonLearnedTables
    }, {
        displayName: "Reporting",
        tables: reportingTables
    }
]