import './MarketTable.css'
import {Component} from 'react'

class MarketTable extends Component {

    constructor(props) {
        super()

        this.state = {

        }
    }

    render() {
        console.log("Rendering market table")

        var tableHeaderCells = this.createTableHeader(this.props.data.meta.reportTimestamp, this.props.data.meta.reportGeneratedTimestamp, this.props.data.meta.dataKeys)
        console.log(this.props.data.meta.dataKeys)
        console.log(this.props.data)
        //delete this.props.data.meta
        var tableRows = this.createTableRows(this.props.data)

        return (
            <div className="section-panel overflow-y">
                <table className="market-table" style={{fontSize: '0.9em', tableLayout: 'fixed', width: '100%'}}>
                    {tableHeaderCells}
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            </div>
        )
    }

    /* report date 1-24 hours of day */
    createTableHeader(reportTimestamp, reportGeneratedTimestamp, dataKeys) {

        [reportTimestamp, reportGeneratedTimestamp] = [new Date(reportTimestamp), new Date(reportGeneratedTimestamp)]

        var reportIsCurrentDay = reportGeneratedTimestamp.toLocaleString('en-US', {dateStyle: "long"})===reportTimestamp.toLocaleString('en-US', {dateStyle: "long"})
        var reportIsHistorical, currentLocalizedHour;
        if (!reportIsCurrentDay) {
            reportIsHistorical = reportTimestamp < reportGeneratedTimestamp
        }
        else {
            currentLocalizedHour = Number(reportGeneratedTimestamp.toLocaleString('en-US', {hour: "2-digit", hour12: false}))+1
            currentLocalizedHour = currentLocalizedHour > 24 ? "1" : String(currentLocalizedHour)
        }

        var reportDateCell = <th key={String(reportTimestamp)} colSpan="3" className="section-header">{reportTimestamp.toDateString()}</th>

        var hourCells = dataKeys.map(function(x) {

            let cellTime = "historical"
            if (reportIsCurrentDay) {
                if (Number(x)===Number(currentLocalizedHour)) {
                    cellTime = "current"
                }
                else {
                    cellTime = (x < currentLocalizedHour ? "historical" : "forecast")
                }
            }
            else {
                cellTime = (reportIsHistorical ? "historical" : "forecast")
            }

            return <th key={x} className={cellTime}>{x}</th>
        })

        var tableHeaderCells = [reportDateCell, hourCells]
        var tableHeader = <thead><tr className="header">{tableHeaderCells}</tr></thead>
        return tableHeader
    }

    createTableRows(data) {

        var tableRows = []

        // Iterate grouped tags
        for (let key of Object.keys(data)) {
            if (key==="meta") continue

            let tableGroupHeader = <tr key={key}>
                                        <th colSpan="3" className="section-header">{key}</th>
                                    </tr>
            tableRows.push(tableGroupHeader)

            // Iterate all dimension in tag
            if (!data[key]) continue
            for (let dataChunk of data[key]) {
                let rowLabel = dataChunk.label
                let rowData = Object.values(dataChunk.data)
                let tableRow = this.createTableRow(rowData, rowLabel)
                tableRows.push(tableRow)
            }

        }

        return tableRows

    }

    createTableRow(data, label) {
        var rowCells = data.map((x, idx) => <td key={idx}>{(typeof x == "number") ? x.toFixed(2) : x}</td>)
        // Uh, use a better key here?
        let tableRow = <tr key={Math.random()} > 
                            <td colSpan="3">{label}</td>
                            {rowCells}
                        </tr>
                        
        return tableRow
    }

}

export default MarketTable