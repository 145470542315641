import './InputCss.css'
import { useState } from 'react'

import { format as formatDate } from 'date-fns'
import { convertToUTC } from '../../utils/Dates'
import { HelpPrompt } from '../help/HelpPrompt';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Autocomplete  from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { OutlinedInput } from '@mui/material';



let nullCheck = (x) => (x===null || x===undefined)

export function PalantirSelector({label, value, items, onChange, helperText, variant="standard", style={}, minWidth, HelpProps=null, FormControlProps, ...props}) {

    var label = label || ""
    var labelLower = label.toLowerCase()
    
    var items = items.map(x => {
        if (x.subheader) return <ListSubheader style={{fontSize: "20px", textDecoration: "underline"}}>{x.label}</ListSubheader>
        else return <MenuItem value={x.value}>{x.label}</MenuItem>
    })

    var style = Object.assign({minWidth: minWidth || 180}, style)


    return (
        <TextField
            select
            id={labelLower}
            label={label}
            helperText={helperText}
            variant={variant}
            size="small"
            value={nullCheck(value) ? "" : value}
            onChange={(e) => {
                if (onChange) onChange(e.target.value)
            }}
            InputProps={{
                endAdornment: HelpProps ? <InputAdornment position="start">
                                                <HelpPrompt
                                                    services={props.services}
                                                    style={{position: "relative", right: "12px"}}
                                                    title={HelpProps.title}
                                                    item={HelpProps.item}
                                                />
                                            </InputAdornment> : null
            }}
            sx={style}
            {...props}
        >
            {items}
        </TextField>
    )

    return (
        <FormControl variant={variant || "standard"} size="small" sx={Object.assign({minWidth: minWidth || 180, flexGrow: 1})} style={style || {}} {...FormControlProps}>
            {label && <InputLabel id={labelLower} >{label}</InputLabel>}
            <Select
                labelId={labelLower}
                id={labelLower}
                value={nullCheck(value) ? "" : value}
                onChange={(e) => {
                    if (onChange) onChange(e.target.value)
                }}
                renderValue={(value) => <TextField
                                            variant="standard"
                                            size="small"
                                            value={(value===null || value===undefined) ? "" : value}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start"><HelpPrompt style={{position: "relative", bottom: "3px"}} /></InputAdornment>,
                                            }}
                                        />
                }
                
                {...props}
            >
                {items}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

export function PalantirAutocomplete({value, label, onUpdate, options, HelpProps=null, InputProps={}, ...props}) {

    var initialInputValue = value.label
    // Mui throws error/warning when input value is not a string
    if (initialInputValue===null||initialInputValue===undefined) {
        initialInputValue = ""
    }

    const [inputValue, setInputValue] = useState(initialInputValue);
    
  
    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => onUpdate(newValue ? newValue : {label: null, id: null})}

            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}

            options={options}

            size="small"
            renderInput={(params) => {
                let className = ""
                if (HelpProps) {
                    let offset = props.disableClearable ? "-5px" : "-30px"
                    let adornment = <InputAdornment position="end">
                                        <HelpPrompt
                                            services={props.services}
                                            style={{position: "relative", right: offset, zIndex: 1}}
                                            title={HelpProps.title}
                                            item={HelpProps.item}
                                        />
                                    </InputAdornment>
                    params.InputProps.endAdornment = [adornment, params.InputProps.endAdornment]
                    className+=" help-adornment"
                }

                return <TextField
                            className={className}
                            variant={props.variant || "standard"}
                            label={label}
                            {...params}
                            {...InputProps}
                        />
                }
            }
            isOptionEqualToValue={(option, value) => {
                return option.id===value.id
            }}
            getOptionLabel={(x) => {
                return x.label===null||x.label===undefined ? "" : x.label
            }}
            sx={{flexGrow: 1}}
            
            {...props}
        />
    );
}

export function PalantirMultiSelector({label, value, items, onChange, helperText, delimeter=",", style={}, minWidth, HelpProps=null, FormControlProps, ...props}) {

    var label = label || ""
    var labelLower = label.toLowerCase()
    
    var items = items.map(x => {
        if (x.subheader) return <ListSubheader style={{fontSize: "20px", textDecoration: "underline"}}>{x.label}</ListSubheader>
        else return (
            <MenuItem
              key={x.value}
              value={x.value}
            >
                {x.label}
            </MenuItem>
        )
    })

    var style = Object.assign({minWidth: minWidth || 180}, style)

    return (
        <FormControl variant="standard">
            <InputLabel>{label}</InputLabel>
            <Select
                id={labelLower}
                multiple
                value={nullCheck(value) ? [] : value.split(delimeter)}
                helperText={helperText}
                variant="standard"
                size="small"
                onChange={(e) => {
                    let v = e.target.value ? e.target.value.join(delimeter) : null
                    if (v==="") v = null
                    if (onChange) onChange(v)
                }}
                sx={style}
                {...props}
            >
                {items}
            </Select>
        </FormControl>
    )
}

/**
 * If a string is provided, assumed timezone will be UTC.
 * Default is to return a date string.
 * @param {*} props props to be passed to DatePicker components. 
 * @returns DatePicker Component
 */
export function PalantirDatePicker({value, label, onChange, TextFieldProps, HelpProps, ...props}) {

    let date = value===undefined ? null : value
    if (typeof(date)==="string") date = convertToUTC(new Date(date))

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                value={date}
                onChange={(newValue) => {
                    onChange(newValue)
                    //onChange(!newValue || isNaN(newValue) ? newValue : formatDate(newValue, "yyyy-MM-dd"))
                }}
                renderInput={(params) => <TextField
                                            size="small"
                                            variant="standard"
                                            style={props.style || {paddingRight: "10px"}}
                                            {...params}
                                            {...TextFieldProps || {}}
                                        />}
                {...props}
            />
        </LocalizationProvider>
    )
}

export function PalantirBooleanSelector({checked, label, ...props}) {

    return (
        <FormControlLabel
            control={
                <Checkbox 
                    checked={Boolean(checked)}
                    onChange={(e) => props.onChange(e.target.checked)}
                    name={label} />
            }
            label={label}
            {...props}
        />
    )
}

export function PalantirToggle({checked, label, onChange, InputProps, ...props}) {

    //const [checked, setChecked] = useState(true);

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={(e) => onChange(e.target.checked)}
                    inputProps={{...InputProps}}
                    name={label}
                    {...props}
                />
            }
            label={label}
        />
    );
}