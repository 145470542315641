import './TagDimensionMenu.css'
import { useState, useEffect } from 'react'

import TagMenu from './TagMenu'
import DimensionMenu from './DimensionMenu'
import Panel from '../panel/Panel'
import _ from 'lodash'

import { getTags as getTagsUrlParam, setTags as setTagUrlParam, getDimensions as getDimensionsUrlParam, setDimensions as setDimensionUrlParam } from '../../utils/UrlLocation'
import { isDeviceSubscribedToIntervalTagService, checkTagTypeIsSource, isHighPriorityTag, getLocalSettingShowOnlyHighPrioTags, toggleLocalSettingShowOnlyHighPrioTags } from '../../utils/assetFrameworkMacros'
import { TAG_TYPES } from '../../utils/palantirSchema'

/*
Configure hiding a menu.
Border when missing device.
Finish function comments.
Macro for checking device length?
*/


export const TagDimensionMenu = ({...props}) => {

    var className = "tag-dimension-menu flow-vertical" + (props.className ? " " + props.className : "")

    if (props.selectedDeviceIds.length===0 && !props.selectedDeviceGroupId) {
        return <Panel className={className} style={{border: "dashed lightgrey 1px"}}>Please select a device or device group.</Panel>
    }

    return (
        <div className={className} style={props.style || {}}>
            <TagMenu 
                services={props.services}
                onTagClick={props.updateSelectedTagIds}
                selectedTagIds={props.selectedTagIds}
                tags={props.visibleTags}
                menuTitle={props.tagMenuTitle}
                showOnlyHighPrioTags={props.showOnlyHighPrioTags}
                toggleShowOnlyHighPrioTags={props.toggleShowOnlyHighPrioTags}
                className=" fill-parent"
                style={{height: "70%"}}
            />
            {props.visibleDimensions.length > 0 &&
                <DimensionMenu 
                    dimensions={props.visibleDimensions}
                    selectedDimensionIds={props.selectedDimensionIds} 
                    style={{minHeight: '150px', marginTop: '10px', height: "30%"}} 
                    onDimensionClick={props.updateSelectedDimensionIds}
                    menuTitle={props.dimensionMenuTitle}
                    className="fill-parent"
                />
            }
        </div>
    )
}
export default TagDimensionMenu


export const useLocalSettingShowOnlyHighPrioTagsState = () => {
    const [showOnlyHighPrioTags, setShowOnlyHighPrioTags] = useState(getLocalSettingShowOnlyHighPrioTags())
    const toggleShowOnlyHighPrioTagsProxy = (val) => {
        setShowOnlyHighPrioTags(toggleLocalSettingShowOnlyHighPrioTags(val))
    }

    return [showOnlyHighPrioTags, toggleShowOnlyHighPrioTagsProxy]
}

/**
 * 
 * Visible items are displayed to the user, but not necessarily selected.
 * Selected items are assumed to be both selected AND visible.
 * 
 * @param {*} tagType the type of tags to track state for 
 */
 export const useTagSelectionState = (tagType, selectedDeviceIds, selectedDeviceGroupId, showOnlyHighPrioTags, services) => {

    const [selectedTagIds, setSelectedTagIds] = useState([])//() => getTags(tagType))
    const [visibleTags, setVisibleTags] = useState([])
    const [selectedDimensionIds, setSelectedDimensionIds] = useState({})//() => getDimensions(tagType))
    const [visibleDimensions, setVisibleDimensions] = useState([])
    

    // Set the visible tags/dimensions when device selection changes.
    // Device IDs are stringified to account for shallow comparison.
    useEffect(() => {
        updateVisibleTagsAndDimensions()
    }, [JSON.stringify(selectedDeviceIds), selectedDeviceGroupId, showOnlyHighPrioTags])


    const updateSelectedDimensionIds = (newDimensionIdSelection, dimensionId, dimensionValue) => {

        setDimensionUrlParam(dimensionId, dimensionValue, tagType)
        setSelectedDimensionIds(newDimensionIdSelection)
    }

    const updateSelectedTagIds = (newSelectedTagIds, tagId) => {

        var visibleDimensionTemplates = getVisibleDimensionTemplates(newSelectedTagIds)
        var selectedDimensionIds = getVisibleSelectedDimensionIds(visibleDimensionTemplates)

        setTagUrlParam(tagId, tagType)
        setSelectedTagIds(newSelectedTagIds)
        setVisibleDimensions(visibleDimensionTemplates)
        setSelectedDimensionIds(selectedDimensionIds)
    }

    /**
     * Determines the visible tags/dimensions based upon current site/device/tag selections.
     * Visible tags are affected by the selected site/devices.
     * The selected tags are a subset of visible tags.
     * This should be called when circumstances arise that affect the state of visible tags (ie. site/device selection change).
     */
    const updateVisibleTagsAndDimensions = () => {

        const allSelectedTagIds = getTagsUrlParam(tagType)

        const visibleTags = getVisibleTagTemplates()
        const visibleSelectedTagIds = visibleTags.filter(x => allSelectedTagIds.includes(x["_id"])).map(x => x["_id"])

        const visibleDimensionTemplates = getVisibleDimensionTemplates(visibleSelectedTagIds)
        const visibleSelectedDimensionIds = getVisibleSelectedDimensionIds(visibleDimensionTemplates)

        setVisibleTags(visibleTags)
        setVisibleDimensions(visibleDimensionTemplates)
        setSelectedTagIds(visibleSelectedTagIds)
        setSelectedDimensionIds(visibleSelectedDimensionIds)
    }

    /**
     * Determines the selected dimension ids from a list of visible dimension templates
     * @param {Array[Object]} visibleDimensionTemplates all visible dimension templates
     * @returns list of dimension ids that are both selected and visible
     */
    const getVisibleSelectedDimensionIds = (visibleDimensionTemplates) => {

        const allSelectedDimensionIds = getDimensionsUrlParam(tagType)

        const visibleDimensionIds = visibleDimensionTemplates.map(x => x["_id"])
        const visibleSelectedDimensionIds = Object.fromEntries(
            Object.entries(allSelectedDimensionIds).filter(dimensionSelection => {
                let [dimensionId, _dimensionValues] = dimensionSelection
                return visibleDimensionIds.includes(Number(dimensionId))
            })
        )
        return visibleSelectedDimensionIds
    }

    /**
     * Determines visible dimensions based upon the selected tags.
     * @returns an array of dimenion templates that are currently visible.
     */
    const getVisibleDimensionTemplates = (selectedTagIds) => {

        const dimensions = services.assetFramework.getDimensions()
        const visibleTags = getVisibleTags()
        
        const visibleTagsWithDimensions = visibleTags.filter(x => x["dimensions"])
        const visibleSelectedTagsWithDimensions = visibleTagsWithDimensions.filter(x => selectedTagIds.includes(x["template_id"]))
        
        const visibleDimensionIds = _.uniq(visibleSelectedTagsWithDimensions.map(x => x["dimensions"]).flat())
        const visibleDimensionTemplates = dimensions.filter(x => visibleDimensionIds.includes(x["_id"]))

        return visibleDimensionTemplates
    }

    /**
     * @returns a sorted list of all visible tag templates based upon the current site and device selection
     */
    const getVisibleTagTemplates = () => {

        const tagTemplates = services.assetFramework.getTags()

        const visibleTags = getVisibleTags()
        const visibleTagIds = visibleTags.map(x => x['template_id'])

        var visibleTagTemplates = tagTemplates.filter(x => visibleTagIds.includes(x["_id"]))
        return _.sortBy(visibleTagTemplates, [(tag) => {
            let tagName = tag["name"]
            return tagName ? tagName.toUpperCase() : ""
        }])
    }

    /**
     * @returns a list of all visible tags based upon the current site and device selection.
     */
    const getVisibleTags = () => {
        
        let selectedVisibleDevices
        if (selectedDeviceGroupId) {
            const selectedDeviceGroup = services.assetFramework.getDeviceGroup(selectedDeviceGroupId)
            selectedVisibleDevices = services.assetFramework.getDeviceGroupDeviceIds(selectedDeviceGroup)
        }
        else selectedVisibleDevices = services.assetFramework.getDevices(undefined, selectedDeviceIds)

        if (tagType===TAG_TYPES.interval) selectedVisibleDevices = selectedVisibleDevices.filter(device => isDeviceSubscribedToIntervalTagService(device))

        // Determine unique tags from device selection
        var allSelectedDeviceTags = selectedVisibleDevices.map(x => x.tags).flat()
        if (tagType===TAG_TYPES.source) allSelectedDeviceTags = allSelectedDeviceTags.filter(tag => checkTagTypeIsSource(tag))

        var visibleDeviceTags = _.uniqBy(allSelectedDeviceTags, "template_id")   // TODO: template_id shouldn't be hardcoded in here

        // Filter to high prio devices if applicable
        if (showOnlyHighPrioTags) {
            visibleDeviceTags = visibleDeviceTags.filter(x => isHighPriorityTag(x))
        }
        
        return visibleDeviceTags
    }

    return [
        visibleTags,
        visibleDimensions,
        selectedTagIds,
        selectedDimensionIds,
        updateSelectedTagIds, updateSelectedDimensionIds
    ]
}