import { OpportunityRegister, Plant, RiskRegister, TemporalProjectPricingBlocks, TemporalProjectPricingPrices, TemporalProjectPricingTemplate, TemporalProjectPricingType } from "../../table_configuration/Project"
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from "../../../components/input/Text"
import { PalantirAutocomplete, PalantirBooleanSelector, PalantirDatePicker, PalantirSelector, } from "../../../components/input/SelectPicker"
import { useChangeLog } from "../../../hooks/changeLog"
import { useDBViewFormValidation } from "../../../hooks/databaseViewFormValidation"
import ViewPanel from "../ViewPanel"
import { generateUUID } from "../../../utils/databaseAppUtils"
import { CardHeaderWithMenu } from "../../../components/panelv2/Panel2"
import { OEM } from "../../table_configuration/Counterparty"
import { useState } from "react"
import { DialogProvider, useDialog } from "../../../components/dialog/DialogProvider"
import { DialogContentText } from "@mui/material"
import { PalantirBasicCRUDMenu } from "../../../components/menuv2/Menu"
import { Text, SegmentedControl, Badge, Alert } from "@mantine/core"
import { Counterparty } from "../../table_configuration/Entity"
import _ from 'lodash'
import { booleanOptions } from "../../table_configuration/CommonColumns"
import { BESSSpec, InverterSpec, ModuleSpec, TrackerSpec } from "../../table_configuration/EquipmentSpec"
import { formatDateDisplay } from "../../../utils/Dates"
import { AddFloaterButtonWithPrompt, DeleteFloaterButtonWithPrompt } from "../../../components/button/FloaterButtonWithPrompt"


const TextMaskNumber = makeTextMaskNumber({
    scale: 4
})

const makeBlockPriceRecord = (blockId, pricingTypeId, equipmentSpecId) => {
    const record = TemporalProjectPricingPrices.buildNewRecord()
    record[TemporalProjectPricingPrices.columnSchema.oid] = generateUUID()
    record[TemporalProjectPricingPrices.columnSchema.priceBlockId] = blockId
    record[TemporalProjectPricingPrices.columnSchema.pricingTypeId] = pricingTypeId
    record[TemporalProjectPricingPrices.columnSchema.equipmentSpecId] = equipmentSpecId
    return record
}


export default function ProjectPricingPanelProxy(props) {

    const projectPricingBlocks = props.data[TemporalProjectPricingBlocks.buildId()].filter(x => x[TemporalProjectPricingBlocks.columnSchema.projectId]===props.selectedPlantId)
    const projectPricingBlocksIds = projectPricingBlocks.map(x => x[TemporalProjectPricingBlocks.columnSchema.oid])
    const projectPricingPrices = props.data[TemporalProjectPricingPrices.buildId()].filter(x => projectPricingBlocksIds.includes(x[TemporalProjectPricingPrices.columnSchema.priceBlockId]))
    const projectPricingTypes = props.data[TemporalProjectPricingType.buildId()]

    const equipmentSpecMap = {
        [InverterSpec.tableName]: [InverterSpec, props.data[InverterSpec.buildId()]],
        [ModuleSpec.tableName]: [ModuleSpec, props.data[ModuleSpec.buildId()]],
        [TrackerSpec.tableName]: [TrackerSpec, props.data[TrackerSpec.buildId()]],
        [BESSSpec.tableName]: [BESSSpec, props.data[BESSSpec.buildId()]]
    }

    return (
        <DialogProvider>
        <ProjectPricingPanel
            initialPricingBlocksRecords={projectPricingBlocks}
            initialPricingPricesRecords={projectPricingPrices}
            projectPricingTypesRecords={projectPricingTypes}
            equipmentSpecMap={equipmentSpecMap}
            {...props}
        />
        </DialogProvider>
    )
}

function ProjectPricingPanel({initialPricingBlocksRecords, initialPricingPricesRecords, projectPricingTypesRecords, equipmentSpecMap, ...props}) {

    const [
        blockRecords, prepareBlockLog, updateBlockLog, addToBlockLog,
        deleteFromBlockLog, bulkOpOnBlockLog, mergeAndResetBlockLog, resetBlockLog, popFromBlockLog
    ] = useChangeLog(initialPricingBlocksRecords, TemporalProjectPricingBlocks)
    const [
        priceRecords, preparePriceLog, updatePriceLog, addToPriceLog,
        deleteFromPriceLog, bulkOpOnPriceLog, mergeAndResetPriceLog, resetPriceLog, popFromPriceLog
    ] = useChangeLog(initialPricingPricesRecords, TemporalProjectPricingPrices)
    const [isCreating, setIsCreating] = useState(false)
    
    const [selectedBlockId, setSelectedBlockId] = useState(null)
    const selectedBlock = selectedBlockId ? blockRecords.find(x => x[TemporalProjectPricingBlocks.columnSchema.oid]===selectedBlockId) : null

    const filteredBlockPrices = priceRecords.filter(x => x[TemporalProjectPricingPrices.columnSchema.priceBlockId]===selectedBlockId)

    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()
    const [openDialog, closeDialog] = useDialog();

    const makeBlockName = (date) => {
        const displayDate = (!isNaN(date) && date) ? formatDateDisplay(date) : null
        const name = displayDate ? `${props.selectedPlant[Plant.columnSchema.plantName]} - ${displayDate}` : null
        return name
    }

    const updateBlockLogProxy = (update) => updateBlockLog(selectedBlock, update)
    /* Functions to create a new block */
    const addToBlockLogProxy = () => {
        const newBlock = TemporalProjectPricingBlocks.buildNewRecord()
        const blockId = generateUUID()
        newBlock[TemporalProjectPricingBlocks.columnSchema.oid] = blockId
        newBlock[TemporalProjectPricingBlocks.columnSchema.projectId] = props.selectedPlantId
        // If there are existing block records, copy the record with the latest date field
        if (blockRecords.length > 0) {
            const latestBlock = _.maxBy(blockRecords, (x) => x[TemporalProjectPricingBlocks.columnSchema.date])
            const latestBlockId = latestBlock[TemporalProjectPricingBlocks.columnSchema.oid]
            addToPriceLogProxy(blockId, latestBlockId)
        }
        setSelectedBlockId(blockId)
        bulkOpOnBlockLog([{
            method: "clear"
        }, {
            method: "insert", changes: [newBlock]
        }])
    }
    const addToPriceLogProxy = (blockId, blockToCopyId) => {
        // Since the price records changelog has not yet been flushed, any uncommitted changes will be copied to the new records.
        // This isn't ideal behavior, but the likelihood if this happening is low and so are the consequences.
        // And to address this is not worht the effort now.
        const priceRecordsToCopy = priceRecords.filter(x => x[TemporalProjectPricingPrices.columnSchema.priceBlockId]===blockToCopyId)
        const newPriceRecords = priceRecordsToCopy.map(x => {
            return makeBlockPriceRecord(blockId, x[TemporalProjectPricingPrices.columnSchema.pricingTypeId], x[TemporalProjectPricingPrices.columnSchema.equipmentSpecId])
        })
        bulkOpOnPriceLog([{
            method: "clear"
        }, {
            method: "insert", changes: newPriceRecords
        }])
    }

    const createPricingBlock = () => {
        const create = () => {
            addToBlockLogProxy()
            resetErrors()
            setIsCreating(true)
        }
        if (isCreating) {
            window.alert("You may only add one at a time.")
            return
        }
        if (prepareBlockLog().length > 0 || preparePriceLog().length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    create()
                }
            });
        }
        else create()
    }

    /* Functions to select a new risk/opportunity */
    const selectPriceBlock = (record) => {
        const blockId = record[TemporalProjectPricingBlocks.columnSchema.oid]
        if (blockId===selectedBlockId) return
        tryPurgeChanges(prepareBlockLog(), preparePriceLog(), () => setSelectedBlockId(blockId))
    }
    const tryPurgeChanges = (log1, log2, callback) => {
        const purge = () => {
            if (callback) callback()
            resetBlockLog()
            resetPriceLog()
            resetErrors()
            setIsCreating(false)
        }
        if (log1.length > 0 || log2.length > 0) {
            openDialog({
                title: "You have unsaved edits.",
                body: <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>,
                onAccept: () => {
                    closeDialog()
                    purge()
                }
            });
        }
        else {
            purge()
        }
    }

    /* Functions to delete a risk/opportunity */
    const deleteBlock = (callback) => {
        openDialog({
            title: "Watch out!",
            body: <DialogContentText>Are you sure you want to delete the selected Price Block and associated prices?</DialogContentText>,
            onAccept: () => {
                closeDialog()
                setSelectedBlockId(null)
                deleteFromBlockLog(selectedBlock)
                deleteFromPriceLog(filteredBlockPrices)
                setTimeout(() => {
                    callback({
                        onFail: () => {
                            resetErrors()
                            setSelectedBlockId(selectedBlockId)
                            popFromBlockLog()
                            popFromPriceLog(0)
                        }
                    })
                }, 100)
            }
        });
    }

    return (
        <div className="flow-horizontal fill-parent" style={{overflow: "auto"}}>
            <PalantirBasicCRUDMenu
                title="Price Blocks"
                selectedId={selectedBlockId}
                records={_.sortBy(blockRecords, (x) => x[TemporalProjectPricingBlocks.columnSchema.date])}
                recordIdCol={TemporalProjectPricingBlocks.columnSchema.oid}
                recordNameCol={TemporalProjectPricingBlocks.columnSchema.name}
                onSelect={selectPriceBlock}
                onAdd={createPricingBlock}
                style={{marginRight: "20px", marginTop: "25px", width: "225px", flexShrink: 0}}
            />
            <ViewPanel
                services={props.services}
                submitUrl='api/precious/table/temporal_project_pricing'
                verifySubmit={(payload) => {
                    return verifyChangelogSubmission(
                        {
                            changeLog: payload.blockChangeLog,
                            checks: [{
                                checkColumn: TemporalProjectPricingBlocks.columnSchema.date,
                                checkFunction: "nullCheck",
                                errMessage: "Please enter a date.",
                                path: [
                                    {name: TemporalProjectPricingBlocks.buildId(), type: "static"},
                                    {name: TemporalProjectPricingBlocks.columnSchema.oid, type: "eval"}
                                ]
                            }]
                        }, {
                            changeLog: payload.blockPricesChangeLog,
                            checks: []
                        }
                    )
                }}
                onSubmitSuccess={(response, requestPayload) => {
                    props.handleUpdate(false)
                    resetErrors()
                    mergeAndResetBlockLog()
                    mergeAndResetPriceLog()
                    setIsCreating(false)
                }}
                showDelete={!isCreating && selectedBlockId}
                onDelete={deleteBlock}
                onSubmitError={null}
                buildSubmitPayload={() => {
                    return {
                        blockChangeLog: prepareBlockLog(),
                        blockPricesChangeLog: preparePriceLog()
                    }
                }}
                style={{minWidth: "440px"}}
            >
                {isCreating && <Alert style={{marginBottom: "10px"}}>The fields in this block were inherited from the most recent existing price block.</Alert>}
                {selectedBlock ?
                    <PricingBlock
                        key={selectedBlockId}
                        pricingBlock={selectedBlock}
                        makeBlockName={makeBlockName}

                        projectPricingTypesRecords={projectPricingTypesRecords}
                        priceRecords={filteredBlockPrices}
                        equipmentSpecMap={equipmentSpecMap}
                        
                        updatePricingBlock={updateBlockLogProxy}
                        updatePrice={updatePriceLog}
                        addPrice={(pricingTypeId) => {
                            const newPriceRecord = makeBlockPriceRecord(selectedBlockId, pricingTypeId, null)
                            addToPriceLog(newPriceRecord)
                        }}
                        deletePrice={deleteFromPriceLog}
                        errors={errors}
                        removeErrors={removeErrors}
                    /> :
                    <div className="flow-vertical" style={{textAlign: "center"}}>Please select a Pricing Block.</div>
                }
            </ViewPanel>
        </div>
    )
}

const PricingBlock = ({pricingBlock, updatePricingBlock, updatePrice, priceRecords, projectPricingTypesRecords, addPrice, deletePrice, makeBlockName, equipmentSpecMap, errors, removeErrors}) => {

    const blockErrorPath = [TemporalProjectPricingBlocks.buildId(), pricingBlock[TemporalProjectPricingBlocks.columnSchema.oid]]
    const blockError = errors.get(...blockErrorPath)
    const blockErrorProps = blockError ? {error: true, helperText: blockError.getMessage()} : {}

    return (
        <div className="form-instance2">
            <CardHeaderWithMenu
                label="Pricing Block"
            />
            <div className="body flow-vertical">
                <div className="flow-horizontal" style={{flexWrap: "wrap"}}>
                    <div className="flow-vertical" style={{paddingRight: "15px"}}>
                        <PalantirTextField
                            label="Block ID"
                            value={pricingBlock[TemporalProjectPricingBlocks.columnSchema.oid]}
                            disabled
                            style={{marginBottom: "15px", width: "220px"}}
                        />
                        <PalantirTextField
                            label="Block Name"
                            value={pricingBlock[TemporalProjectPricingBlocks.columnSchema.name]}
                            disabled
                            style={{marginBottom: "15px", width: "220px"}}
                        />
                    </div>
                    <div className="flow-vertical" style={{paddingRight: "15px"}}>
                        <PalantirTextField
                            label="Project ID"
                            value={pricingBlock[TemporalProjectPricingBlocks.columnSchema.projectId]}
                            disabled
                            style={{marginBottom: "15px", width: "220px"}}
                        />
                        <PalantirDatePicker
                            label="Date"
                            value={pricingBlock[TemporalProjectPricingBlocks.columnSchema.date]}
                            onChange={(date) => {
                                removeErrors(blockErrorPath)
                                updatePricingBlock({
                                    [TemporalProjectPricingBlocks.columnSchema.date]: date,
                                    [TemporalProjectPricingBlocks.columnSchema.name]: makeBlockName(date)
                                })
                            }}
                            style={{marginBottom: "15px", width: "220px"}}
                            InputProps={{...blockErrorProps}}
                        />
                    </div>
                    <PalantirDispatchedTextField
                        label="Notes"
                        value={pricingBlock[TemporalProjectPricingBlocks.columnSchema.notes]}
                        onChange={(x) => updatePricingBlock({[TemporalProjectPricingBlocks.columnSchema.notes]: x})}
                        multiline
                        rows={5}
                        variant="filled"
                        style={{marginBottom: "20px", flexGrow: 1, minWidth: "250px"}}
                    />
                </div>
                <Text size="lg" weight={500}>Prices</Text>
                <hr style={{width: "100%"}}></hr>
                {projectPricingTypesRecords.map(record => {
                    const pricingTypeId = record[TemporalProjectPricingType.columnSchema.oid]
                    const matchingPriceRecords = priceRecords.filter(x => x[TemporalProjectPricingPrices.columnSchema.pricingTypeId]===pricingTypeId)
                    return (
                        <PricingTypeSection key={pricingTypeId} pricingTypeRecord={record} matchingPriceRecords={matchingPriceRecords} equipmentSpecMap={equipmentSpecMap} updatePrice={updatePrice} addPrice={addPrice} deletePrice={deletePrice} />
                    )
                })}
            </div>
        </div>
    )
}

const PricingTypeSection = ({pricingTypeRecord, matchingPriceRecords, equipmentSpecMap, updatePrice, addPrice, deletePrice}) => {
    const id = pricingTypeRecord[TemporalProjectPricingType.columnSchema.oid]
    const name = pricingTypeRecord[TemporalProjectPricingType.columnSchema.pricingType]
    const units = pricingTypeRecord[TemporalProjectPricingType.columnSchema.units]

    const tableName = pricingTypeRecord[TemporalProjectPricingType.columnSchema.equipmentSpecTable]
    const hasEquipmentSpec = Boolean(tableName)

    return (
        <div className="flow-vertical">
            <div className="flow-horizontal" style={{alignItems: "baseline", marginBottom: "10px"}}>
                <Text weight={500}>{name}</Text>
                <Text size="xs" style={{marginLeft: "6px", marginRight: "8px"}}>({units})</Text>
                <AddFloaterButtonWithPrompt
                    onClick={() => addPrice(id)}
                    height={13}
                    width={13}
                />
            </div>
            {matchingPriceRecords.map(pricingRecord => {
                return (
                    <div className="flow-horizontal">
                        <DeleteFloaterButtonWithPrompt
                            onClick={() => deletePrice(pricingRecord)}
                            height={12}
                            width={12}
                        />
                        <PalantirTextField
                            label="Price"
                            value={pricingRecord[TemporalProjectPricingPrices.columnSchema.price]}
                            onChange={(x) => updatePrice(pricingRecord, {[TemporalProjectPricingPrices.columnSchema.price]: x})}
                            InputProps={{inputComponent: TextMaskNumber}}
                            style={{width: "100px", marginBottom: "10px"}}
                        />
                        {hasEquipmentSpec &&
                            <EquipmentSpecField
                                pricingRecord={pricingRecord}
                                tableName={tableName}
                                equipmentSpecMap={equipmentSpecMap}
                                updatePrice={updatePrice}
                            />
                        }
                    </div>
                )
            })}
        </div>
    )
}

const EquipmentSpecField = ({pricingRecord, tableName, equipmentSpecMap, updatePrice}) => {
    if (!(tableName in equipmentSpecMap)) return <Text size="xs">Invalid pricingType table name '{tableName}'</Text>
    const [specTable, specRecords] = equipmentSpecMap[tableName]
    const equipmentSpecId = pricingRecord[TemporalProjectPricingPrices.columnSchema.equipmentSpecId]
    const equipmentSpec = specRecords.find(x => x[specTable.getIdCol()]===equipmentSpecId)
    const equipmentSpecName = equipmentSpec ? `${equipmentSpec.manufacturer} - ${equipmentSpec.model}` : ""
    return (
        <PalantirAutocomplete
            label={specTable.displayNameSingular}
            value={{label: equipmentSpecName, id: equipmentSpecId}}
            options={specRecords.map(spec => {
                return {
                    label: `${spec.manufacturer} - ${spec.model}`,
                    id: spec[specTable.getIdCol()]
                }
            })}
            onUpdate={(value) => {                        
                updatePrice(pricingRecord, {[TemporalProjectPricingPrices.columnSchema.equipmentSpecId]: value.id})
            }}
            style={{marginLeft: "20px", marginBottom: "10px", maxWidth: "250px"}}
        />
    )
}