import React, {Component} from 'react'

import BaseChart from '../../../charts/BaseChart'
import DatePicker from '../../../date_picker/DateRangePicker'
import StandardDataProvider from '../../../data_provider/StandardDataProvider'

import { convertObjectToSeries, createYAxes } from '../../../../utils/Highcharts'
import { formatDate } from '../../../../utils/Dates'
import { startOfDay, endOfDay } from 'date-fns'
import Highcharts from 'highcharts'
import HC_more from 'highcharts/highcharts-more'
HC_more(Highcharts)

class RegulationTrackingContainer extends Component {

    constructor(props) {
        super()

        this.defaultOptions = {
            boost: {
                allowForce: false, 
                enabled: true
            }, 
            plotOptions: {
                series: {
                    marker: false,
                    step: 'left',
                },
            },
            legend: {
                floating: false,
                verticalAlign: 'top',
                align: 'right',
            },
        }

        this.yAxisUnits = "MW"

        this.state = {
            selectedStartDate: formatDate(startOfDay(new Date())),
            selectedEndDate: formatDate(endOfDay(new Date())),
            chartHeight: '500px'
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        // Reflow all charts on page if chart height changed
        // not sure how to identify specific chart
        if (this.state.chartHeight!==prevState.chartHeight) {
            Highcharts.charts.forEach(function(chart) { if (chart) {chart.reflow()}});
        }
    }

    render() {
        console.debug("Rendering regulation tracking chart")

        var RegulationTrackingDataProvider = <StandardDataProvider
                                                services={this.props.services}
                                                selectedSiteId={this.props.selectedSiteId}
                                                selectedStartDate={this.state.selectedStartDate}
                                                selectedEndDate={this.state.selectedEndDate}
                                                autoRefresh
                                                queryProps={["selectedSiteId", "selectedStartDate", "selectedEndDate"]}
                                                queryUrl={'/api/market/regulation/tracking'}
                                                render={this.constructHighchart.bind(this)}
                                            />

        return (
            <div className="section-panel flow-vertical" style={{marginBottom: "20px", minHeight: this.state.chartHeight, maxHeight: this.state.chartHeight}}>
                {/* Chart header */}
                <div className="section-header flow-horizontal flex-fixed">
                    <div className="title">Regulation Tracking</div>
                    <div class="fill-parent"></div>
                    <div onClick={() => this.resizePanel()}>Expand</div>
                </div>
                {/* Chart date picker */}
                <div className="flex-fixed">
                    <DatePicker 
                        services={this.props.services}
                        startDate={this.state.selectedStartDate}
                        endDate={this.state.selectedEndDate}
                        onValueChange={this.updateSelectedDates.bind(this)}
                    />
                </div>
                {/* Chart content */}
                {RegulationTrackingDataProvider}
            </div>
        )
    }

    resizePanel() {
        var newHeight;
        if (this.state.chartHeight==='500px') {
            newHeight = '1000px'
        }
        else {
            newHeight = '500px'
        }
        this.setState({
            chartHeight: newHeight
        })
    }

    updateSelectedDates(start, end) {
        console.log(start, end)
        this.setState({
            selectedStartDate: start,
            selectedEndDate: end
        })
    }

    constructHighchart(data, dataRequestId) {
        return (
            <BaseChart 
                services={this.props.services}
                provideChartComponents={true}
                data={{
                    series: this.createHighchartsSeries(data),
                    yAxes: createYAxes({title: "Power", units: this.yAxisUnits})
                }}
                dataRequestId={dataRequestId}
                timezone={this.props.services.assetFramework.getSiteTimezone(this.props.selectedSiteId)}
                chartOptions={this.defaultOptions} 
            />
        )
    }

    createHighchartsSeries(data) {

        var allHighchartsSeries = []
        for (let chunk of data) {

            let highchartsSeries = {}
            let label = chunk.label

            if (label==="boundaries") {
                Object.assign(highchartsSeries, {
                    data: chunk.data,
                    type: "arearange",
                    color: "#a2c0d9",
                    fillOpacity: 0.5,
                    boostThreshold: 6666666666, // Set unreasonably high because arearange series errors out when boosted, so this series should never be boosted
                })
            }
            else {
                let highchartsData = convertObjectToSeries(chunk.data)
            
                Object.assign(highchartsSeries, {
                    data: highchartsData,
                    boostThreshold: 5000,
                })
            }

            Object.assign(highchartsSeries, {
                name: label,
                tooltip: {
                    valueSuffix: ' ' + this.yAxisUnits
                }
            })
            
            allHighchartsSeries.push(highchartsSeries)
        }
        return allHighchartsSeries
    }

}

export default RegulationTrackingContainer