import {findTableObjectRecords} from '../../utils/databaseAppUtils'

// This doesn't really do anything as of now
export default class Table {

    constructor(schemaName, tableName, columnSchema, displayNameSingular, displayNamePlural, identifiers, dependencies=[], pkUidColumn=null) {

        this.schemaName = schemaName   // name of SQL schema table belongs to
        this.tableName = tableName   // name of SQL table

        // contains mappings to the SQL column names eg. {idCol: id_col}
        this.columnSchema = columnSchema

        this.displayNameSingular = displayNameSingular
        this.displayNamePlural = displayNamePlural

        // name of primary key column if the table contains a UUID PK
        // when a new record is created, a UUID will be generated and assigned to this column
        this.pkUidColumn = pkUidColumn
        // column names that uniquely identify records
        // update statements will build the SQL WHERE condition using thes columns
        this.identifiers = identifiers
        // list of tables this table is linked to
        // when this table is selected, all the dependency tables will also be fetched 
        this.dependencies = dependencies

        // whether this table is allowed to be viewed on a project-by-project basis
        this.allowProjectDisplay = true
    }

    buildTableProps() {
        throw new Error("Super class must override this method")
    }

    getIdCol() {
        return this.columnSchema.oid
    }

    getDependenciesList = () => {
        var selfDependencies = []
        for (let dep of (this.dependencies || {})) {
            let dependencies = dep.dependencies
            selfDependencies = selfDependencies.concat([dep, ...dependencies])
        }
        return selfDependencies.map(x => {
            return {
                schema: x.schemaName,
                table: x.tableName,
            }
        })
    }

    /**
     * Returns the referenced plant id given for the given record and necessary dependencies
     * 
     * Default assumes item has the plant id attribute
     * Can be overriden to use the data param to determine the associated plant id
     *  This is useful when the association is indirect, where the record references another record that contains the plant id attribute
     * 
     * @param {Object} record record to determine associated plant id
     * @param {Array[Object]} data optional param containing list of dependency data
     * @returns 
     */
    getReferencedPlantId(record, data) {
        return record.plant_id
    }

    /**
     * 
     * @param {*} requestType 
     * @param {*} param1 
     * @returns 
     */
    buildRequestBody(requestType, {updatedItem, originalItem, deletedItem, createdItem}) {
        switch (requestType) {
            case "delete":
                return {
                    schema: this.schemaName,
                    table: this.tableName,
                    deletedRecord: deletedItem,
                    identifiers: this.identifiers,
                }
            case "update":
                return {
                    schema: this.schemaName,
                    table: this.tableName,
                    originalRecord: originalItem,
                    newRecord: updatedItem,
                    identifiers: this.identifiers,
                }
            case "create":
                return {
                    schema: this.schemaName,
                    table: this.tableName,
                    createdRecord: createdItem,
                    pkUidColumn: this.pkUidColumn,
                    identifiers: this.identifiers,
                }
            default:
                return {}
        }
    }

    buildHelpPanel() {
        return (
            <div></div>
        )
    }

    buildNewRecord() {
        var colNames = Object.values(this.columnSchema)

        var newRecord = {}
        colNames.forEach(x => {
            newRecord[x] = null
        })
        return newRecord
    }

    // attach to class for convenience
    findTableObjectRecords = findTableObjectRecords

    buildId = () => {
        return `${this.schemaName}.${this.tableName}`
    }

}