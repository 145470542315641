import './Interconnection.css'
import React, { useState } from 'react'

import ViewPanel from '../../ViewPanel'
import { 
    PalantirSelector,
    PalantirDatePicker,
    PalantirBooleanSelector
} from '../../../../components/input/SelectPicker'
import { PalantirTextField, PalantirDispatchedTextField, makeTextMaskNumber } from '../../../../components/input/Text'
import { InterconnectionContract, InterconnectionCounterpartiesContract } from '../../../table_configuration/Contract'
//import { Interconnection } from '../../../table_configuration/Counterparty'
import { useChangeLog, injectedChangeLogIdCol } from '../../../../hooks/changeLog'
import { useDBViewFormValidation } from '../../../../hooks/databaseViewFormValidation'
import { AddFloaterButtonWithPrompt } from '../../../../components/button/FloaterButtonWithPrompt'
import ContractCounterparties from '../ContractCounterparties'
import ContractHeader from '../ContractHeader'
import ContractIdentification from '../ContractIdentification'
import { getContractCounterpartyData, validateCreateContractCounterparty, buildContractNameErrorConfig } from '../utils'
import { generateUUID, ErrorRND, ViewFormError } from '../../../../utils/databaseAppUtils'

import _ from 'lodash'
import { Plant } from '../../../table_configuration/Project'
import { Interconnection } from '../../../table_configuration/Counterparty'
import { booleanOptions } from '../../../table_configuration/CommonColumns'

const injectedCommercialOperationDateCurrent = "injectedCODCurrent"
const interconnectionIdCol = InterconnectionCounterpartiesContract.columnSchema.interconnectionId
const injectedInterconnectionName = "interconnectionName"

const TextMaskNumber = makeTextMaskNumber({
    min: 0
})


/**
 * Proxy component for InterconnectionPanel.
 * @param {*} props 
 * @returns 
 */
export default function InterconnectionPanelProxy(props) {

    const contractRecords = props.data[InterconnectionContract.buildId()]
    const contractCounterpartyRecords = props.data[InterconnectionCounterpartiesContract.buildId()]
    const interconnectionRecords = props.data[Interconnection.buildId()]

    const [filteredToPlantContractRecords, filteredContractCounterpartyRecords] = getContractCounterpartyData(
        contractRecords, contractCounterpartyRecords, interconnectionRecords,
        props.selectedPlantId,
        InterconnectionContract.columnSchema.contractId, InterconnectionContract.columnSchema.plantId,
        InterconnectionCounterpartiesContract.columnSchema.contractId, InterconnectionCounterpartiesContract.columnSchema.interconnectionId, injectedInterconnectionName,
    )

    return (
        <InterconnectionPanel
            interconnectionContractRecords={filteredToPlantContractRecords}
            interconnectionRecords={interconnectionRecords}
            interconnectionContractCounterpartyRecords={filteredContractCounterpartyRecords}
            validateCreateContractCounterparty={(record, newRecord) => validateCreateContractCounterparty(InterconnectionCounterpartiesContract, InterconnectionCounterpartiesContract.columnSchema.interconnectionId, record, newRecord)}
            {...props}
        />
    )

}

/**
 * Main stateful InterconnectionPanel component.
 * @param {*} props 
 * @returns 
 */
function InterconnectionPanel(props) {

    const [
        interconnectionContractRecords, prepareContractLog,
        updateContractLog, addToContractLog, deleteFromContractLog, bulkOpOnContractLog, mergeAndResetContractLog
    ] = useChangeLog(props.interconnectionContractRecords, InterconnectionContract)
    const [
        interconnectionContractCounterpartiesRecords, prepareCounterpartyLog,
        updateCounterpartyLog, addToCounterpartyLog, deleteFromCounterpartyLog, bulkOpOnCounterpartyLog, mergeAndResetCounterpartyLog
    ] = useChangeLog(props.interconnectionContractCounterpartyRecords, InterconnectionCounterpartiesContract, props.validateCreateContractCounterparty)
    const [errors, addErrors, removeErrors, setErrors, verifyChangelogSubmission, resetErrors] = useDBViewFormValidation()

    const addToContractLogProxy = () => {
        let contract = InterconnectionContract.buildNewRecord()
        contract[InterconnectionContract.columnSchema.contractId] = generateUUID()
        contract[InterconnectionContract.columnSchema.plantId] = props.selectedPlantId
        addToContractLog(contract)
    }
    const deleteFromContractLogProxy = (interconnectionContract) => {
        // Delete all counterparty records associated with this contract, if any
        let contractId = interconnectionContract[InterconnectionContract.columnSchema.contractId]
        let counterpartiesInDeletedContract = interconnectionContractCounterpartiesRecords.filter(record => record[InterconnectionCounterpartiesContract.columnSchema.contractId]===contractId)
        deleteFromCounterpartyLog(counterpartiesInDeletedContract)
        deleteFromContractLog(interconnectionContract)
        removeErrors([contractId])
    }

    const interconnectionContractComponents = interconnectionContractRecords.map(interconnectionContract => {
        let contractId = interconnectionContract[InterconnectionContract.columnSchema.contractId]
        let interconnectionCounterparties = interconnectionContractCounterpartiesRecords.filter(x => x[InterconnectionCounterpartiesContract.columnSchema.contractId]===contractId)
        return (
            <InterconnectionContractForm
                key={contractId}
                interconnectionContract={interconnectionContract}
                contractId={contractId}
                allInterconnectionCounterparties={props.interconnectionRecords}
                interconnectionCounterparties={interconnectionCounterparties}
                updateContract={(update) => updateContractLog(interconnectionContract, update)}
                updateContractCounterparty={updateCounterpartyLog}
                addToCounterpartyLog={() => {
                    let contractCounterparty = InterconnectionCounterpartiesContract.buildNewRecord()
                    contractCounterparty[InterconnectionCounterpartiesContract.columnSchema.contractId] = contractId
                    addToCounterpartyLog(contractCounterparty)
                }}
                deleteFromContractLog={() => deleteFromContractLogProxy(interconnectionContract)}
                deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                errors={errors}
                removeErrors={removeErrors}
            />
        )
    })

    return (
        <ViewPanel
            services={props.services}
            title="Interconnection Contract"
            submitUrl='api/precious/table/interconnection_contract'
            verifySubmit={(payload) => {
                return verifyChangelogSubmission(
                    {
                        changeLog: payload.interconnectionContractChangelog,
                        checks: [buildContractNameErrorConfig(InterconnectionContract)]
                    }, {
                        changeLog: payload.interconnectionContractCounterpartyChangelog,
                        checks: []
                    }
                )
            }}
            onSubmitSuccess={(response, requestPayload) => {
                props.handleUpdate(false)
                resetErrors()
                mergeAndResetContractLog()
                mergeAndResetCounterpartyLog()
            }}
            onSubmitError={null}
            buildSubmitPayload={() => {
                return {
                    interconnectionContractChangelog: prepareContractLog(),
                    interconnectionContractCounterpartyChangelog: prepareCounterpartyLog(),
                }
            }}
        >
            {interconnectionContractComponents}
            <AddFloaterButtonWithPrompt
                onClick={addToContractLogProxy}
                labelContent="Add new contract"
                height={18}
                width={18}
            />
        </ViewPanel>
    )
}

/**
 * 
 */
const InterconnectionContractForm = React.memo(function({
    interconnectionContract, contractId, allInterconnectionCounterparties, interconnectionCounterparties,
    updateContract, updateContractCounterparty, addToCounterpartyLog, deleteFromCounterpartyLog, deleteFromContractLog, errors, removeErrors
}) {
    
    return (
        <div className="field-group form-instance" style={{scroll: "auto", marginBottom: "100px", minWidth: "400px"}}>
            <ContractHeader
                contractName={interconnectionContract[InterconnectionContract.columnSchema.contractName]}
                deleteFromContractLog={deleteFromContractLog}
            />
            <ContractIdentification
                ContractTable={InterconnectionContract}
                contract={interconnectionContract}
                contractIdCol={InterconnectionContract.columnSchema.contractId}
                contractNameCol={InterconnectionContract.columnSchema.contractName}
                contractPlantIdCol={InterconnectionContract.columnSchema.plantId}
                onContractNameChange={(x) => updateContract({[InterconnectionContract.columnSchema.contractName]: x})}
                errors={errors}
                removeErrors={removeErrors}
            />
            <div style={{marginBottom: "30px"}}>
                <PalantirTextField
                    label="Sharepoint Contract Folder Link"
                    value={interconnectionContract[InterconnectionContract.columnSchema.sharepointLink]}
                    helperText="This should be the folder link, not the file."
                    onChange={(x) => updateContract({[InterconnectionContract.columnSchema.sharepointLink]: x})}
                />
                <div style={{marginTop: "4px"}}><a href={interconnectionContract[InterconnectionContract.columnSchema.sharepointLink]} target="_blank">Click here to visit Sharepoint link</a></div>
            </div>

            <div className="flow-horizontal horizontal-children-spacing vertical-children-spacing" style={{flexWrap: "wrap"}}>
                <div className="flow-vertical vertical-children-spacing">
                    <PalantirSelector 
                        label="Status"
                        value={interconnectionContract[InterconnectionContract.columnSchema.status]}
                        items={InterconnectionContract.options.status}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.status]: x})}
                    />
                    <PalantirDatePicker
                        label="Effective Date"
                        value={interconnectionContract[InterconnectionContract.columnSchema.effectiveDate]}
                        onChange={(date) => updateContract({[InterconnectionContract.columnSchema.effectiveDate]: date})}
                        TextFieldProps={{style: {marginRight: "20px"}}}
                    />
                    <PalantirDatePicker
                        label="Interconnection COD"
                        value={interconnectionContract[InterconnectionContract.columnSchema.interconnectionCOD]}
                        onChange={(date) => updateContract({[InterconnectionContract.columnSchema.interconnectionCOD]: date})}
                        TextFieldProps={{style: {marginRight: "20px"}}}
                    />
                    <PalantirDatePicker
                        label="Application COD"
                        value={interconnectionContract[InterconnectionContract.columnSchema.applicationCOD]}
                        onChange={(date) => updateContract({[InterconnectionContract.columnSchema.applicationCOD]: date})}
                        TextFieldProps={{style: {marginRight: "20px"}}}
                    />
                    <PalantirDatePicker
                        label="COD Cliff Date"
                        value={interconnectionContract[InterconnectionContract.columnSchema.CODCliffDate]}
                        onChange={(date) => updateContract({[InterconnectionContract.columnSchema.CODCliffDate]: date})}
                        TextFieldProps={{style: {marginRight: "20px"}}}
                    />
                    <PalantirDatePicker
                        label="Transmission Owner Completion Date"
                        value={interconnectionContract[InterconnectionContract.columnSchema.transmissionOwnerCompletionDate]}
                        onChange={(date) => updateContract({[InterconnectionContract.columnSchema.transmissionOwnerCompletionDate]: date})}
                        TextFieldProps={{style: {marginRight: "20px"}}}
                    />
                    
                </div>
                <div className="flow-vertical vertical-children-spacing">
                    <PalantirTextField
                        label="POI Location"
                        value={interconnectionContract[InterconnectionContract.columnSchema.poiLocation]}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.poiLocation]: x})}
                    />
                    <div>
                        <PalantirTextField
                            label="POI Lat"
                            value={interconnectionContract[InterconnectionContract.columnSchema.poiLatitude]}
                            onChange={(x) => updateContract({[InterconnectionContract.columnSchema.poiLatitude]: x})}
                            style={{maxWidth: "80px", marginRight: "10px"}}
                        />
                        <PalantirTextField
                            label="POI Long"
                            value={interconnectionContract[InterconnectionContract.columnSchema.poiLongitude]}
                            onChange={(x) => updateContract({[InterconnectionContract.columnSchema.poiLongitude]: x})}
                            style={{maxWidth: "80px"}}
                        />
                    </div>
                    <PalantirTextField
                        label="POI Max AC Injection (MW)"
                        value={interconnectionContract[InterconnectionContract.columnSchema.poiMaxACInjectionMW]}
                        InputProps={{
                            inputComponent: TextMaskNumber
                        }}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.poiMaxACInjectionMW]: x})}
                    />
                    <PalantirTextField
                        label="POI Deliverable Capacity (MW)"
                        value={interconnectionContract[InterconnectionContract.columnSchema.poiDeliverableCapacityMW]}
                        InputProps={{
                            inputComponent: TextMaskNumber
                        }}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.poiDeliverableCapacityMW]: x})}
                    />
                    <PalantirTextField
                        label="POI Grid Voltage (kV)"
                        value={interconnectionContract[InterconnectionContract.columnSchema.poiGridVoltage]}
                        InputProps={{
                            inputComponent: TextMaskNumber
                        }}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.poiGridVoltage]: x})}
                    />
                </div>
                <div className="flow-vertical vertical-children-spacing">
                    <PalantirDispatchedTextField
                        label="Gen Tie Length"
                        value={interconnectionContract[InterconnectionContract.columnSchema.genTieLength]}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.genTieLength]: x})}
                    />
                    <PalantirTextField
                        label="Power Factor Requirements"
                        value={interconnectionContract[InterconnectionContract.columnSchema.powerFactorRequirements]}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.powerFactorRequirements]: x})}
                    />
                    
                    <PalantirSelector 
                        label="Site Exclusivity Status"
                        value={interconnectionContract[InterconnectionContract.columnSchema.siteExclusivityStatus]}
                        items={InterconnectionContract.options.exclusivityStatus}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.siteExclusivityStatus]: x})}
                    />
                    <PalantirDispatchedTextField
                        label="Queue ID"
                        value={interconnectionContract[InterconnectionContract.columnSchema.queueId]}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.queueId]: x})}
                    />
                    <PalantirDispatchedTextField
                        label="ISO Accounting ID"
                        value={interconnectionContract[InterconnectionContract.columnSchema.ISOAccountingId]}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.ISOAccountingId]: x})}
                    />
                    <PalantirDispatchedTextField
                        label="ISO Application Submission ID"
                        value={interconnectionContract[InterconnectionContract.columnSchema.ISOApplicationSubmissionId]}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.ISOApplicationSubmissionId]: x})}
                    />
                </div>
                <div className="flow-vertical vertical-children-spacing">
                    <PalantirSelector 
                        label="Shared Facility Agreement Required"
                        value={interconnectionContract[InterconnectionContract.columnSchema.sharedFacilityAgreementRequired]}
                        items={booleanOptions}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.sharedFacilityAgreementRequired]: x})}
                    />
                    {interconnectionContract[InterconnectionContract.columnSchema.sharedFacilityAgreementRequired] &&
                        <PalantirDispatchedTextField
                            label="Shared Facility Notes"
                            value={interconnectionContract[InterconnectionContract.columnSchema.sharedFacilityNotes]}
                            multiline
                            rows={4}
                            variant="filled"
                            onChange={(x) => updateContract({[InterconnectionContract.columnSchema.sharedFacilityNotes]: x})}
                        />
                    }
                    <PalantirSelector 
                        label="Native or Attaining Balancing Authority"
                        value={interconnectionContract[InterconnectionContract.columnSchema.nativeOrAttainingBalancingAuth]}
                        items={InterconnectionContract.options.nativeOrAttainingBalancingAuth}
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.nativeOrAttainingBalancingAuth]: x})}
                        style={{width: "220px"}}
                    />
                    <PalantirDispatchedTextField
                        label="Notes"
                        value={interconnectionContract[InterconnectionContract.columnSchema.notes]}
                        multiline
                        rows={4}
                        variant="filled"
                        onChange={(x) => updateContract({[InterconnectionContract.columnSchema.notes]: x})}
                    />
                </div>
            </div>
            <ContractCounterparties
                ContractCounterpartyTable={InterconnectionCounterpartiesContract}
                contract={interconnectionContract}
                contractIdCol={InterconnectionContract.columnSchema.contractId}
                contractCounterpartyIdCol={InterconnectionCounterpartiesContract.columnSchema.interconnectionId}
                contractCounterpartyNameCol={injectedInterconnectionName}
                contractCounterpartyNotesCol={InterconnectionCounterpartiesContract.columnSchema.notes}
                allCounterpartyRecords={allInterconnectionCounterparties}
                filteredContractCounterpartyRecords={interconnectionCounterparties}
                deleteFromCounterpartyLog={deleteFromCounterpartyLog}
                addToCounterpartyLog={addToCounterpartyLog}
                updateContractCounterparty={updateContractCounterparty}
                orientation="horizontal"
                counterpartyType={"Interconnection"}
                counterpartyTypePlural={"Interconnections"}
                SupplementalComponent={({contractCounterpartyRecord}) => {
                    return (
                        <PalantirSelector
                            label="Relationship"
                            value={contractCounterpartyRecord[InterconnectionCounterpartiesContract.columnSchema.relationship]}
                            items={InterconnectionCounterpartiesContract.options.relationship}
                            onChange={(x) => updateContractCounterparty(contractCounterpartyRecord, {[InterconnectionCounterpartiesContract.columnSchema.relationship]: x})}
                        />
                    )
                }}
                errors={errors}
                removeErrors={removeErrors}
                style={{marginTop: "20px"}}
            />
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        _.isEqual(prevProps.interconnectionContract, nextProps.interconnectionContract) &&
        _.isEqual(prevProps.interconnectionCounterparties, nextProps.interconnectionCounterparties) &&
        _.isEqual(prevProps.errors, nextProps.errors)
    )
})