import React, { useEffect } from 'react'

import { PalantirAutocomplete } from '../../../components/input/SelectPicker'
import { PalantirTextField, makeTextMaskNumber } from '../../../components/input/Text';
import { timezones } from '../../../utils/timezones';
import { Plant } from '../../table_configuration/Project'

import axios from 'axios'

const plantSchema = Plant.columnSchema

const TextMaskZipcode = makeTextMaskNumber({
    min: 10000,
    max: 99999,
    scale: 0,
    thousandsSeparator: '',
})
const TextMaskLatitude = makeTextMaskNumber({
    min: -90,
    max: 90,
    scale: 8,
    normalizeZeros: false,
})
const TextMaskLongitude = makeTextMaskNumber({
    min: -180,
    max: 180,
    scale: 8,
    normalizeZeros: false,
})


const Address = React.memo(function(props) {

    useEffect(() => {
        if (!window.google) {
            console.error("Could not find google maps global object.")
        }
        else {
            var addressField = document.querySelector("#plant-address");
            var autocomplete = new window.google.maps.places.Autocomplete(addressField, {
                componentRestrictions: { country: ["us", "ca"] },
                fields: ["address_components", "geometry"],
                types: ["address"],
            });
    
            autocomplete.addListener("place_changed", (data) => {
                let place = autocomplete.getPlace()
                onAutoCompleteSelect(place)
            });
        }
    }, [])

    var onAutoCompleteSelect = (place) => {
        
        let addressComps = place.address_components || []
        let streetNumberComp = addressComps.find(x => x.types.includes("street_number")) || {}
        let streetAddressComp = addressComps.find(x => x.types.includes("route")) || {}
        let countryComp = addressComps.find(x => x.types.includes("country")) || {}
        let stateComp = addressComps.find(x => x.types.includes("administrative_area_level_1")) || {}
        let cityComp = addressComps.find(x => x.types.includes("locality")) || {}
        let countyComp = addressComps.find(x => x.types.includes("administrative_area_level_2")) || {}
        let zipcodeComp = addressComps.find(x => x.types.includes("postal_code")) || {}
        var latitude = place.geometry.location.lat()
        var longitude = place.geometry.location.lng()

        let streetNumber = streetNumberComp.long_name || ""
        let streetAddress = streetAddressComp.long_name || ""
        let streetAddressFull = `${streetNumber} ${streetAddress}`
        let country = countryComp.long_name || ""
        let state = stateComp.short_name || ""
        let city = cityComp.long_name || ""
        let county = countyComp.long_name || ""
        let zipcode = zipcodeComp.long_name || ""

        let update = {
            street_address: streetAddressFull,
            country: country,
            state: state,
            city: city,
            county: county,
            zipcode: zipcode,
            latitude: latitude,
            longitude: longitude,
        }

        updateTimezone(update)
    }

    var updateTimezone = (plantUpdate) => {

        axios({
            method: 'get',
            url: 'api/precious/gmap/timezone',
            params: {
                lat: plantUpdate.latitude,
                lng: plantUpdate.longitude
            }
        })
        .then(function (response) {
            props.updatePlant(Object.assign(plantUpdate, {
                timezone: response.data?.timeZoneId
            }))
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    return (
        <div id="plant-location" className="field-group" >
            <div className="header">Location</div>
            <PalantirTextField
                id="plant-address"
                label="Address"
                value={props.plantStreetAddress}
                onChange={(x) => props.updatePlant({[plantSchema.streetAddress]: x})}
                sx={{width: "450px"}}
            />
            <PalantirTextField
                label="Country"
                value={props.plantCountry}
                onChange={(x) => props.updatePlant({[plantSchema.country]: x})}
            />
            <PalantirTextField
                label="State"
                value={props.plantState}
                onChange={(x) => props.updatePlant({[plantSchema.state]: x})}
            />
            <PalantirTextField
                label="Region"
                value={props.plantRegion}
                onChange={(x) => props.updatePlant({[plantSchema.region]: x})}
            />
            <PalantirTextField
                label="City"
                value={props.plantCity}
                onChange={(x) => props.updatePlant({[plantSchema.city]: x})}
            />
            <PalantirTextField
                label="County"
                value={props.plantCounty}
                onChange={(x) => props.updatePlant({[plantSchema.county]: x})}
            />
            <PalantirTextField
                label="Zipcode"
                value={props.plantZipcode}
                InputProps={{                    
                    inputComponent: TextMaskZipcode
                }}
                onChange={(x) => props.updatePlant({[plantSchema.zipcode]: x})}
            />
            <PalantirTextField
                label="Latitude"
                value={props.plantLatitude}
                InputProps={{
                    inputComponent: TextMaskLatitude
                }}
                onChange={(x) => props.updatePlant({[plantSchema.latitude]: x})}
            />
            <PalantirTextField
                label="Longitude"
                value={props.plantLongitude}
                InputProps={{
                    inputComponent: TextMaskLongitude
                }}
                onChange={(x) => props.updatePlant({[plantSchema.longitude]: x})}
            />
            <PalantirAutocomplete
                label="Timezone"
                value={{label: props.plantTimezone, id: props.plantTimezone }}
                options={timezones.map((x) => {return {label: x, id: x}})}
                onUpdate={(x) => props.updatePlant({[plantSchema.timezone]: x.id})}
                sx={{width: 450, display: "inline-block"}}
            />
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        prevProps.plantStreetAddress===nextProps.plantStreetAddress &&
        prevProps.plantCountry===nextProps.plantCountry &&
        prevProps.plantRegion===nextProps.plantRegion &&
        prevProps.plantState===nextProps.plantState &&
        prevProps.plantCounty===nextProps.plantCounty &&
        prevProps.plantCity===nextProps.plantCity &&
        prevProps.plantZipcode===nextProps.plantZipcode &&
        prevProps.plantLatitude===nextProps.plantLatitude &&
        prevProps.plantLongitude===nextProps.plantLongitude &&
        prevProps.plantTimezone===nextProps.plantTimezone
    )
})

export default Address



export const buildAddress = (street, city, state, zipcode) => {

    var replaceF = (x) => x.replaceAll(" ", "+")
    var street = replaceF(street || "")
    var city = replaceF(city || "")

    return `${street},${city},${state}+${zipcode}`
}

