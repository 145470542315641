import './AssetMenu.css'
import React, { Component, useState } from 'react'

import MenuContainer from '../menu/MenuContainer'
import { getPortfolio, setPortfolio, getSite, setSite } from '../../utils/UrlLocation'


export const useAssetSelectionState = () => {

    const [selectedPortfolioId, setSelectedPortfolioId] = useState(getPortfolio)
    const [selectedSiteId, setSelectedSiteId] = useState(getSite)

    const updateSiteId = (newSiteId) => {
        setPortfolio(null)
        setSite(newSiteId)
        setSelectedSiteId(newSiteId)
        setSelectedPortfolioId(null)
    }
    const updatePortfolioId = (newPortfolioId) => {
        setPortfolio(newPortfolioId)
        setSite(null)
        setSelectedPortfolioId(newPortfolioId)
        setSelectedSiteId(null)
    }

    return [
        selectedPortfolioId, selectedSiteId,
        updatePortfolioId, updateSiteId
    ]
}

class AssetSidebar extends Component {

    constructor(props) {
        super()
        this.state = {

        }
        this.className = "asset-menu "
    }

    shouldComponentUpdate(prevProps, prevState) {
        if (prevProps.selectedSiteId !== this.props.selectedSiteId) return true
        if (prevProps.selectedPortfolioId !== this.props.selectedPortfolioId) return true
        return false
    }

    render() {
        return (
            <MenuContainer 
                menuSkeleton={this.constructAssetHeirarchy()}
                headerName={"Asset Menu"}
                leafNodeClick={(siteNode) => this.props.updateSelectedSite(siteNode.meta._id)}
                parentNodeClick={(portfolioNode) => this.props.updateSelectedPortfolio(portfolioNode.meta._id)}
                className={this.className + (this.props.className || "")}
                searchable
                {...this.props.menuProps}
            />
        )
    }

    constructAssetHeirarchy() {

        var selectedPortfolioId = this.props.selectedPortfolioId
        var selectedSiteId = this.props.selectedSiteId

        var portfolios = this.props.services.assetFramework.getPortfolios()
        var sites = this.props.services.assetFramework.getSites()
        sites = sites.sort(function(a, b) {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
        })

        var portfolioHeirarchy = []
        
        portfolios.forEach(function(portfolio) {
            let portfolioId = portfolio._id
            let portfolioName = portfolio.id
            let portfolioNameLong = portfolio.name

            let portfolioSites = sites.filter(x => x.portfolio_id===portfolioId).map(function(site) {
                return {
                    "key": site._id,
                    "label": site.id + " - " + site.name,
                    "meta": site,
                    "selectable": true,
                    "active": site._id===selectedSiteId,
                    "children": null,
                }
            })
            let portfolioTree = {
                "key": portfolioId,
                "label": portfolioName + " - " + portfolioNameLong,
                "meta": portfolio,
                "selectable": true,
                "active": portfolioId===selectedPortfolioId,
                "children": portfolioSites,
            }
            portfolioHeirarchy.push(portfolioTree)
        })

        return portfolioHeirarchy
    }

}

export default AssetSidebar