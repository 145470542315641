import Button from '@mui/material/Button';

export function PalantirButton({onClick, ...props}) {
    return (
        <Button
            variant="contained"
            color="success"
            onClick={onClick}
            {...props}
        >
            {props.children}
        </Button>
    );
}