import {Component} from 'react'

import TemplateConfigTable from './EventTemplateConfigTable'
import SectionHeader from '../../../section/SectionHeader'
import {HelpEventTemplateConfiguration} from '../../../help/descriptions/tabs/Configuration'
import HelpPanel from '../../../help/HelpPanel'

class EventTemplateConfiguration extends Component {

    constructor(props) {
        super()
        this.state = {
            
        }
    }

    render() {

        const Tab = <HelpPanel
                        services={this.props.services}
                        render={(newServices) => {
                            return (
                                <div className="section-main flow-vertical fill-parent">
                                    <SectionHeader services={newServices} sectionTitle="Event Template Configuration" sectionDescription={<HelpEventTemplateConfiguration />} />
                                    <TemplateConfigTable services={newServices} selectedSiteId={this.props.selectedSiteId} />
                                </div>
                            )
                        }}
                    />

        return Tab
        
    }

}

export default EventTemplateConfiguration