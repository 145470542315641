import React, {Component} from 'react'

import DatePicker from '../../../date_picker/DateRangePicker'
import StandardDataProvider from '../../../data_provider/StandardDataProvider'
import RegulationSetpointAdherence from './RegulationSetpointAdherence'

import { formatDate } from '../../../../utils/Dates'
import { startOfDay, endOfDay } from 'date-fns'
import Highcharts from 'highcharts'
import HC_more from 'highcharts/highcharts-more'
HC_more(Highcharts)

class RegulationSetpointAdherenceContainer extends Component {

    constructor(props) {
        super()

        this.state = {
            selectedStartDate: formatDate(startOfDay(new Date())),
            selectedEndDate: formatDate(endOfDay(new Date())),
        }
        
    }

    render() {
        var siteTimezone = this.props.services.assetFramework.getSiteTimezone(this.props.selectedSiteId)

        return (
            <div className="section-panel flow-vertical fill-parent">
                <div className="section-header flow-horizontal flex-fixed">
                    <div className="title">Regulation Setpoint Adherence Tracking</div>
                    <div class="fill-parent"></div>
                    <div className="flex-fixed">
                        <DatePicker 
                            services={this.props.services}
                            startDate={this.state.selectedStartDate}
                            endDate={this.state.selectedEndDate}
                            onValueChange={this.updateSelectedDates}
                            containerProps={{
                                placement: "bottomEnd"
                            }}
                        />
                    </div>
                </div>                
                <StandardDataProvider
                    services={this.props.services}
                    selectedSiteId={this.props.selectedSiteId}
                    selectedStartDate={this.state.selectedStartDate}
                    selectedEndDate={this.state.selectedEndDate}
                    queryProps={["selectedSiteId", "selectedStartDate", "selectedEndDate"]}///
                    queryUrl={'/api/market/regulation/rsat_summary'}
                    render={(data) => {
                        return <RegulationSetpointAdherence services={this.props.services} data={data} siteTimezone={siteTimezone} />
                    }}
                />
            </div>
        )
    }

    updateSelectedDates = (start, end) => {
        this.setState({
            selectedStartDate: start,
            selectedEndDate: end
        })
    }

}

export default RegulationSetpointAdherenceContainer