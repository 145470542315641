import React, {Component} from 'react'

import BaseChart from '../../../charts/BaseChart'
import StandardDataProvider from '../../../data_provider/StandardDataProvider'
import DatePicker from '../../../date_picker/DateRangePicker'

import { createBasicSeries, createYAxes } from '../../../../utils/Highcharts'
import { formatDate } from '../../../../utils/Dates'
import { startOfDay, endOfDay } from 'date-fns'
import Highcharts from 'highcharts'
import ChartOptionButton from '../../../button/ChartOptionButton'
import {AiOutlineQuestionCircle} from 'react-icons/ai'
import {Whisper, Toggle, Tooltip} from 'rsuite'

class RegulationUsageContainer extends Component {

    constructor(props) {
        super()

        this.dataLabelEnum = {
            regUpMegawatt: "reg_up_usage",
            regDownMegawatt: "reg_down_usage",
            regUpPercentage: "reg_up_percentage",
            regDownPecentage: "reg_down_percentage",
            regUpPrice: "reg_up_price",
            regDownPrice: "reg_down_price",
        }

        this.megawattLabel = "megawatt"
        this.percentageLabel = "percentage"

        this.state = {
            dataUnits : this.megawattLabel,
            displayHourlySummary: false,
            displayPrices: false,
            selectedStartDate: formatDate(startOfDay(new Date())),
            selectedEndDate: formatDate(endOfDay(new Date())),
            chartHeight: '500px',
            renderId: 0,
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.chartHeight!==prevState.chartHeight) {
            Highcharts.charts.forEach(function(chart) { if (chart) {chart.reflow()}});
        }
    }

    render() {
        console.log("Rendering regulation usage container")

        const tooltip = <Tooltip>
                            <p style={{textAlign: 'left'}}>These intervals are hours <i>beginning</i>.</p>
                        </Tooltip>

        var RegulationUsageDataProvider = <StandardDataProvider
                                            services={this.props.services}
                                            selectedSiteId={this.props.selectedSiteId}
                                            selectedStartDate={this.state.selectedStartDate}
                                            selectedEndDate={this.state.selectedEndDate}
                                            queryProps={["selectedSiteId", "selectedStartDate", "selectedEndDate"]}
                                            queryUrl={'/api/market/regulation/usage'}
                                            render={this.constructHighchart.bind(this)}
                                        />

        return (
            <div className="section-panel flow-vertical regulation-usage-container" style={{minHeight: this.state.chartHeight, maxHeight: this.state.chartHeight, marginBottom: "20px"}}>
                <div className="section-header flow-horizontal flex-fixed">
                    <div className="title" style={{marginRight: '5px'}}>Regulation Usage</div>
                    <Whisper placement="rightStart" trigger="hover" speaker={tooltip}>
                        <AiOutlineQuestionCircle size={'26px'} />
                    </Whisper>
                    <div className="fill-parent"></div>
                    <ChartOptionButton onClick={(x) => this.updateDataUnits(x)} active={this.state.dataUnits===this.megawattLabel} style={{marginRight: '5px'}}>{this.megawattLabel}</ChartOptionButton>
                    <ChartOptionButton onClick={(x) => this.updateDataUnits(x)} active={this.state.dataUnits===this.percentageLabel} style={{marginRight: '15px'}}>{this.percentageLabel}</ChartOptionButton>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '15px'}}>
                        <span style={{marginRight: '5px'}}>1x24</span>
                        <Toggle onChange={() => this.toggleDisplayHourlySummary()} checked={this.state.displayHourlySummary} style={{}} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '15px'}}>
                        <span style={{marginRight: '5px'}}>Prices</span>
                        <Toggle onChange={() => this.toggleDisplayPrices()} checked={this.state.displayPrices} style={{}} />
                    </div>
                    <div class="chart-resizer" onClick={() => this.resizePanel()}>Expand</div>
                </div>
                <div className="flex-fixed">
                    <DatePicker 
                        services={this.props.services}
                        startDate={this.state.selectedStartDate}
                        endDate={this.state.selectedEndDate}
                        onValueChange={this.updateSelectedDates.bind(this)}
                    />
                </div>
                {RegulationUsageDataProvider}
            </div>
        )
    }

    resizePanel() {
        var newHeight;
        if (this.state.chartHeight==='500px') {
            newHeight = '800px'
        }
        else {
            newHeight = '500px'
        }
        this.setState({
            chartHeight: newHeight
        })
    }

    toggleDisplayPrices() {
        this.setState({
            displayPrices: !this.state.displayPrices
        })
    }

    toggleDisplayHourlySummary() {
        this.setState({
            displayHourlySummary: !this.state.displayHourlySummary
        })
    }

    updateDataUnits(units) {
        console.log(units)
        this.setState({
            dataUnits: units
        })
    }

    updateSelectedDates(start, end) {
        this.setState({
            selectedStartDate: start,
            selectedEndDate: end
        })
    }

    constructHighchart(data, dataRequestId) {
        var [chartSeries, chartAxes] = this.createHighchartsSeriesAndAxes(data)

        return (
            <BaseChart
                services={this.props.services}
                timezone={this.props.services.assetFramework.getSiteTimezone(this.props.selectedSiteId)}
                provideChartComponents={true}
                data={{
                    series: chartSeries,
                    yAxes: chartAxes
                }}
                dataRequestId={dataRequestId}
                compareData={true}
                chartOptions={{
                    xAxis: {
                        type: (this.state.displayHourlySummary ? "linear" : "datetime")
                    },
                    plotOptions: {
                        line: {
                            dashStyle: 'Dot',
                        }
                    },
                    legend: {
                        floating: false,
                        verticalAlign: 'top',
                        align: 'right',
                    },
                }} 
            />
        )
    }

    createHighchartsSeriesAndAxes(rawData) {

        var chartAxes = []
        var chartSeries = []

        var axisUnits;
        var axisTitle;
        var regUpData;
        var regDownData;

        var data;

        if (this.state.displayHourlySummary) {
            data = rawData["hourly_mean"]
            console.log(data)
        }
        else {
            data = rawData["raw"]
        }

        // Set the reg up/down data according to the data type selected
        if (this.state.dataUnits===this.megawattLabel) {
            axisUnits = "MW"
            axisTitle = "Power"
            regUpData = data[this.dataLabelEnum.regUpMegawatt]
            regDownData = data[this.dataLabelEnum.regDownMegawatt]
        }
        else if (this.state.dataUnits===this.percentageLabel) {
            axisUnits = "%"
            axisTitle = "Percentage"
            regUpData = data[this.dataLabelEnum.regUpPercentage]
            regDownData = data[this.dataLabelEnum.regDownPecentage]
        }

        // Create appropriate series and axis for the reg data
        var regUpSeries = createBasicSeries({data: regUpData, label: "Reg Up", type: "column", color: "blue", axisUnits: axisUnits})
        var regDownSeries = createBasicSeries({data: regDownData, label: "Reg Down", type: "column", color: "red", axisUnits: axisUnits})
        chartSeries.push(...[regUpSeries, regDownSeries])
        
        var yAxis = createYAxes({title: axisTitle, units: axisUnits})
        chartAxes.push(yAxis)

        // Configure chart to display pricing data if option is selected
        if (this.state.displayPrices) {
            var priceAxisUnits = "$";
            var regUpPriceData = data[this.dataLabelEnum.regUpPrice]
            var regDownPriceData = data[this.dataLabelEnum.regDownPrice]
            var regUpPriceSeries = createBasicSeries({data: regUpPriceData, label: "Reg Up Price", type: "line", color: "blue", axisUnits: priceAxisUnits, yAxisIndex: 1})
            var regDownPriceSeries = createBasicSeries({data: regDownPriceData, label: "Reg Down Price", type: "line", color: "red", axisUnits: priceAxisUnits, yAxisIndex: 1})
            chartSeries.push(...[regUpPriceSeries, regDownPriceSeries])
            var priceYAxis = createYAxes({title: "Price", units: priceAxisUnits}, true)
            chartAxes.push(priceYAxis)
        }

        return [chartSeries, chartAxes]
    }

}

export default RegulationUsageContainer