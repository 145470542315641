import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import axios from 'axios'

import AppSelector from './components/navigation/AppSelector';
import AppAuthHandler from './components/authentication/AuthHandler'


if (process.env.NODE_ENV!=="development") {
    let proxy = process.env.REACT_APP_SERVER_PROXY
    if (!proxy) throw new Error("REACT_APP_SERVER_PROXY must be specified when creating non-development build.")
    axios.defaults.baseURL = proxy
}

ReactDOM.render( 
    <React.StrictMode>
        <AppAuthHandler
            services={{}}
            render={(propsWithAuthServices) => <AppSelector {...propsWithAuthServices} />}
        />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();